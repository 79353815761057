import React, { Component } from "react";
import { View } from "react-native";
import { Content, Card, CardItem, Button, H3, Text } from "native-base";
import { connect } from "react-redux";
import { promoteToLead, demoteToVolunteer, disconnectGroup } from "./actions";
import VolunteerLeadDetails from "../../components/VolunteerLeadDetails";
import Header from "./details/header";
import style from "./details/style";
import styles from "../../styles";
import { Screen } from "../../utils";
import I18n from "../../i18n";

const ConnectHeader = connect(state => ({
  group: state.groups.group,
  user: state.groups.user
}))(props => (
  <Header title={I18n.t(`groups.${props.user.role}details.title`)} {...props} />
));

export default Screen(
  connect(state => ({
    currentUser: state.auth.currentUser,
    user: state.groups.user,
    group: state.groups.group,
    role: state.groups.user.role,
    isAdmin: state.groups.user.isAdmin,
    promote: () => promoteToLead(state.groups.group, state.groups.user),
    demote: () => demoteToVolunteer(state.groups.group, state.groups.user)
  }))(
    class extends Component {
      disconnect = async () => {
        await disconnectGroup(this.props.group, this.props.user);
        this.props.navigation.goBack();
      };

      render() {
        return (
          <Content container>
            <View style={style.padding}>
              <VolunteerLeadDetails
                {...this.props}
                disconnect={this.disconnect}
              />
            </View>
          </Content>
        );
      }
    }
  ),
  props => <ConnectHeader {...props} />
);
