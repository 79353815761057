import React, { Component } from "react";
import {
  View,
  Image,
  StyleSheet,
  Alert,
  Platform,
  TouchableOpacity
} from "react-native";
import {
  Content,
  Item,
  Label,
  Input,
  Picker,
  Form,
  Button,
  Text,
  Icon,
  Header,
  Left,
  Body,
  Title,
  Right,
  ActionSheet,
  H3
} from "native-base";
import { Screen } from "../../utils";
import styles from "../../styles";
import variable from "../../../native-base-theme/variables/platform";
import I18n from "../../i18n";
import MapView, { Marker } from "react-native-maps";
import Modal from "react-native-modal";
import DatePicker from "react-native-datepicker";
import moment from "moment";
import MapPicker from "../../components/MapPicker";
import ContactPicker from "../../components/ContactPicker";
import { connect } from "react-redux";
import { NavigationActions } from "react-navigation";
import {
  setTask,
  getCategories,
  getContacts,
  getAddressByCoordinates,
  getSuggestionsByAddress,
  removeAutoSuggests
} from "./actions";
import ValidatedInput from "../../components/ValidatedInput";
import ConnectHeader from "../../components/ConnectHeader";
import CustomPickerHeader from "../../components/CustomPickerHeader";
import AutoSuggestContainer from "./components/autosuggest";
import Immersive from "react-native-immersive";
import _ from "lodash";

const ConnectedHeader = connect(state => ({
  taskDetails: state.task.taskDetails.task,
  mode: state.task.taskDetails.mode,
  currentUser: state.auth.currentUser
}))(props => {
  if (props.mode === "add") {
    return <ConnectHeader title={I18n.t("screenHeader.addTask")} {...props} />;
  } else {
    return (
      <ConnectHeader
        title={I18n.t("screenHeader.editTask")}
        subtitle={props.taskDetails.name}
        {...props}
      />
    );
  }
});

export default Screen(
  connect(state => ({
    taskDetails: state.task.taskDetails.task,
    groups: state.groups.managed,
    contacts: state.task.taskDetails.contacts,
    currentUser: state.auth.currentUser,
    locationResults: state.task.taskDetails.locationResults,
    mode: state.task.taskDetails.mode
  }))(
    class AddTask extends Component {
      getSuggestionsByAddress = _.debounce(getSuggestionsByAddress, 1000);
      _working = false;

      state = {
        validationErrors: [],
        mapLoading: false,
        modalVisible: false,
        contactModalVisible: false,
        submitted: false,
        openedMap: null,
        activeInput: null,
        groupPosition: null,
        startDatePosition: null,
        endDatePosition: null,
        startLocationPosition: null,
        titlePositon: null,
        volunteerPosition: null,
        descriptionPosition: null,
        tempRegion: {},

        tempGroups: this.generateTempGroup(this.props.groups),
        ...this.props.taskDetails,
        group:
          this.props.groups.length === 1
            ? { ...this.props.groups[0] }
            : this.props.taskDetails.group,
        startdate: moment(
          this.props.taskDetails.startdate || new Date()
        ).toDate(),
        owner: {
          name:
            this.props.currentUser.firstname +
            " " +
            this.props.currentUser.lastname,
          id: this.props.currentUser.id,
          phone: this.props.currentUser.phone
        }
      };

      async componentDidMount() {
        if (this.props.mode !== "add" || this.props.groups.length === 1) {
          await getContacts(this.state.group.id);
        }
      }

      generateTempGroup(group) {
        const result = group.reduce((prev, next) => {
          if (next.role !== "volunteer") {
            return [...prev, next.name];
          } else {
            return [...prev];
          }
        }, []);

        return result;
      }

      handleGroupChange = async value => {
        if (value) {
          const filteredGroup = this.props.groups.find(
            group => group.name === value
          );
          this.setState({
            group: filteredGroup
          });
          await getContacts(filteredGroup.id);
        }
      };

      handlePickerChange = (field, value) => {
        this.setState({
          [field]: value
        });
      };

      openContactModal = async () => {
        if (Platform.OS === "android") {
          Immersive.off();
        }
        if (this.state.group && this.state.group.id) {
          await getContacts(this.state.group.id);
          this.setState({
            contactModalVisible: true
          });
        }
      };

      closeContactModal = () => {
        if (Platform.OS === "android") {
          Immersive.on();
        }
        this.setState({
          contactModalVisible: false
        });
      };

      submitContact = contacts => {
        this.setState({ contacts: contacts, contactModalVisible: false });
      };

      openModal = location => {
        if (Platform.OS === "android") {
          Immersive.off();
        }
        this.setState({
          modalVisible: true,
          tempRegion: {},
          mapLoading: true,
          openedMap: location
        });
      };

      closeModal = () => {
        if (Platform.OS === "android") {
          Immersive.on();
        }
        this.setState({ modalVisible: false, openedMap: null });
      };

      onRegionChange = region => {
        this.setState({ tempRegion: region });
      };

      submitRegionChange = async region => {
        this.setState({ modalVisible: false });
        const result = await getAddressByCoordinates(
          region.latitude,
          region.longitude
        );

        this.setState({ [this.state.openedMap]: result, openedMap: null });
        this.setState({ tempRegion: {} });
      };

      submitMapTarget = async result => {
        this.setState({
          modalVisible: false,
          [this.state.openedMap]: result,
          openedMap: null,
          tempRegion: {}
        });
      };

      submitAutosuggestSelection = (field, result) => {
        removeAutoSuggests();
        this.setState({ [field]: result, activeInput: null });
      };

      mapLoadingFinished = () => {
        this.setState({
          mapLoading: false
        });
      };

      handleInputChange = (evt, name, subProp) => {
        if (!subProp) {
          this.setState({
            [name]:
              name === "volunteerneeded"
                ? parseInt(evt.nativeEvent.text)
                : evt.nativeEvent.text
          });
        } else {
          this.setState({
            [name]: {
              ...this.state[name],
              [subProp]: evt.nativeEvent.text
            }
          });
        }
      };

      validateSubmit = async () => {
        if (this._working) return;
        let error = false;
        const needToFilter = [
          "validationErrors",
          "mapLoading",
          "modalVisible",
          "contactModalVisible",
          "tempRegion",
          "submitted",
          "tempGroups",
          "groups",
          "openedMap",
          "locationResults",
          "activeInput",
          "startDatePosition",
          "endDatePosition",
          "groupPosition",
          "startLocationPosition",
          "titlePositon",
          "volunteerPosition",
          "descriptionPosition"
        ];

        const filtered = Object.keys(this.state)
          .filter(key => !needToFilter.includes(key))
          .reduce((obj, key) => {
            obj[key] = this.state[key];
            return obj;
          }, {});

        switch (true) {
          case !this.state.group:
          case this.state.group && !this.state.group.id:
            error = true;
            this.scrollView._root.scrollToPosition(
              0,
              this.state.groupPosition.y
            );
            break;
          case !this.state.name:
          case this.state.name.length < 1:
            error = true;
            this._title._root.focus();
            this.scrollView._root.scrollToPosition(
              0,
              this.state.titlePositon.y
            );
            break;
          case !this.state.volunteerneeded:
          case this.state.volunteerneeded.length < 1:
            error = true;
            this._numberVolunteers._root.focus();
            this.scrollView._root.scrollToPosition(
              0,
              this.state.volunteerPosition.y
            );
            break;
          case !this.state.description:
          case this.state.description.length < 1:
            error = true;
            this._briefDesc._root.focus();
            this.scrollView._root.scrollToPosition(
              0,
              this.state.descriptionPosition.y
            );
            break;

          case !this.state.startdate:
            this.scrollView._root.scrollToPosition(
              0,
              this.state.startDatePosition.y
            );
            error = true;
            break;
          case this.state.enddate && this.state.startdate >= this.state.enddate:
            // this.scrollView._root.scrollToPosition(
            //   0,
            //   this.state.endDatePosition.y
            // );
            error = true;
            break;
          case !this.state.startlocation &&
            this.state.endlocation &&
            Object.keys(this.state.endlocation).length > 0:
            // this.scrollView._root.scrollToPosition(
            //   0,
            //   this.state.startLocationPosition.y
            // );
            error = true;
            break;
          default:
            error = false;
        }

        if (!error) {
          this._working = true;
          if (await getCategories(this.state.group.id)) {
            setTask(filtered);
            this.props.navigation.navigate("Categories");
          }
        } else {
          this.setState({ submitted: true });
        }

        this._working = false;
      };

      async handleLocationQuery(evt, field) {
        const value = evt.nativeEvent.text;
        this.setState({
          [field]:
            value.length == 0
              ? null
              : {
                  ...this.state[field],
                  address: value
                }
        });

        if (value.length > 3) {
          await this.getSuggestionsByAddress(value);
        }
      }

      render() {
        return (
          <Content container ref={c => (this.scrollView = c)}>
            <View style={style.containerStyle}>
              <Form>
                <View
                  onLayout={event => {
                    const { x, y } = event.nativeEvent.layout;

                    this.setState({ groupPosition: { x, y } });
                  }}>
                  <View style={style.panelStyle}>
                    <H3 style={style.panelTitleRequired}>
                      {I18n.t("editTask.group")}
                    </H3>

                    {this.props.groups.length > 1 && (
                      <Item
                        {...(this.props.groups.length > 1
                          ? { picker: true, stackedLabel: true, noBorder: true }
                          : { noBorder: true, floatingLabel: true })}
                        error={
                          (!this.state.group ||
                            (this.state.group && !this.state.group.id)) &&
                          this.state.submitted
                        }>
                        <TouchableOpacity
                          onPress={() => {
                            ActionSheet.show(
                              {
                                options: this.state.tempGroups,
                                title: I18n.t("editTask.group")
                              },
                              buttonIndex => {
                                this.handleGroupChange(
                                  this.state.tempGroups[buttonIndex]
                                );
                              }
                            );
                          }}>
                          <View style={style.approvalNote}>
                            <View>
                              {this.state.group && !this.state.group.id ? (
                                <Text
                                  style={{
                                    color: variable.inputColorPlaceholder,
                                    marginVertical: 7
                                  }}>
                                  {I18n.t("editTask.chooseGroup")}{" "}
                                  <Icon
                                    name="ios-arrow-down"
                                    style={{ fontSize: 20 }}
                                  />
                                </Text>
                              ) : (
                                <Text style={{ marginVertical: 7 }}>
                                  {this.state.group.name}{" "}
                                  <Icon
                                    name="ios-arrow-down"
                                    style={{ fontSize: 20 }}
                                  />
                                </Text>
                              )}
                            </View>
                          </View>
                        </TouchableOpacity>
                      </Item>
                    )}
                    {this.props.groups.length === 1 && (
                      <Item
                        {...(this.props.groups.length > 1
                          ? { picker: true, stackedLabel: true, noBorder: true }
                          : { noBorder: true, stackedLabel: true })}
                        error={
                          (!this.state.group ||
                            (this.state.group && !this.state.group.id)) &&
                          this.state.submitted
                        }>
                        <Input
                          disabled
                          value={this.state.group.name}
                          style={{ padding: 0 }}
                        />
                      </Item>
                    )}
                  </View>

                  {(!this.state.group ||
                    (this.state.group && !this.state.group.id)) &&
                    this.state.submitted && (
                      <Text error>{I18n.t("errorMessages.groupRequired")}</Text>
                    )}
                </View>

                <View style={style.panelStyle}>
                  <View
                    onLayout={event => {
                      const { x, y } = event.nativeEvent.layout;

                      this.setState({ titlePositon: { x, y } });
                    }}>
                    <H3 style={style.panelTitleRequired}>
                      {I18n.t("editTask.taskSummary")}
                    </H3>

                    <ValidatedInput
                      validInput={this.state.name.length > 0}
                      handleInputChange={this.handleInputChange}
                      label={I18n.t("editTask.title")}
                      errorMessage={I18n.t("errorMessages.titleRequired")}
                      name="name"
                      value={this.state.name}
                      showError={this.state.submitted}
                      getRef={ref => (this._title = ref)}
                      returnKeyType="next"
                      onSubmitEditing={() =>
                        this._numberVolunteers._root.focus()
                      }
                    />
                  </View>
                  <View
                    onLayout={event => {
                      const { x, y } = event.nativeEvent.layout;

                      this.setState({ volunteerPosition: { x, y } });
                    }}>
                    <ValidatedInput
                      validInput={!!this.state.volunteerneeded}
                      handleInputChange={this.handleInputChange}
                      label={I18n.t("editTask.numberVolunteers")}
                      errorMessage={I18n.t(
                        "errorMessages.numberVolunteersRequired"
                      )}
                      name="volunteerneeded"
                      keyboardType="numeric"
                      returnKeyType="next"
                      getRef={ref => (this._numberVolunteers = ref)}
                      value={
                        this.state.volunteerneeded
                          ? this.state.volunteerneeded.toString()
                          : ""
                      }
                      showError={this.state.submitted}
                      onSubmitEditing={() => this._briefDesc._root.focus()}
                    />
                  </View>
                </View>

                <View style={style.panelStyle}>
                  <View
                    onLayout={event => {
                      const { x, y } = event.nativeEvent.layout;

                      this.setState({ descriptionPosition: { x, y } });
                    }}>
                    <H3 style={style.panelTitleRequired}>
                      {I18n.t("editTask.taskDetails")}
                    </H3>

                    <Item
                      stackedLabel
                      error={
                        !(this.state.description.length > 0) &&
                        this.state.submitted
                      }
                      style={{ marginTop: 15 }}>
                      <Input
                        ref={ref => (this._briefDesc = ref)}
                        name="description"
                        keyboardType="default"
                        returnKeyType="done"
                        multiline={true}
                        autoCorrect={true}
                        onChange={evt =>
                          this.handleInputChange(evt, "description")
                        }
                        value={
                          this.state.description ? this.state.description : ""
                        }
                        placeholder={I18n.t("editTask.briefDescPlaceholder")}
                      />
                    </Item>
                    {!(this.state.description.length > 0) &&
                      this.state.submitted && (
                        <Text error>
                          {I18n.t("errorMessages.briefDescRequired")}
                        </Text>
                      )}
                  </View>
                </View>

                <View style={{ marginTop: 30, paddingHorizontal: 15 }}>
                  <Text style={{ fontWeight: "200" }}>
                    {I18n.t("editTask.additionalInformation")}
                  </Text>
                </View>

                <View style={style.panelStyle}>
                  <View
                    onLayout={event => {
                      const { x, y } = event.nativeEvent.layout;
                      this.setState({ startDatePosition: { x, y } });
                    }}>
                    <Text style={style.panelTitle}>
                      {I18n.t("editTask.datePanelTitle")}
                    </Text>

                    <Item
                      stackedLabel
                      error={!this.state.startdate && this.state.submitted}>
                      <Label>{I18n.t("global.startDate")}</Label>
                      <DatePicker
                        style={style.datePickerStyle}
                        date={this.state.startdate}
                        mode="datetime"
                        androidMode="spinner"
                        is24Hour={false}
                        placeholder={I18n.t("editTask.selectDatePlaceholder")}
                        format="LLL"
                        locale={I18n.currentLocale()}
                        confirmBtnText={I18n.t("global.confirm")}
                        cancelBtnText={I18n.t("global.cancel")}
                        customStyles={{
                          dateIcon: {
                            position: "absolute",
                            left: 0,
                            top: 4,
                            marginLeft: 0
                          },
                          dateInput: {
                            marginLeft: 20,
                            borderWidth: 0
                          }
                        }}
                        onDateChange={date => {
                          this.setState({
                            startdate: moment(date, "LLL").toDate()
                          });
                        }}
                      />
                    </Item>
                    {!this.state.startdate && this.state.submitted && (
                      <Text error>
                        {I18n.t("errorMessages.startDateRequired")}
                      </Text>
                    )}
                  </View>
                  <View
                    onLayout={event => {
                      const { x, y } = event.nativeEvent.layout;
                      this.setState({ endDatePosition: { x, y } });
                    }}>
                    <Item stackedLabel>
                      <Label>{I18n.t("global.endDate")}</Label>
                      <DatePicker
                        style={style.datePickerStyle}
                        date={
                          this.state.enddate
                            ? new Date(this.state.enddate)
                            : null
                        }
                        mode="datetime"
                        androidMode="spinner"
                        is24Hour={false}
                        placeholder={I18n.t("editTask.selectDatePlaceholder")}
                        format="LLL"
                        locale={I18n.currentLocale()}
                        confirmBtnText={I18n.t("global.confirm")}
                        cancelBtnText={I18n.t("global.cancel")}
                        customStyles={{
                          dateIcon: {
                            position: "absolute",
                            left: 0,
                            top: 4,
                            marginLeft: 0
                          },
                          dateInput: {
                            marginLeft: 20,
                            borderWidth: 0
                          }
                        }}
                        onDateChange={date => {
                          this.setState({
                            enddate: moment(date, "LLL").toDate()
                          });
                        }}
                      />
                    </Item>
                    {!!this.state.enddate &&
                      this.state.startdate >= this.state.enddate &&
                      this.state.submitted && (
                        <Text error>
                          {I18n.t("errorMessages.endDateNeedToHigher")}
                        </Text>
                      )}
                  </View>
                </View>

                <View style={style.panelStyle}>
                  <View
                    onLayout={event => {
                      const { x, y } = event.nativeEvent.layout;
                      this.setState({ startLocationPosition: { x, y } });
                    }}>
                    <Text style={style.panelTitle}>
                      {I18n.t("editTask.locationPanelTitle")}{" "}
                    </Text>

                    <Item floatingLabel>
                      <Label>{I18n.t("global.startLocation")}</Label>
                      <Input
                        getRef={ref => (this._startLocation = ref)}
                        returnKeyType="done"
                        autoCorrect={true}
                        numberOfLines={1}
                        onFocus={() =>
                          this.setState({ activeInput: "startlocation" })
                        }
                        onChange={evt =>
                          this.handleLocationQuery(evt, "startlocation")
                        }
                        value={
                          this.state.startlocation
                            ? this.state.startlocation.address ||
                              `${this.state.startlocation.lat}, ${
                                this.state.startlocation.lng
                              }`
                            : ""
                        }
                      />
                      <Icon
                        active
                        name="md-pin"
                        style={{ color: variable.brandInfo }}
                        onPress={() => this.openModal("startlocation")}
                      />
                    </Item>
                    {!this.state.startlocation &&
                      this.state.endlocation &&
                      Object.keys(this.state.endlocation).length > 0 &&
                      this.state.submitted && (
                        <Text error>
                          {I18n.t("global.startLocation") +
                            " " +
                            I18n.t("global.isRequired")}
                        </Text>
                      )}
                    {this.props.locationResults.length > 0 &&
                      this.state.activeInput === "startlocation" &&
                      this.state.startlocation &&
                      this.state.startlocation.address && (
                        <AutoSuggestContainer
                          results={this.props.locationResults}
                          name={"startlocation"}
                          handleResult={this.submitAutosuggestSelection}
                        />
                      )}
                  </View>

                  <View>
                    <Item floatingLabel>
                      <Label>{I18n.t("global.endLocation")}</Label>
                      <Input
                        getRef={ref => (this._endLocation = ref)}
                        returnKeyType="done"
                        autoCorrect={true}
                        numberOfLines={1}
                        onFocus={() =>
                          this.setState({ activeInput: "endlocation" })
                        }
                        value={
                          this.state.endlocation
                            ? this.state.endlocation.address ||
                              `${this.state.endlocation.lat}, ${
                                this.state.endlocation.lng
                              }`
                            : ""
                        }
                        onChange={evt =>
                          this.handleLocationQuery(evt, "endlocation")
                        }
                      />

                      <Icon
                        active
                        name="md-pin"
                        style={{ color: variable.brandInfo }}
                        onPress={() => this.openModal("endlocation")}
                      />
                    </Item>
                    {this.props.locationResults.length > 0 &&
                      this.state.activeInput === "endlocation" &&
                      this.state.endlocation &&
                      this.state.endlocation.address && (
                        <AutoSuggestContainer
                          results={this.props.locationResults}
                          name={"endlocation"}
                          handleResult={this.submitAutosuggestSelection}
                        />
                      )}
                  </View>
                </View>

                {this.props.contacts && this.props.contacts.length > 0 && (
                  <View style={style.panelStyle}>
                    <Text style={style.panelTitle}>
                      {I18n.t("editTask.contactsPanelTitle")}
                    </Text>
                    <Text
                      style={{ marginTop: 5, color: "#808080", fontSize: 14 }}>
                      {I18n.t("editTask.selectContactsDescription")}
                    </Text>
                    <View>
                      <View style={{ margin: 10 }}>
                        {this.state.contacts.map(contact => (
                          <Text key={contact.id} style={style.textStyle}>
                            {contact.name}
                          </Text>
                        ))}
                      </View>
                      <Button
                        bordered
                        small
                        info
                        onPress={this.openContactModal}>
                        <Text uppercase={false}>
                          {I18n.t("editTask.selectContacts")}
                        </Text>
                      </Button>
                    </View>
                  </View>
                )}
              </Form>
              <View style={{ padding: 15 }}>
                <Button iconRight block info onPress={this.validateSubmit}>
                  <Text uppercase={false}>
                    {I18n.t("editTask.continueCategories")}
                  </Text>
                  <Icon name="ios-arrow-forward" />
                </Button>
              </View>
            </View>
            {!!this.state.openedMap && (
              <MapPicker
                onSubmit={this.submitMapTarget}
                modalVisible={this.state.modalVisible}
                region={this.state.tempRegion}
                closeModal={this.closeModal}
                location={
                  this.state[this.state.openedMap]
                    ? {
                        latitude: this.state[this.state.openedMap].lat,
                        longitude: this.state[this.state.openedMap].lng,
                        address: this.state[this.state.openedMap].address
                      }
                    : null
                }
              />
            )}
            {this.state.contactModalVisible &&
              this.props.contacts &&
              this.props.contacts.length > 0 && (
                <ContactPicker
                  modalVisible={this.state.contactModalVisible}
                  closeModal={this.closeContactModal}
                  submitResult={this.submitContact}
                  contacts={this.props.contacts}
                  selected={this.state.contacts}
                />
              )}
          </Content>
        );
      }
    }
  ),
  props => <ConnectedHeader {...props} />
);

const style = StyleSheet.create({
  containerStyle: {},
  panelStyle: {
    padding: 15,
    marginTop: 10,
    backgroundColor: "#FFFFFF"
  },
  panelTitle: {
    fontWeight: "bold"
  },
  panelTitleRequired: {
    color: variable.brandInfo
  },
  picker: {
    justifyContent: "flex-start",
    width: "100%",
    height: 50
  },
  mapInputWrapper: {
    flexDirection: "row"
  },
  contactsWrapper: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center"
  },
  inputWidth: {
    width: "80%"
  },
  buttonsStyle: {
    marginTop: 40,
    marginLeft: 10
  },
  submitButtonStyle: {
    marginTop: 20
  },
  innerContainer: {
    width: "100%",
    height: "70%"
  },
  textStyle: {
    marginRight: 10,
    textAlign: "left"
  },
  map: {
    width: "100%",
    height: "90%"
  },
  mapPointerStyle: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent"
  },
  datePickerCustomStyle: {},
  datePickerStyle: { width: "100%", marginTop: 20 }
});
