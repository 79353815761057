import variable from "./../variables/platform";

export default (variables = variable) => {
  const textTheme = {
    fontSize: variables.DefaultFontSize - 1,
    fontFamily: variables.fontFamily,
    color: variables.textColor,
    ".note": {
      color: variable.brandInfo,
      fontSize: variables.noteFontSize
    },
    ".link": {
      color: "#336598"
    },
    ".bold": {
      fontWeight: "500"
    },
    ".line": {
      flex: 1,
      alignItems: "flex-start"
    },
    ".date": {
      fontSize: 12
    },
    ".error": {
      color: "red",
      fontSize: 12,
      marginTop: 5,
      textAlign: "right"
    },
    ".compact": {
      fontSize: 14,
      marginLeft: "10%",
      marginRight: "10%",
      padding: 0
    }
  };

  return textTheme;
};
