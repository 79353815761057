export default {
  global: {
    email: "Email Address",
    password: "Password",
    rememberMe: "Remember me",
    forgotPass: "Forgot Password",
    login: "Login",
    loginTitle: "Login to 4Bells",
    loginFailedTitle: "Login",
    loginFailed: "Invalid username or password",
    createAcc: "Create an Account",
    changePass: "Change Password",
    and: "and",
    firstName: "First Name",
    lastName: "Last Name",
    eula: "End User License Agreement",
    privacypolicy: "Privacy Policy",
    continue: "Continue",
    save: "Save",
    saveChanges: "Save Changes",
    send: "Send",
    cancel: "Cancel",
    callPhone: "Call Phone",
    noItemFoundForList: "No items found for this list",
    briefDesc: "Brief Description",
    startDate: "Start Date",
    endDate: "End Date",
    startLocation: "Start Location",
    endLocation: "End Location",
    ownerName: "Owner Name",
    ownerPhone: "Owner Phone",
    numberVolunteers: "Number of Volunteers",
    categories: "Categories",
    language: "Language",
    pleaseSelectLanguage: "Choose language",
    isRequired: "is required!",
    title: "Title",
    noCategoriesSelected: "No categories selected",
    confirm: "Please Confirm",
    needToRestart: "We will need to restart the app.",
    restart: "Restart",
    ok: "OK",
    yes: "yes",
    no: "no",
    close: "Close",
    selecetOne: "Select One",
    group: "Group",
    thankYou: "Thank You!",
    view: "View",
    taskStatus: {
      open: "Open",
      claimed: "Claimed",
      decline: "Declined",
      completed: "Completed",
      closed: "Closed",
      recalled: "Removed"
    },
    forminvalid: "Form is invalid"
  },
  location: {
    pin: "Tap on the map to select location",
    title: "4Bells requires Location permission",
    message:
      "We required Location permission in order to get device location. Please grant us.",
    openInMapApp: "Open in map app"
  },
  drawer: {
    activities: "Summary",
    tasks: "Tasks",
    groups: "Groups",
    myAccount: "My Account",
    sync: "Sync",
    settings: "Settings",
    contactUs: "Contact Us",
    logout: "Logout",
    faq: "FAQ",
    terms: "End User License Agreement",
    privacy: "Privacy Policy",
    about: "About"
  },
  sync: {
    complete: "Sync completed.",
    cancel: "Sync cancelled.",
    syncGroup: "Syncing group: {{name}}",
    groupFailed: "Group: {{name}} sync failed",
    tasks: "Syncing {{list}} tasks",
    open: "open",
    claimed: "claimed",
    syncTask: "Syncing {{index}}/{{count}} {{list}} tasks...",
    taskFailed: "Task: {{name}} sync failed",
    modes: {
      none: "Turn off",
      always: "Always",
      wifi: "Wi-Fi only"
    }
  },
  errorMessages: {
    unexpectedError: "Unexpected error occurred",
    firstNameRequired: "First name is required",
    lastNameRequired: "Last name is required",
    emailRequired: "Email is required",
    emailNotValid: "Email is not a valid email address",
    phoneRequired: "Phone number is required",
    phoneInvalid: "Phone number is invalid",
    passwordRequired: "Password is required",
    passwordRule:
      "Passwords must be at least 6 characters, and should contain a mix of upper and lower case letters, as well as digits. Do not use special characters.",
    passwordNotMatch: "Passwords must be identical",
    somethingWentWrong: "Something went wrong",
    messageRequired: "Message is required",
    endDateNeedToHigher: "End Date need to greater than the Start Date!",
    groupRequired: "Group is required",
    titleRequired: "Title is required",
    briefDescRequired: "Brief Description is required",
    startDateRequired: "Start Date is required",
    numberVolunteersRequired: "Number of Volunteers is required"
  },
  appError: {
    "Network Error": "Network Error",
    "Invalid username or password": "Invalid username or password",
    "Registration warning": "Email address already used",
    "Request failed with status code 504": "Server not responding",
    "Request failed with status code 404": "Resource not found",
    "Resource not found": "Resource not found"
  },
  twilio: {
    lookup: "Checking phone number...",
    invalid: "Phone number is invalid"
  },
  screenHeader: {
    activity: "Activity",
    taskDetails: "Task Details",
    editTask: "Edit Task",
    addTask: "Create Task",
    createAccount: "Create an Account",
    editAccount: "Edit My Account",
    myAccount: "My Account",
    help: "Help",
    settings: "Settings"
  },
  welcome: {
    welcome: "Welcome to 4Bells",
    description:
      "Deploy volunteers from the palm of your hand. Use 4Bells to send tasks, track progress, and get critical tasks done.",
    or: "or",
    needHelp: "Need Help?",
    settings: "Settings"
  },
  settings: {
    sync: {
      description: "Data Usage Settings",
      none: "Always use less data",
      noneDescription:
        "Only download your group and task information as needed.",
      always: "Automatic (Default)",
      alwaysDescription:
        "Automatically download all of your latest group and task information whenever the app is online.",
      wifi: "Use less data on cellular",
      wifiDescription:
        "Download your group and task information only as needed when using cellular data.",
      reducedOfflineData:
        "NOTE: Selecting less data may reduce the amount of information that is available while offline.",
      update: "Update all groups and tasks now"
    }
  },
  login: {
    rememberMe: "Remember me"
  },
  signup: {
    keepMe: "Keep me logged in",
    fourbellsAlert: "4Bells Alerts",
    sendTextMessage: "Send me text messages",
    ratesApply: "Rates will apply",
    sendNotification: "Send me notifications",
    sendMessenger: "Send me Facebook Messenger notifications",
    termsAccept: "By using this appication, you agree to the",
    phone: "Phone Number",
    accountInfoSectionTitle: "Account Information",
    passwordSectionTitle: "Password",
    languageSectionTitle: "Language"
  },
  newuser: {
    title: "Welcome",
    firststep: "Your First Step",
    description:
      "You can be both a volunteer and a team lead of groups. So, select one path now and you can create groups or connect to an existing group through your dashboard.",
    volunteer: "Volunteer",
    creategroup: "Create A Group",
    viewPasswordRules: "View password rules"
  },
  help: {
    contactUs: "Contact Us",
    aboutFourBells: "About 4Bells",
    faq: "FAQ",
    forgotPassLink: "Forgot Password?"
  },
  about: {
    title: "About 4Bells",
    version: "Version of App",
    releaseDate: "Date of release",
    buildNumber: "Build number",
    financialSupport: "We received financial support from:",
    inspirationFeedback: "We received inspiration and feedback from:",
    beforeJayStackLink:
      "4Bells is brought to you by Caravan Studios, a division of TechSoup. We collaborate with",
    afterJayStackLink: "to build and maintain 4Bells.",
    terms: "End User License Agreement",
    policy: "Privacy Policy"
  },
  offline: {
    status: "offline",
    title: "No Network Connection",
    descriptionTitle: "Network connection not found",
    description:
      "Sorry, no network connection found. Please enable Wi-Fi or cellular network on your device and restart 4Bells. Thank you!"
  },
  forgotPass: {
    emailMe: "Email Me My Information",
    emailSucces: "Success Email Sending"
  },
  contactUs: {
    title: "Contact Us",
    bellsfeedback: "Feedback for 4Bells",
    bellsIssue: "4Bells issue resolution needed",
    message: "Message",
    sent: "Message sent...",
    error: "Failed to send message"
  },
  editAccount: {
    changePass: "Change Password"
  },
  changePass: {
    succesfullyChange: "You succesfully changed your Password!",
    passwordRule:
      "Passwords must be at least 6 characters, and should contain a mix of upper and lower case letters, as well as digits. Do not use special characters.",
    save: "Save"
  },
  activities: {
    title: "Summary",
    tabHeaders: {
      alerts: "To Do",
      warnings: "Activity Log"
    },
    emptyTodos: "You do not have any todos.",
    emptyActivities: "You do not have any activities.",
    removed: "Activity removed...",
    actionNeeded: "Action Needed",
    view: "View..."
  },
  dashboard: {
    spacesLeft: "spaces remaining",
    spaceLeft: "space remaining",
    claimedBy: "Claimed by",
    claimedMultiple: "Multiple Volunteers",
    claimedUnknown: "unknown",
    noOpenTask: "You have no tasks open to claim",
    noClaimedTask: "You have no claimed tasks",
    noClosedTask: "You have not completed any tasks",
    view: "View...",
    manage: "Manage...",
    tagManaging: "Managing",
    tagOpen: "Open",
    tagClosed: "Closed",
    tagClaimed: "Claimed",
    claimedByYou: "You have claimed this task",
    managedByYou: "You are a manager for this task",
    pleaseRespond: "Please respond to this task"
  },
  taskDetails: {
    tabHeaders: {
      details: "Details",
      volunteers: "Volunteers",
      contacts: "Contacts",
      location: "Location",
      history: "History",
      actions: "Actions"
    },
    history: {
      claimed: "Claimed",
      declined: "Declined",
      completed: "Completed",
      closed: "Closed",
      modified: "Modified",
      unclaimed: "Unclaimed",
      created: "Created",
      incomplete: "Incomplete",
      admin: "(admin)",
      decline: "Decline"
    },
    actions: {
      claim: "Claim",
      decline: "Decline",
      unclaim: "Unclaim",
      markComplete: "Mark Complete",
      cancel: "Cancel",
      taskRemoved: "Task was removed!",
      noMoreSpacesLeft: "No more spaces left!",
      unclaimError: "Error on unclaiming task!",
      declineError: "Error on declining task!",
      markCompleteError: "Error on marking complete!",
      markIncompleteError: "Error on marking incomplete!",
      missingTask: "Missing task",
      changeYourMind: "Change your mind?",
      claimThisTask: "Claim this task"
    },
    details: {
      editTask: "Edit This Task",
      briefDesc: "Brief Description",
      categories: "Categories",
      status: "Status",
      ownerName: "Owner Name",
      ownerPhone: "Owner Phone",
      startDate: "Start Date",
      endDate: "End Date",
      startLocation: "Start Location",
      endLocation: "End Location",
      organization: "Organization",
      volunteers: "Volunteers",
      pleaseRespond: "Please respond to this task",
      isDeclined: "You have declined this task."
    },
    volunteers: {
      all: "All",
      claimed: "Claimed",
      declined: "Declined",
      completed: "Completed",
      closed: "Closed",
      show: "Show",
      status: "Status:"
    },
    contacts: {
      ratesApply: "Standard text messaging and phone call rates apply",
      noContactsForTask: "No contacts were specified for this task.",
      text: "Text",
      sendMail: "Send Mail"
    }
  },
  groups: {
    title: "Groups",
    groupsJoined: "My Volunteer Groups",
    groupsManaged: "Groups I Manage",
    joinGroup: "Join a group...",
    joinAnotherGroup: "Join another group...",
    createGroup: "Create a group...",
    profile: {
      title: "Create Group",
      edit: "Edit Group Profile",
      groupName: "Group Name",
      address1: "Address 1",
      address2: "Address 2",
      city: "City",
      country: "Country",
      postalCode: "Postal Code",
      state: "State, Province Or Country",
      volunteerApproval: "Volunteer Approval",
      enableApproval: "Require approval of volunteers?",
      yes: "Yes",
      no: "No",
      continue: "Step 2: Categories",
      save: "Save",
      addressTitle: "Address",
      groupSettingsTitle: "Group Settings",
      selectValuePlaceholder: "Select an option",
      selectStatePlaceholder: "Select a state",
      errorMessage: {
        groupName: "Group name is required",
        volunteerApproval: "Volunteer approval is required"
      }
    },
    categories: {
      title: "Categories",
      description:
        "You can organize your volunteers by skills, types of tasks, or whatever category meets your needs.",
      examplesLabel: "Examples",
      examples: "Event management, Phone duty, Food server, Transportation",
      categoryLabel: "Select or add a new category",
      categoryButton: "Add",
      invalid: "Your group must have at least one category.",
      continue: "Step 3: Review",
      subscribe: "subscribe category...",
      unsubscribe: "unsubscribe category...",
      next: "Next",
      heading: "Volunteer Categories",
      prompt:
        "Choose from the example categories below, or add your own categories.",
      placeholder: "Add a category...",
      addButtonLabel: "Add",
      selectCategoriesPrompt: "Select the types of tasks you are interested in."
    },
    contacts: {
      title: "Add Contacts",
      description:
        "Include people who can help or provide information about tasks in this group.",
      remove: "Remove",
      add: "Add Contact...",
      save: "OK",
      form: {
        name: "Name",
        phone: "Phone",
        email: "Email"
      },
      errorMessage: {
        name: "Name is required",
        phone: "Phone is required",
        email: "Email is required",
        emailInvalid: "Email is not valid"
      },
      continue: "Step 4: Review",
      rates: "Standard text messaging and phone call rates apply",
      callPhone: "Call Phone",
      sendText: "Text",
      sendMail: "Send Mail"
    },
    review: {
      title: "Review",
      details: {
        groupName: "Group Name",
        connectionCode: "Connection Code",
        address1: "Address 1",
        address2: "Address 2",
        country: "Country",
        city: "City",
        state: "State",
        postalCode: "Postal Code",
        volunteerApproval: "Volunteer Approval",
        yes: "yes",
        no: "no"
      }
    },
    created: {
      title: "Group Created",
      heading: "Your group has been created.",
      groupName: "Group Name",
      connectionCode: "Connection Code",
      description:
        "You will receive a confirmation email with additional information and instructions for connecting with volunteers and creating tasks that your volunteers can claim.",
      invite: "Invite Volunteers"
    },
    volunteer: {
      title: "Group"
    },
    admin: {
      title: "Group",
      manageGroup: "Manage This Group"
    },
    manage: {
      title: "Manage Group",
      editGroup: "Edit This Group",
      deleteGroup: "Delete This Group",
      addCategory: "Add a Category",
      showPendingOnly: "Show only pending approvals",
      categoryVolunteers: "{{count}} volunteers",
      approveVolunteers:
        "{{approved}} approved {{pending}} pending {{rejected}} rejected volunteers",
      removeCategory: "Remove",
      viewVolunteers: "View",
      manageVolunteers: "Manage",
      addContact: "Add a Contact",
      removeContact: "Remove",
      removeContactConfirm: "Are you sure you want to remove contact {{name}}?",
      inviteMore: "Invite More Volunteers",
      volunteers: "Volunteers",
      invite: "Invite More Volunteers",
      categorySubtitle: "Category",
      manageCategoryDescription:
        "Manage categories for this group and review volunteers that are associated with each category.",
      statusApproved: "Approved",
      statusRejected: "Rejected",
      statusPending: "Pending"
    },
    invitation: {
      subject: "Invitation to Join 4Bells"
    },
    actions: {
      completeSetup: "Save this group",
      createFailed: "Failed to create group",
      disconnect: "Disconnect From This Group",
      disconnectConfirm: "Are you sure you want to disconnect from the group?",
      deleteGroup: "Delete This Group",
      deleteConfirm: "Are you sure you want to delete the group?"
    },
    tabs: {
      details: "Details",
      categories: "Categories",
      contacts: "Contacts",
      lead: "Lead",
      volunteers: "Volunteers",
      interests: "My Interests"
    },
    categoryvolunteers: {
      title: "Volunteer List",
      subtitle: "Of {{name}} Category",
      status: "status",
      approved: "Approved",
      pending: "Pending",
      rejected: "Rejected",
      actions: {
        approve: "Approve",
        reject: "Reject",
        disconnect: "Disconnect",
        remove: "Remove",
        changeToRejected: "Change status to Rejected",
        changeToApproved: "Change status to Approved"
      }
    },
    volunteerdetails: {
      title: "Volunteer Details"
    },
    leaddetails: {
      title: "Lead Details"
    }
  },
  volunteer: {
    name: "Name",
    phone: "Phone",
    email: "Email",
    status: "Status",
    categories: "Categories",
    promote: "Promote To Lead",
    demote: "Demote To Volunteer",
    disconnect: "Disconnect From This Group",
    markComplete: "Mark Complete",
    markIncomplete: "Mark Incomplete",
    unassignVolunteer: "Unassign Volunteer",
    groupMembership: "Group Membership",
    taskAssignment: "Task Assignment",
    errorMessages: {
      markCompleteFailed: "Failed to mark complete",
      markIncompleteFailed: "Failed to mark incomplete",
      unassignVolunteerFailed: "Failed to unassign volunteer"
    }
  },
  connect: {
    title: "Connect To A Group",
    volunteerText:
      "If you are a volunteer, enter the connection code provided by your team leader:",
    connectCodeLabel: "Connection Code",
    connectButton: "Connect",
    invalidCode:
      "That is not a valid connection code. Please check with your group's administrator to find the correct code.",
    alreadyConnected:
      "You are already connected with this group. You may be in a pending status. Please check with your group's administrator to be approved.",
    approve:
      "The connection request has been submitted. The group will appear on your dashboard only if the request is approved.",
    thanksConnecting:
      "Thanks for connecting to {{groupName}}. After you're approved as a volunteer you will start receiving tasks.",
    groupName: "Group name:",
    selectCategories: "Select the types of tasks you are interested in.",
    locating: "locating group...",
    connecting: "connecting you to group..."
  },
  editTask: {
    headerTitles: {
      categories: "Select Categories",
      review: "Review"
    },
    chooseGroup: "Choose a group",
    briefDescPlaceholder: "Brief description of the task",
    group: "Group",
    title: "Title",
    taskSummary: "Task Summary",
    taskDetails: "Task Details",
    additionalInformation: "Additional Information (Optional)",
    datePanelTitle: "DATE",
    locationPanelTitle: "LOCATION",
    contactsPanelTitle: "CONTACTS",
    selectDatePlaceholder: "Select Date",
    selectContactsDescription:
      "Select people who can provide help or information for this task.",
    selectContactsTip: "Tip: Add contacts from your Groups.",
    selectContacts: "Select contacts...",
    numberVolunteers: "Number of Volunteers",
    continueCategories: "Continue To Categories",
    setLocaion: "Set Location",
    contacts: "Contacts",
    checkAllFields: "Check/uncheck all categories",
    continueToReview: "Continue to Review",
    submit: "Submit",
    didYouMean: "Did you mean ?",
    errorMessages: {
      addFailed: "Failed to create task",
      editFailed: "Failed to update task",
      smsFailedToSent: "Error occurred, the SMS can not be sent",
      appLimit:
        "Everyone must be using 4Bells today, because we’ve reached the daily text limit. This doesn’t happen often: contact us if you’ve received this message before or if you think you’ll be using 4Bells this much in the future.",
      dailyLimit:
        "You must be really busy! You’ve reached the daily texting limit of 50 texts a day. You’ll have to wait until {{now}} 11:59 PM (UTC) to send more opportunities. Contact us if you think you’ll be using 4Bells this much in the future."
    },
    noSms: "SMS not sent to anyone",
    sms: "SMS sent to {{count}} people",
    smsPerson: "SMS sent to {{count}} person"
  },
  tasks: {
    title: "Tasks",
    tabHeaders: {
      openClaim: "Open",
      currentClaimed: "Claimed",
      closedTasks: "Closed"
    },
    addTask: "Add a Task...",
    addContacts: "Select Contacts"
  },
  retryLogin: {
    title: "Network error",
    content: "Cannot connect to server",
    cancel: "Cancel",
    retry: "Retry"
  }
};
