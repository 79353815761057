import React, { Component } from "react";
import {
  Header,
  Left,
  Button,
  Icon,
  Body,
  Title,
  Subtitle,
  Right
} from "native-base";
import { NavigationActions } from "react-navigation";
import { connect } from "react-redux";
import store from "../../../store";
import I18n from "../../../i18n";

const ConnectHeader = connect(state => ({
  mode: state.groups.mode,
  group: state.groups.group
}))(
  class extends Component {
    render() {
      const { mode, group, hasTabs, navigation, title, subtitle } = this.props;

      return (
        <Header hasTabs={typeof hasTabs == "undefined" ? true : hasTabs}>
          <Left>
            <Button
              transparent
              onPress={() => navigation.dispatch(NavigationActions.back())}
            >
              <Icon name="arrow-back" />
            </Button>
          </Left>
          <Body>
            <Title>{title || I18n.t(`groups.${mode}.title`)}</Title>
            <Subtitle numberOfLines={1}>
              {subtitle || group.profile.name}
            </Subtitle>
          </Body>
          <Right />
        </Header>
      );
    }
  }
);

export default props => <ConnectHeader {...props} />;
