import React, { Component } from "react";
import { Polyline } from "@react-google-maps/api";

class MapViewPolyline extends Component {
  render() {
    const { coordinates, ...rest } = this.props;
    return (
      <Polyline
        path={coordinates.map(coord => ({
          lat: coord.latitude,
          lng: coord.longitude
        }))}
        options={rest}
      />
    );
  }
}

export default MapViewPolyline;
