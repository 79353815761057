import React, { Component, PureComponent } from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Alert,
  RefreshControl,
  Platform,
  FlatList
} from "react-native";
import PropTypes from "prop-types";
import {
  Content,
  Button,
  Text,
  Card,
  CardItem,
  H3,
  Icon,
  Left,
  Right,
  Body,
  Badge
} from "native-base";
import Progress from "../../components/Progress";
import I18n from "../../i18n";
import styles from "../../styles";
import { connect } from "react-redux";
import { isCloseToBottom, isCloseToTop } from "../../utils";
import { getList } from "./actions";
import itemFactory from "./items";
import variables from "../../../native-base-theme/variables/platform";

export default itemFactory(
  "Closed",
  class ClosedTask extends PureComponent {
    render() {
      const { task, key, openTask } = this.props;
      return (
        <TouchableOpacity onPress={() => openTask(task.id)} key={key}>
          <Card style={{ marginTop: 10 }}>
            <CardItem>
              <View>
                <View>
                  <Badge
                    primary
                    style={{
                      margin: 3,
                      backgroundColor: "transparent",
                      borderColor: "#808080",
                      borderWidth: 1
                    }}>
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        color: "#808080"
                      }}>
                      {I18n.t("dashboard.tagClosed")}
                    </Text>
                  </Badge>
                  <H3 style={{ fontWeight: "bold" }}>{task.name}</H3>
                  <View style={{ paddingVertical: 5 }}>
                    <Text style={{ fontSize: 14, fontWeight: "bold" }}>
                      <Icon name="people" style={{ fontSize: 14 }} />{" "}
                      {task.group.name}
                    </Text>
                  </View>
                </View>
              </View>
            </CardItem>

            <View style={[styles.right, { padding: 10, paddingTop: 0 }]}>
              <Text note>{I18n.t("dashboard.view")}</Text>
            </View>
          </Card>
        </TouchableOpacity>
      );
    }
  },
  () => (
    <View style={{ padding: 30 }}>
      <Text>{I18n.t("dashboard.noClosedTask")}</Text>
    </View>
  ),
  null,
  () => I18n.t("tasks.tabHeaders.closedTasks")
);
