import React, { Component } from "react";
import { Footer, FooterTab, Button, Icon, Text } from "native-base";
import { Alert } from "react-native";
import { connect } from "react-redux";
import { NavigationActions, StackActions } from "react-navigation";
import {
  saveGroup,
  disconnectGroup,
  deleteGroup,
  changeMode
} from "../actions";
import store from "../../../store";
import I18n from "../../../i18n";
import { showToast } from "../../../utils";

const ConnectFooter = connect(state => ({
  mode: state.groups.mode,
  group: state.groups.group,
  currentUser: state.auth.currentUser,
  offline: state.offline
}))(
  class Footer extends Component {
    _working = false;

    render() {
      let actions = [];
      const { mode, group, currentUser, offline } = this.props;

      switch (mode) {
        case "review":
          actions = [
            {
              onPress: async () => {
                if (this._working) return;
                this._working = true;
                if (await saveGroup()) {
                  this.props.navigation.dispatch(
                    NavigationActions.navigate({
                      routeName: "Main",
                      action: StackActions.reset({
                        index: 1,
                        actions: [
                          NavigationActions.navigate({
                            routeName: "Groups"
                          }),
                          NavigationActions.navigate({
                            routeName: "Created"
                          })
                        ]
                      })
                    })
                  );
                } else {
                  showToast(
                    I18n.t("groups.actions.createFailed", "danger", 3000)
                  );
                }
                this._working = false;
              },
              icon: "ios-checkmark-circle-outline",
              text: I18n.t("groups.actions.completeSetup"),
              buttonStyle: { backgroundColor: "green" },
              textStyle: { color: "#FFFFFF", fontWeight: "bold" },
              iconStyle: { color: "#FFFFFF" }
            }
          ];
          break;
        // case "manage":
        //   if (!offline) {
        //     actions = [
        // {
        //   onPress: () => {
        //     if (this._working) return;
        //     this._working = true;
        //     Alert.alert(
        //       I18n.t("global.confirm"),
        //       I18n.t("groups.actions.deleteConfirm"),
        //       [
        //         {
        //           text: I18n.t("global.no"),
        //           style: "cancel"
        //         },
        //         {
        //           text: I18n.t("global.yes"),
        //           onPress: async () => {
        //             await deleteGroup();
        //             this.props.navigation.dispatch(
        //               NavigationActions.reset({
        //                 index: 0,
        //                 actions: [
        //                   NavigationActions.navigate({
        //                     routeName: "Groups"
        //                   })
        //                 ]
        //               })
        //             );
        //             this._working = false;
        //           }
        //         }
        //       ]
        //     );
        //   },
        //   icon: "close",
        //   text: I18n.t("groups.actions.deleteGroup")
        // }
        //   ];
        // }
        // break;
        // case "volunteer":
        //   if (!offline) {
        //     actions = [
        // {
        //   onPress: () => {
        //     if (this._working) return;
        //     this._working = true;
        //     Alert.alert(
        //       I18n.t("global.confirm"),
        //       I18n.t("groups.actions.disconnectConfirm"),
        //       [
        //         {
        //           text: I18n.t("global.no"),
        //           style: "cancel"
        //         },
        //         {
        //           text: I18n.t("global.yes"),
        //           onPress: async () => {
        //             await disconnectGroup(group, currentUser);
        //             this.props.navigation.dispatch(
        //               NavigationActions.reset({
        //                 index: 0,
        //                 actions: [
        //                   NavigationActions.navigate({
        //                     routeName: "Groups"
        //                   })
        //                 ]
        //               })
        //             );
        //             this._working = false;
        //           }
        //         }
        //       ]
        //     );
        //   },
        //   icon: "close",
        //   text: I18n.t("groups.actions.disconnect")
        // }
        //   ];
        // }
        // break;
        // case "admin":
        //   actions = [
        // {
        //   onPress: () => {
        //     changeMode("manage");
        //   },
        //   icon: "settings",
        //   text: I18n.t("groups.admin.manageGroup")
        // }
        // ];
        // break;
      }

      if (actions.length > 0) {
        return (
          <FooterTab>
            {actions.map((action, index) => (
              <Button
                full
                vertical
                active
                onPress={() => action.onPress.call(this)}
                key={`${action.text}_${index}`}
                style={action.buttonStyle}>
                <Icon
                  name={action.icon}
                  type={action.type}
                  style={action.iconStyle}
                />
                <Text uppercase={false} style={action.textStyle}>
                  {" "}
                  {action.text}
                </Text>
              </Button>
            ))}
          </FooterTab>
        );
      }

      return null;
    }
  }
);

export default props => <ConnectFooter {...props} />;
