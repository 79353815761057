import { Platform } from "react-native";

import variable from "./../variables/platform";

export default (variables = variable) => {
  const radioTheme = {
    ".selected": {
      "NativeBase.IconNB": {
        color:
          Platform.OS === "ios"
            ? variables.radioColor
            : variables.radioSelectedColorAndroid,
        lineHeight: Platform.OS === "ios" ? 20 : variables.radioBtnLineHeight
      }
    },
    "NativeBase.IconNB": {
      color: Platform.OS === "ios" ? "transparent" : undefined,
      lineHeight:
        Platform.OS === "ios" ? undefined : variables.radioBtnLineHeight,
      fontSize: Platform.OS === "ios" ? undefined : variables.radioBtnSize
    },
    ".rounded": {
      borderWidth: Platform.OS === "ios" ? 0.5 : undefined
    },
    width: Platform.OS === "ios" ? 25 : undefined,
    height: Platform.OS === "ios" ? 25 : undefined,
    borderWidth: undefined,
    borderColor: Platform.OS === "ios" ? "black" : undefined,
    borderRadius: Platform.OS === "ios" ? 15 : undefined,
    paddingLeft: Platform.OS === "ios" ? 6 : undefined,
    paddingBottom: Platform.OS === "ios" ? 25 : undefined,
    marginRight: 20
  };

  return radioTheme;
};
