import types from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case types.VOLUNTEER_DETAILS:
      return { ...action.volunteer, role: "volunteer" };
    case types.LEAD_DETAILS:
      return { ...action.lead, role: "lead" };
    case types.LOGOUT:
      return {};
    default:
      return state;
  }
};
