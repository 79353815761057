import React, { Component } from "react";
import {
  Content,
  List,
  ListItem,
  Icon,
  Text,
  Left,
  Right,
  Badge
} from "native-base";
import { View, Image, Dimensions, Linking, Platform } from "react-native";
import { connect } from "react-redux";
import { logout } from "../actions/auth";
import styles from "../styles";
import I18n from "../i18n";

const deviceHeight = Dimensions.get("window").height;

export default connect(state => ({
  offline: state.offline,
  openTasks: state.auth.openTasks,
  currentScreen: state.misc.currentScreen
}))(
  class DrawerItems extends Component {
    static navigationOptions = {
      header: null
    };

    navigate = route => {
      return () => {
        this.props.navigation.closeDrawer();
        this.props.navigation.navigate(route);
      };
    };

    isActive = tab => {
      return (
        (!this.props.currentScreen && tab == "Activities") ||
        this.props.currentScreen == tab
      );
    };

    navigateTab = (tab, resolver = () => true) => {
      return async () => {
        if (!this.isActive(tab)) {
          if (await resolver()) {
            this.props.navigation.navigate(tab);
          }
        }
      };
    };

    tabLabelStyle = data => ({
      fontWeight: "bold",
      textDecorationLine: this.isActive(data.route) ? "underline" : "none"
    });

    render() {
      const { offline, openTasks } = this.props;

      const drawerItems = [
        ...(Platform.OS == "web"
          ? [
              {
                text: I18n.t("drawer.activities"),
                labelStyle: this.tabLabelStyle,
                route: "Activities"
              },
              {
                text: I18n.t("drawer.tasks"),
                labelStyle: this.tabLabelStyle,
                route: "Tasks"
              },
              {
                text: I18n.t("drawer.groups"),
                labelStyle: this.tabLabelStyle,
                route: "Groups"
              }
            ]
          : []),
        {
          text: I18n.t("drawer.myAccount"),
          route: "MyAccount",
          style: Platform.OS == "web" ? { marginTop: 50 } : undefined,
          offline: true
        },
        {
          text: I18n.t("drawer.contactUs"),
          route: "ContactUs"
        },
        {
          text: I18n.t("drawer.settings"),
          route: "Settings"
        },
        {
          text: I18n.t("drawer.logout"),
          onPress: function() {
            return async () => {
              this.props.navigation.closeDrawer();
              await logout(true);
            };
          }
        },
        {
          text: I18n.t("drawer.faq"),
          onPress: () => () =>
            Linking.openURL("https://www.4bells.org/4bells-faq")
        },
        {
          text: I18n.t("drawer.terms"),
          onPress: () => () =>
            Linking.openURL("https://www.4bells.org/4bells-terms")
        },
        {
          text: I18n.t("drawer.privacy"),
          onPress: () => () =>
            Linking.openURL("https://www.4bells.org/4bells-privacy")
        },
        {
          text: I18n.t("drawer.about"),
          route: "About",
          offline: true
        }
      ];

      return (
        <Content style={{ backgroundColor: "#fff" }}>
          <View style={{ height: deviceHeight / 6 }}>
            <View style={[styles.center, styles.container]}>
              <Image
                source={require("../images/logo_matted.png")}
                style={{
                  height: deviceHeight / 8,
                  width: 200,
                  resizeMode: "contain",
                  marginVertical: 5
                }}
              />
            </View>
          </View>
          <List style={styles.container}>
            {drawerItems.map((data, index) =>
              !offline || (offline && data.offline) ? (
                <ListItem
                  drawerItem
                  key={`${data.text}_${index}`}
                  button
                  style={
                    typeof data.style == "function"
                      ? data.style(data)
                      : data.style
                  }
                  onPress={(data.onPress || this.navigate).call(
                    this,
                    data.route
                  )}>
                  <Left>
                    {data.icon ? <Icon active name={data.icon} /> : undefined}
                    <Text
                      style={
                        typeof data.labelStyle == "function"
                          ? data.labelStyle(data)
                          : data.labelStyle
                      }>
                      {data.text}
                    </Text>
                  </Left>
                  <Right>
                    {data.badge > 0 && (
                      <Badge>
                        <Text>{data.badge}</Text>
                      </Badge>
                    )}
                  </Right>
                </ListItem>
              ) : null
            )}
          </List>
        </Content>
      );
    }
  }
);
