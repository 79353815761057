import I18n, { getLanguages } from "react-native-i18n";
import moment from "moment";
import en from "./locale/en";
import es from "./locale/es";
import pt from "./locale/pt";
import pl from "./locale/pl";
import moment_es from "moment/locale/es-do";
import moment_pt from "moment/locale/pt-br";
import moment_pl from "moment/locale/pl";

I18n.fallbacks = true;

I18n.translations = {
  en,
  es,
  pt,
  pl
};

export default I18n;

export function setLocale(locale) {
  I18n.locale = locale;
  if (locale.indexOf("en") == 0) {
    moment.locale("en");
  } else if (locale.indexOf("pl") == 0) {
    moment.locale("pl");
  } else if (
    locale.startsWith("es") ||
    locale.startsWith("pt") ||
    locale.startsWith("pl")
  ) {
    moment.locale(
      locale
        .toLowerCase()
        .replace("es-es", "es-do")
        .replace("es-us", "es-do")
        .replace("pt-pt", "pt-br")
        .replace("pl-pl", "pl")
    );
  } else moment.locale("en");
}

export async function getDeviceLang() {
  return await getLanguages();
}
