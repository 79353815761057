import { Platform } from "react-native";
import variable from "./../variables/platform";

export default (variables = variable) => {
  const viewTheme = {
    ".padder": {
      padding: variables.contentPadding
    },
    ".container": {
      width: "100%",
      maxWidth: Platform.OS == "web" ? 1200 : undefined,
      alignSelf: Platform.OS == "web" ? "center" : undefined
    }
  };

  return viewTheme;
};
