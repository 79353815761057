import React, { Component } from "react";
import {
  UIManager,
  StatusBar,
  Platform,
  Alert,
  BackHandler,
  KeyboardAvoidingView,
  Dimensions,
  View
} from "react-native";
import { Root, StyleProvider, ActionSheet, Toast } from "native-base";
import * as NativeBase from "native-base";
import { Provider, connect } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Immersive from "react-native-immersive";
import store, { persistor } from "./store";
import { sync } from "./sync";
import { getUser, updateNotification, getLanguages } from "./actions/auth";
import AppNavigation, { resetNavigator } from "./navigation";
import { DismissKeyboard } from "./utils";
import Progress from "./components/Progress";
import OfflineIndicator from "./components/OfflineIndicator";
import styles from "./styles";
import getTheme from "../native-base-theme/components";
import discoversion from "./discoversion";
import { useApi, setToken } from "./axios";
import { useTwilio } from "./twilio";
import { useFB_PAGE_ID } from "./const";
import { initOffline, isOffline } from "./offline";
import initPushNotifications from "./notifications";
import RNRestart from "react-native-restart";
import { setJSExceptionHandler } from "react-native-exception-handler";
import I18n, { setLocale } from "./i18n";

const height = Dimensions.get("window").height;

const errorHandler = (e, isFatal) => {
  if (!__DEV__) {
    if (e) {
      Alert.alert(
        I18n.t("errorMessages.unexpectedError"),
        I18n.t("global.needToRestart") + ": " + e.toString(),
        [
          {
            text: I18n.t("global.restart"),
            onPress: () => {
              RNRestart.Restart();
            }
          }
        ],
        {
          cancelable: false
        }
      );
    }
  }
};

setJSExceptionHandler(errorHandler, true);

if (typeof UIManager.setLayoutAnimationEnabledExperimental == "function") {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

initPushNotifications();

const AppRoot = connect(state => ({
  modalProgress: state.progress.modal
}))(
  class AppRoot extends Component {
    componentDidMount() {
      ActionSheet.actionsheetInstance = null;
      Toast.toastInstance = null;
    }

    render() {
      return (
        <KeyboardAvoidingView
          style={[
            styles.container,
            {
              height: height
            }
          ]}>
          <PersistGate loading={<Progress />} persistor={persistor}>
            <StyleProvider style={getTheme()}>
              <AppNavigation />
            </StyleProvider>
            {this.props.modalProgress && <Progress />}
            <OfflineIndicator />
          </PersistGate>
        </KeyboardAvoidingView>
      );
    }
  }
);

class App extends Component {
  state = {
    isReady: false,
    isImmersive: false,
    immersiveState: null
  };

  setImmersiveOn = () => {
    Immersive.on();
    this.setState({ isImmersive: true });
  };
  setImmersiveOff = () => {
    Immersive.off();
    this.setState({ isImmersive: false });
  };

  restoreImmersive = () => {
    Immersive.on();
  };

  async componentDidMount() {
    // Keyboard.dismiss();
    if (Platform.OS === "android") {
      this.setImmersiveOn();
      Immersive.addImmersiveListener(this.restoreImmersive);
    }
    if (await discoversion()) {
      this.setState({
        isReady: true
      });
    } else {
      BackHandler.exitApp();
    }
  }
  componentWillUnmount() {
    if (Platform.OS === "android") {
      Immersive.removeImmersiveListener(this.restoreImmersive);
    }
  }

  render() {
    if (!this.state.isReady) return <StatusBar hidden={true} />;
    return (
      <Root>
        <StatusBar hidden={true} />
        {this.state.isReady ? (
          <Provider store={store}>
            <AppRoot />
          </Provider>
        ) : (
          undefined
        )}
      </Root>
    );
  }
}

export default DismissKeyboard(App);

function wrapOnPress(Component) {
  return props => {
    let onPress = props.onPress;
    if (typeof onPress == "function") {
      const _onPress = onPress;
      onPress = _.debounce((...args) => {
        return _onPress(...args);
      }, 300);
    }

    return React.createElement(Component, { ...props, onPress });
  };
}

// NativeBase.Button = wrapOnPress(NativeBase.Button);
// NativeBase.Text = wrapOnPress(NativeBase.Text);
// NativeBase.ListItem = wrapOnPress(NativeBase.ListItem);
