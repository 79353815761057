import React, { Component } from "react";
import { Text, Content, Form, Item, Label, Input, Button } from "native-base";
import { View, Alert } from "react-native";
import { Screen, HeaderWithBack } from "../utils";
import { NavigationActions, StackActions } from "react-navigation";
import I18n from "../i18n";
import ValidatedInput from "../components/ValidatedInput";
import { changePass } from "../actions/auth";
import { passwordPattern } from "../variables";

export default Screen(
  class ForgotPass extends Component {
    state = {
      password: "",
      passwordConf: "",
      error: false,
      submitted: false
    };
    _working = false;

    handleSubmit = async () => {
      if (this._working) return;
      this._working = true;
      this.setState({ submitted: true });

      if (this.hasError()) {
        this.setState({ error: true });
      } else {
        await changePass(this.state.password);
        const resetAction = StackActions.reset({
          index: 0,
          actions: [NavigationActions.navigate({ routeName: "Main" })]
        });

        this.props.navigation.dispatch(resetAction);
      }
      this._working = false;
    };

    hasError = () => {
      if (this.state.password === null || this.state.password.length === 0) {
        return true;
      }

      if (!passwordPattern.test(this.state.password)) {
        return true;
      }

      if (this.state.password !== this.state.passwordConf) {
        return true;
      }

      return false;
    };

    handleInputChange = (evt, name) => {
      this.setState({ [name]: evt.nativeEvent.text });
    };

    generatePasswordErrorMessage = () => {
      if (this.state.password === null || this.state.password.length === 0) {
        return I18n.t("errorMessages.passwordRequired");
      } else if (!passwordPattern.test(this.state.password)) {
        return I18n.t("errorMessages.passwordRule");
      } else {
        return "";
      }
    };

    render() {
      return (
        <Content>
          <View style={{ padding: 30 }}>
            <Form>
              <ValidatedInput
                validInput={
                  (this.state.password !== null ||
                    this.state.password.length > 0) &&
                  passwordPattern.test(this.state.password)
                }
                label={I18n.t("global.password")}
                getRef={ref => (this._password = ref)}
                name="password"
                secureTextEntry
                returnKeyType="next"
                errorMessage={this.generatePasswordErrorMessage()}
                onSubmitEditing={() => this._passConf._root.focus()}
                handleInputChange={this.handleInputChange}
                showError={this.state.submitted}
                value={this.state.password}
              />
              <View style={{ marginTop: 20 }}>
                <Text>{I18n.t("changePass.passwordRule")}</Text>
              </View>

              <ValidatedInput
                validInput={this.state.password === this.state.passwordConf}
                label={
                  I18n.t("global.confirm") + " " + I18n.t("global.password")
                }
                getRef={ref => (this._passConf = ref)}
                name="passwordConf"
                secureTextEntry
                errorMessage={I18n.t("errorMessages.passwordNotMatch")}
                returnKeyType="done"
                handleInputChange={this.handleInputChange}
                showError={this.state.submitted}
                value={this.state.passwordConf}
              />
            </Form>

            <Button block primary onPress={() => this.handleSubmit()}>
              <Text uppercase={false}>{I18n.t("changePass.save")}</Text>
            </Button>
          </View>
        </Content>
      );
    }
  },
  _ => I18n.t("global.changePass")
);
