import React, { Component } from "react";
import PropTypes from "prop-types";
import { View, StyleSheet, Text } from "react-native";
import {
  Button,
  Icon,
  Content,
  Card,
  CardItem,
  CheckBox,
  H3,
  List,
  ListItem,
  Body
} from "native-base";
import I18n from "../i18n";
import Modal from "react-native-modal";
import variables from "../../native-base-theme/variables/platform";
import _ from "lodash";

export default class ContactPicker extends Component {
  static propTypes = {
    modalVisible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    submitResult: PropTypes.func.isRequired,
    contacts: PropTypes.array.isRequired
  };

  state = {
    contacts: ContactPicker.addCheck(
      this.props.contacts,
      this.props.selected || []
    )
  };

  static addCheck(array, selected) {
    if (!array) return [];
    const filteredArray = array.filter(
      contact =>
        !!contact.name && !!contact.id && !!contact.phone && !!contact.email
    );

    return filteredArray.reduce((prev, next) => {
      return [
        ...prev,
        {
          ...next,
          checked: !!selected.find(c => c.id == next.id)
        }
      ];
    }, []);
  }

  handleChecking = key => () => {
    const newContacts = this.state.contacts.map(item => {
      if (item.id === key) {
        return {
          ...item,
          checked: !item.checked
        };
      } else {
        return item;
      }
    });

    this.setState({
      contacts: newContacts
    });
  };

  getAllChecked = () => {
    const chekedContacts = this.state.contacts.filter(
      item => item.checked === true
    );

    const needToFilter = ["checked", "status"];

    const filteredArray = chekedContacts.map(contact => {
      return Object.keys(contact)
        .filter(key => !needToFilter.includes(key))
        .reduce((obj, key) => {
          obj[key] = contact[key];
          return obj;
        }, {});
    });

    return filteredArray;
  };

  renderCheckboxes = () => {
    return this.state.contacts.map(contact => {
      const key = contact.id;
      return (
        <ListItem noBorder key={key}>
          <CheckBox
            checked={contact.checked}
            onPress={this.handleChecking(key)}
          />
          <Body>
            <Text style={{ marginLeft: 10 }}>{contact.name}</Text>
          </Body>
        </ListItem>
      );
    });
  };

  render() {
    return (
      <Modal
        isVisible={this.props.modalVisible}
        onBackdropPress={this.props.closeModal}>
        <View style={style.innerContainer}>
          <Content style={{ flex: 1 }}>
            {this.state.contacts.length === 0 ? (
              <View>
                <View style={style.noItemWrapper}>
                  <H3>{I18n.t("global.noItemFoundForList")}</H3>
                </View>
                <View style={style.buttonWrapper}>
                  <Button block primary onPress={this.props.closeModal}>
                    <Text style={{ color: "white" }}>
                      {I18n.t("global.close")}
                    </Text>
                  </Button>
                </View>
              </View>
            ) : (
              <View>
                <View style={{ padding: 15 }}>
                  <Text
                    style={{
                      color: variables.brandInfo,
                      fontWeight: "bold",
                      fontSize: 16
                    }}>
                    {I18n.t("editTask.selectContactsDescription")}
                  </Text>
                </View>
                <List>{this.renderCheckboxes()}</List>

                <View style={style.buttonWrapper}>
                  <Button
                    block
                    info
                    onPress={() =>
                      this.props.submitResult(this.getAllChecked())
                    }>
                    <Text style={{ color: "white" }}>
                      {I18n.t("tasks.addContacts")}
                    </Text>
                  </Button>
                </View>

                <View style={{ padding: 15 }}>
                  <Text style={{ color: "#808080", fontSize: 14 }}>
                    {I18n.t("editTask.selectContactsTip")}
                  </Text>
                </View>
              </View>
            )}
          </Content>
        </View>
      </Modal>
    );
  }
}

const style = StyleSheet.create({
  innerContainer: {
    width: "100%",
    height: 500,
    backgroundColor: "white",
    justifyContent: "center"
  },
  buttonWrapper: {
    width: "100%",
    paddingHorizontal: 10
  },
  noItemWrapper: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 20
  }
});
