import React, { Component } from "react";
import {
  Content,
  Form,
  Item,
  Label,
  Input,
  Button,
  Card,
  CardItem,
  CheckBox,
  List,
  ListItem,
  Text
} from "native-base";
import { StyleSheet, View, Keyboard } from "react-native";
import ValidatedInput from "../../../components/ValidatedInput";
import { updateGroup } from "../actions";
import { Screen, showToast } from "../../../utils";
import styles from "../../../styles";
import I18n from "../../../i18n";
import { lookup } from "../../../twilio";
import { emailPattern } from "../../../variables";

export default class ContactForm extends Component {
  state = {
    form: {
      name: "",
      phone: "",
      email: ""
    },
    twilio: false
  };

  handleInputChange = name => evt => {
    let newState = {
      form: {
        ...this.state.form,
        [name]: evt.nativeEvent.text
      }
    };
    if (name == "phone") {
      newState = {
        form: {
          ...newState.form,
          phoneformatted: "",
          phoneraw: ""
        },
        twilio: false
      };
    }

    this.setState(newState);
  };

  generatePhoneError = () => {
    switch (true) {
      case !this.state.form.phone || this.state.form.phone.length == 0:
        return I18n.t("errorMessages.phoneRequired");
      case this.state.twilio &&
        (!this.state.form.phoneformatted ||
          this.state.form.phoneformatted.length == 0):
        return I18n.t("errorMessages.phoneInvalid");
      default:
        return "";
    }
  };

  save = async () => {
    this.setState({ validating: true });
    if (this.state.form.phone && this.state.form.phone.length > 0) {
      showToast(I18n.t("twilio.lookup"));
      let data = await lookup(this.state.form.phone);
      if (!data) {
        data = { phone_number: this.state.form.phone, national_format: "" };
        showToast(I18n.t("twilio.invalid"), "danger");
      }

      this.setState({
        form: {
          ...this.state.form,
          phone: data.phone_number,
          phoneraw: this.state.form.phone,
          phoneformatted: data.national_format
        },
        twilio: true
      });
    }
    this.setState({ validating: false });
    this.props.onFormChanged({ ...this.state.form });
  };

  render() {
    return (
      <Card>
        <CardItem>
          <Form style={styles.container}>
            <ValidatedInput
              validInput={
                !!this.state.form.name && this.state.form.name.length > 0
              }
              handleInputChange={this.handleInputChange("name")}
              label={I18n.t("groups.contacts.form.name")}
              errorMessage={I18n.t("groups.contacts.errorMessage.name")}
              name="name"
              value={this.state.form.name}
              showError={this.props.submitted}
              getRef={ref => (this._name = ref)}
              returnKeyType="next"
              onSubmitEditing={() => this._phone._root.focus()}
            />
            <ValidatedInput
              validInput={
                this.state.form.phone.length > 0 &&
                (!this.state.twilio ||
                  (this.state.twilio &&
                    this.state.form.phoneformatted.length > 0))
              }
              handleInputChange={this.handleInputChange("phone")}
              label={I18n.t("groups.contacts.form.phone")}
              errorMessage={this.generatePhoneError()}
              name="phone"
              value={this.state.form.phone}
              showError={this.props.submitted}
              getRef={ref => (this._phone = ref)}
              keyboardType="phone-pad"
              returnKeyType="next"
              onSubmitEditing={() => this._email._root.focus()}
            />
            <ValidatedInput
              validInput={
                !!this.state.form.email &&
                this.state.form.email.length > 0 &&
                emailPattern.test(this.state.form.email)
              }
              handleInputChange={this.handleInputChange("email")}
              label={I18n.t("groups.contacts.form.email")}
              errorMessage={I18n.t(
                `groups.contacts.errorMessage.${
                this.state.form.email &&
                  this.state.form.email.length > 0 &&
                  !emailPattern.test(this.state.form.email)
                  ? "emailInvalid"
                  : "email"
                }`
              )}
              name="email"
              value={this.state.form.email}
              showError={this.props.submitted}
              getRef={ref => (this._email = ref)}
              keyboardType="email-address"
              returnKeyType="next"
              onSubmitEditing={() => this._name._root.focus()}
            />
          </Form>
        </CardItem>
        <CardItem style={styles.right}>
          <Button small primary bordered disabled={this.state.validating} onPress={this.save}>
            <Text uppercase={false}>{I18n.t("groups.contacts.save")}</Text>
          </Button>
        </CardItem>
      </Card>
    );
  }
}
