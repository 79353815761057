import React, { Component } from "react";
import {
  Content,
  Text,
  H3,
  Button,
  Card,
  CardItem,
  Left,
  Right,
  Body,
  Icon
} from "native-base";
import { View } from "react-native";
import { connect } from "react-redux";
import { volunteerDetails, invite } from "../actions";
import { sortString } from "../../../utils";
import I18n from "../../../i18n";
import styles from "../../../styles";
import { GET } from "../../../axios";
import { isOffline } from "../../../offline";
import style from "./style";
import variables from "../../../../native-base-theme/variables/platform";

export default connect(state => ({
  mode: state.groups.mode,
  group: state.groups.group,
  volunteers: state.groups.group.volunteers,
  offline: state.offline
}))(
  class Volunteers extends Component {
    _working = false;
    volunteerDetails = volunteer => async () => {
      if (this._working) return;
      this._working = true;
      const offline = await isOffline();
      if (!offline) {
        await volunteerDetails(volunteer);
        this.props.navigation.navigate("VolunteerDetails");
      }
      this._working = false;
    };

    render() {
      const { mode, volunteers, group } = this.props;

      return (
        <Content>
          <View style={style.padding}>
            {volunteers && volunteers.length > 0 ? (
              sortString(volunteers, "name").map((volunteer, index) => (
                <Card
                  key={`${volunteer.name}_${volunteer.phoneformatted}_${
                    volunteer.email
                  }_${index}`}
                >
                  <CardItem bordered>
                    <Left>
                      <Icon
                        name="contact"
                        style={{ color: variables.brandInfo }}
                      />
                      <Body>
                        <Text
                          style={{
                            fontWeight: "bold",
                            color: variables.brandInfo
                          }}
                        >
                          {volunteer.name}
                        </Text>
                      </Body>
                    </Left>
                    <Right>
                      <Button
                        info
                        small
                        bordered
                        onPress={this.volunteerDetails(volunteer)}
                      >
                        <Text uppercase={false}>
                          {I18n.t("groups.manage.manageVolunteers")}
                        </Text>
                      </Button>
                    </Right>
                  </CardItem>

                  <CardItem>
                    <Left>
                      <Icon name="call" style={{ fontSize: 14 }} />
                      <Body>
                        <Text style={{ fontSize: 14 }}>
                          {volunteer.phoneformatted || volunteer.phone}
                        </Text>
                      </Body>
                    </Left>
                  </CardItem>
                  <CardItem>
                    <Left>
                      <Icon name="mail" style={{ fontSize: 14 }} />
                      <Body>
                        <Text style={{ fontSize: 14 }}>{volunteer.email}</Text>
                      </Body>
                    </Left>
                  </CardItem>
                </Card>
              ))
            ) : (
              <View style={style.section}>
                <Text>{I18n.t("global.noItemFoundForList")}</Text>
              </View>
            )}

            {mode == "manage" ? (
              <View
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                  marginVertical: 15
                }}
              >
                <Button
                  info
                  small
                  iconLeft
                  transparent
                  onPress={() => invite(group)}
                >
                  <Icon name="md-person-add" style={{ fontSize: 14 }} />
                  <Text uppercase={false}>
                    {I18n.t("groups.manage.invite")}
                  </Text>
                </Button>
              </View>
            ) : (
              undefined
            )}
          </View>
        </Content>
      );
    }
  }
);
