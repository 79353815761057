import axios from "axios";
import { setSyncMode } from "./actions/auth";
import {
  getGroups,
  groupDetails,
  groupCategories,
  groupContacts,
  groupLeads,
  groupVolunteers
} from "./screens/groups/actions";
import groupTypes from "./screens/groups/actions/types";
import { getList, loadMore, resetList } from "./screens/dashboard/actions";
import taskTypes from "./screens/taskDetails/actions/types";
import { delay, showToast } from "./utils";
// import types from "./actions/types";
import store from "./store";
import I18n from "./i18n";
import * as connectionType from "./connectiontype";
import { syncGET } from "./axios";

export const ifModifiedSince = "__If-Modified-Since__";

let cancel = false;
// function backPress() {
//   cancel = true;
//   return true;
// }
function start() {
  cancel = false;
  // BackHandler.addEventListener("hardwareBackPress", backPress);
  // store.dispatch({ type: types.PROGRESS_START, modal: true });
}
function end() {
  // store.dispatch({ type: types.PROGRESS_END });
  // BackHandler.removeEventListener("hardwareBackPress", backPress);
}

let workerQueue = [];
export function queue(fn) {
  workerQueue.push(fn);
}
export async function process(force) {
  try {
    const state = store.getState();
    const offline = state.offline;
    const { syncMode, currentUser } = state.auth;
    if (
      offline ||
      (!force &&
        (syncMode == "none" ||
          (syncMode == "wifi" && !connectionType.isWifi()))) ||
      !currentUser
    ) {
      cancel = true;
      workerQueue = [];
      return false;
    }
    if (workerQueue.length > 0) {
      const result = await workerQueue[0]();
      if (result) {
        workerQueue.shift();
        return true;
      } else {
        // retry sync
        // workerQueue.push(workerQueue.shift());
        // await delay(200);
        // return true;
      }
    }
    return false;
  } catch (err) {
    console.error(err);
  }
}

export async function syncGroups(force) {
  try {
    await getGroups(true);
    const state = store.getState();
    const { joined, managed } = state.groups;
    const groups = [...joined, ...managed];
    if (groups.length > 0) {
      groups.forEach(group => {
        queue(async () => {
          try {
            const cache = state.groups.offline[group.id];
            if (!cache) {
              console.log(I18n.t("sync.syncGroup", { name: group.name }));
              if (await groupDetails(group.role, group, true)) {
                await Promise.all([
                  groupCategories(group, true),
                  groupContacts(group, true),
                  groupLeads(group, true),
                  groupVolunteers(group, true)
                ]);
              } else {
                console.log(
                  I18n.t("sync.groupFailed", { name: group.name }),
                  "danger"
                );
                return false;
              }
            } else if (
              false &&
              cache[ifModifiedSince] &&
              !store.getState().offline
            ) {
              try {
                const res = await axios.head(`/groups/${group.id}`, {
                  validateStatus: status => status == 304 || status == 200,
                  headers: {
                    "If-Modified-Since": cache[ifModifiedSince]
                  }
                });
                if (res && res.status == 200) {
                  store.dispatch({ type: groupTypes.SYNC_GROUP_DELETE, group });
                  return false;
                }
              } catch (err) {
                console.error(err);
                return false;
              }
            }
            return true;
          } catch (err) {
            console.error(err);
          }
        });
      });
    }
    while (await process(force)) {}
  } catch (err) {
    console.error(err);
  }
}

export async function syncTasks(force) {
  try {
    [
      { list: "Open", pages: 1 },
      { list: "Claimed", pages: Infinity }
    ].forEach(({ list, pages }) => {
      queue(async () => {
        try {
          const state = store.getState();
          const { joined, managed } = state.groups;
          if (joined && joined.length == 0 && managed && managed.length == 0) {
            return true;
          }
          const listState = state.dashboard[list];
          let tasks = [...((state.dashboard[list] || {}).list || [])];
          let count = tasks.length;
          if (tasks.length == 0 && !listState[ifModifiedSince]) {
            console.log(
              I18n.t("sync.tasks", {
                list: I18n.t(`sync.${list.toLowerCase()}`)
              })
            );
            await resetList(list);
            let page = 0;
            let result = await getList(0, list, true);
            while (result) {
              count += result.length;
              tasks = [...tasks, ...result];
              page++;
              if (page < pages) {
                result = await loadMore(list, true);
              } else {
                result = null;
              }
            }
          }
          if (tasks.length > 0) {
            let { task: currentTask } = state.taskDetails;
            tasks.forEach((task, index) => {
              queue(async () => {
                const cache = state.taskDetails.offline[task.id];
                if (!cache) {
                  console.log(
                    I18n.t("sync.syncTask", {
                      index: index + 1,
                      count,
                      list: I18n.t(`sync.${list.toLowerCase()}`)
                    })
                  );
                  const result = await syncGET(`/tasks/${task.id}`);
                  if (!result) {
                    console.log(
                      I18n.t("sync.taskFailed", { name: task.name }),
                      "danger",
                      3000
                    );
                    return false;
                  }
                  store.dispatch({
                    type: taskTypes.SYNC_GET_TASK,
                    task: result,
                    id: task.id
                  });
                  if (currentTask && currentTask.id == task.id) {
                    store.dispatch({
                      type: taskTypes.GET_TASK,
                      task: result,
                      id: task.id
                    });
                  }
                } else if (
                  false &&
                  cache[ifModifiedSince] &&
                  !store.getState().offline
                ) {
                  try {
                    const res = await axios.head(`/tasks/${task.id}`, {
                      validateStatus: status => status == 304 || status == 200,
                      headers: {
                        "If-Modified-Since": cache[ifModifiedSince]
                      }
                    });
                    if (res && res.status == 200) {
                      store.dispatch({
                        type: taskTypes.SYNC_TASK_DELETE,
                        group
                      });
                      return false;
                    }
                  } catch (err) {
                    console.error(err);
                    return false;
                  }
                }
                return true;
              });
            });
          }
          return true;
        } catch (err) {
          console.error(err);
        }
      });
    });
    while (await process(force)) {}
  } catch (err) {
    console.error(err);
  }
}

export async function sync(force = false) {
  try {
    const { syncMode } = store.getState().auth;
    // if (syncMode == null) {
    //   await new Promise((resolve, reject) => {
    //     Alert.alert(
    //       I18n.t("drawer.sync"),
    //       I18n.t("settings.sync.description"),
    //       [

    //         {
    //           text: I18n.t("sync.modes.always"),
    //           onPress: async () => {
    //             await setSyncMode("always");
    //             resolve();
    //           }
    //         },
    //         {
    //           text: I18n.t("sync.modes.wifi"),
    //           onPress: async () => {
    //             setSyncMode("wifi");
    //             if (connectionType.isWifi()) resolve();
    //             else reject();
    //           }
    //         },
    //         {
    //           text: I18n.t("sync.modes.none"),
    //           onPress: async () => {
    //             await setSyncMode("none");
    //             reject();
    //           }
    //         }
    //       ],
    //       {
    //         onDismiss: () => {
    //           reject();
    //         }
    //       }
    //     );
    //   });
    // } else if (
    if (
      !force &&
      (syncMode == "none" || (syncMode == "wifi" && connectionType.isWifi()))
    ) {
      return true;
    }

    start();
    await syncGroups(force);
    await syncTasks(force);
    end();

    if (!cancel) {
      console.log(I18n.t("sync.complete"));
    } else {
      console.log(I18n.t("sync.cancel"));
    }

    return !cancel;
  } catch (err) {}
}
