import React, { Component } from "react";
import { View, Platform } from "react-native";
import { Button, Text, Icon } from "native-base";
import { phonecall, text, email } from "react-native-communications";
import style from "../details/style";
import styles from "../../../styles";
import I18n from "../../../i18n";

export default class extends Component {
  render() {
    const { user, i18n } = this.props;

    return (
      <View style={styles.left}>
        {Platform.OS != "web" && (
          <Button
            bordered
            info
            small
            style={style.action}
            onPress={() => phonecall(user.phone, true)}>
            <Text uppercase={false}>{I18n.t(`${i18n}.callPhone`)}</Text>
          </Button>
        )}
        <Button
          bordered
          info
          small
          style={style.action}
          onPress={() => text(user.phone)}>
          <Text uppercase={false}>{I18n.t(`${i18n}.sendText`)}</Text>
        </Button>
        <Button
          bordered
          info
          small
          style={style.action}
          onPress={() => email([user.email], null, null, null, null)}>
          <Text uppercase={false}>{I18n.t(`${i18n}.sendMail`)}</Text>
        </Button>
      </View>
    );
  }
}
