import { Alert, Linking, Platform, AsyncStorage } from "react-native";
import axios from "axios";
import { discoversion, namespace } from "./const";
import I18n from "./i18n";
import { useApi } from "./axios";
import { useTwilio } from "./twilio";
import { useFB_PAGE_ID, manifest } from "./const";

const MessageType = Object.freeze({
  0: "warning",
  1: "information",
  2: "blocked"
});

export async function getSettings(settingsOnly) {
  const body = {
    namespace,
    version: manifest.version || "2.0.2",
    platform: Platform.OS == "web" ? location.host : Platform.OS.toUpperCase()
  };
  let settings = {};
  let messages = null;
  let name = namespace;
  try {
    const { data } = await axios
      .create({
        responseType: "json",
        headers: {
          "Content-Type": "application/json"
        }
      })
      .post(discoversion, body);
    (data.Settings || []).reduce((prev, it) => {
      prev[it.EndpointName || it.Name] = it.EndpointValue || it.Value;
      return prev;
    }, settings);
    messages = data.Messages;
    name = data.Name;
  } catch (err) {
    console.log(err);
  }

  if (settingsOnly) return settings;

  try {
    if (messages) {
      for (let i = 0; i < messages.length; i++) {
        await (message => {
          return new Promise(async (resolve, reject) => {
            const type =
              typeof message.Type == "number"
                ? MessageType[message.Type]
                : message.Type.toLowerCase();
            const buttons =
              message.MessageButtons && message.MessageButtons.length
                ? message.MessageButtons
                : [{ Title: "OK" }];
            const title =
              message.Title ||
              name ||
              I18n.t(type.toUpperCase().slice(0, 1) + type.slice(1), {
                defaultValue: "4Bells"
              });
            if (type != "blocked" && message.Repeat) {
              const key = `messageoftheday_${name}_${message.Id}_${type}_${
                message.Repeat
              }`;
              let repeat = Math.max(
                -1,
                +((await AsyncStorage.getItem(key)) || message.Repeat) - 1
              );
              if (repeat < 0) {
                return resolve();
              } else {
                await AsyncStorage.setItem(key, `${repeat}`);
              }
            }
            Alert.alert(
              I18n.t(title, { defaultValue: title }),
              I18n.t(message.Message, { defaultValue: message.Message }),
              buttons.map(button => ({
                text: I18n.t(button.Title, { defaultValue: button.Title }),
                onPress: async () => {
                  if (button.Url && Linking.canOpenURL(button.Url)) {
                    setTimeout(() => Linking.openURL(button.Url));
                  }
                  if (type == "blocked") return reject();
                  return resolve();
                }
              })),
              {
                cancelable: false
              }
            );
          });
        })(messages[i]);
      }
    }
    return settings;
  } catch (err) {
    return false;
  }
}

export default async function(settingsOnly) {
  const settings = await getSettings(settingsOnly);
  if (settings) {
    if (settings.rest_api && (Platform.OS == "web" || !__DEV__))
      useApi(settings.rest_api);
    if (
      settings.twilio_sid &&
      settings.twilio_token &&
      (Platform.OS == "web" || !__DEV__)
    )
      useTwilio(settings.twilio_sid, settings.twilio_token);
    if (settings.FB_PAGE_ID && (Platform.OS == "web" || !__DEV__))
      useFB_PAGE_ID(settings.FB_PAGE_ID);
    return true;
  } else {
    return false;
  }
}
