import React, { Component } from "react";
import {
  Content,
  Card,
  CardItem,
  Body,
  Right,
  CheckBox,
  Icon,
  Text,
  ListItem,
  H3
} from "native-base";
import { View, Alert } from "react-native";
import { connect } from "react-redux";
import { categorySubscribe, categoryUnsubscribe } from "../actions";
import I18n from "../../../i18n";
import styles from "../../../styles";
import { showToast, sortString } from "../../../utils";
import style from "./style";
import variables from "../../../../native-base-theme/variables/platform";

export default connect(state => ({
  group: state.groups.group,
  currentUser: state.auth.currentUser,
  categories: [...(state.groups.group.categories || [])],
  offline: state.offline
}))(
  class Subscription extends Component {
    _working = false;
    subscriptionChanged = category => async () => {
      if (this._working) return;
      this._working = true;
      category.subscription = !category.subscription;
      const { group, currentUser } = this.props;
      await (category.subscription ? categorySubscribe : categoryUnsubscribe)(
        group,
        category,
        currentUser
      );
      showToast(
        I18n.t(
          `groups.categories.${
          category.subscription ? "subscribe" : "unsubscribe"
          }`
        )
      );
      this._working = false;
    };

    render() {
      const { categories, offline } = this.props;

      return (
        <Content>
          <View >
            <View style={style.padding}>
              <Text style={{ fontWeight: "200", paddingVertical: 5 }}>{I18n.t("groups.categories.selectCategoriesPrompt")}</Text>
            </View>
            {categories && categories.length > 0 ? (
              <View style={{ paddingVertical: 5 }}>
                {sortString(categories, "name").map((category, index) => (
                  <ListItem noBorder key={`${category.name}_${index}`}>
                    {!offline ? (
                      <CheckBox
                        checked={category.subscription}
                        onPress={this.subscriptionChanged(category)}
                      />
                    ) : category.subscription ? (
                      <Icon
                        name="checkmark"
                        style={{ color: variables.brandInfo }}
                      />
                    ) : null}
                    <Body>
                      <Text style={{ fontWeight: "bold" }}>{category.name}</Text>
                    </Body>
                  </ListItem>

                ))}
              </View>
            ) : (
                <Text style={{ paddingVertical: 15 }}>{I18n.t("global.noItemFoundForList")}</Text>
              )}
          </View>
        </Content>
      );
    }
  }
);
