import React, { Component } from "react";
import { View, Image, StyleSheet, Alert } from "react-native";
import {
  Content,
  Item,
  H3,
  Picker,
  Form,
  Button,
  Text,
  Icon
} from "native-base";
import { Screen, showToast } from "../../utils";
import styles from "../../styles";
import variable from "../../../native-base-theme/variables/platform";
import I18n from "../../i18n";
import moment from "moment";
import { NavigationActions, StackActions } from "react-navigation";
import { connect } from "react-redux";
import ConnectHeader from "../../components/ConnectHeader";
import { createNewTask, updateTask } from "./actions";

const ConnectedHeader = connect(state => ({
  taskDetails: state.task.taskDetails.task,
  mode: state.task.taskDetails.mode
}))(props => {
  return <ConnectHeader title={props.taskDetails.name} {...props} />;
});

export default Screen(
  connect(state => ({
    categories: state.task.categories.categories,
    taskDetails: state.task.taskDetails.task,
    mode: state.task.taskDetails.mode
  }))(
    class Review extends Component {
      _working = false;
      submitTask = async () => {
        if (this._working) return;
        this._working = true;

        const success =
          this.props.mode === "add"
            ? await createNewTask(this.props.taskDetails)
            : await updateTask(
                this.props.taskDetails.id,
                this.props.taskDetails
              );

        if (success) {
          this.props.navigation.dispatch(
            NavigationActions.navigate({
              routeName: "Main",
              action: NavigationActions.navigate({
                routeName: "Tasks"
              })
            })
          );
        } else {
          showToast(
            I18n.t(`editTask.errorMessages.${this.props.mode}Failed`),
            "danger",
            3000
          );
        }

        this._working = false;
      };

      render() {
        return (
          <Content container>
            <View style={style.containerStyle}>
              <View style={style.panelStyle}>
                <H3 style={{ color: variable.brandInfo }}>
                  {I18n.t("editTask.headerTitles.review")}
                </H3>
                <View style={style.section}>
                  <Text uppercase={true} style={style.sectionLabel}>
                    {I18n.t("editTask.title")}
                  </Text>
                  <Text>{this.props.taskDetails.name}</Text>
                </View>

                <View style={style.section}>
                  <Text uppercase={true} style={style.sectionLabel}>
                    {I18n.t("global.numberVolunteers")}
                  </Text>
                  <Text>{this.props.taskDetails.volunteerneeded}</Text>
                </View>

                <View style={style.section}>
                  <Text uppercase={true} style={style.sectionLabel}>
                    {I18n.t("global.briefDesc")}
                  </Text>
                  <Text>{this.props.taskDetails.description}</Text>
                </View>

                <View style={style.section}>
                  <Text uppercase={true} style={style.sectionLabel}>
                    {I18n.t("global.startDate")}
                  </Text>
                  <Text>
                    {" "}
                    {moment(this.props.taskDetails.startdate).format("L LT")}
                  </Text>
                </View>

                {!!this.props.taskDetails.enddate && (
                  <View style={style.section}>
                    <Text uppercase={true} style={style.sectionLabel}>
                      {I18n.t("global.endDate")}
                    </Text>
                    <Text>
                      {" "}
                      {moment(this.props.taskDetails.enddate).format("L LT")}
                    </Text>
                  </View>
                )}

                {!!this.props.taskDetails.startlocation &&
                  !!this.props.taskDetails.startlocation.address && (
                    <View style={style.section}>
                      <Text uppercase={true} style={style.sectionLabel}>
                        {I18n.t("global.startLocation")}
                      </Text>
                      <Text>
                        {this.props.taskDetails.startlocation.address ||
                          `${this.props.taskDetails.startlocation.lat}, ${this.props.taskDetails.startlocation.lng}`}
                      </Text>
                    </View>
                  )}

                {!!this.props.taskDetails.endlocation &&
                  !!this.props.taskDetails.endlocation.address && (
                    <View style={style.section}>
                      <Text uppercase={true} style={style.sectionLabel}>
                        {I18n.t("global.endLocation")}
                      </Text>
                      <Text>
                        {this.props.taskDetails.endlocation.address ||
                          `${this.props.taskDetails.endlocation.lat}, ${this.props.taskDetails.endlocation.lng}`}
                      </Text>
                    </View>
                  )}

                <View style={style.section}>
                  <Text uppercase={true} style={style.sectionLabel}>
                    {I18n.t("global.categories")}
                  </Text>
                  <View style={style.row}>
                    {this.props.taskDetails.categories.map((item, key) => {
                      return (
                        <Text key={key} style={style.margin}>
                          {item.name}
                        </Text>
                      );
                    })}
                  </View>
                </View>
              </View>
              <View style={{ padding: 15 }}>
                <Button block info onPress={() => this.submitTask()}>
                  <Text uppercase={false}>{I18n.t("editTask.submit")}</Text>
                </Button>
              </View>
            </View>
          </Content>
        );
      }
    }
  ),
  props => <ConnectedHeader {...props} />
);

const style = StyleSheet.create({
  containerStyle: {},
  section: {
    paddingVertical: 5
  },
  sectionLabel: {
    color: "#808080",
    fontWeight: "bold",
    fontSize: 12,
    marginVertical: 3
  },
  row: {
    flexDirection: "column"
  },
  margin: {
    marginHorizontal: 5
  },
  panelStyle: {
    padding: 15,
    marginTop: 10,
    backgroundColor: "#FFFFFF"
  },
  panelTitle: {
    fontWeight: "bold"
  },
  panelTitleRequired: {
    color: variable.brandInfo
  }
});
