import React, { Component } from "react";
import { View, ActivityIndicator } from "react-native";
import { connect } from "react-redux";
import styles from "../styles";
import variable from "../../native-base-theme/variables/platform";

export default connect(state => ({
  progress: state.progress.active,
  modalProgress: state.progress.modal
}))(
  class Progress extends Component {
    render() {
      if (this.props.progress) {
        return (
          <View
            style={
              this.props.modalProgress
                ? [
                    styles.backgroundImage,
                    { backgroundColor: "rgba(0, 0, 0, 0.2)" }
                  ]
                : { height: 50 }
            }
          >
            <ActivityIndicator
              animating={true}
              size="large"
              color={variable.toolbarDefaultBg}
              style={styles.backgroundImage}
            />
          </View>
        );
      }

      return null;
    }
  }
);
