import { createStackNavigator } from "react-navigation-stack";
import Groups from "./groups";
import Connect from "./connect";
import Profile from "./profile";
import Edit from "./edit";
import CategoryVolunteers from "./categoryvolunteers";
import VolunteerDetails from "./volunteerdetails";
import Categories from "./categories";
import Contacts from "./contacts";
import Details from "./details";
import Created from "./created";
import { transitionConfig } from "../../utils";

export default createStackNavigator(
  {
    Groups,
    Created,
    Connect,
    Profile,
    Categories,
    Contacts,
    Details,
    Edit,
    CategoryVolunteers,
    VolunteerDetails
  },
  {
    initialRouteName: "Groups",
    headerMode: "none",
    transitionConfig
  }
);
