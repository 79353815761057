import React, { Component } from "react";
import {
  Content,
  Form,
  Item,
  Label,
  Input,
  Button,
  Card,
  CardItem,
  CheckBox,
  List,
  ListItem,
  Text,
  Icon,
  Body,
  Left,
  Right
} from "native-base";
import { StyleSheet, View, Keyboard } from "react-native";
import ContactForm from "./components/ContactForm";
import { updateGroup, groupDetails } from "./actions";
import { Screen } from "../../utils";
import styles from "../../styles";
import variables from "../../../native-base-theme/variables/platform";
import I18n from "../../i18n";
import { emailPattern } from "../../variables";
import { connect } from "react-redux";

export default Screen(
  connect(state => ({
    contacts: state.groups.group.contacts
  }))(
    class Categories extends Component {
      state = {
        contacts: [...this.props.contacts],
        form: null
      };

      create = () => {
        this.setState({
          form: {
            name: null,
            phone: null,
            email: null
          },
          submitted: false
        });
      };

      remove = contact => {
        this.setState({
          contacts: this.state.contacts.filter(it => it != contact)
        });
      };

      save = contact => {
        if (
          contact.name &&
          contact.name.length > 0 &&
          contact.phone &&
          contact.phone.length > 0 &&
          contact.phoneformatted &&
          contact.phoneformatted.length > 0 &&
          contact.email &&
          contact.email.length > 0 &&
          emailPattern.test(contact.email)
        ) {
          const result = [
            ...this.state.contacts,
            {
              ...this.state.form,
              ...contact
            }
          ];

          updateGroup({ contacts: [...result] });

          this.setState({
            contacts: result,
            form: null,
            submitted: false
          });
        } else {
          this.setState({
            submitted: true
          });
        }
      };

      next = () => {
        updateGroup({ contacts: [...this.state.contacts] });
        groupDetails("review");
        this.props.navigation.navigate("Details");
      };

      render() {
        return (
          <Content container>
            <View>
              <View
                style={{
                  backgroundColor: "#FFFFFF",
                  marginVertical: 15,
                  padding: 15
                }}>
                <Text>{I18n.t("groups.contacts.description")}</Text>

                <View style={style.margin}>
                  {!this.state.form ? (
                    <View
                      style={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                        marginVertical: 15
                      }}>
                      <Button
                        small
                        iconLeft
                        transparent
                        bordered
                        info
                        onPress={this.create}>
                        <Icon
                          name="ios-add-circle-outline"
                          style={{ fontSize: 14 }}
                        />
                        <Text uppercase={false}>
                          {I18n.t("groups.contacts.add")}
                        </Text>
                      </Button>
                    </View>
                  ) : (
                    <View container>
                      <ContactForm
                        onFormChanged={this.save}
                        submitted={this.state.submitted}
                      />
                    </View>
                  )}

                  {this.state.contacts.map((contact, index) => (
                    <Card
                      key={`${contact.name}_${contact.phone}_${contact.email}_${index}`}
                      style={{ marginVertical: 15 }}>
                      <CardItem bordered>
                        <Left>
                          <Icon
                            name="contact"
                            style={{ color: variables.brandInfo }}
                          />
                          <Body>
                            <Text
                              style={{
                                fontWeight: "bold",
                                color: variables.brandInfo
                              }}>
                              {contact.name}
                            </Text>
                          </Body>
                        </Left>
                        <Right>
                          <Button
                            danger
                            small
                            transparent
                            onPress={() => this.remove(contact)}>
                            <Text uppercase={false}>
                              {I18n.t("groups.contacts.remove")}
                            </Text>
                          </Button>
                        </Right>
                      </CardItem>
                      <CardItem>
                        <Left>
                          <Icon name="call" style={{ fontSize: 14 }} />
                          <Body>
                            <Text style={{ fontSize: 14 }}>
                              {contact.phone}
                            </Text>
                          </Body>
                        </Left>
                      </CardItem>
                      <CardItem>
                        <Left>
                          <Icon name="mail" style={{ fontSize: 14 }} />
                          <Body>
                            <Text style={{ fontSize: 14 }}>
                              {contact.email}
                            </Text>
                          </Body>
                        </Left>
                      </CardItem>
                    </Card>
                  ))}
                </View>
              </View>
              <View style={{ marginVertical: 15, paddingHorizontal: 15 }}>
                <Button iconRight block info onPress={this.next}>
                  <Text uppercase={false}>
                    {I18n.t("groups.contacts.continue")}
                  </Text>
                  <Icon name="ios-arrow-forward" />
                </Button>
              </View>
            </View>
          </Content>
        );
      }
    }
  ),
  _ => I18n.t("groups.contacts.title")
);

const style = StyleSheet.create({
  padding: {
    padding: 15
  },
  margin: {
    marginVertical: 15
  }
});
