import React, { Component } from "react";
import { Content, H2, H3, Text, Button } from "native-base";
import { View, Platform } from "react-native";
import { connect } from "react-redux";
import { invite } from "./actions";
import { Screen } from "../../utils";
import I18n from "../../i18n";
import styles from "../../styles";
import style from "./details/style";
import variable from "../../../native-base-theme/variables/platform";
import TabBar from "../../components/TabBar";
import variables from "../../../native-base-theme/variables/platform";

export default Screen(
  connect(state => ({
    group: state.groups.group
  }))(
    class Created extends Component {
      render() {
        const { group } = this.props;

        return (
          <Content container>
            <View style={style.padding}>
              <View style={style.margin}>
                <H2 style={{ color: variables.brandInfo }}>
                  {I18n.t("groups.created.heading")}
                </H2>
              </View>
              <View style={style.section}>
                <H3>{I18n.t("groups.created.groupName")}</H3>
                <Text>{group.profile.name}</Text>
              </View>
              <View style={style.section}>
                <H3>{I18n.t("groups.created.connectionCode")}</H3>
                <Text>{group.profile.connectioncode}</Text>
              </View>
              <View style={style.section}>
                <Text style={{ fontWeight: "200" }}>
                  {I18n.t("groups.created.description")}
                </Text>
              </View>
              <View style={style.margin}>
                <Button info bordered block onPress={() => invite(group)}>
                  <Text uppercase={false}>
                    {I18n.t("groups.created.invite")}
                  </Text>
                </Button>
              </View>
            </View>
          </Content>
        );
      }
    }
  ),
  _ => I18n.t("groups.created.title"),
  props => <TabBar {...props} currentScreen={"Groups"} />
);
