import { GET, POST, DELETE } from "../../../axios";
import store from "../../../store";
import types from "./types";
import { buildWP, showToast } from "../../../utils";
import axios from "axios";
import jsonp from "jsonp";
import { Platform } from "react-native";
import { BING_ADDRESS, BING_KEY } from "../../../const";
import { offlineGuard } from "../../../offline";
import {
  claimTaskList,
  declineTaskList,
  completeTaskList,
  unclaimTaskList,
  cancelTask
} from "../../dashboard/actions";
import I18n from "../../../i18n";

export async function getTask(id) {
  return await offlineGuard(
    () => {
      const cache = store.getState().taskDetails.offline[id];
      if (cache) {
        const task = { ...cache };
        store.dispatch({
          type: types.GET_TASK,
          id,
          task
        });
        return task;
      }
    },
    async () => {
      const result = await GET(`/tasks/${id}`);
      store.dispatch({ type: types.GET_TASK, task: result, id });
      return result;
    }
  );
}

async function afterActionFailed(id, msg) {
  const task = await GET(`/tasks/${id}`);
  if (task) {
    store.dispatch({ type: types.GET_TASK, task, id });
    showToast(
      I18n.t(
        `taskDetails.actions.${
          task.status == "recalled" ? "taskRemoved" : msg(task.status)
        }`
      ),
      "danger",
      3000
    );
  } else {
    showToast(I18n.t("taskDetails.actions.missingTask"), "danger", 3000);
  }
  return false;
}

export async function claimTask(id) {
  const result = await POST("/user/tasks", [
    {
      id: id,
      status: "claim"
    }
  ]);
  if (!result || (result && !result[0])) {
    return await afterActionFailed(id, status => "noMoreSpacesLeft");
  }

  const task = store.getState().taskDetails.task;

  const user = store.getState().auth.currentUser;
  const modifiedTask = {
    ...task,
    status: "claimed",
    claimedUser: task.volunteerneeded == 1 ? user : task.claimedUser,
    claims: [
      ...task.claims,
      {
        status: "claimed",
        createdAt: new Date(),
        user
      }
    ]
  };

  claimTaskList(modifiedTask);

  store.dispatch({ type: types.GET_TASK, task: modifiedTask });
  return true;
}

export async function unclaimTask(id, user) {
  const result = await POST(user ? `/users/${user.id}/tasks` : "/user/tasks", [
    {
      id: id,
      status: "unclaim"
    }
  ]);
  if (!result || (result && !result[0])) {
    return await afterActionFailed(id, () => "unclaimError");
  }

  const state = store.getState();
  const task = state.taskDetails.task;
  user = user || state.auth.currentUser;
  const modifiedTask = {
    ...task,
    status: "open",
    claims: task.claims.filter(claim => claim.user.id !== user.id)
  };

  unclaimTaskList(modifiedTask);

  store.dispatch({ type: types.GET_TASK, task: modifiedTask });
  return true;
}

export async function declineTask(id) {
  const result = await POST("/user/tasks", [
    {
      id: id,
      status: "decline"
    }
  ]);

  if (!result || (result && !result[0])) {
    return await afterActionFailed(id, () => "declineError");
  }

  const task = store.getState().taskDetails.task;
  const user = store.getState().auth.currentUser;

  const modifiedTask = {
    ...task,
    claims: [
      ...task.claims.filter(claim => claim.user.id !== user.id),
      {
        createdAt: new Date(),
        user: user,
        ...(task.claims.find(claim => claim.user.id === user.id) || {}),
        status: "decline"
      }
    ]
  };

  declineTaskList(modifiedTask);
  store.dispatch({ type: types.GET_TASK, task: modifiedTask });
  return true;
}

export async function markCompleteTask(id, user) {
  const result = await POST(user ? `/users/${user.id}/tasks` : "/user/tasks", [
    {
      id: id,
      status: "complete"
    }
  ]);
  if (!result || (result && !result[0])) {
    return await afterActionFailed(id, () => "markCompleteError");
  }

  const state = store.getState();
  const task = state.taskDetails.task;
  user = user || state.auth.currentUser;

  if (!task.canEdit) {
    showToast(I18n.t("global.thankYou"), "success");
  }

  const modifiedTask = {
    ...task,
    status: "closed",
    claims: task.claims.reduce((prev, next) => {
      if (next.user.id === user.id) {
        return [
          ...prev,
          {
            ...next,
            status: "closed"
          }
        ];
      } else {
        return [...prev, ...next];
      }
    }, [])
  };

  completeTaskList(modifiedTask);

  store.dispatch({ type: types.GET_TASK, task: modifiedTask });
  return true;
}

export async function markIncompleteTask(id, user) {
  const result = await POST(`/users/${user.id}/tasks`, [
    {
      id: id,
      status: "incomplete"
    }
  ]);
  if (!result || (result && !result[0])) {
    return await afterActionFailed(id, () => "markIncompleteError");
  }

  const state = store.getState();
  const task = state.taskDetails.task;

  const modifiedTask = {
    ...task,
    status: "claimed",
    claims: task.claims.reduce((prev, next) => {
      if (next.user.id === user.id) {
        return [
          ...prev,
          {
            ...next,
            status: "claimed"
          }
        ];
      } else {
        return [...prev, ...next];
      }
    }, [])
  };

  claimTaskList(modifiedTask);

  store.dispatch({ type: types.GET_TASK, task: modifiedTask });
  return true;
}

export async function removeTask(id) {
  await DELETE(`/tasks/${id}`);
  const task = store.getState().taskDetails.task;
  cancelTask(task);
  return true;
}

export async function getDirections(startPos, endPos, currentPos) {
  return await offlineGuard(
    () => null,
    async () => {
      try {
        store.dispatch({ type: types.PROGRESS_START });

        let response = null;
        if (Platform.OS == "web") {
          response = await new Promise((resolve, reject) => {
            jsonp(
              `${BING_ADDRESS}${buildWP(
                startPos,
                endPos,
                currentPos
              )}&routePathOutput=Points&output=json&jsonp=BING_CALLBACK&key=${BING_KEY}`,
              {
                name: "BING_CALLBACK"
              },
              (err, data) => {
                if (err) return reject(err);
                resolve({ data });
              }
            );
          });
        } else {
          response = await axios.get(
            `${BING_ADDRESS}${buildWP(
              startPos,
              endPos,
              currentPos
            )}&routePathOutput=Points&output=json&key=${BING_KEY}`
          );
        }
        store.dispatch({ type: types.PROGRESS_END });

        const modifiedRoute = response.data.resourceSets[0].resources[0].routePath.line.coordinates.map(
          it => {
            return {
              latitude: it[0],
              longitude: it[1]
            };
          }
        );

        store.dispatch({ type: types.GET_DIRECTION, direction: modifiedRoute });
        return modifiedRoute;
      } catch (err) {
        console.error(err);
      }
    }
  );
}

export function updateTaskDetails(task, id) {
  store.dispatch({ type: types.GET_TASK, task, id });
}
