import React, { Component } from "react";
import { Header, Left, Button, Body, Title, Right, Icon } from "native-base";
import I18n from "../i18n";

export default class CustomPickerHeader extends Component {
  render() {
    return (
      <Header>
        <Left>
          <Button transparent onPress={this.props.backAction}>
            <Icon name="arrow-back" style={{ color: "#fff" }} />
          </Button>
        </Left>
        <Body style={{ flex: 3 }}>
          <Title style={{ color: "#fff" }}>{I18n.t("global.selecetOne")}</Title>
        </Body>
        <Right />
      </Header>
    );
  }
}
