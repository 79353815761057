import { inlineGET as GET, DELETE } from "../../../axios";
import store from "../../../store";
import { offlineGuard } from "../../../offline";
import { showToast } from "../../../utils";
import I18n from "../../../i18n";
import types from "../actions/types";

const paginationSize = 10;

const fetching = {
  alert: false,
  "warning,info": false
};
export async function getList(page = 0, list) {
  if (fetching[list]) {
    return;
  }
  fetching[list] = true;

  const result = await offlineGuard(async () => {
    const result = await GET(
      `/activities?page=${page}&size=${paginationSize}&type=${list}`
    );

    if (result) {
      const loadMore = result.length == paginationSize;

      if (page == 0) {
        resetList(list);
      }
      store.dispatch({
        type: types.ACTIVITY_GET_LIST,
        page,
        list,
        result,
        loadMore
      });

      return result;
    }
  });

  fetching[list] = false;
  return result;
}

export async function loadMore(list) {
  const { page, loadMore } = store.getState().activities[list];
  if (loadMore) {
    await getList(page + 1, list);
  }
}

export function resetList(list) {
  fetching[list] = false;
  store.dispatch({ type: types.ACTIVITY_RESET_LIST, list });
}

export async function removeActivity(item) {
  await DELETE(`/activities/${item.id}`);
  const { error } = store.getState();
  if (!error) {
    store.dispatch({ type: types.ACTIVITY_REMOVE, item });
  }
}
