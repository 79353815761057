import types from "../actions/types";
import { ifModifiedSince } from "../../../sync";

const initalState = {
  Open: {
    loadMore: true,
    page: 0,
    list: null,
    [ifModifiedSince]: null
  },
  Claimed: {
    loadMore: true,
    page: 0,
    list: null,
    [ifModifiedSince]: null
  },
  Closed: {
    loadMore: true,
    page: 0,
    list: null,
    [ifModifiedSince]: null
  }
};

export default (state = initalState, action) => {
  switch (action.type) {
    case types.GET_LIST:
      return {
        ...state,
        [action.list]: {
          list:
            action.page == 0
              ? [...action.result]
              : [...(state[action.list].list || []), ...action.result],
          page: action.page,
          loadMore: action.loadMore,
          [ifModifiedSince]: new Date()
        }
      };
    case types.RESET_LIST:
      return {
        ...state,
        [action.list]: {
          list: [],
          page: 0,
          loadMore: true,
          [ifModifiedSince]: null
        }
      };
    case types.ADD_NEW_TASK: {
      return {
        ...state,
        Open: {
          list: [...(state.Open.list || []), action.task]
        }
      };
    }
    case types.UPDATE_TASK_IN_LIST: {
      const newState = {
        ...state,
        [action.task.status.capitalize()]: {
          ...state[action.task.status.capitalize()],
          list: [
            ...(state[action.task.status.capitalize()].list || []).reduce(
              (prev, next) => {
                if (next.id !== action.task.id) {
                  return [...prev, next];
                } else {
                  return [...prev, action.task];
                }
              },
              []
            )
          ]
        }
      };

      return {
        ...newState
      };
    }
    case types.LOGOUT:
      return {
        ...initalState
      };

    case types.CLAIM_TASK: {
      return {
        ...state,
        Open: {
          ...state.Open,
          list: [
            ...(state.Open.list || []).filter(
              item => item.id !== action.task.id
            )
          ]
        },
        Claimed: {
          ...state.Claimed,
          list: [...(state.Claimed.list || []), action.task]
        }
      };
    }
    case types.UNCLAIM_TASK: {
      return {
        ...state,
        Claimed: {
          ...state.Claim,
          list: [
            ...(state.Claimed.list || []).filter(
              item => item.id !== action.task.id
            )
          ]
        },
        Open: {
          ...state.Open,
          list: [...(state.Open.list || []), action.task]
        }
      };
    }
    case types.DECLINE_TASK: {
      return {
        ...state,
        Open: {
          ...state.Open,
          list: [
            ...(state.Open.list || []).filter(
              item => item.id !== action.task.id
            )
          ]
        }
      };
    }
    case types.COMPLETE_TASK: {
      return {
        ...state,
        Claimed: {
          ...state.Claimed,
          list: [
            ...(state.Claimed.list || []).filter(
              item => item.id !== action.task.id
            )
          ]
        },
        Closed: {
          ...state.Closed,
          list: [...(state.Closed.list || []), action.task]
        }
      };
    }
    case types.CANCEL_TASK: {
      return {
        ...state,
        [action.task.status.capitalize()]: {
          ...state[action.task.status.capitalize()],
          list: [
            ...(state[action.task.status.capitalize()].list || []).filter(
              task => task.id !== action.task.id
            )
          ]
        }
      };
    }
    default:
      return state;
  }
};
