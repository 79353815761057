import variable from "./../variables/platform";

export default (variables = variable) => {
  const iconTheme = {
    fontSize: variables.iconFontSize,
    color: "#000",
    ".compact": {
      fontSize: 14,
      marginLeft: "10%"
    }
  };

  return iconTheme;
};
