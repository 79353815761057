import React, { Component } from "react";
import {
  Content,
  Text,
  Icon,
  Button,
  Right,
  H3,
  H1,
  Card,
  CardItem,
  View
} from "native-base";
import Hyperlink from "react-native-hyperlink";
import { sortAlpha } from "../../utils";
import I18n from "../../i18n";
import styles from "../../styles";
import variables from "../../../native-base-theme/variables/platform";
import { editTask } from "../singleTask/actions";
import { connect } from "react-redux";
import moment from "moment";
import {
  claimTask,
  declineTask,
  markCompleteTask,
  unclaimTask
} from "./actions";

export default connect(state => ({
  task: state.taskDetails.task,
  currentUser: state.auth.currentUser,
  offline: state.offline
}))(
  class Details extends Component {
    editTask = async () => {
      await editTask(this.props.task);
      this.props.navigation.navigate("EditTask");
    };

    generateText(array) {
      return sortAlpha(array, "name").map((item, index) => {
        return <Text key={index}>{item.name}</Text>;
      });
    }

    generateVolunteerText(array) {
      return array.reduce((prev, next, index) => {
        return prev + index > 0
          ? " "
          : "" + next.user.firstname + " " + next.user.lastname;
      }, "");
    }

    isDeclinedTask = () => {
      return (
        this.props.task.claims.length > 0 &&
        this.props.task.claims.some(c => {
          return (
            c.user.id == this.props.currentUser.id && c.status == "decline"
          );
        }) &&
        !this.props.task.claims.some(c => {
          return (
            c.user.id == this.props.currentUser.id && c.status !== "decline"
          );
        })
      );
    };

    isClaimEnabled = () => {
      return (
        this.props.task.status == "open" &&
        (this.props.task.claims.length === 0 ||
          (this.props.task.claims.length > 0 &&
            !this.props.task.claims.some(c => {
              return (
                c.user.id == this.props.currentUser.id && c.status !== "decline"
              );
            })))
      );
    };

    isDeclineEnabled = () => {
      return (
        this.props.task &&
        !this.props.task.canEdit &&
        this.props.task.status == "open" &&
        (this.props.task.claims.length === 0 ||
          (this.props.task.claims.length > 0 &&
            !this.props.task.claims.some(c => {
              return (
                c.user.id == this.props.currentUser.id &&
                (c.status == "claimed" ||
                  c.status == "closed" ||
                  c.status === "decline")
              );
            })))
      );
    };

    isUnClaimEnabled = () => {
      return (
        (this.props.task &&
          this.props.task.claims &&
          this.props.task.claims.some(c => {
            return (
              c.user.id == this.props.currentUser.id && c.status == "claimed"
            );
          })) ||
        (this.props.task.status == "claimed" && this.props.task.canEdit)
      );
    };

    isCompleteEnabled = () => {
      return (
        (this.props.task &&
          this.props.task.claims.length > 0 &&
          this.props.task.claims.some(c => {
            return (
              c.user.id == this.props.currentUser.id && c.status == "claimed"
            );
          })) ||
        (this.props.task.status != "closed" && this.props.task.canEdit)
      );
    };

    countClaims = () => {
      return this.props.task.claims.filter(
        claim => claim.status === "claimed" || claim.status === "closed"
      ).length;
    };

    renderButtons = () => {
      if (this.props.offline) return null;
      if (this.props.task.canEdit && this.props.task.status !== "closed") {
        return (
          <View style={{ marginVertical: 5 }}>
            <Button
              bordered
              small
              info
              onPress={this.editTask}
              style={styles.button}>
              <Text uppercase={false}>
                {I18n.t("taskDetails.details.editTask")}
              </Text>
            </Button>
          </View>
        );
      } else {
        return (
          <View container>
            {this.isDeclinedTask() ? (
              <View>
                <Text
                  style={{ color: variables.brandSuccess, fontWeight: "bold" }}>
                  <Icon
                    name="ios-close-circle"
                    style={{ fontSize: 20, color: variables.brandSuccess }}
                  />{" "}
                  {I18n.t("taskDetails.details.isDeclined")}
                </Text>
                <View>
                  <Text
                    uppercase={true}
                    style={{
                      marginVertical: 10,
                      fontSize: 12,
                      fontWeight: "bold",
                      color: variables.brandInfo
                    }}>
                    {I18n.t("taskDetails.actions.changeYourMind")}
                  </Text>
                  <Button
                    small
                    bordered
                    info
                    onPress={() => claimTask(this.props.task.id)}>
                    <Text uppercase={false}>
                      {I18n.t("taskDetails.actions.claimThisTask")}
                    </Text>
                  </Button>
                </View>
              </View>
            ) : null}

            {this.isClaimEnabled() && this.isDeclineEnabled() ? (
              <Card style={{ margin: 5, borderColor: variables.brandDanger }}>
                <CardItem>
                  <Text style={{ color: variables.brandDanger }}>
                    <Icon
                      name="ios-notifications"
                      style={{ fontSize: 14, color: variables.brandDanger }}
                    />{" "}
                    {I18n.t("taskDetails.details.pleaseRespond")}
                  </Text>
                </CardItem>

                <CardItem>
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "space-between"
                    }}>
                    {this.isClaimEnabled() && (
                      <View style={{ width: "45%" }}>
                        <Button
                          block
                          iconLeft
                          info
                          onPress={() => claimTask(this.props.task.id)}>
                          <Icon name="star" style={{ fontSize: 14 }} />
                          <Text
                            uppercase={false}
                            compact
                            style={{ textAlign: "center" }}>
                            {I18n.t("taskDetails.actions.claim")}
                          </Text>
                        </Button>
                      </View>
                    )}
                    {this.isDeclineEnabled() && (
                      <View style={{ width: "45%" }}>
                        <Button
                          block
                          iconLeft
                          bordered
                          onPress={() => declineTask(this.props.task.id)}>
                          <Icon
                            name="md-close"
                            compact
                            style={{ color: variables.brandDanger }}
                          />
                          <Text
                            uppercase={false}
                            compact
                            style={{ textAlign: "center" }}>
                            {I18n.t("taskDetails.actions.decline")}
                          </Text>
                        </Button>
                      </View>
                    )}
                  </View>
                </CardItem>
              </Card>
            ) : (
              undefined
            )}

            <View style={style.bigButtonContainer}>
              {this.isCompleteEnabled() && (
                <Button
                  iconLeft
                  block
                  info
                  style={style.buttonStyle}
                  onPress={() => markCompleteTask(this.props.task.id)}>
                  <Icon name="md-checkbox-outline" />
                  <Text uppercase={false}>
                    {I18n.t("taskDetails.actions.markComplete")}
                  </Text>
                </Button>
              )}

              {this.isUnClaimEnabled() && (
                <Button
                  iconLeft
                  bordered
                  block
                  transparent
                  style={style.buttonStyle}
                  onPress={() => unclaimTask(this.props.task.id)}>
                  <Icon name="md-undo" />
                  <Text uppercase={false}>
                    {I18n.t("taskDetails.actions.unclaim")}
                  </Text>
                </Button>
              )}
            </View>
          </View>
        );
      }
    };

    renderMainSection = () => {
      const {
        canEdit,
        description,
        categories,
        startdate,
        enddate,
        startlocation,
        endlocation,
        claims,
        owner,
        group,
        status,
        volunteerneeded
      } = this.props.task;

      if (canEdit) {
        return (
          <View>
            <View style={style.section}>
              <H3 style={{ fontWeight: "bold" }}>{this.props.task.name}</H3>
              <Hyperlink
                linkDefault={true}
                linkStyle={{ color: variables.brandInfo }}>
                <Text style={{ fontWeight: "300" }}>{description}</Text>
              </Hyperlink>
            </View>
            <View style={style.section}>
              <Text uppercase={true} style={style.label}>
                {I18n.t("taskDetails.details.status")}
              </Text>
              <Text>{I18n.t("global.taskStatus." + status)}</Text>
            </View>
            {!!group && (
              <View style={style.section}>
                <Text uppercase={true} style={style.label}>
                  {I18n.t("taskDetails.details.organization")}
                </Text>
                <Text>{group.name}</Text>
              </View>
            )}
            <View style={style.section}>
              <Text uppercase={true} style={style.label}>
                {I18n.t("taskDetails.details.categories")}
              </Text>
              <View style={style.categories}>
                {this.generateText(categories)}
              </View>
            </View>

            {!!startdate && (
              <View style={style.section}>
                <Text uppercase={true} style={style.label}>
                  {I18n.t("taskDetails.details.startDate")}
                </Text>
                <Text>{moment(startdate).format("L LT")}</Text>
              </View>
            )}
            {!!enddate && (
              <View style={style.section}>
                <Text uppercase={true} style={style.label}>
                  {I18n.t("taskDetails.details.endDate")}
                </Text>
                <Text>{moment(enddate).format("L LT")}</Text>
              </View>
            )}
            {startlocation && startlocation.address && (
              <View style={style.section}>
                <Text uppercase={true} style={style.label}>
                  {I18n.t("taskDetails.details.startLocation")}
                </Text>
                <Text>
                  {startlocation.address ||
                    `${startlocation.lat}, ${startlocation.lng}`}
                </Text>
              </View>
            )}
            {endlocation && endlocation.address && (
              <View style={style.section}>
                <Text uppercase={true} style={style.label}>
                  {I18n.t("taskDetails.details.endLocation")}
                </Text>
                <Text>
                  {endlocation.address ||
                    `${endlocation.lat}, ${endlocation.lng}`}
                </Text>
              </View>
            )}

            <View style={style.section}>
              <Text uppercase={true} style={style.label}>
                {I18n.t("taskDetails.details.volunteers")}
              </Text>
              <Text>{this.countClaims() + "/" + volunteerneeded}</Text>
            </View>
          </View>
        );
      } else {
        return (
          <View>
            <View style={style.section}>
              <H3 style={{ fontWeight: "bold" }}>{this.props.task.name}</H3>
              <Hyperlink
                linkDefault={true}
                linkStyle={{ color: variables.brandInfo }}>
                <Text style={{ fontWeight: "300" }}>{description}</Text>
              </Hyperlink>
            </View>
            <View style={style.section}>
              <Text uppercase={true} style={style.label}>
                {I18n.t("taskDetails.details.status")}
              </Text>
              <Text style={{ color: variables.primary, fontWeight: "300" }}>
                {I18n.t("global.taskStatus." + status)}
              </Text>
            </View>
            {!!group && (
              <View style={style.section}>
                <Text uppercase={true} style={style.label}>
                  {I18n.t("taskDetails.details.organization")}
                </Text>
                <Text>{group.name}</Text>
              </View>
            )}
            <View style={style.section}>
              <Text uppercase={true} style={style.label}>
                {I18n.t("taskDetails.details.categories")}
              </Text>
              <View style={style.categories}>
                {this.generateText(categories)}
              </View>
            </View>
            {!!startdate && (
              <View style={style.section}>
                <Text uppercase={true} style={style.label}>
                  {I18n.t("taskDetails.details.startDate")}
                </Text>
                <Text>{moment(startdate).format("L LT")}</Text>
              </View>
            )}
            {startlocation && startlocation.address && (
              <View style={style.section}>
                <Text uppercase={true} style={style.label}>
                  {I18n.t("taskDetails.details.startLocation")}
                </Text>
                <Text>
                  {startlocation.address ||
                    `${startlocation.lat}, ${startlocation.lng}`}
                </Text>
              </View>
            )}
            {!!enddate && (
              <View style={style.section}>
                <Text uppercase={true} style={style.label}>
                  {I18n.t("taskDetails.details.endDate")}
                </Text>
                <Text>{moment(enddate).format("L LT")}</Text>
              </View>
            )}
            {endlocation && endlocation.address && (
              <View style={style.section}>
                <Text uppercase={true} style={style.label}>
                  {I18n.t("taskDetails.details.endLocation")}
                </Text>
                <Text>
                  {endlocation.address ||
                    `${endlocation.lat}, ${endlocation.lng}`}
                </Text>
              </View>
            )}

            <View style={style.section}>
              <Text uppercase={true} style={style.label}>
                {I18n.t("taskDetails.details.volunteers")}
              </Text>
              <Text>{this.countClaims() + "/" + volunteerneeded}</Text>
            </View>
          </View>
        );
      }
    };

    render() {
      return (
        <Content>
          <View style={style.container}>
            {this.renderButtons()}
            {this.renderMainSection()}
          </View>
        </Content>
      );
    }
  }
);

const style = {
  container: {
    marginTop: 20,
    paddingHorizontal: 20
  },
  section: {
    marginBottom: 10
  },
  buttonContainer: {
    flexDirection: "row",
    // justifyContent: "center",
    alignItems: "center",
    //width: "50%",
    marginBottom: 10
  },
  bigButtonContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10
  },
  buttonStyle: {
    marginLeft: 5,
    marginBottom: 5
  },
  categories: {
    flexDirection: "column"
  },
  label: {
    color: "#808080",
    fontWeight: "bold",
    fontSize: 12,
    marginVertical: 3
  }
};
