import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "@react-native-community/async-storage";
import reducers from "./reducers";
import devToolsEnhancer from "remote-redux-devtools";

const store = createStore(
  persistReducer(
    {
      key: "root",
      storage,
      stateReconciler: autoMergeLevel2
    },
    reducers
  ),
  {}
  // __DEV__ ? devToolsEnhancer() : undefined
);

export const persistor = persistStore(store);
export default store;
