import React, { Component, PureComponent } from "react";
import {
  View,
  StatusBar,
  WebView,
  Linking,
  Alert,
  Easing,
  Image,
  TouchableWithoutFeedback,
  Keyboard,
  ActivityIndicator,
  BackHandler,
  Platform
} from "react-native";
import {
  Container,
  Header,
  Left,
  Body,
  Right,
  Title,
  Subtitle,
  Footer,
  Button,
  Icon,
  Toast,
  Text
} from "native-base";
import { NavigationActions } from "react-navigation";
import { connect } from "react-redux";
import hoistStatics from "hoist-non-react-statics";
import styles from "./styles";
import store from "./store";
import types from "./actions/types";
import variable from "../native-base-theme/variables/platform";

export function Screen(
  WrappedComponent,
  header = null,
  footer = null,
  staticProps = {}
) {
  return Object.assign(
    class extends Component {
      constructor(props) {
        super(props);

        if (typeof header == "function") {
          this.header = header(this.props);
          if (typeof this.header == "string") {
            this.header = HeaderWithBack(header, this.props);
          }
        } else if (typeof header == "string") {
          this.header = HeaderWithBack(header, this.props);
        }

        if (typeof footer == "function") {
          this.footer = footer(this.props);
        }
      }

      render() {
        return (
          <Container>
            <View style={{ flex: 1 }}>
              {this.header}
              <View style={styles.container}>
                <WrappedComponent {...this.props} />
              </View>
              {this.footer}
            </View>
          </Container>
        );
      }
    },
    {
      navigationOptions: {
        header: null
      }
    },
    staticProps
  );
}

export function HeaderWithLogo(title, props, hasTabs) {
  return (
    <Header {...props} hasTabs>
      <Left>
        <Image
          source={require("./images/logo_matted.png")}
          style={{ width: 52, resizeMode: "contain" }}
        />
        <Button
          transparent
          onPress={() => props.navigation.dispatch(NavigationActions.back())}>
          <Icon name="menu" style={{ color: "white" }} />
        </Button>
      </Left>
      <Body>
        <Title>
          {title} {"" + this.props.progress}
        </Title>
      </Body>
      <Right />
    </Header>
  );
}

export function HeaderWithDrawerMenu(title, props) {
  return (
    <Header {...props}>
      <Left>
        {Platform.OS != "web" && (
          <Button transparent onPress={() => props.navigation.openDrawer()}>
            <Icon name="menu" style={{ color: "white" }} />
          </Button>
        )}
      </Left>
      <Body>
        <Title>{title}</Title>
      </Body>
      <Right />
    </Header>
  );
}

export function HeaderWithBack(title, props) {
  return (
    <Header {...props}>
      <Left>
        <Button
          transparent
          onPress={() => props.navigation.dispatch(NavigationActions.back())}>
          <Icon name="arrow-back" style={{ color: "white" }} />
        </Button>
      </Left>
      <Body>
        <Title>{typeof title == "string" ? title : title(props)}</Title>
      </Body>
      <Right />
    </Header>
  );
}

export function HTMLContent(title, html) {
  return class extends Component {
    script = `
    (function(){
      document.querySelectorAll('a').forEach(function(a){
        a.onclick = a.ontouchstart = function(e){
          e.preventDefault();
          window.postMessage(a.href);
          e.stopPropagation();
        };
      });
    })();`;
    openExternalLink = ({ nativeEvent }) => {
      const { data } = nativeEvent;
      const isLocal = data.search("http://localhost") !== -1;

      if (isLocal) {
        return true;
      } else if (data.search("fourbells://") !== -1) {
        this.props.navigation.navigate(data.split("://")[1]);
      } else {
        Linking.openURL(data);
        return false;
      }
    };

    render() {
      return (
        <Container>
          {HeaderWithBack(title, this.props)}
          <WebView
            style={{ backgroundColor: "transparent" }}
            source={html}
            injectedJavaScript={this.script}
            onMessage={this.openExternalLink}
            onShouldStartLoadWithRequest={this.openExternalLink}
          />
        </Container>
      );
    }
  };
}

export function NavigationChange(Navigator) {
  const nav = class extends Component {
    state = { currentScreen: null };

    _getCurrentRouteName(navState) {
      if (navState.hasOwnProperty("index")) {
        return this._getCurrentRouteName(navState.routes[navState.index]);
      } else {
        return navState.routeName;
      }
    }

    _navigationStateChange = (prevState, newState, action) => {
      this.setState({ currentScreen: this._getCurrentRouteName(newState) });
    };

    render() {
      return (
        <Navigator
          onNavigationStateChange={this._navigationStateChange}
          screenProps={{ ...this.props.screenProps, ...this.state }}
          {...this.props}
        />
      );
    }
  };

  hoistStatics(nav, Navigator);
  return nav;
}

export const transitionConfig = () => {
  return {
    transitionSpec: {
      duration: 500,
      easing: Easing.bezier(0.2833, 0.99, 0.31833, 0.99)
    },
    screenInterpolator: sceneProps => {
      const { position, layout, scene, navigation } = sceneProps;
      const { index } = scene;
      const width = layout.initWidth;
      const height = layout.initHeight;

      const translateX = position.interpolate({
        inputRange: [index - 1, index, index + 1],
        outputRange: [width, 0, 0]
      });
      const translateY = position.interpolate({
        inputRange: [index - 1, index, index + 1],
        outputRange: [height, 0, 0]
      });
      const opacity = position.interpolate({
        inputRange: [index - 1, index],
        outputRange: [0, 1]
      });

      return {
        opacity,
        transform: [Platform.OS == "web" ? { translateX } : { translateY }]
      };
    }
  };
};

export function DismissKeyboard(WrappedComponent) {
  return ({ children, ...props }) => (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
      <WrappedComponent {...props}>{children}</WrappedComponent>
    </TouchableWithoutFeedback>
  );
}

export function showToast(text, type, duration, options) {
  Toast.show({
    text,
    type,
    duration: duration || 3000,
    position: "top",
    ...options
  });
}

export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function sortString(arr, prop) {
  return arr.sort((a, b) =>
    a[prop].toLowerCase().localeCompare(b[prop].toLowerCase())
  );
}

export function isCloseToBottom({
  layoutMeasurement,
  contentOffset,
  contentSize
}) {
  const paddingToBottom = 20;
  return (
    layoutMeasurement.height + contentOffset.y >=
    contentSize.height - paddingToBottom
  );
}

export function isCloseToTop({ contentOffset }) {
  return contentOffset.y == 0;
}

export function buildWP(startPos, endPos, currentPos) {
  if (currentPos) {
    return (
      "wp.0" +
      currentPos.lat +
      "," +
      currentPos.lng +
      "wp.1=" +
      startPos.lat +
      "," +
      startPos.lng +
      "&wp.2=" +
      endPos.lat +
      "," +
      endPos.lng
    );
  } else {
    return (
      "wp.0=" +
      startPos.lat +
      "," +
      startPos.lng +
      "&wp.1=" +
      endPos.lat +
      "," +
      endPos.lng
    );
  }
}

export const generateInitialRegion = latLong => {
  //@param distance later it will be enable to modify the range of map
  const distance = 10 / 2;
  const circumference = 40075;
  const oneDegreeOfLatitudeInMeters = 111.32 * 1000;
  const angularDistance = distance / circumference;

  const latitudeDelta = distance / oneDegreeOfLatitudeInMeters;
  const longitudeDelta = Math.abs(
    Math.atan2(
      Math.sin(angularDistance) * Math.cos(latLong.latitude),
      Math.cos(angularDistance) -
        Math.sin(latLong.latitude) * Math.sin(latLong.latitude)
    )
  );

  return {
    latitude: latLong.latitude + latitudeDelta,
    longitude: latLong.longitude + longitudeDelta,
    latitudeDelta: 0,
    longitudeDelta: 0
  };
};

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export function sortAlpha(arr, prop) {
  return sortString(arr, prop);
}

export function sortCondition(arr, cond) {
  return arr.sort(function(x, y) {
    return x.status == cond ? -1 : y.status == cond ? 1 : 0;
  });
}

export function lazy(LazyComponent) {
  const lazyWrapper = class extends PureComponent {
    state = { loaded: this.props.active };

    static getDerivedStateFromProps(props, state) {
      // console.log(props, state);
      if (props.active && !state.loaded) {
        return { loaded: true };
      }
      return null;
    }

    render() {
      const { active } = this.props;
      // console.log(LazyComponent, this.state.loaded, active);
      return active || this.state.loaded /* || Platform.OS === "web"*/ ? (
        <LazyComponent {...this.props} />
      ) : null;
    }
  };

  hoistStatics(lazyWrapper, LazyComponent);
  return lazyWrapper;
}
