import React, { Component } from "react";
import { Content, Text, Icon, Button, Right } from "native-base";
import { View, StyleSheet, Linking, Platform } from "react-native";
import { generateInitialRegion } from "../../utils";
//import styles from '../../styles';
import MapView, { Marker, Polyline } from "react-native-maps";
import openMap from "react-native-open-maps";
import OpenMapDirections from "../../openmap";
import { connect } from "react-redux";
import { getDirections } from "./actions";
import variable from "../../../native-base-theme/variables/platform";
import I18n from "../../i18n";
import GoogleAPIAvailability from "react-native-google-api-availability-bridge";

export default connect(state => ({
  task: state.taskDetails.task,
  direction: state.taskDetails.direction
}))(
  class Location extends Component {
    state = {
      playServiceAvailable: true
    };

    componentDidMount() {
      if (Platform.OS === "android") {
        GoogleAPIAvailability.checkGooglePlayServices(result => {
          if (
            result === "failure" ||
            result === "missing" ||
            result === "invalid" ||
            result === "disabled" ||
            result === "update"
          ) {
            this.setState({ playServiceAvailable: false });
          }
        });
      }
    }

    onMapReady = async () => {
      switch (true) {
        case !!(
          this.props.task.startlocation &&
          this.props.task.startlocation.lat &&
          this.props.task.endlocation &&
          this.props.task.endlocation.lat
        ):
          const coordinates = await getDirections(
            this.props.task.startlocation,
            this.props.task.endlocation
          );
          if (coordinates && coordinates.length > 0) {
            this._map.fitToCoordinates(coordinates, {
              edgePadding: { top: 50, right: 50, bottom: 50, left: 50 },
              animated: false
            });
          }
          break;
        case !!(
          this.props.task.startlocation && this.props.task.startlocation.lat
        ):
          this._map.fitToSuppliedMarkers(["start"], false);
          break;
        case !!(this.props.task.endlocation && this.props.task.endlocation.lat):
          this._map.fitToSuppliedMarkers(["end"], false);
          break;
      }
    };

    render() {
      let { startlocation, endlocation } = this.props.task;
      if (startlocation && Object.keys(startlocation).length) {
        startlocation = {
          latitude: startlocation.lat,
          longitude: startlocation.lng
        };
      }
      if (endlocation && Object.keys(endlocation).length) {
        endlocation = {
          latitude: endlocation.lat,
          longitude: endlocation.lng
        };
      }

      const initialRegion =
        !!this.props.task.startlocation && !!this.props.task.startlocation.lat
          ? {
              latitude: this.props.task.startlocation.lat,
              longitude: this.props.task.startlocation.lng,
              name: this.props.task.startlocation.address
            }
          : {
              latitude: this.props.task.endlocation
                ? this.props.task.endlocation.lat
                : null,
              longitude: this.props.task.endlocation
                ? this.props.task.endlocation.lng
                : null,
              name: this.props.task.endlocation
                ? this.props.task.endlocation.address
                : null
            };

      return (
        <View style={styles.container}>
          {this.state.playServiceAvailable && (
            <Button
              small
              warning
              // onPress={() => openMap(initialRegion)}
              onPress={() =>
                startlocation && endlocation
                  ? OpenMapDirections(startlocation, endlocation, "d")
                  : OpenMapDirections(null, startlocation, "d")
              }
              style={styles.showMap}>
              <Text uppercase={false} numberOfLines={1}>
                {I18n.t("location.openInMapApp")}
              </Text>
            </Button>
          )}
          <View style={styles.mapWrapper}>
            <MapView
              style={styles.map}
              initialRegion={generateInitialRegion(initialRegion)}
              ref={ref => {
                this._map = ref;
              }}
              scrollEnabled={false}
              showsUserLocation
              onMapReady={this.onMapReady}
              pitchEnabled={false}
              rotateEnabled={false}
              loadingEnabled>
              {!!this.props.task.startlocation &&
                !!this.props.task.startlocation.lat && (
                  <Marker
                    identifier="start"
                    coordinate={{
                      latitude: this.props.task.startlocation.lat,
                      longitude: this.props.task.startlocation.lng
                    }}
                  />
                )}
              {!!this.props.task.endlocation &&
                !!this.props.task.endlocation.lat && (
                  <Marker
                    identifier="end"
                    coordinate={{
                      latitude: this.props.task.endlocation.lat,
                      longitude: this.props.task.endlocation.lng
                    }}
                  />
                )}

              {this.props.direction.length > 0 && (
                <Polyline
                  coordinates={this.props.direction}
                  strokeColor={variable.brandInfo}
                  strokeWidth={6}
                />
              )}
            </MapView>
          </View>
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center"
    // paddingHorizontal: 20,
    // marginTop: 20
  },
  mapWrapper: {
    // marginTop: 20
    flex: 1
  },
  map: {
    width: "100%",
    height: "100%"
  },
  showMap:
    Platform.OS == "web"
      ? {
          position: "absolute",
          left: "50%",
          top: 10,
          width: 200,
          marginLeft: -100,
          justifyContent: "center",
          zIndex: 9999
        }
      : {
          position: "absolute",
          left: 10,
          top: 10,
          zIndex: 9999
        }
});
