import { Platform } from "react-native";
import variable from "./../variables/platform";

export default (variables = variable) => {
  const contentTheme = {
    ".padder": {
      padding: variables.contentPadding
    },
    flex: 1,
    backgroundColor: "transparent",
    "NativeBase.Segment": {
      borderWidth: 0,
      backgroundColor: "transparent"
    },
    ".container": {
      width: "100%",
      maxWidth: Platform.OS == "web" ? 1200 : undefined,
      alignSelf: Platform.OS == "web" ? "center" : undefined
    }
  };

  return contentTheme;
};
