import React, { Component } from "react";
import {
  Content,
  Button,
  Text,
  H3,
  Card,
  CardItem,
  Left,
  Body,
  Icon,
  Right,
  View
} from "native-base";
import { Alert } from "react-native";
import { connect } from "react-redux";
import CommunicationActions from "../components/CommunicationActions";
import ContactForm from "../components/ContactForm";
import { removeContact, addContact } from "../actions";
import { sortString } from "../../../utils";
import I18n from "../../../i18n";
import styles from "../../../styles";
import style from "./style";
import { emailPattern } from "../../../variables";
import variables from "../../../../native-base-theme/variables/platform";

export default connect(state => ({
  mode: state.groups.mode,
  contacts: [...(state.groups.group.contacts || [])],
  offline: state.offline
}))(
  class Contacts extends Component {
    state = {
      form: null
    };
    _working = false;

    removeContact = contact => () => {
      Alert.alert(
        I18n.t("global.confirm"),
        I18n.t("groups.manage.removeContactConfirm", contact),
        [
          {
            text: I18n.t("global.no"),
            style: "cancel"
          },
          {
            text: I18n.t("global.yes"),
            onPress: async () => {
              await removeContact(contact);
            }
          }
        ]
      );
    };

    create = () => {
      this.setState({
        form: {
          name: null,
          phone: null,
          email: null
        },
        submitted: false
      });
    };

    save = async contact => {
      if (this._working) return;
      this._working = true;
      if (
        contact.name &&
        contact.name.length > 0 &&
        contact.phone &&
        contact.phone.length > 0 &&
        contact.phoneformatted &&
        contact.phoneformatted.length > 0 &&
        contact.email &&
        contact.email.length > 0 &&
        emailPattern.test(contact.email)
      ) {
        await addContact({ ...this.state.form, ...contact });
        this.setState({
          form: null
        });
      } else {
        this.setState({
          submitted: true
        });
      }
      this._working = false;
    };

    render() {
      const { mode, contacts, offline } = this.props;

      return (
        <Content>
          <View style={style.padding}>
            {mode == "manage" && !offline && !this.state.form ? (
              <View>
                <View>
                  <Text note>{I18n.t("groups.contacts.description")}</Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    marginVertical: 15
                  }}>
                  <Button
                    small
                    iconLeft
                    transparent
                    bordered
                    info
                    onPress={this.create}>
                    <Icon
                      name="ios-add-circle-outline"
                      style={{ fontSize: 14 }}
                    />
                    <Text uppercase={false}>
                      {I18n.t("groups.manage.addContact")}
                    </Text>
                  </Button>
                </View>
              </View>
            ) : (
              undefined
            )}

            {contacts && contacts.length > 0 && mode != "review" ? (
              <View style={style.section}>
                <Text style={{ color: "#808080", fontSize: 12 }}>
                  {I18n.t("groups.contacts.rates")}
                </Text>
              </View>
            ) : (
              undefined
            )}

            {this.state.form ? (
              <View container>
                <ContactForm
                  onFormChanged={this.save}
                  submitted={this.state.submitted}
                />
              </View>
            ) : (
              undefined
            )}
            {contacts && contacts.length > 0 ? (
              sortString(contacts, "name").map((contact, index) => (
                <View
                  style={style.section}
                  key={`${contact.name}_${contact.phone}_${contact.email}_${index}`}>
                  <Card>
                    <CardItem bordered>
                      <Left>
                        <Icon
                          name="contact"
                          style={{ color: variables.brandInfo }}
                        />
                        <Body>
                          <Text
                            style={{
                              fontWeight: "bold",
                              color: variables.brandInfo
                            }}>
                            {contact.name}
                          </Text>
                        </Body>
                      </Left>
                      {mode == "manage" ? (
                        <Right>
                          <Button
                            danger
                            small
                            transparent
                            onPress={this.removeContact(contact)}>
                            <Text uppercase={false}>
                              {I18n.t("groups.manage.removeContact")}
                            </Text>
                          </Button>
                        </Right>
                      ) : (
                        undefined
                      )}
                    </CardItem>
                    <CardItem>
                      <Left>
                        <Icon name="call" style={{ fontSize: 14 }} />
                        <Body>
                          <Text style={{ fontSize: 14 }}>
                            {contact.phoneformatted || contact.phone}
                          </Text>
                        </Body>
                      </Left>
                    </CardItem>
                    <CardItem>
                      <Left>
                        <Icon name="mail" style={{ fontSize: 14 }} />
                        <Body>
                          <Text style={{ fontSize: 14 }}>{contact.email}</Text>
                        </Body>
                      </Left>
                    </CardItem>

                    {mode != "review" ? (
                      <CardItem footer bordered>
                        <CommunicationActions
                          user={contact}
                          i18n="groups.contacts"
                        />
                      </CardItem>
                    ) : (
                      undefined
                    )}
                  </Card>
                </View>
              ))
            ) : (
              <View style={style.section}>
                <Text style={{ color: "#808080" }}>
                  {I18n.t("global.noItemFoundForList")}
                </Text>
              </View>
            )}
          </View>
        </Content>
      );
    }
  }
);
