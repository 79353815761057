import React, { Component } from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Alert,
  RefreshControl,
  Platform,
  FlatList,
  Text as RNText
} from "react-native";
import {
  Content,
  Button,
  Text,
  Card,
  CardItem,
  H3,
  Icon,
  Left,
  Right
} from "native-base";
import { SafeAreaView } from "react-navigation";
import I18n from "../../i18n";
import { connect } from "react-redux";
import Progress from "../../components/Progress";
import { getList, resetList, loadMore } from "./actions";
import { lazy } from "../../utils";
import variables from "../../../native-base-theme/variables/platform";

export default function Items(type, renderItem, renderEmpty, label) {
  return lazy(
    connect(state => ({
      items: state.activities[type].list,
      canLoadMore: state.activities[type].loadMore,
      progress: state.progress.active,
      offline: state.offline
    }))(
      class extends Component {
        static title = label;

        state = {
          offline: this.props.offline,
          isRefreshing: false
        };

        async componentDidMount() {
          this._mounted = true;
          await this.refreshList(true);
        }

        componentWillUnmount() {
          this._mounted = false;
        }

        refreshList = async force => {
          if (!this.props.items || force) {
            await getList(0, type);
          }
        };

        loadMore = async () => {
          await loadMore(type);
        };

        refreshActiveTab = async () => {
          if (this._mounted) {
            this.setState({
              isRefreshing: true
            });
          }
          await this.refreshList(true);
          if (this._mounted) {
            this.setState({
              isRefreshing: false
            });
          }
        };

        static getDerivedStateFromProps(props, state) {
          if (!props.offline && state.offline != props.offline) {
            return {
              offline: props.offline
            };
          }
          return null;
        }

        async componentDidUpdate(prevProps) {
          if (!this.props.offline && prevProps.offline != this.props.offline) {
            await this.refreshList(true);
          }
        }

        render() {
          return (
            <SafeAreaView
              style={{ height: "100%" }}
              //  forceInset={{ horizontal: "always", top: "always" }}
            >
              <FlatList
                contentContainerStyle={{
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 0,
                  paddingBottom: 0
                }}
                data={this.props.items}
                keyExtractor={(item, key) => `${item.id}_${key}`}
                renderItem={({ item, key }) =>
                  renderItem(this.props)(item, key)
                }
                ListEmptyComponent={
                  !this.props.progress &&
                  this.props.items &&
                  this.props.items.length == 0 &&
                  (renderEmpty || (_ => _))()
                }
                ListFooterComponent={
                  (this.props.canLoadMore ||
                    !this.props.items ||
                    (this.props.items && this.props.items.length == 0)) &&
                  !this.state.isRefreshing && <Progress />
                }
                onRefresh={this.refreshActiveTab}
                refreshing={this.state.isRefreshing}
                onEndReachedThreshold={0.1}
                onEndReached={() => {
                  if (
                    this.props.items &&
                    this.props.items.length > 0 &&
                    !this.props.progress &&
                    this.props.canLoadMore
                  ) {
                    this.loadMore();
                  }
                }}
              />
            </SafeAreaView>
          );
        }
      }
    )
  );
}

const style = StyleSheet.create({
  containerStyle: {
    marginTop: 20,
    paddingHorizontal: 10
  }
});
