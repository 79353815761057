import React, { Component } from "react";
import {
  Content,
  Text,
  H3,
  Button,
  Card,
  CardItem,
  Body,
  Left,
  Right,
  CheckBox,
  Icon,
  ListItem
} from "native-base";
import { View } from "react-native";
import { connect } from "react-redux";
import {
  getGlobalCategories,
  removeCategory,
  viewVolunteers
} from "../actions";
import { sortString } from "../../../utils";
import I18n from "../../../i18n";
import styles from "../../../styles";
import style from "./style";
import variables from "../../../../native-base-theme/variables/platform";


export default connect(state => ({
  mode: state.groups.mode,
  categories: state.groups.group.categories,
  globalCategories: state.groups.categories,
  group: state.groups.group,
  offline: state.offline
}))(
  class Categories extends Component {
    state = {
      showPendingOnly: this.props.pending == "1" || false
    };
    _working = false;

    addCategory = async () => {
      if (this._working) return;
      this._working = true;
      // disabling global categories (issue #289)
      /*
      if (
        !this.props.globalCategories ||
        this.props.globalCategories.length == 0
      ) {
        await getGlobalCategories();
      }
      */
      this.props.navigation.navigate("Categories");
      this._working = false;
    };

    viewVolunteers = category => async () => {
      if (this._working) return;
      this._working = true;
      await viewVolunteers(category);
      this.props.navigation.navigate("CategoryVolunteers");
      this._working = false;
    };

    filterPending = () => {
      this.setState({ showPendingOnly: !this.state.showPendingOnly });
    };

    render() {
      const { mode, categories, group, offline } = this.props;
      let filteredCategories = categories;
      if (this.state.showPendingOnly) {
        filteredCategories = categories.filter(it => it.pending > 0);
      }
      return (
        <Content>
          <View style={style.padding}>

            {mode == "manage" ? (
              <View>

                <Text style={{ fontWeight: "200", paddingVertical: 5 }}>{I18n.t("groups.manage.manageCategoryDescription")}</Text>

                {group &&
                  group.profile &&
                  group.profile.approvevolunteer && (
                    <ListItem noBorder >
                      <CheckBox
                        checked={this.state.showPendingOnly}
                        onPress={this.filterPending}
                      />
                      <Body>
                        <Text >{I18n.t("groups.manage.showPendingOnly")}</Text>
                      </Body>

                    </ListItem>
                  )}
              </View>
            ) : (
                undefined
              )}

            {filteredCategories && filteredCategories.length > 0 ? (
              sortString(filteredCategories, "name").map((category, index) => (


                <View key={`${category.name}_${index}`}>
                  <Card>
                    <CardItem bordered>
                      <Left>
                        <Icon name="md-contacts" style={{ color: variables.brandPrimary }} />
                        <Body>
                          <Text style={{ fontWeight: "bold" }}>{category.name}</Text>
                          <Text note>{I18n.t("groups.manage.categorySubtitle")}</Text>
                        </Body>
                      </Left>

                      {mode == "manage" && !offline ? (
                        categories.length > 1 ? (
                          <Right>
                            <Button small bordered onPress={() => removeCategory(category)}><Text>{I18n.t("groups.manage.removeCategory")}</Text></Button>
                          </Right>
                        ) : (
                            undefined
                          )
                      ) : (
                          undefined
                        )}

                    </CardItem>
                    <CardItem >
                      {group.profile.approvevolunteer ? (
                        <Body>
                          <Text uppercase={true} style={{ fontSize: 12, color: variables.brandInfo, fontWeight: "bold", paddingVertical: 5 }}>{I18n.t("groups.manage.volunteers")}</Text>
                          <Text style={{ color: variables.brandPrimary, fontWeight: "300", marginVertical: 3 }}>
                            <Icon active name="md-checkmark-circle" style={{ color: category.approved ? "green" : "#CCCCCC", fontSize: 16 }} />{" "}
                            <Text style={{ fontWeight: "bold", marginLeft: 5 }}>{category.approved || 0}</Text> {I18n.t("groups.manage.statusApproved")}
                          </Text>
                          <Text style={{ color: variables.brandPrimary, fontWeight: "300", marginVertical: 3 }}>
                            <Icon active name="md-help-circle" style={{ color: category.pending ? variables.brandWarning : "#CCCCCC", fontSize: 16 }} />{" "}
                            <Text style={{ fontWeight: "bold", marginLeft: 5 }}>{category.pending || 0}</Text> {I18n.t("groups.manage.statusPending")}
                          </Text>
                          <Text style={{ color: variables.brandPrimary, fontWeight: "300", marginVertical: 3 }}>
                            <Icon active name="md-close-circle" style={{ color: category.rejected ? variables.brandDanger : "#CCCCCC", fontSize: 16 }} />{" "}
                            <Text style={{ fontWeight: "bold", marginLeft: 5 }}>{category.rejected || 0}</Text> {I18n.t("groups.manage.statusRejected")}
                          </Text>
                        </Body>
                      ) : (
                          <Body>
                            <Text style={{ color: variables.brandPrimary, fontWeight: "300", marginVertical: 15 }}>
                              <Text style={{ fontWeight: "bold" }}>{category.volunteers || 0}</Text> {I18n.t("groups.manage.volunteers")}
                            </Text>

                          </Body>

                        )}


                      {mode == "manage" && !offline ? (
                        (category.volunteers || 0) > 0 ? (
                          <Right>
                            <Button transparent iconRight info onPress={this.viewVolunteers(category)}>
                              <Text uppercase={false} style={{ fontSize: 18 }}>{I18n.t("groups.manage.viewVolunteers")}</Text>
                              <Icon name="arrow-forward" />
                            </Button>
                          </Right>
                        ) : (
                            undefined
                          )
                      ) : (
                          undefined
                        )}



                    </CardItem>

                  </Card>

                </View>

              ))
            ) : (
                <View style={style.section}>
                  <Text>{I18n.t("global.noItemFoundForList")}</Text>
                </View>
              )}

            {mode == "manage" ? (
              <View style={{ padding: 15 }}>
                {!offline ? (
                  <Button info small block onPress={this.addCategory}>
                    <Text uppercase={false}>
                      {I18n.t("groups.manage.addCategory")}
                    </Text>
                  </Button>
                ) : null}

              </View>
            ) : (
                undefined
              )}
          </View>
        </Content>
      );
    }
  }
);
