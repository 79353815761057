import types from "../actions/types";

export default (state = [], action) => {
  switch (action.type) {
    case types.GET_GROUPS:
      
      return (action.groups || [])
        .filter(group => group.role == "volunteer")
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        );
    case types.ADD_GROUP:
      if (action.group && action.group.role == "volunteer") {
        return [...state, action.group].sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        );
      }
      return state;
    case types.DELETE_GROUP:
      if (action.group) {
        return state.filter(it => it.id != action.group.id);
      }
      return state;
    case types.LOGOUT:
      return [];
    default:
      return state;
  }
};
