import React, { Component } from "react";
import {
  Content,
  Form,
  Item,
  Label,
  Input,
  Button,
  Card,
  CardItem,
  CheckBox,
  Left,
  Right,
  Body,
  Text,
  ListItem,
  H3,
  Icon
} from "native-base";
import { StyleSheet, View, Keyboard } from "react-native";
import { connect } from "react-redux";
import Header from "./details/header";
import { updateGroup, saveGroup, addCategories } from "./actions";
import { Screen } from "../../utils";
import styles from "../../styles";
import I18n from "../../i18n";

let isInProgress = false;

export default Screen(
  connect(state => ({
    mode: state.groups.mode,
    group: state.groups.group,
    categories: [
      ...(state.groups.categories || []).filter(
        category =>
          !(state.groups.group.categories || []).find(
            it => it.id == category.id
          )
      )
    ]
  }))(
    class Categories extends Component {
      state = {
        category: null,
        categoryInvalid: true,
        categories: this.props.categories,
        submitted: false
      };
      _working = false;

      handleInputChange = name => evt => {
        const value = evt.nativeEvent.text;
        let categoryInvalid = true;
        if (name == "category") {
          categoryInvalid =
            value.length == 0 ||
            this.state.categories.filter(
              category =>
                category.name.trim().toLowerCase() == value.trim().toLowerCase()
            ).length > 0;
        }
        this.setState({ [name]: value, categoryInvalid });
      };

      addNewCategory = () => {
        const categories = [
          { selected: true, name: this.state.category.trim() },
          ...this.state.categories
        ];
        this.setState({
          categories,
          category: null,
          categoryInvalid: true
        });
        if (!this.props.group.id) {
          updateGroup({ categories });
        }
        Keyboard.dismiss();
      };

      selectCategoryChanged = category => {
        return () => {
          category.selected = !category.selected;
          this.setState({
            ...this.state
          });
          if (!this.props.group.id) {
            updateGroup({ categories: [...this.state.categories] });
          }
        };
      };

      get isInvalid() {
        return (
          this.state.categories.length == 0 ||
          (this.state.categories.length > 0 &&
            this.state.categories.filter(category => category.selected)
              .length == 0)
        );
      }

      get selectedCategories() {
        return this.state.categories
          .filter(category => category.selected)
          .map(category => ({
            id: category.id,
            name: category.name
          }));
      }

      continue = () => {
        if (isInProgress) return;
        isInProgress = true;
        if (this.isInvalid) {
          this.setState({ submitted: true });
        } else {
          updateGroup({ categories: this.selectedCategories });
          this.props.navigation.navigate("Details");
        }
        isInProgress = false;
      };

      next = async () => {
        if (isInProgress) return;
        isInProgress = true;
        if (this.isInvalid) {
          this.setState({ submitted: true });
        } else {
          if (this._working) return;
          this._working = true;
          await addCategories(this.selectedCategories);
          this.props.navigation.goBack();
          this._working = false;
        }
        isInProgress = false;
      };

      render() {
        const { mode, group } = this.props;
        return (
          <Content container>
            <View>
              <View
                style={{
                  backgroundColor: "#FFFFFF",
                  padding: 15,
                  marginTop: 15
                }}>
                <View>
                  <H3>{I18n.t("groups.categories.heading")}</H3>

                  {mode == "review" ? (
                    <View style={{ marginTop: 15 }}>
                      <Text style={{ fontWeight: "200" }}>
                        {I18n.t("groups.categories.description")}
                      </Text>
                    </View>
                  ) : (
                    undefined
                  )}
                </View>

                <View style={{ marginTop: 15 }}>
                  <Form>
                    <View style={{ flexDirection: "row" }}>
                      <View style={{ flex: 0.8 }}>
                        <Item rounded>
                          <Input
                            name="category"
                            returnKeyType="done"
                            autoCorrect={true}
                            onChange={this.handleInputChange("category")}
                            value={this.state.category}
                            placeholder={I18n.t(
                              "groups.categories.placeholder"
                            )}
                            style={{ padding: 0 }}
                          />
                        </Item>
                      </View>
                      <View style={{ flex: 0.2, paddingHorizontal: 10 }}>
                        <Button
                          small
                          disabled={this.state.categoryInvalid}
                          onPress={this.addNewCategory}>
                          <Text uppercase={false}>{I18n.t("global.ok")}</Text>
                        </Button>
                      </View>
                    </View>
                  </Form>
                </View>

                <View style={{ marginTop: 5 }}>
                  <Text note>
                    <Text note style={{ fontWeight: "bold" }}>
                      {I18n.t("groups.categories.examplesLabel")}
                    </Text>
                    : {I18n.t("groups.categories.examples")}
                  </Text>
                </View>

                <View style={{ marginTop: 15 }}>
                  {this.isInvalid && this.state.submitted ? (
                    <Text style={style.error}>
                      {I18n.t("groups.categories.invalid")}
                    </Text>
                  ) : (
                    undefined
                  )}
                  {this.state.categories.length > 0 ? (
                    <View>
                      {this.state.categories.map((category, index) => (
                        <ListItem
                          key={`${category.name}_${index}`}
                          style={{ marginLeft: 0 }}>
                          <CheckBox
                            checked={category.selected}
                            onPress={this.selectCategoryChanged(category)}
                          />
                          <Body>
                            <Text>{category.name}</Text>
                          </Body>
                        </ListItem>
                      ))}
                    </View>
                  ) : (
                    undefined
                  )}
                </View>
              </View>
              <View style={style.padding}>
                {mode == "review" ? (
                  <Button
                    iconRight
                    block
                    info
                    //disabled={this.isInvalid}
                    onPress={this.continue}>
                    <Text uppercase={false}>
                      {I18n.t("groups.categories.continue")}
                    </Text>
                    <Icon name="ios-arrow-forward" />
                  </Button>
                ) : (
                  <Button iconRight block info onPress={this.next}>
                    <Text uppercase={false}>
                      {I18n.t("groups.categories.next")}
                    </Text>
                    <Icon name="ios-arrow-forward" />
                  </Button>
                )}
              </View>
            </View>
          </Content>
        );
      }
    }
  ),
  props => (
    <Header
      hasTabs={false}
      title={I18n.t("groups.categories.title")}
      {...props}
    />
  )
);

const style = StyleSheet.create({
  padding: {
    padding: 15
  },
  error: {
    color: "#e00",
    fontWeight: "bold"
  }
});
