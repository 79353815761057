export default {
  global: {
    email: "E-mail",
    password: "Senha",
    rememberMe: "Lembrar-me",
    forgotPass: "Esqueceu a senha?",
    login: "Entrar",
    loginTitle: "Entre no 4Bells",
    loginFailedTitle: "Entrar",
    loginFailed: "Usuário ou senha inválida",
    createAcc: "Criar Conta",
    changePass: "Alterar Senha",
    and: "e",
    firstName: "Nome",
    lastName: "Sobrenome",
    eula: "Acordo de Licença de Usuário Final",
    privacypolicy: "Politica de Privacidade",
    continue: "Avançar",
    save: "Salvar",
    saveChanges: "Salvar Alterações",
    send: "Enviar",
    cancel: "Cancelar",
    callPhone: "Chamar Número",
    noItemFoundForList: "Nenhum item encontrado",
    briefDesc: "Descrição Curta",
    startDate: "Data de Início",
    endDate: "Data Final",
    startLocation: "Ponto Inicial",
    endLocation: "Ponto Final",
    ownerName: "Criador da Tarefa",
    ownerPhone: "Contato do Criador",
    numberVolunteers: "Número de Voluntários",
    categories: "Categorias",
    language: "Idioma",
    pleaseSelectLanguage: "Escolha o Idioma",
    isRequired: "Obrigatório",
    title: "Título",
    noCategoriesSelected: "Nenhuma categoria escolhida",
    confirm: "Confirmar",
    needToRestart: "Precisaremos reiniciar o app",
    restart: "Reiniciar",
    ok: "OK",
    yes: "Sim",
    no: "Não",
    close: "Fechar",
    selecetOne: "Selecione uma",
    group: "Grupo",
    thankYou: "Obrigado!",
    view: "Ver",
    taskStatus: {
      open: "Aberta",
      claimed: "Aceita",
      decline: "Recusada",
      completed: "Concluída",
      closed: "Encerrada",
      recalled: "Removida"
    },
    forminvalid: "Formato Inválido"
  },
  location: {
    pin: "Toque no mapa para selecionar o local",
    title: "4Bells solicitou liberação de localização",
    message:
      "Solicitamos permissão de localização para encontar seu dispositivo. Por favor, autorize-nos.",
    openInMapApp: "Abrir no app de mapas"
  },
  drawer: {
    activities: "Geral",
    tasks: "Tarefas",
    groups: "Grupos",
    myAccount: "Minha Conta",
    sync: "Sincronização",
    settings: "Configurações",
    contactUs: "Contate-nos",
    logout: "Sair",
    faq: "Perguntas Frequentes",
    terms: "Acordo de Licença de Usuário Final",
    privacy: "Política de Privacidade",
    about: "Sobre"
  },
  sync: {
    complete: "Sincronização Completa",
    cancel: "Sincronização Cancelada",
    syncGroup: "Sincronizando grupo {{name}}...",
    groupFailed: "Sincronização do grupo {name}} falhou",
    tasks: "Sincronizando tarefas da lista {{list}} ",
    open: "Aberta",
    claimed: "Aceita",
    syncTask: "Sincronizando {{index}}/{{count} tarefas da lista {{list}} ...",
    taskFailed: "Sincronização da tarefa {{name}} falhou",
    modes: {
      none: "Desativar",
      always: "Sempre",
      wifi: "Somente com Wi-Fi"
    }
  },
  errorMessages: {
    unexpectedError: "Erro inesperado",
    firstNameRequired: "Nome é obrigatório",
    lastNameRequired: "Sobrenome é obrigatório",
    emailRequired: "E-mail é obrigatório",
    emailNotValid: "E-mail inválido",
    phoneRequired: "Um telefone é obrigatório",
    phoneInvalid: "Informe um número válido (ex: +5548999887766)",
    passwordRequired: "Insira sua senha",
    passwordRule:
      "A senha deve ser de no mínimo 6 caracteres e conter um mix de letras maiúsculas e minúsculas, além de números. Não use caracteres especiais.",
    passwordNotMatch: "As senhas devem ser iguais",
    somethingWentWrong: "Ocorreu algo errado",
    messageRequired: "É obrigatório inserir uma mensagem",
    endDateNeedToHigher: "A data final deve ser posterior à inicial!",
    groupRequired: "É obrigatório informar um grupo",
    titleRequired: "Informe um Título",
    briefDescRequired: "Insira uma breve descrição",
    startDateRequired: "Data de Início é obrigatória",
    numberVolunteersRequired: "Informe o número de voluntários"
  },
  appError: {
    "Network Error": "Erro de Conexão",
    "Invalid username or password": "Usuário ou senha inválida",
    "Registration warning": "E-mail já usado anteriormente",
    "Request failed with status code 504": "O servidor não está respondendo",
    "Request failed with status code 404": "Recurso não encontrado",
    "Resource not found": "Recurso não encontrado"
  },
  twilio: {
    lookup: "Verificando nº de telefone...",
    invalid: "O número é inválido"
  },
  screenHeader: {
    activity: "Atividade",
    taskDetails: "Detalhes da Tarefa",
    editTask: "Editar Tarefa",
    addTask: "Adicionar Tarefa",
    createAccount: "Criar Conta",
    editAccount: "Editar Minha Conta",
    myAccount: "Minha Conta",
    help: "Ajuda",
    settings: "Configurações"
  },
  welcome: {
    welcome: "Bem-vindo(a) ao 4Bells",
    description:
      "Organize voluntários na palma da sua mão. Use o 4Bells para distribuir, monitorar e concluir a execução de tarefas.",
    or: "ou",
    needHelp: "Precisa de Ajuda?",
    settings: "Configurações"
  },
  settings: {
    sync: {
      description: "Configuração do Uso de Dados",
      none: "Sempre poupar dados",
      noneDescription:
        "Baixar apenas informações e tarefas do meu grupo quando necessário.",
      always: "Sincronização Automática (Padrão)",
      alwaysDescription:
        "Baixar automaticamente todas as novas informações de tarefas e grupos ao se conectar",
      wifi: "Usar menos dados móveis",
      wifiDescription:
        "Ao usar dados móveis, baixar apenas as informações e tarefas do seu grupo, caso necessário",
      reducedOfflineData:
        "AVISO: ao poupar dados, pode ocorrer uma redução de informações disponíveis offline.",
      update: "Atualizar todos os grupos e tarefas"
    }
  },
  login: {
    rememberMe: "Lembrar-me"
  },
  signup: {
    keepMe: "Seguir Conectado",
    fourbellsAlert: "Alertas 4Bells",
    sendTextMessage: "Notique-me via SMS",
    ratesApply: "Tarifas padrão serão aplicadas",
    sendNotification: "Notifique-me via aplicativo",
    sendMessenger: "Notifique-me via Facebook Messenger",
    termsAccept: "Ao utilizar este aplicativo, o usuário concorda com o",
    phone: "Número de Telefone",
    accountInfoSectionTitle: "Informações da Conta",
    passwordSectionTitle: "Senha",
    languageSectionTitle: "Idioma"
  },
  newuser: {
    title: "Bem-Vindo(a)",
    firststep: "Primeiros Passos",
    description:
      "Você pode ser tanto voluntário/a como coordenador/a de grupos. Então, selecione uma posição agora e você poderá criar grupos ou conectar-se com um existente através de seu painel.",
    volunteer: "Voluntário",
    creategroup: "Criar um Grupo",
    viewPasswordRules: "Ver requisitos da senha"
  },
  help: {
    contactUs: "Entre em Contato",
    aboutFourBells: "Sobre 4Bells",
    faq: "Perguntas Frequentes",
    forgotPassLink: "Esqueceu a senha?"
  },
  about: {
    title: "Sobre 4Bells",
    version: "Versão do Aplicativo",
    releaseDate: "Data de Lançamento",
    buildNumber: "Número de Instalação",
    financialSupport: "Apoio Financeiro:",
    inspirationFeedback: "Nos inspiramos e recebemos ideias e opiniões de:",
    beforeJayStackLink:
      "4Bells é um projeto da Caravan Studios, uma divisão da TechSoup Global. Colaboramos com...",
    afterJayStackLink: "para construir e manter o 4Bells.",
    terms: "Acordo de Licença de Usuário Final",
    policy: "Política de Privacidade"
  },
  offline: {
    status: "Desconectado",
    title: "Sem Conexão de Rede",
    descriptionTitle: "Conexão de rede não encontrada",
    description:
      "Desculpe, não encontramos uma conexão. Por favor, ative seu Wi-Fi ou dados móveis do seu ceular e reinicie o 4Bells. Obrigado!"
  },
  forgotPass: {
    emailMe: "Enviar minhas informações por e-mail",
    emailSucces: "E-mail enviado com Sucesso!"
  },
  contactUs: {
    title: "Entre em Contato",
    bellsfeedback: "Avaliação e Feedback do 4Bells",
    bellsIssue: "Resolução de problemas do 4Bells",
    message: "Mensagem",
    sent: "Mensagem Enviada",
    error: "Falha ao enviar ensagem"
  },
  editAccount: {
    changePass: "Alterar Senha"
  },
  changePass: {
    succesfullyChange: "Senha alterada com sucesso!",
    passwordRule:
      "A senha deve ser de no mínimo 6 caracteres e conter letras maiúsculas e minúsculas, além de números. Não use caracteres especiais.",
    save: "Salvar"
  },
  activities: {
    title: "Painel",
    tabHeaders: {
      alerts: "A Fazer",
      warnings: "Atividade"
    },
    emptyTodos: "Você não possui tarefas",
    emptyActivities: "Você não possui nenhuma atividade.",
    removed: "Atividade Removida",
    actionNeeded: "Ação Requerida",
    view: "Ver..."
  },
  dashboard: {
    spacesLeft: "postos restantes",
    spaceLeft: "posto livre",
    claimedBy: "Aceita por",
    claimedMultiple: "Múltiplos Voluntários",
    claimedUnknown: "Desconhecido",
    noOpenTask: "Não há tarefas abertas para executar",
    noClaimedTask: "Você não possui tarefas a fazer",
    noClosedTask: "Você ainda não completou tarefas",
    view: "Ver...",
    manage: "Gerenciar",
    tagManaging: "Gerenciando",
    tagOpen: "Abertas",
    tagClosed: "Concluídas",
    tagClaimed: "Aceitas",
    claimedByYou: "Você solicitou esta tarefa",
    managedByYou: "Você coordena esta tarefa",
    pleaseRespond: "Por favor, responda à esta tarefa",
    isDeclined: "Você recusou esta tarefa"
  },
  taskDetails: {
    tabHeaders: {
      details: "Detalhes",
      volunteers: "Voluntários",
      contacts: "Contatos",
      location: "Localização",
      history: "Histórico",
      actions: "Ações"
    },
    history: {
      claimed: "Aceita",
      declined: "Recusada",
      completed: "Finalizada",
      closed: "Encerrada",
      modified: "Modificada",
      unclaimed: "Desistência",
      created: "Criada",
      incomplete: "Incompleta",
      admin: "(administrador)",
      decline: "Recusar"
    },
    actions: {
      claim: "Aceitar",
      decline: "Recusar",
      unclaim: "Retirar",
      markComplete: "Marcar como Completa",
      cancel: "Deletar",
      taskRemoved: "Tarefa Removida!",
      noMoreSpacesLeft: "Vagas Esgotadas!",
      unclaimError: "Erro ao remover atribuição!",
      declineError: "Erro ao negar tarefa!",
      markCompleteError: "Erro ao marcar como completa!",
      markIncompleteError: "Erro ao marcar como incompleta!",
      missingTask: "Tarefa pendente",
      changeYourMind: "Mudou de ideia?",
      claimThisTask: "Aceitar esta tarefa"
    },
    details: {
      editTask: "Editar Tarefa",
      briefDesc: "Descrição Curta",
      categories: "Categorias",
      status: "Status",
      ownerName: "Criada por",
      ownerPhone: "Contato",
      startDate: "Data de Início",
      endDate: "Data Final",
      startLocation: "Ponto Inicial",
      endLocation: "Ponto Final",
      organization: "Grupo",
      volunteers: "Voluntários",
      pleaseRespond: "Por favor, responda à esta tarefa",
      isDeclined: "Você recusou esta tarefa"
    },
    volunteers: {
      all: "Todas",
      claimed: "Solicitadas",
      declined: "Recusadas",
      completed: "Finalizadas",
      closed: "Encerrada",
      show: "Mostrar",
      status: "Status:"
    },
    contacts: {
      ratesApply:
        "Serão aplicadas tarifas padrão para serviços de SMS e ligações",
      noContactsForTask: "Nenhum contato específico para a tarefa",
      text: "Texto",
      sendMail: "Enviar E-mail"
    }
  },
  groups: {
    title: "Grupos",
    groupsJoined: "Meus Grupos Voluntários",
    groupsManaged: "Grupos que Coordeno",
    joinGroup: "Entrar num Grupo...",
    joinAnotherGroup: "Entrar em outro Grupo...",
    createGroup: "Criar Grupo...",
    profile: {
      title: "Criar Grupo",
      edit: "Editar Perfil do Grupo",
      groupName: "Nome do Grupo",
      address1: "Endereço 1",
      address2: "Endereço 2",
      city: "Cidade",
      country: "País",
      postalCode: "Código Postal",
      state: "Estado",
      volunteerApproval: "Aprovação dos Voluntários",
      enableApproval: "Ativar aprovação de voluntários?",
      yes: "Sim",
      no: "Não",
      continue: "Passo 2: Categorias",
      save: "Salvar",
      addressTitle: "Endereço",
      groupSettingsTitle: "Configuração do Grupo",
      selectValuePlaceholder: "Selecione uma opção",
      selectStatePlaceholder: "Selecione um estado",
      errorMessage: {
        groupName: "É necessário dar um nome ao grupo",
        volunteerApproval: "Aprovação do voluntário é obrigatória"
      }
    },
    categories: {
      title: "Categorias",
      description:
        "Você pode organizar voluntários por habilidades, tipos de tarefas ou quaisquer categorias que supram sua necessidade.",
      examplesLabel: "Exemplos",
      examples:
        "Coordenação de eventos, chamadas telefônicas, servir refeições, cuidar do transporte",
      categoryLabel: "Selecione ou adicione novas categorias",
      categoryButton: "Adicionar",
      invalid: "Seu grupo deve ter pelo menos uma categoria",
      continue: "Passo 3: Revisar",
      subscribe: "Adicionar à Categoria...",
      unsubscribe: "Remover da Categoria...",
      next: "Próximo",
      heading: "Categorias de Voluntários",
      prompt: "Escolha uma das categorias abaixo, ou adicione as suas próprias",
      placeholder: "Adicione uma categoria...",
      addButtonLabel: "Adicionar",
      selectCategoriesPrompt: "Selecione os tipos de tarefa que te interessam"
    },
    contacts: {
      title: "Adicionar Contatos",
      description:
        "Adicione aqui as pessoas que podem ajudar ou orientar os voluntários sobre as tarefas deste grupo.",
      remove: "Remover",
      add: "Adicionar Contato...",
      save: "OK",
      form: {
        name: "Nome",
        phone: "Telefone",
        email: "E-mail"
      },
      errorMessage: {
        name: "Informe um Nome",
        phone: "Informe um Telefone",
        email: "Informe um E-mail",
        emailInvalid: "E-mail inválido"
      },
      continue: "Passo 4: Revisar",
      rates: "Serão aplicadas tarifas padrão de serviços para SMS e Ligações",
      callPhone: "Ligar",
      sendText: "SMS",
      sendMail: "E-mail"
    },
    review: {
      title: "Revisar",
      details: {
        groupName: "Nome do Grupo",
        connectionCode: "Código de Acesso",
        address1: "Endereço 1",
        address2: "Endereço 2",
        country: "País",
        city: "Cidade",
        state: "Estado",
        postalCode: "CEP",
        volunteerApproval: "Aprovação do Voluntário",
        yes: "Sim",
        no: "Não"
      }
    },
    created: {
      title: "Grupo Criado",
      heading: "Seu grupo foi criado.",
      groupName: "Nome do Grupo",
      connectionCode: "Código de Acesso",
      description:
        "Você receberá um e-mail de confirmação com informações adicionais e instruções para se conectar aos voluntários e criar tarefas a serem delegadas.",
      invite: "Convidar Voluntários"
    },
    volunteer: {
      title: "Grupo"
    },
    admin: {
      title: "Grupo",
      manageGroup: "Gerenciar Este Grupo"
    },
    manage: {
      title: "Gerenciar Grupo",
      editGroup: "Editar Grupo",
      deleteGroup: "Deletar Grupo",
      addCategory: "Adicionar Categoria",
      showPendingOnly: "Mostar aprovações pendentes",
      categoryVolunteers: "{{count}} voluntário(s)",
      approveVolunteers:
        "{{approved}} voluntário(s) aprovado(s) {{pending}} pendente(s) {{rejected}} recusado(s)",
      removeCategory: "Remover",
      viewVolunteers: "Ver",
      manageVolunteers: "Gerenciar",
      addContact: "Adicionar um Contato",
      removeContact: "Remover",
      removeContactConfirm: "Tem certeza que deseja remover {{name}}?",
      inviteMore: "Convidar Mais Voluntários",
      volunteers: "Voluntários",
      invite: "Recrutar Voluntários",
      categorySubtitle: "Categoria",
      manageCategoryDescription:
        "Administre as categorias do grupo e confira os voluntários que estão inscritos nelas",
      statusApproved: "Aprovado(s)",
      statusRejected: "Recusado(s)",
      statusPending: "Pendente(s)"
    },
    invitation: {
      subject: "Convite para juntar-se ao 4Bells"
    },
    actions: {
      completeSetup: "Criar Grupo",
      createFailed: "Falha ao criar grupo",
      disconnect: "Sair do Grupo",
      disconnectConfirm: "Tem certeza que você quer sair deste grupo?",
      deleteGroup: "Deletar Grupo",
      deleteConfirm: "Tem certeza que quer deletar o grupo?"
    },
    tabs: {
      details: "Detalhes",
      categories: "Categorias",
      contacts: "Contatos",
      lead: "Coordenação",
      volunteers: "Voluntários",
      interests: "Meus interesses"
    },
    categoryvolunteers: {
      title: "Lista de Voluntários",
      subtitle: "Da Categoria {{name}}",
      status: "Status",
      approved: "Aprovado",
      pending: "Pendente",
      rejected: "Rejeitado",
      actions: {
        approve: "Aprovar",
        reject: "Recusar",
        disconnect: "Desconectar",
        remove: "Remover",
        changeToRejected: "Alterar status para Recusado",
        changeToApproved: "Alterar status para Aprovado"
      }
    },
    volunteerdetails: {
      title: "Detalhes do Voluntário"
    },
    leaddetails: {
      title: "Detalhes do Líder"
    }
  },
  volunteer: {
    name: "Nome",
    phone: "Telefone",
    email: "E-mail",
    status: "Status",
    categories: "Categorias",
    promote: "Promover a Coordenador",
    demote: "Despromover a Voluntário",
    disconnect: "Retirar deste grupo",
    markComplete: "Marcar como Completo",
    markIncomplete: "Marcar como Incompleto",
    unassignVolunteer: "Cancelar atribuição do Voluntário",
    groupMembership: "`Participação no Grupo",
    taskAssignment: "Distribuição de Tarefas",
    errorMessages: {
      markCompleteFailed: "Falha ao marcar como completa",
      markIncompleteFailed: "Falha ao marcar como incompleta",
      unassignVolunteerFailed: "Falha ao remover atribuição"
    }
  },
  connect: {
    title: "Entrar num Grupo",
    volunteerText:
      "Se você é voluntário(a), insira o código de acesso enviado pelo seu líder de grupo:",
    connectCodeLabel: "Código de Acesso",
    connectButton: "Entrar",
    invalidCode:
      "O código de acesso não é válido. Por favor, verifique com o administrador do grupo qual é o código correto.",
    alreadyConnected:
      "Você já está conectado a este grupo. Seu status pode estar como pendente. Por favor, verifique sua aprovação com o administrador do grupo.",
    approve:
      "O pedido de adesão foi realizado. O grupo aparecerá em seu painel assim que seu pedido for aprovado.",
    thanksConnecting:
      "Obrigado por se juntar ao grupo {{groupName}}. Você começará a receber tarefas após sua aprovação.",
    groupName: "Nome do Grupo:",
    selectCategories: "Selecione os tipos de tarefas que te interessam",
    locating: "Localizando grupo...",
    connecting: "Conectando-se ao grupo..."
  },
  editTask: {
    headerTitles: {
      categories: "Selecionar Categorias",
      review: "Revisar"
    },
    chooseGroup: "Selecione um grupo",
    briefDescPlaceholder: "Descrição da Tarefa",
    group: "Grupo",
    title: "Título",
    taskSummary: "Resumo da Tarefa",
    taskDetails: "Detalhes",
    additionalInformation: "Informações Adicionais (Opcional)",
    datePanelTitle: "DATA",
    locationPanelTitle: "LOCALIZAÇÃO",
    contactsPanelTitle: "CONTATOS",
    selectDatePlaceholder: "Selecione uma Data",
    selectContactsDescription:
      "Selecione pessoas que podem ajudar ou orientar sobre esta tarefa.",
    selectContactsTip: "Dica: Adicione contatos dos seus grupos.",
    selectContacts: "Selecionar Contatos",
    numberVolunteers: "Número de Voluntários",
    continueCategories: "Avançar para Categorias",
    setLocaion: "Definir Localização",
    contacts: "Contatos",
    checkAllFields: "Marcar/Desmarcar Todas as Categorias",
    continueToReview: "Avançar e Revisar",
    submit: "Concluir",
    didYouMean: "Você quis dizer?",
    errorMessages: {
      addFailed: "Falha ao criar tarefa",
      editFailed: "Falha ao atualizar tarefa",
      smsFailedToSent: "Ocorreu um erro e o SMS não foi enviado",
      appLimit:
        "Todo mundo deve estar usando o 4Bells hoje pois atingimos nosso limite diário de mensagens. Isso não acontece com frequência: contate-nos caso já tenha recebido essa mensagem antes ou acha que irá enviar muitas mensagens via 4Bells futuramente",
      dailyLimit:
        "Você deve estar super ocupado! Hoje atingiu o limite diário de 50 mensagens por dia. Espere até {{now}} 11:59 PM (UTC) para enviar mais oportunidades. Contate-nos caso ache que irá utilizar o 4Bells no futuro tanto quanto hoje."
    },
    noSms: "Nenhum SMS enviado",
    sms: "SMS enviado para {{count}} pessoa(s)",
    smsPerson: "SMS enviado para {{count}} pessoa(s)"
  },
  tasks: {
    title: "Tarefas",
    tabHeaders: {
      openClaim: "Abertas",
      currentClaimed: "Aceitas",
      closedTasks: "Finalizadas"
    },
    addTask: "Adicionar Tarefa...",
    addContacts: "Selecione Contatos"
  },
  retryLogin: {
    title: "Erro de Conexão",
    content: "Não é possível se conectar ao servidor",
    cancel: "Cancelar",
    retry: "Tentar novamente"
  }
};
