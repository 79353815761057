import React, { Component, PureComponent } from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Alert,
  RefreshControl,
  Platform,
  FlatList
} from "react-native";
import PropTypes from "prop-types";
import {
  Content,
  Button,
  Text,
  Card,
  CardItem,
  Body,
  H3,
  Icon,
  Left,
  Right,
  H2,
  Badge
} from "native-base";
import moment from "moment";
import Progress from "../../components/Progress";
import I18n from "../../i18n";
import styles from "../../styles";
import variables from "../../../native-base-theme/variables/platform";
import { startNewTask } from "../singleTask/actions";
import { connect } from "react-redux";
import { getList } from "./actions";
import itemFactory from "./items";

export default itemFactory(
  "Open",
  class OpenTask extends PureComponent {
    render() {
      const { task, key, openTask } = this.props;
      return (
        <TouchableOpacity
          key={key}
          onPress={() => (task.id ? openTask(task.id) : () => {})}>
          <Card style={{ marginTop: 10 }}>
            <CardItem>
              <View>
                <View>
                  <View style={{ flexDirection: "row" }}>
                    <Badge success style={{ margin: 3 }}>
                      <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                        {I18n.t("dashboard.tagOpen")}
                      </Text>
                    </Badge>
                    {task.canEdit ? (
                      <Badge
                        primary
                        style={{
                          margin: 3,
                          backgroundColor: "transparent",
                          borderColor: variables.brandPrimary,
                          borderWidth: 1
                        }}>
                        <Text
                          style={{
                            fontSize: 12,
                            fontWeight: "bold",
                            color: variables.brandPrimary
                          }}>
                          {I18n.t("dashboard.tagManaging")}
                        </Text>
                      </Badge>
                    ) : (
                      undefined
                    )}
                  </View>

                  <H3 style={{ fontWeight: "bold" }}>{task.name}</H3>
                  <View style={{ paddingVertical: 5 }}>
                    <Text style={{ fontSize: 14, fontWeight: "bold" }}>
                      <Icon name="people" style={{ fontSize: 14 }} />{" "}
                      {task.group.name}
                    </Text>
                  </View>

                  <Text style={{ fontSize: 16, fontWeight: "200" }}>
                    {moment(task.startdate).format("LLLL")}
                  </Text>
                </View>
              </View>
            </CardItem>

            <CardItem>
              <Body>
                {task.canEdit ? (
                  <View style={{ paddingVertical: 5 }}>
                    <Text style={{ fontSize: 14, fontWeight: "200" }}>
                      <Icon
                        name="md-clipboard"
                        style={{ fontSize: 14, fontWeight: "100" }}
                      />{" "}
                      {I18n.t("dashboard.managedByYou")}
                    </Text>
                  </View>
                ) : (
                  <View style={{ paddingVertical: 5 }}>
                    <Text
                      style={{ fontSize: 14, color: variables.brandDanger }}>
                      <Icon
                        name="ios-notifications"
                        style={{ fontSize: 14, color: variables.brandDanger }}
                      />{" "}
                      {I18n.t("dashboard.pleaseRespond")}
                    </Text>
                  </View>
                )}

                {task.volunteerneeded > 1 ? (
                  <View style={{ paddingVertical: 5 }}>
                    <Text style={{ fontSize: 14, fontWeight: "100" }}>
                      <Icon
                        name="ios-information-circle-outline"
                        style={{ fontSize: 14, fontWeight: "200" }}
                      />{" "}
                      {(task.claimedNumber
                        ? task.volunteerneeded - task.claimedNumber
                        : task.volunteerneeded) +
                        " " +
                        ((task.claimedNumber
                          ? task.volunteerneeded - task.claimedNumber
                          : task.volunteerneeded) == 1
                          ? I18n.t("dashboard.spaceLeft")
                          : I18n.t("dashboard.spacesLeft"))}
                    </Text>
                  </View>
                ) : (
                  undefined
                )}
              </Body>
            </CardItem>

            <View style={[styles.right, { padding: 10, paddingTop: 0 }]}>
              {task.canEdit ? (
                <Text note>{I18n.t("dashboard.manage")}</Text>
              ) : (
                <Text note>{I18n.t("dashboard.view")}</Text>
              )}
            </View>
          </Card>
        </TouchableOpacity>
      );
    }
  },
  () => (
    <View style={{ padding: 30 }}>
      <Text>{I18n.t("dashboard.noOpenTask")}</Text>
    </View>
  ),
  props =>
    !props.offline &&
    props.managed &&
    props.managed.length > 0 && (
      <Button style={{ margin: 10 }} block info onPress={props.addTask}>
        <Text
          uppercase={false}
          numberOfLines={1}
          style={{ overflow: "visible" }}>
          {I18n.t("tasks.addTask")}
        </Text>
      </Button>
    ),
  () => I18n.t("tasks.tabHeaders.openClaim")
);
