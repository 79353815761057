import React, { Component } from "react";
import {
  Content,
  Form,
  Item,
  Label,
  Input,
  Text,
  Button,
  Picker,
  Header,
  Icon,
  Left,
  Body,
  Title,
  Right,
  ActionSheet,
  H3
} from "native-base";
import { StyleSheet, View, Platform } from "react-native";
import ValidatedInput from "../../../components/ValidatedInput";
import { countries, states } from "../const";
import { Screen, transitionConfig } from "../../../utils";
import I18n from "../../../i18n";
import styles from "../../../styles";
import variable from "../../../../native-base-theme/variables/platform";

export default class ProfileForm extends Component {
  state = {
    ...this.props.group.profile
  };

  componentDidMount() {
    this.props.setInputPosition("nameInput", this._groupName);
  }

  componentDidUpdate() {
    this.props.onFormChanged(this.state);
  }

  handleInputChange = name => evt => {
    this.setState({ [name]: evt.nativeEvent.text });
  };

  handlePickerChange = name => value => {
    this.setState({
      [name]: value,
      state:
        name == "country" ? null : name == "state" ? value : this.state.state
    });
  };

  regenerateState(state, placeholder) {
    return state.reduce(
      (current, next) => [
        ...current,
        {
          label: next,
          value: next
        }
      ],
      Platform.OS === "android" || Platform.OS === "web"
        ? [
            {
              label: placeholder,
              value: ""
            }
          ]
        : []
    );
  }

  selectVolunteer = selection => {
    if (selection && selection !== I18n.t("global.cancel")) {
      if (selection === I18n.t("groups.profile.yes")) {
        this.setState({ approvevolunteer: true });
      } else {
        this.setState({ approvevolunteer: false });
      }
    }
  };

  showActionSheet = () => {
    const sheetOptions =
      Platform.OS === "ios"
        ? [
            I18n.t("groups.profile.yes"),
            I18n.t("groups.profile.no"),
            I18n.t("global.cancel")
          ]
        : [I18n.t("groups.profile.yes"), I18n.t("groups.profile.no")];

    ActionSheet.show(
      {
        options: sheetOptions,
        ...{
          cancelButtonIndex: Platform.OS === "ios" ? 2 : null
        },
        title: I18n.t("groups.profile.enableApproval")
      },
      buttonIndex => {
        this.selectVolunteer(sheetOptions[buttonIndex]);
      }
    );
  };

  render() {
    return (
      <Form>
        <View style={style.panelStyle}>
          <View
            onLayout={event => {
              const { x, y } = event.nativeEvent.layout;
              this.props.setInputPosition("groupNamePosition", { x, y });
            }}>
            <H3 style={style.panelTitleRequired}>
              {I18n.t("groups.profile.groupSettingsTitle")}
            </H3>

            <ValidatedInput
              validInput={!!this.state.name && this.state.name.length > 0}
              handleInputChange={this.handleInputChange("name")}
              label={I18n.t("groups.profile.groupName")}
              errorMessage={I18n.t("groups.profile.errorMessage.groupName")}
              name="name"
              value={this.state.name}
              showError={this.props.submitted}
              getRef={ref => (this._groupName = ref)}
              returnKeyType="next"
              onSubmitEditing={() => this.showActionSheet()}
            />
          </View>

          <View
            style={{ marginTop: 15 }}
            onLayout={event => {
              const { x, y } = event.nativeEvent.layout;
              this.props.setInputPosition("approveVolunteerPosition", { x, y });
            }}>
            <Item
              ref={ref => (this._volunteerApproval = ref)}
              picker
              stackedLabel
              noBorder
              error={
                this.props.submitted &&
                typeof this.state.approvevolunteer != "boolean"
              }>
              <Label>{I18n.t("groups.profile.enableApproval")}</Label>

              <View>
                <View style={{ marginTop: 15, marginBottom: 15 }}>
                  <Button
                    iconRight
                    small
                    bordered
                    onPress={() => this.showActionSheet()}>
                    <Text uppercase={false}>
                      {typeof this.state.approvevolunteer != "boolean" &&
                        I18n.t("groups.profile.selectValuePlaceholder")}
                      {typeof this.state.approvevolunteer == "boolean" &&
                        (this.state.approvevolunteer
                          ? I18n.t("groups.profile.yes")
                          : I18n.t("groups.profile.no"))}
                    </Text>
                    <Icon name="ios-arrow-down" style={{ fontSize: 20 }} />
                  </Button>
                </View>
              </View>
            </Item>

            {this.props.submitted &&
              typeof this.state.approvevolunteer != "boolean" && (
                <Text error>
                  {I18n.t("groups.profile.errorMessage.volunteerApproval")}
                </Text>
              )}
          </View>
        </View>

        <View style={style.panelStyle}>
          <H3 style={style.panelTitleRequired}>
            {I18n.t("groups.profile.addressTitle")}
          </H3>

          <Item stackedLabel picker>
            <Label>{I18n.t("groups.profile.country")}</Label>
            {Platform.OS === "ios" ? (
              <Picker
                style={style.picker}
                prompt={I18n.t("groups.profile.country")}
                selectedValue={this.state.country}
                textStyle={{ color: variable.inputColor }}
                onValueChange={this.handlePickerChange("country")}
                renderHeader={backAction => (
                  <Header>
                    <Left>
                      <Button transparent onPress={backAction}>
                        <Icon name="arrow-back" style={{ color: "#fff" }} />
                      </Button>
                    </Left>
                    <Body style={{ flex: 3 }}>
                      <Title style={{ color: "#fff" }}>
                        {I18n.t("global.selecetOne")}
                      </Title>
                    </Body>
                    <Right />
                  </Header>
                )}>
                {countries.map((country, index) => (
                  <Picker.Item
                    label={country}
                    value={country}
                    key={`${country}_${index}`}
                  />
                ))}
              </Picker>
            ) : (
              <Picker
                style={style.picker}
                prompt={I18n.t("groups.profile.country")}
                selectedValue={this.state.country}
                onValueChange={this.handlePickerChange("country")}>
                {countries.map((country, index) => (
                  <Picker.Item
                    label={country}
                    value={country}
                    key={`${country}_${index}`}
                  />
                ))}
              </Picker>
            )}
          </Item>

          <Item floatingLabel>
            <Label>{I18n.t("groups.profile.address1")}</Label>
            <Input
              name="address1"
              autoCorrect={true}
              returnKeyType="next"
              onSubmitEditing={() => this._address2._root.focus()}
              onChange={this.handleInputChange("address1")}
              value={this.state.address1}
            />
          </Item>

          <Item floatingLabel>
            <Label>{I18n.t("groups.profile.address2")}</Label>
            <Input
              ref={ref => (this._address2 = ref)}
              name="address2"
              autoCorrect={true}
              returnKeyType="next"
              onSubmitEditing={() => this._city._root.focus()}
              onChange={this.handleInputChange("address2")}
              value={this.state.address2}
            />
          </Item>

          <Item floatingLabel>
            <Label>{I18n.t("groups.profile.city")}</Label>
            <Input
              ref={ref => (this._city = ref)}
              name="city"
              returnKeyType="next"
              autoCorrect={true}
              onSubmitEditing={() => this._postalCode._root.focus()}
              onChange={this.handleInputChange("city")}
              value={this.state.city}
            />
          </Item>

          {states[this.state.country] &&
          states[this.state.country].length > 0 ? (
            <Item stackedLabel picker>
              <Label>{I18n.t("groups.profile.state")}</Label>
              {Platform.OS === "ios" ? (
                <Picker
                  style={[
                    style.picker
                    //,
                    //!this.state.state
                    //  ? { color: variable.inputColorPlaceholder }
                    //  : {}
                  ]}
                  prompt={I18n.t("groups.profile.state")}
                  textStyle={{ color: variable.inputColor }}
                  placeholderStyle={{ color: variable.inputColorPlaceholder }}
                  selectedValue={this.state.state}
                  onValueChange={this.handlePickerChange("state")}
                  placeholder={
                    Platform.OS === "ios"
                      ? I18n.t("groups.profile.selectStatePlaceholder")
                      : ""
                  }
                  renderHeader={backAction => (
                    <Header>
                      <Left>
                        <Button transparent onPress={backAction}>
                          <Icon name="arrow-back" style={{ color: "#fff" }} />
                        </Button>
                      </Left>
                      <Body style={{ flex: 3 }}>
                        <Title style={{ color: "#fff" }}>
                          {I18n.t("global.selecetOne")}
                        </Title>
                      </Body>
                      <Right />
                    </Header>
                  )}>
                  {this.regenerateState(
                    states[this.state.country],
                    I18n.t("groups.review.details.state")
                  ).map((state, index) => (
                    <Picker.Item
                      label={state.label}
                      value={state.value}
                      key={`${state.value}_${index}`}
                    />
                  ))}
                </Picker>
              ) : (
                <Picker
                  style={[style.picker]}
                  prompt={I18n.t("groups.profile.state")}
                  selectedValue={this.state.state}
                  onValueChange={this.handlePickerChange("state")}
                  placeholder={
                    Platform.OS === "ios"
                      ? I18n.t("groups.profile.selectStatePlaceholder")
                      : ""
                  }>
                  {this.regenerateState(
                    states[this.state.country],
                    I18n.t("groups.review.details.state")
                  ).map((state, index) => (
                    <Picker.Item
                      label={state.label}
                      value={state.value}
                      key={`${state.value}_${index}`}
                    />
                  ))}
                </Picker>
              )}
            </Item>
          ) : (
            <Item floatingLabel>
              <Label>{I18n.t("groups.profile.state")}</Label>
              <Input
                ref={ref => (this._state = ref)}
                name="state"
                autoCorrect={true}
                returnKeyType="next"
                onSubmitEditing={() => this._groupName._root.focus()}
                onChange={this.handleInputChange("state")}
                value={this.state.state}
              />
            </Item>
          )}

          <Item floatingLabel>
            <Label>{I18n.t("groups.profile.postalCode")}</Label>
            <Input
              ref={ref => (this._postalCode = ref)}
              name="postalcode"
              keyboardType="numeric"
              returnKeyType="next"
              autoCorrect={true}
              onSubmitEditing={() =>
                this[
                  states[this.state.country] &&
                  states[this.state.country].length > 0
                    ? "_groupName"
                    : "_state"
                ]._root.focus()
              }
              onChange={this.handleInputChange("postalcode")}
              value={this.state.postalcode}
            />
          </Item>
        </View>
      </Form>
    );
  }
}

const style = StyleSheet.create({
  picker: {
    width: "100%",
    height: 50
    //,
    //color: variable.brandPrimary
  },
  approvalNote: {
    marginVertical: 5
  },
  approvalNoteText: {
    flex: 1,
    color: "#888"
  },
  approvalContainer: {
    marginTop: 0
  },
  panelStyle: {
    padding: 15,
    marginTop: 10,
    backgroundColor: "#FFFFFF"
  },
  panelTitle: {
    fontWeight: "bold"
  },
  panelTitleRequired: {
    color: variable.brandInfo
  }
});
