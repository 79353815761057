import React, { Component } from "react";
import { Content, Text, H3, Button } from "native-base";
import { View, Alert } from "react-native";
import { connect } from "react-redux";
import { disconnectGroup, deleteGroup, changeMode } from "../actions";
import { NavigationActions, StackActions } from "react-navigation";
import I18n from "../../../i18n";
import styles from "../../../styles";
import style from "./style";

export default connect(state => ({
  mode: state.groups.mode,
  group: state.groups.group.profile,
  groupEntity: state.groups.group,
  currentUser: state.auth.currentUser,
  offline: state.offline
}))(
  class Details extends Component {
    _working = false;

    editGroup = () => {
      this.props.navigation.navigate("Edit");
    };

    renderButton(mode) {
      if (this.props.offline) return null;
      switch (mode) {
        case "admin":
          // return (
          //   <Button warning onPress={() => changeMode("manage")}>
          //     <Text uppercase={false}>
          //       {I18n.t("groups.admin.manageGroup")}
          //     </Text>
          //   </Button>
          // );
          return null;
        case "manage":
          return (
            <Button info bordered small onPress={this.editGroup}>
              <Text uppercase={false}>{I18n.t("groups.manage.editGroup")}</Text>
            </Button>
          );
      }
    }

    deleteConfirm = () => {
      if (this._working) return;
      this._working = true;
      Alert.alert(
        I18n.t("global.confirm"),
        I18n.t("groups.actions.deleteConfirm"),
        [
          {
            text: I18n.t("global.no"),
            style: "cancel",
            onPress: () => {
              this._working = false;
            }
          },
          {
            text: I18n.t("global.yes"),
            onPress: async () => {
              if (await deleteGroup()) {
                this.props.navigation.dispatch(
                  NavigationActions.navigate({
                    routeName: "Main",
                    action: NavigationActions.navigate({
                      routeName: "Groups"
                    })
                  })
                );
              }
              this._working = false;
            }
          }
        ],
        {
          onDismiss: () => {
            this._working = false;
          }
        }
      );
    };

    disconnectConfirm = () => {
      if (this._working) return;
      this._working = true;
      const { groupEntity, currentUser } = this.props;
      Alert.alert(
        I18n.t("global.confirm"),
        I18n.t("groups.actions.disconnectConfirm"),
        [
          {
            text: I18n.t("global.no"),
            style: "cancel",
            onPress: () => {
              this._working = false;
            }
          },
          {
            text: I18n.t("global.yes"),
            onPress: async () => {
              await disconnectGroup(groupEntity, currentUser);
              this.props.navigation.dispatch(
                NavigationActions.navigate({
                  routeName: "Main",
                  action: NavigationActions.navigate({
                    routeName: "Groups"
                  })
                })
              );
              this._working = false;
            }
          }
        ],
        {
          onDismiss: () => {
            this._working = false;
          }
        }
      );
    };

    renderActions(mode) {
      if (this.props.offline) return null;
      switch (mode) {
        case "manage":
          return (
            <Button danger block small onPress={this.deleteConfirm}>
              <Text uppercase={false}>
                {I18n.t("groups.actions.deleteGroup")}
              </Text>
            </Button>
          );
        case "volunteer":
          return (
            <Button danger block small onPress={this.disconnectConfirm}>
              <Text uppercase={false}>
                {I18n.t("groups.actions.disconnect")}
              </Text>
            </Button>
          );
      }
    }

    render() {
      const { mode, group } = this.props;

      return (
        <Content>
          <View style={style.padding}>
            <View style={style.section}>{this.renderButton(mode)}</View>
            <View style={style.section}>
              {/*<H3>{I18n.t("groups.review.details.groupName")}</H3>*/}
              <H3 style={{ fontWeight: "bold" }}>{group.name}</H3>
            </View>
            <View style={style.section}>
              <Text uppercase={true} style={style.label}>
                {I18n.t("groups.review.details.connectionCode")}
              </Text>
              <Text>{group.connectioncode}</Text>
            </View>
            <View style={style.section}>
              <Text uppercase={true} style={style.label}>
                {I18n.t("groups.review.details.address1")}
              </Text>
              <Text>{group.address1}</Text>
            </View>
            <View style={style.section}>
              <Text uppercase={true} style={style.label}>
                {I18n.t("groups.review.details.address2")}
              </Text>
              <Text>{group.address2}</Text>
            </View>
            <View style={style.section}>
              <Text uppercase={true} style={style.label}>
                {I18n.t("groups.review.details.country")}
              </Text>
              <Text>{group.country}</Text>
            </View>
            <View style={style.section}>
              <Text uppercase={true} style={style.label}>
                {I18n.t("groups.review.details.city")}
              </Text>
              <Text>{group.city}</Text>
            </View>
            <View style={style.section}>
              <Text uppercase={true} style={style.label}>
                {I18n.t("groups.review.details.state")}
              </Text>
              <Text>{group.state}</Text>
            </View>
            <View style={style.section}>
              <Text uppercase={true} style={style.label}>
                {I18n.t("groups.review.details.postalCode")}
              </Text>
              <Text>{group.postalcode}</Text>
            </View>

            {mode == "manage" ? (
              <View style={style.section}>
                <Text uppercase={true} style={style.label}>
                  {I18n.t("groups.review.details.volunteerApproval")}
                </Text>
                <Text>
                  {I18n.t(
                    `groups.review.details.${
                      group.approvevolunteer ? "yes" : "no"
                    }`
                  )}
                </Text>
              </View>
            ) : null}

            <View style={style.section}>{this.renderActions(mode)}</View>
          </View>
        </Content>
      );
    }
  }
);
