import { StyleSheet } from "react-native";

export default StyleSheet.create({
  padding: {
    padding: 15
  },
  section: {
    paddingVertical: 5
  },
  margin: {
    marginVertical: 15
  },
  action: {
    margin: 5
  },
  label: {
    color: "#808080",
    fontWeight: "bold",
    fontSize: 12,
    marginVertical: 3
  }
});
