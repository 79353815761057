import React, { Component } from "react";
import {
  Content,
  Form,
  Item,
  Label,
  Input,
  Text,
  Button,
  Picker,
  Icon
} from "native-base";
import { StyleSheet, View, Alert } from "react-native";
import { connect } from "react-redux";
import ProfileForm from "./components/ProfileForm";
import { updateGroup, getGlobalCategories } from "./actions";
import { countries, states } from "./const";
import { Screen, transitionConfig } from "../../utils";
import I18n from "../../i18n";
import styles from "../../styles";
import variable from "../../../native-base-theme/variables/platform";

export default Screen(
  connect(state => ({
    group: state.groups.group
  }))(
    class Profile extends Component {
      state = {
        submitted: false,
        nameInput: null,
        approveVolunteerPosition: null,
        groupNamePosition: null
      };
      _working = false;

      formChanged = group => {
        this.group = group;
      };

      next = async () => {
        if (this._working) return;
        this._working = true;
        if (!this.handleError()) {
          updateGroup({ profile: this.group });
          //await getGlobalCategories(); // disabling global categories (issue #289)
          this.props.navigation.navigate("Categories");
        } else {
          this.setState({ submitted: true });
        }
        this._working = false;
      };

      setInputPosition = (field, value) => {
        this.setState({ [field]: value });
      };

      handleError = () => {
        let error = false;
        switch (true) {
          case !this.group:
            error = true;
            break;
          case !this.group.name && this.group.name.length === 0:
            error = true;
            this.state.nameInput._root.focus();
            this.scrollView._root.scrollToPosition(
              0,
              this.state.groupNamePosition.y
            );
            break;
          case typeof this.group.approvevolunteer !== "boolean":
            this.scrollView._root.scrollToPosition(
              0,
              this.state.approveVolunteerPosition.y
            );
            error = true;
            break;
          default:
            error = false;
            break;
        }

        return error;
      };

      render() {
        const { group } = this.props;

        return (
          <Content container ref={c => (this.scrollView = c)}>
            <View>
              <ProfileForm
                group={group}
                onFormChanged={this.formChanged}
                submitted={this.state.submitted}
                setInputPosition={this.setInputPosition}
              />
              <View style={{ padding: 15 }}>
                <Button iconRight block info onPress={this.next}>
                  <Text uppercase={false}>
                    {I18n.t("groups.profile.continue")}
                  </Text>
                  <Icon name="ios-arrow-forward" />
                </Button>
              </View>
            </View>
          </Content>
        );
      }
    }
  ),
  _ => I18n.t("groups.profile.title")
);

const style = StyleSheet.create({
  padding: {
    paddingHorizontal: 15
  }
});
