import React, { Component } from "react";
import {
  Left,
  Button,
  Icon,
  Body,
  Title,
  Subtitle,
  Right,
  Content,
  Tabs,
  Tab,
  Text,
  H1,
  H2,
  H3,
  Card,
  CardItem,
  List,
  ListItem,
  FooterTab
} from "native-base";
import { NavigationActions } from "react-navigation";
import { StyleSheet, View, Alert, Platform } from "react-native";
import { text, email, phonecall } from "react-native-communications";
import { connect } from "react-redux";
import {
  groupCategories,
  groupContacts,
  groupDetails,
  groupLeads,
  groupVolunteers
} from "../actions";
import Details from "./details";
import Subscription from "./subscription";
import Categories from "./categories";
import Contacts from "./contacts";
import Lead from "./lead";
import Volunteers from "./volunteers";
import Header from "./header";
import Footer from "./footer";
import ScrollableTab from "../../../components/ScrollableTab";
import { Screen, HeaderWithBackAndSubtitle } from "../../../utils";
import I18n from "../../../i18n";
import styles from "../../../styles";
import variable from "../../../../native-base-theme/variables/platform";
import style from "./style";

const tabs = [
  "profile",
  "volunteers",
  "interests",
  "categories",
  "contacts",
  "leads"
];
export default Screen(
  connect(state => ({
    mode: state.groups.mode,
    group: state.groups.group,
    offline: state.offline,
    joined: state.groups.joined,
    managed: state.groups.managed
  }))(
    class extends Component {
      state = {
        categories: this.props.mode == "review" ? true : false,
        contacts: this.props.mode == "review" ? true : false,
        leads: this.props.mode == "review" ? true : false,
        volunteers: this.props.mode == "review" ? true : false,
        initialPage:
          this.props.navigation.state.params &&
          this.props.navigation.state.params.pivot
            ? tabs.indexOf(this.props.navigation.state.params.pivot)
            : 0
      };

      async componentDidMount() {
        const idParam =
          +this.props.navigation.getParam("id") || this.props.group.id;
        if (idParam !== this.props.group.id) {
          const group =
            (this.props.joined ?? []).find(it => it.id === idParam) ??
            (this.props.managed ?? []).find(it => it.id === idParam);
          await groupDetails(
            group.role === "admin" ? "manage" : "volunteer",
            group
          );
        }

        if (this.state.initialPage > 0) {
          this._activeTab =
            this._profileTab ||
            this._interestsTab ||
            this._categoriesTab ||
            this._contactsTab ||
            this._leadsTab ||
            this._volunteersTab;
          if (this._activeTab && this._activeTab.props.resolver) {
            await this._activeTab.props.resolver();
          }
          setTimeout(() => {
            if (this.state.initialPage > 0) {
              this._tabs.goToPage(this.state.initialPage);
              this.props.navigation.setParams({ pivot: null });
            }
          }, 1);
        }
      }

      static getDerivedStateFromProps(props, state) {
        if (!props.offline && state.offline != props.offline) {
          return {
            offline: props.offline,
            categories: props.mode == "review" ? true : false,
            contacts: props.mode == "review" ? true : false,
            leads: props.mode == "review" ? true : false,
            volunteers: props.mode == "review" ? true : false
          };
        }
        return null;
      }

      async componentDidUpdate(prevProps) {
        if (
          !this.props.offline &&
          prevProps.offline != this.props.offline &&
          this._activeTab &&
          this._activeTab.props.resolver
        ) {
          await this._activeTab.props.resolver();
        }
      }

      changeTab = async ({ i, ref, from }) => {
        this._activeTab = ref;
        let { resolver } = ref.props;
        if (resolver) {
          await resolver();
        }
      };

      load = (type, group, action) => async () => {
        if (!this.state[type]) {
          await action(group);
          this.setState({ [type]: true });
        }
      };

      render() {
        const { mode, group } = this.props;

        return (
          <Tabs
            initialPage={this.state.initialPage}
            renderTabBar={() => (
              <ScrollableTab
                tabsContainerStyle={{
                  backgroundColor:
                    Platform.OS === "android"
                      ? variable.toolbarDefaultBg
                      : "white"
                }}
              />
            )}
            onChangeTab={this.changeTab}
            ref={ref => (this._tabs = ref)}>
            <Tab
              heading={I18n.t("groups.tabs.details")}
              ref={ref => (this._profileTab = ref)}>
              <Details {...this.props} />
            </Tab>
            {mode == "manage" && (
              <Tab
                heading={I18n.t("groups.tabs.volunteers")}
                resolver={this.load("volunteers", group, groupVolunteers)}
                ref={ref => (this._volunteersTab = ref)}>
                {this.state.volunteers ? (
                  <Volunteers {...this.props} />
                ) : (
                  undefined
                )}
              </Tab>
            )}
            {mode != "review" && (
              <Tab
                heading={I18n.t("groups.tabs.interests")}
                resolver={this.load("categories", group, groupCategories)}
                ref={ref => (this._interestsTab = ref)}>
                {this.state.categories && <Subscription {...this.props} />}
              </Tab>
            )}
            {(mode == "manage" || mode == "review") && (
              <Tab
                heading={I18n.t("groups.tabs.categories")}
                resolver={this.load("categories", group, groupCategories)}
                ref={ref => (this._categoriesTab = ref)}>
                <Categories
                  {...this.props}
                  {...this.props.navigation.state.params}
                />
              </Tab>
            )}
            <Tab
              heading={I18n.t("groups.tabs.contacts")}
              resolver={this.load("contacts", group, groupContacts)}
              ref={ref => (this._contactsTab = ref)}>
              {this.state.contacts ? <Contacts {...this.props} /> : undefined}
            </Tab>
            <Tab
              heading={I18n.t("groups.tabs.lead")}
              resolver={this.load("leads", group, groupLeads)}
              ref={ref => (this._leadsTab = ref)}>
              {this.state.leads ? <Lead {...this.props} /> : undefined}
            </Tab>
          </Tabs>
        );
      }
    }
  ),
  Header,
  Footer
);
