import types from "../actions/types";
import { showToast } from "../utils";
import I18n from "../i18n";

export default (state = null, action) => {
  switch (action.type) {
    case types.ERROR:
      if (action.err) {
        try {
          const { err } = action;
          if (
            err &&
            err.response &&
            err.response.status >= 400 &&
            err.response.status < 500 &&
            err.response.status != 404 &&
            (!err.response.data ||
              (err.response.data &&
                !err.response.data.msg &&
                !err.response.data.error))
          ) {
            return err;
          }
          if (err && err.response) {
            const msg =
              err &&
              err.response &&
              err.response.data &&
              (err.response.data.msg || err.response.data.error);
            showToast(
              I18n.t(`appError.${msg.message || msg.toString()}`, {
                defaultValue: msg.message || msg.toString()
              }),
              "danger"
            );
          }
          return err;
        } catch (_) {
          console.error(action.err);
          return action.err;
        }
      } else return null;
    default:
      return state;
  }
};
