import { StyleSheet } from "react-native";
import variables from "../native-base-theme/variables/platform";

export default StyleSheet.create({
  container: {
    flex: 1
  },
  center: {
    justifyContent: "center",
    alignItems: "center"
  },
  vertical: {
    flexDirection: "column",
    flex: 1
  },
  body: {
    padding: 15
  },
  left: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  right: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  backgroundImage: {
    position: "absolute",
    width: "100%",
    height: "100%"
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    margin: 10
  },
  instructions: {
    textAlign: "center",
    color: "#333333",
    marginBottom: 5
  },
  text: {
    color: "#fff"
  },
  green: {
    color: "#4d331f"
  },
  blue: {
    color: "rgb(51,102,153)"
  },
  shadow: {
    marginVertical: 5,
    marginHorizontal: 2,
    borderWidth: variables.borderWidth,
    borderRadius: 2,
    borderColor: variables.cardBorderColor,
    flexWrap: "nowrap",
    backgroundColor: variables.cardDefaultBg,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 1.5,
    elevation: 3
  }
});
