import { email } from "react-native-communications";
import { Alert } from "react-native";
import store from "../../../store";
import types from "./types";
import {
  GET,
  POST,
  PUT,
  PATCH,
  DELETE,
  inlineGET,
  syncGET
} from "../../../axios";
import { offlineGuard, isOffline } from "../../../offline";
import I18n from "../../../i18n";

export async function getGroups(sync) {
  const offline = await isOffline();
  if (!offline) {
    const groups = await (sync ? syncGET : inlineGET)("/groups");
    if (groups) {
      store.dispatch({ type: types.GET_GROUPS, groups });
      return groups;
    } else {
      return store.getState().groups;
    }
  } else {
    return store.getState().groups;
  }
}

export function createGroup() {
  const { auth } = store.getState();
  const { currentUser } = auth;
  store.dispatch({
    type: types.CREATE_GROUP,
    group: {
      leads: [
        {
          ...currentUser,
          name: `${currentUser.firstname} ${currentUser.lastname}`
        }
      ]
    }
  });
}

export function updateGroup(group) {
  const managed = store.getState().groups.group;
  group = { ...group, id: managed.id };
  store.dispatch({ type: types.UPDATE_GROUP, group });
}

export async function saveGroup() {
  const { group } = store.getState().groups;
  if (group.id) {
    return await PUT(`/groups/${group.id}`, {
      id: group.id,
      profile: group.profile
    });
  } else {
    const result = await POST("/groups", {
      profile: group.profile,
      categories: group.categories,
      contacts: group.contacts
    });
    if (result) {
      store.dispatch({
        type: types.UPDATE_GROUP,
        group: {
          ...result,
          ...group,
          profile: { ...group.profile, ...result.profile },
          id: result.id
        }
      });
      store.dispatch({
        type: types.ADD_GROUP,
        group: { id: result.id, name: group.profile.name, role: "admin" }
      });
      return result;
    }
  }
  return false;
}

export async function promoteToLead(group, user) {
  await POST(`/groups/${group.id}/leads`, [user.id]);
  await leadDetails(user);
  store.dispatch({
    type: types.UPDATE_GROUP,
    group: {
      ...group,
      leads: group.leads ? [...group.leads, user] : [],
      volunteers: group.volunteers
        ? group.volunteers.filter(it => it.id != user.id)
        : []
    }
  });
}

export async function demoteToVolunteer(group, user) {
  await POST(`/groups/${group.id}/volunteers`, [user.id]);
  await volunteerDetails(user);
  store.dispatch({
    type: types.UPDATE_GROUP,
    group: {
      ...group,
      leads: group.leads ? group.leads.filter(it => it.id != user.id) : [],
      volunteers: group.volunteers ? [...group.volunteers, user] : []
    }
  });
}

export async function joinRequest(connectioncode) {
  let group = await POST("/groups/joinrequests", { connectioncode });
  if (!group) return;
  const { error } = group;
  if (error) return group;
  group.profile = { connectioncode };
  group.categories = await GET(`/groups/${group.id}/categories`);
  return { ...group, role: "volunteer" };
}

export async function connectGroup(group, categories) {
  await POST(`/groups/${group.profile.connectioncode}/users`, categories);
  store.dispatch({ type: types.ADD_GROUP, group });
  return true;
}

export async function disconnectGroup(group, user) {
  await DELETE(`/groups/${group.id}/users/${user.id}`);
  group = {
    ...group,
    volunteers: group.volunteers
      ? group.volunteers.filter(it => it.id != user.id)
      : []
  };
  store.dispatch({
    type: types.UPDATE_GROUP,
    group
  });
  const { currentUser } = store.getState().auth;
  if (currentUser.id == user.id) {
    store.dispatch({
      type: types.DELETE_GROUP,
      group
    });
  }
  if (group.categories) {
    await groupCategories(group);
  }
}

export async function categorySubscribe(group, category, user) {
  await POST(`/groups/${group.id}/categories/${category.id}/subscriptions`);
  store.dispatch({
    type: types.UPDATE_GROUP,
    group: {
      ...group,
      categories: group.categories
        ? group.categories.map(it => (it.id == category.id ? category : it))
        : []
    }
  });
}

export async function categoryUnsubscribe(group, category, user) {
  await DELETE(`/groups/${group.id}/categories/${category.id}/subscriptions`);
  store.dispatch({
    type: types.UPDATE_GROUP,
    group: {
      ...group,
      categories: group.categories
        ? group.categories.map(it => (it.id == category.id ? category : it))
        : []
    }
  });
}

export async function deleteGroup() {
  const { group } = store.getState().groups;
  await DELETE(`/groups/${group.id}`);
  store.dispatch({ type: types.DELETE_GROUP, group });
  return true;
}

export async function getGlobalCategories() {
  store.dispatch({
    type: types.GLOBAL_CATEGORIES,
    categories: await GET("/categories")
  });
}

export async function addCategories(categories) {
  if (categories.length > 0) {
    const { group } = store.getState().groups;
    if (group.id) {
      await Promise.all(
        categories.map(category =>
          (async () => {
            const { id } = await POST(`/groups/${group.id}/categories`, {
              id: category.id,
              name: category.name
            });
            categories.find(it => it == category).id = id;
          })()
        )
      );
    }
    store.dispatch({
      type: types.UPDATE_GROUP,
      group: {
        categories: [
          ...group.categories,
          ...categories.map(it => ({
            ...it,
            approved: 0,
            pending: 0,
            rejected: 0,
            volunteers: 0,
            subscription: false
          }))
        ]
      }
    });
  }
}

export async function removeCategory(category) {
  const { group } = store.getState().groups;
  const index = (group.categories || []).indexOf(category);
  if (index >= 0) {
    await DELETE(`/groups/${group.id}/categories/${category.id}`);
    store.dispatch({
      type: types.UPDATE_GROUP,
      group: {
        categories: group.categories.filter(it => it != category)
      }
    });
  }
}

export async function removeContact(contact) {
  const { group } = store.getState().groups;
  const index = (group.contacts || []).indexOf(contact);
  if (index >= 0) {
    await DELETE(`/groups/${group.id}/contacts/${contact.id}`);
    store.dispatch({
      type: types.UPDATE_GROUP,
      group: {
        contacts: group.contacts.filter(it => it.id != contact.id)
      }
    });
  }
}

export async function addContact(contact) {
  const { group } = store.getState().groups;
  contact = (await POST(`/groups/${group.id}/contacts`, contact)) || contact;
  store.dispatch({
    type: types.UPDATE_GROUP,
    group: {
      contacts: [contact, ...group.contacts]
    }
  });
}

export async function viewVolunteers(category) {
  const { group } = store.getState().groups;
  store.dispatch({
    type: types.CATEGORY_VOLUNTEERS,
    category,
    volunteers: await GET(
      `/groups/${group.id}/categories/${category.id}/subscriptions`
    )
  });
}

async function volunteerStatus(volunteer, status) {
  const { category, categoryvolunteers, group } = store.getState().groups;
  await PATCH(
    `/groups/${group.id}/categories/${category.id}/subscriptions/${
      volunteer.id
    }`,
    { status }
  );
  const { categories } = group;
  let newCategory = category;
  store.dispatch({
    type: types.UPDATE_GROUP,
    group: {
      categories: (categories || []).map(it =>
        it.id == category.id
          ? (newCategory = {
              ...it,
              [status]: it[status] + 1,
              [volunteer.status]: it[volunteer.status] - 1
            })
          : it
      )
    }
  });
  store.dispatch({
    type: types.CATEGORY_VOLUNTEERS,
    category: newCategory,
    volunteers: categoryvolunteers.map(it =>
      it.id == volunteer.id ? { ...it, status } : it
    )
  });
}

export async function volunteerApprove(volunteer) {
  await volunteerStatus(volunteer, "approved");
}

export async function volunteerReject(volunteer) {
  await volunteerStatus(volunteer, "rejected");
}

export async function volunteerDisconnect(volunteer) {
  const { category, categoryvolunteers, group } = store.getState().groups;
  await DELETE(`/groups/${group.id}/users/${volunteer.id}`);
  const { categories, volunteers } = group;
  let newCategory = {
    ...category,
    volunteers: Math.max(0, category.volunteers - 1),
    [volunteer.status]: Math.max(0, category[volunteer.status] - 1)
  };
  await groupCategories(group);
  store.dispatch({
    type: types.CATEGORY_VOLUNTEERS,
    category: newCategory,
    volunteers: categoryvolunteers.filter(it => it.id != volunteer.id)
  });
}

export async function volunteerRemove(volunteer) {
  const { category, categoryvolunteers, group } = store.getState().groups;
  await DELETE(
    `/groups/${group.id}/categories/${category.id}/subscriptions/${
      volunteer.id
    }`
  );
  const { categories } = group;
  let newCategory = category;
  store.dispatch({
    type: types.UPDATE_GROUP,
    group: {
      categories: (categories || []).map(it =>
        it.id == category.id
          ? (newCategory = {
              ...it,
              volunteers: Math.max(0, it.volunteers - 1),
              [volunteer.status]: Math.max(0, it[volunteer.status] - 1)
            })
          : it
      )
    }
  });
  store.dispatch({
    type: types.CATEGORY_VOLUNTEERS,
    category: newCategory,
    volunteers: categoryvolunteers.filter(it => it.id != volunteer.id)
  });
}

export async function volunteerDetails(volunteer) {
  const { group } = store.getState().groups;
  const categories = await GET(
    `/groups/${group.id}/users/${volunteer.id}/subscriptions`
  );
  volunteer = {
    ...volunteer,
    categories,
    isAdmin: true
  };
  store.dispatch({ type: types.VOLUNTEER_DETAILS, volunteer });
}

export async function leadDetails(lead) {
  const { group, managed } = store.getState().groups;
  lead = {
    ...lead,
    categories: [{ name: "foobar" }],
    isAdmin: !!managed.find(it => it.id == group.id)
  };
  store.dispatch({ type: types.LEAD_DETAILS, lead });
}

export async function groupDetails(mode, group, sync) {
  return await offlineGuard(
    () => {
      if (group && group.id && !sync) {
        const cache = store.getState().groups.offline[group.id];
        if (cache) {
          group = { ...group, ...cache };
          store.dispatch({
            type: types.GROUP_DETAILS,
            mode,
            group
          });
          return group;
        }
      }
    },
    async () => {
      if (group) {
        const result = await (sync ? syncGET : GET)(`/groups/${group.id}`);
        if (!result) return false;
        group = { ...group, ...result };
      }
      store.dispatch({
        type: sync ? types.SYNC_GROUP_DETAILS : types.GROUP_DETAILS,
        mode,
        group
      });
      return group;
    }
  );
}

export async function groupContacts(group, sync) {
  const offline = await isOffline();
  if (!offline) {
    group = {
      ...group,
      contacts: await (sync ? syncGET : GET)(`/groups/${group.id}/contacts`)
    };
    store.dispatch({
      type: sync ? types.SYNC_UPDATE_GROUP : types.UPDATE_GROUP,
      group
    });
  }
  return group;
}

export async function groupCategories(group, sync) {
  const offline = await isOffline();

  if (!offline) {
    group = {
      ...group,
      categories: await (sync ? syncGET : GET)(`/groups/${group.id}/categories`)
    };
    store.dispatch({
      type: sync ? types.SYNC_UPDATE_GROUP : types.UPDATE_GROUP,
      group
    });
  }
  return group;
}

export async function groupLeads(group, sync) {
  const offline = await isOffline();

  if (!offline) {
    group = {
      ...group,
      leads: await (sync ? syncGET : GET)(`/groups/${group.id}/leads`)
    };
    store.dispatch({
      type: sync ? types.SYNC_UPDATE_GROUP : types.UPDATE_GROUP,
      group
    });
  }
  return group;
}

export async function groupVolunteers(group, sync) {
  const offline = await isOffline();

  if (!offline) {
    group = {
      ...group,
      volunteers: await (sync ? syncGET : GET)(`/groups/${group.id}/volunteers`)
    };
    store.dispatch({
      type: sync ? types.SYNC_UPDATE_GROUP : types.UPDATE_GROUP,
      group
    });
  }
  return group;
}

export function changeMode(mode) {
  store.dispatch({ type: types.GROUP_DETAILS, mode });
}

export async function invite(group) {
  const offline = await isOffline();

  const template =
    (!offline &&
      (await GET(`/groups/${group.id}/invitation`, {
        responseType: "text"
      }))) ||
    `Hello,

Our organization uses the 4Bells app to help us deploy volunteers to urgent, time-sensitive, and location-specific tasks.

I’m writing to invite you to download 4Bells so we can send volunteer opportunities to your mobile phone. Connecting with our organization via 4Bells is easy. Here’s what you need to do:

1. On your mobile phone, go to www.4Bells.org and click the badge for your type of phone. You’ll be sent to the 4Bells marketplace for your phone.

2. Download 4Bells.

3. Once 4Bells is downloaded, launch it and tap the Sign Up link in the lower right hand corner.

4. Enter your information.

5. At the end of that process, you will be asked if you are a volunteer or an admin. Select Volunteer and enter the connection code below.

Connection code: ${group.profile.connectioncode}

You are now connected to us! Thank you for supporting our organization. When we have a volunteer opportunity that matches your skills and interests, you will receive an alert. You can choose to claim that volunteer opportunity or wait for the right one to appear. Thank you.`;
  email(null, null, null, I18n.t("groups.invitation.subject"), template);
}
