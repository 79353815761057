import React, { Component } from "react";
import { Platform } from "react-native";
import { View, H3, Text, Button } from "native-base";
import CommunicationActions from "../screens/groups/components/CommunicationActions";
import { sortString } from "../utils";
import style from "../screens/groups/details/style";
import I18n from "../i18n";

export default class extends Component {
  render() {
    const {
      currentUser,
      user,
      isAdmin,
      role,
      group,
      task,
      promote,
      demote,
      disconnect,
      markComplete,
      markIncomplete,
      unassignVolunteer
    } = this.props;

    return (
      <View>
        <View style={style.section}>
          <H3 style={{ fontWeight: "bold" }}>{user.name}</H3>
        </View>
        {user.taskStatus ? (
          <View style={style.section}>
            <Text uppercase={true} style={style.label}>
              {I18n.t("volunteer.status")}
            </Text>
            <Text>{I18n.t("global.taskStatus." + user.taskStatus)}</Text>
          </View>
        ) : (
          undefined
        )}

        <View style={style.section}>
          {Platform.OS != "web" && (
            <Text uppercase={true} style={style.label}>
              {I18n.t("volunteer.phone")}
            </Text>
          )}
          <Text>{user.phoneformatted || user.phone}</Text>
        </View>
        <View style={style.section}>
          <Text uppercase={true} style={style.label}>
            {I18n.t("volunteer.email")}
          </Text>
          <Text>{user.email}</Text>
        </View>

        {role == "volunteer" &&
        user.categories &&
        user.categories.length > 0 ? (
          <View style={style.section}>
            <Text uppercase={true} style={style.label}>
              {I18n.t("volunteer.categories")}
            </Text>
            {sortString(user.categories, "name").map((category, index) => (
              <Text key={`${category.name}_${index}`}>{category.name}</Text>
            ))}
          </View>
        ) : (
          undefined
        )}
        <View style={style.section}>
          <CommunicationActions user={user} i18n="groups.contacts" />
        </View>
        {isAdmin && group ? (
          <View style={{ marginVertical: 15 }}>
            {role == "volunteer" ? (
              <View>
                <View style={style.section}>
                  <Text style={{ fontWeight: "bold" }}>
                    {I18n.t("volunteer.groupMembership")}
                  </Text>
                </View>
                <Button
                  info
                  full
                  small
                  bordered
                  style={{ marginTop: 15 }}
                  onPress={promote}>
                  <Text uppercase={false}>{I18n.t("volunteer.promote")}</Text>
                </Button>
                <Button
                  danger
                  full
                  small
                  style={{ marginTop: 15 }}
                  onPress={disconnect}>
                  <Text uppercase={false}>
                    {I18n.t("volunteer.disconnect")}
                  </Text>
                </Button>
              </View>
            ) : user.id != currentUser.id ? (
              <View>
                <View style={style.section}>
                  <Text style={{ fontWeight: "bold" }}>
                    {I18n.t("volunteer.groupMembership")}
                  </Text>
                </View>
                <Button
                  info
                  full
                  small
                  bordered
                  style={{ marginTop: 15 }}
                  onPress={demote}>
                  <Text uppercase={false}>{I18n.t("volunteer.demote")}</Text>
                </Button>
              </View>
            ) : (
              undefined
            )}
          </View>
        ) : (
          undefined
        )}

        {isAdmin && task && role == "volunteer" ? (
          <View style={{ marginTop: 15 }}>
            <View style={style.section}>
              <Text style={{ fontWeight: "bold" }}>
                {I18n.t("volunteer.taskAssignment")}
              </Text>
            </View>
          </View>
        ) : null}

        {isAdmin &&
        task &&
        role == "volunteer" &&
        user.taskStatus != "decline" ? (
          <View>
            {user.taskStatus != "closed" ? (
              <Button
                full
                small
                style={{ backgroundColor: "green", marginTop: 15 }}
                onPress={markComplete}>
                <Text uppercase={false}>
                  {I18n.t("volunteer.markComplete")}
                </Text>
              </Button>
            ) : (
              undefined
            )}
            {user.taskStatus == "closed" ? (
              <Button
                danger
                full
                small
                style={{ marginTop: 15 }}
                onPress={markIncomplete}>
                <Text uppercase={false}>
                  {I18n.t("volunteer.markIncomplete")}
                </Text>
              </Button>
            ) : (
              undefined
            )}
            {user.taskStatus == "claimed" ? (
              <Button
                info
                full
                small
                bordered
                style={{ marginTop: 15 }}
                onPress={unassignVolunteer}>
                <Text uppercase={false}>
                  {I18n.t("volunteer.unassignVolunteer")}
                </Text>
              </Button>
            ) : (
              undefined
            )}
          </View>
        ) : (
          undefined
        )}
      </View>
    );
  }
}
