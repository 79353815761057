import React, { Component } from "react";
import {
  Content,
  Radio,
  Left,
  Text,
  ListItem,
  Body,
  H3,
  Button,
  Icon,
  View
} from "native-base";
import { connect } from "react-redux";
import { setSyncMode } from "../actions/auth";
import { Screen, showToast } from "../utils";
import I18n from "../i18n";
import { sync } from "../sync";
import variables from "../../native-base-theme/variables/platform";

export default Screen(
  connect(state => ({
    syncMode: state.auth.syncMode,
    currentUser: state.auth.currentUser
  }))(
    class Settings extends Component {
      state = {
        type: this.props.syncMode || "always",
        syncDisabled: false
      };
      _working = false;

      handleCheckboxChange(value) {
        this.setState({ type: value });
        setSyncMode(value);
      }

      render() {
        return (
          <Content>
            <View container style={{ padding: 30 }}>
              <View>
                <H3 style={{ color: variables.brandInfo }}>
                  {I18n.t("settings.sync.description")}
                </H3>
                <ListItem avatar style={{ marginLeft: 0 }}>
                  <Left>
                    <Radio
                      onPress={() => this.handleCheckboxChange("always")}
                      selected={this.state.type === "always" ? true : false}
                      rounded
                    />
                  </Left>
                  <Body>
                    <Text style={{ fontWeight: "bold" }}>
                      {I18n.t("settings.sync.always")}
                    </Text>
                    <Text style={{ fontWeight: "200" }}>
                      {I18n.t("settings.sync.alwaysDescription")}
                    </Text>
                  </Body>
                </ListItem>
                <ListItem avatar style={{ marginLeft: 0 }}>
                  <Left>
                    <Radio
                      onPress={() => this.handleCheckboxChange("wifi")}
                      selected={this.state.type === "wifi" ? true : false}
                      rounded
                    />
                  </Left>
                  <Body>
                    <Text style={{ fontWeight: "bold" }}>
                      {I18n.t("settings.sync.wifi")}
                    </Text>
                    <Text style={{ fontWeight: "200" }}>
                      {I18n.t("settings.sync.wifiDescription")}
                    </Text>
                  </Body>
                </ListItem>
                <ListItem avatar style={{ marginLeft: 0 }}>
                  <Left>
                    <Radio
                      onPress={() => this.handleCheckboxChange("none")}
                      selected={this.state.type === "none" ? true : false}
                      rounded
                    />
                  </Left>
                  <Body>
                    <Text style={{ fontWeight: "bold" }}>
                      {I18n.t("settings.sync.none")}
                    </Text>
                    <Text style={{ fontWeight: "200" }}>
                      {I18n.t("settings.sync.noneDescription")}
                    </Text>
                  </Body>
                </ListItem>
              </View>

              {(this.state.type === "wifi" || this.state.type === "none") && (
                <View style={{ marginVertical: 15 }}>
                  <Text note style={{ color: variables.brandDanger }}>
                    {I18n.t("settings.sync.reducedOfflineData")}
                  </Text>
                </View>
              )}

              {this.props.currentUser && (
                <View style={{ padding: 30 }}>
                  <Button
                    iconLeft
                    transparent
                    small
                    block
                    info
                    disabled={this.state.syncDisabled}
                    onPress={async _ => {
                      if (this._working) return;
                      this._working = true;
                      this.setState({ syncDisabled: true });
                      const completed = await sync(true);
                      if (completed) {
                        showToast(I18n.t("sync.complete"));
                      } else {
                        showToast(I18n.t("sync.cancel"));
                      }
                      this.setState({ syncDisabled: false });
                      this._working = false;
                    }}>
                    <Icon name="refresh" style={{ fontSize: 18 }} />
                    <Text uppercase={false}>
                      {I18n.t("settings.sync.update")}
                    </Text>
                  </Button>
                </View>
              )}
            </View>
          </Content>
        );
      }
    }
  ),
  _ => I18n.t("screenHeader.settings")
);
