import React, { Component } from "react";
import { Linking } from "react-native";
import { Content, List, ListItem, Text } from "native-base";
import { Screen, HeaderWithBack } from "../utils";
import I18n from "../i18n";

export default Screen(
  class Help extends Component {
    render() {
      return (
        <Content container>
          <List>
            <ListItem
              noBorder
              onPress={() => this.props.navigation.navigate("ForgotPass")}>
              <Text>{I18n.t("help.forgotPassLink")}</Text>
            </ListItem>
            <ListItem
              noBorder
              onPress={() => this.props.navigation.navigate("ContactUs")}>
              <Text>{I18n.t("help.contactUs")}</Text>
            </ListItem>
            <ListItem
              noBorder
              onPress={() => this.props.navigation.navigate("About")}>
              <Text>{I18n.t("help.aboutFourBells")}</Text>
            </ListItem>
            <ListItem
              noBorder
              onPress={() =>
                Linking.openURL("https://www.4bells.org/4bells-faq")
              }>
              <Text>{I18n.t("help.faq")}</Text>
            </ListItem>
          </List>
        </Content>
      );
    }
  },
  _ => I18n.t("screenHeader.help")
);
