import types from "../actions/types";

export default (state = [], action) => {
  switch (action.type) {
    case types.GET_TASK:
      return [];
    case types.GET_DIRECTION:
      return action.direction || [];
    case types.LOGOUT:
      return [];
    default:
      return state;
  }
};
