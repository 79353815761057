import React, { Component } from "react";
import {
  Content,
  Form,
  Item,
  Label,
  Input,
  Text,
  Button,
  Picker
} from "native-base";
import { StyleSheet, View, Alert } from "react-native";
import { connect } from "react-redux";
import Header from "./details/header";
import ProfileForm from "./components/ProfileForm";
import { updateGroup, saveGroup } from "./actions";
import { countries, states } from "./const";
import { Screen, transitionConfig } from "../../utils";
import I18n from "../../i18n";
import styles from "../../styles";
import variable from "../../../native-base-theme/variables/platform";
import { isOffline } from "../../offline";

export default Screen(
  connect(state => ({
    group: state.groups.group
  }))(
    class Edit extends Component {
      state = {
        submitted: false,
        nameInput: null,
        approveVolunteerPosition: null,
        groupNamePosition: null
      };
      _working = false;

      formChanged = group => {
        this.group = group;
      };

      next = async () => {
        if (this._working) return;
        this._working = true;
        if (await isOffline()) {
          Alert.alert(
            I18n.t("offline.title"),
            I18n.t("offline.descriptionTitle")
          );
          this._working = false;
          return;
        }
        if (this.group && this.group.name && this.group.name.length > 0) {
          updateGroup({ profile: this.group });
          await saveGroup();
          this.props.navigation.goBack();
        } else {
          this.setState({ submitted: true });
        }
        this._working = false;
      };

      setInputPosition = (field, value) => {
        this.setState({ [field]: value });
      };

      handleError = () => {
        let error = false;
        switch (true) {
          case !this.group:
            error = true;
            break;
          case !this.group.name || this.group.name.length === 0:
            error = true;
            this.state.nameInput._root.focus();
            this.scrollView._root.scrollToPosition(
              0,
              this.state.groupNamePosition.y
            );
            break;
          default:
            error = false;
            break;
        }

        return error;
      };

      render() {
        const { group } = this.props;

        return (
          <Content container>
            <View>
              <ProfileForm
                group={group}
                onFormChanged={this.formChanged}
                submitted={this.state.submitted}
                setInputPosition={this.setInputPosition}
              />
              <View style={style.padding}>
                <Button info block onPress={this.next}>
                  <Text uppercase={false}>{I18n.t("groups.profile.save")}</Text>
                </Button>
              </View>
            </View>
          </Content>
        );
      }
    }
  ),
  props => (
    <Header hasTabs={false} title={I18n.t("groups.profile.edit")} {...props} />
  )
);

const style = StyleSheet.create({
  padding: {
    padding: 15
  }
});
