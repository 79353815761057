import React, { Component } from "react";
import {
  Content,
  H3,
  Text,
  Header,
  Left,
  Body,
  Title,
  Right,
  Button
} from "native-base";
import { View } from "react-native";
import { NavigationActions, StackActions } from "react-navigation";
import { connect } from "react-redux";
import { Screen } from "../utils";
import { isOffline } from "../offline";
import { getUser, updateNotification, getLanguages } from "../actions/auth";
import styles from "../styles";
import store from "../store";
import I18n from "../i18n";

export default Screen(
  connect(state => ({
    auth: state.auth,
    offline: state.offline
  }))(
    class Offline extends Component {
      async componentDidUpdate() {
        if (!this.props.offline) {
          const { auth, navigation } = this.props;
          const { languages } = store.getState().misc;

          let routeName = "Welcome";
          if (auth.token) {
            const offline = await isOffline();

            if (!offline) await getUser(auth.token);
            if (store.getState().auth.currentUser) {
              routeName = "App";
              if (!offline) await updateNotification();
            }
          } else if (!languages || (languages && languages.length == 0)) {
            await getLanguages();
          }

          navigation.dispatch(
            NavigationActions.navigate({
              routeName
            })
          );
        }
      }

      render() {
        return (
          <Content>
            <View style={styles.body}>
              <H3>{I18n.t("offline.descriptionTitle")}</H3>
              <Text>{I18n.t("offline.description")}</Text>
            </View>
          </Content>
        );
      }
    }
  ),
  props => (
    <Header>
      <Left />
      <Body>
        <Title>{I18n.t("offline.title")}</Title>
      </Body>
      <Right />
    </Header>
  )
);
