import React, { Component } from "react";
import {
  Content,
  Text,
  Icon,
  Button,
  Card,
  CardItem,
  Left,
  Body,
  H3
} from "native-base";
import { View, StyleSheet } from "react-native";
import I18n from "../../i18n";
import styles from "../../styles";
import CommunicationActions from "../groups/components/CommunicationActions";
import moment from "moment";
import { connect } from "react-redux";
import variables from "../../../native-base-theme/variables/platform";

export default connect(state => ({
  task: state.taskDetails.task
}))(
  class Contacts extends Component {
    removeChars = array => {
      return array.reduce((prev, next) => {
        return [...prev, next.replace(/[^a-zA-Z ]/g, "")];
      }, []);
    };

    renderCards = () => {
      const orderedHistory =
        this.props.task.history && this.props.task.history.length
          ? this.props.task.history.sort((a, b) => {
              return new Date(b.logdate) - new Date(a.logdate);
            })
          : [];

      return orderedHistory.map((history, key) => {
        const historyArray = this.removeChars(
          history.comment.toLowerCase().split(" ")
        );

        return (
          <Card style={style.cardStyle} key={key}>
            <CardItem bordered>
              <Left>
                <Icon
                  name="md-time"
                  style={{ color: variables.brandInfo, fontSize: 20 }}
                />
                <Body>
                  <Text style={{ color: variables.brandInfo, fontSize: 12 }}>
                    {moment(history.logdate).format("LLLL")}
                  </Text>
                </Body>
              </Left>
            </CardItem>

            <CardItem>
              <Text style={{ fontWeight: "bold" }}>
                {historyArray.map(
                  text => I18n.t("taskDetails.history." + text) + " "
                )}
              </Text>
            </CardItem>

            <CardItem>
              <Icon name="ios-contact" style={style.textStyle} />
              <Text style={style.textStyle}>
                {" " + history.user.firstname + " " + history.user.lastname}
              </Text>
            </CardItem>
            <CardItem footer>
              <CommunicationActions
                user={history.user}
                i18n="groups.contacts"
              />
            </CardItem>
          </Card>
        );
      });
    };

    render() {
      return (
        <Content container>
          <View style={style.container}>
            {this.props.task.history.length > 0 ? (
              <View>{this.renderCards()}</View>
            ) : (
              <View>
                <Text>{I18n.t("global.noItemFoundForList")}</Text>
              </View>
            )}
          </View>
        </Content>
      );
    }
  }
);

const style = StyleSheet.create({
  container: {
    padding: 5
  },
  textStyle: {
    fontWeight: "200"
  }
});
