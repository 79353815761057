import React, { Component } from "react";
import {
  Content,
  Text,
  Icon,
  Button,
  Card,
  CardItem,
  Body,
  H3,
  Picker,
  Item,
  Header,
  Left,
  Title,
  Right,
  ActionSheet,
  ListItem,
  View
} from "native-base";
import { StyleSheet, TouchableOpacity, Alert } from "react-native";
import I18n from "../../i18n";
import styles from "../../styles";
import { text, email, phonecall } from "react-native-communications";
import moment from "moment";
import { connect } from "react-redux";
import colors from "../../../native-base-theme/variables/commonColor";
import variables from "../../../native-base-theme/variables/platform";

export default connect(state => ({
  task: state.taskDetails.task,
  claims: state.taskDetails.task.claims
}))(
  class Volunteers extends Component {
    constructor(props) {
      super(props);
      this.state = {
        selected: "all",
        selectedLabel: I18n.t("taskDetails.volunteers.all"),
        values: [
          {
            name: I18n.t("taskDetails.volunteers.all"),
            value: "all"
          },
          {
            name: I18n.t("taskDetails.volunteers.claimed"),
            value: "claimed"
          },
          {
            name: I18n.t("taskDetails.volunteers.declined"),
            value: "decline"
          },
          {
            name: I18n.t("taskDetails.volunteers.closed"),
            value: "closed"
          }
        ]
      };
    }

    selectItem = value => {
      if (value) {
        const element = this.state.values.find(
          element => element.name === value
        );
        this.setState({
          selected: element.value,
          selectedLabel: element.name
        });
      }
    };

    volunteerDetails = claim => () => {
      volunteer = {
        ...claim.user,
        name: claim.user.firstname + " " + claim.user.lastname,
        role: "volunteer",
        taskStatus: claim.status
      };
      this.props.navigation.navigate("VolunteerDetails", { volunteer });
    };

    renderCards = () => {
      const filteredClaims = this.props.claims.filter(
        it => this.state.selected == "all" || this.state.selected == it.status
      );
      if (filteredClaims.length > 0) {
        return filteredClaims.map((claim, key) => (
          <TouchableOpacity key={key} onPress={this.volunteerDetails(claim)}>
            {(() => {
              switch (claim.status) {
                case "claimed":
                  return (
                    <Card>
                      <CardItem>
                        <Left>
                          <Icon
                            name="md-star-outline"
                            style={{ color: variables.brandInfo }}
                          />
                          <Body>
                            <Text
                              style={{
                                fontWeight: "bold",
                                color: variables.brandInfo
                              }}>
                              {claim.user.firstname + " " + claim.user.lastname}
                            </Text>
                            <Text
                              note
                              style={{ color: variables.brandPrimary }}>
                              {I18n.t("global.taskStatus." + claim.status)}{" "}
                              {moment(claim.createdAt).format("L LT")}
                            </Text>
                          </Body>
                        </Left>
                      </CardItem>
                    </Card>
                  );
                case "decline":
                  return (
                    <Card>
                      <CardItem>
                        <Left>
                          <Icon
                            name="md-close"
                            style={{ color: variables.brandDanger }}
                          />
                          <Body>
                            <Text style={{ fontWeight: "200" }}>
                              {claim.user.firstname + " " + claim.user.lastname}
                            </Text>
                            <Text note>
                              {I18n.t("global.taskStatus." + claim.status)}{" "}
                              {moment(claim.createdAt).format("L LT")}
                            </Text>
                          </Body>
                        </Left>
                      </CardItem>
                    </Card>
                  );
                case "completed":
                  return (
                    <Card>
                      <CardItem>
                        <Left>
                          <Icon name="md-checkbox" style={{ color: "green" }} />
                          <Body>
                            <Text
                              style={{
                                fontWeight: "bold",
                                color: variables.brandInfo
                              }}>
                              {claim.user.firstname + " " + claim.user.lastname}
                            </Text>
                            <Text
                              note
                              style={{ color: variables.brandPrimary }}>
                              {I18n.t("global.taskStatus." + claim.status)}{" "}
                              {moment(claim.createdAt).format("L LT")}
                            </Text>
                          </Body>
                        </Left>
                      </CardItem>
                    </Card>
                  );
                case "closed":
                  return (
                    <Card>
                      <CardItem>
                        <Left>
                          <Icon name="md-checkbox" style={{ color: "green" }} />
                          <Body>
                            <Text
                              style={{
                                fontWeight: "bold",
                                color: variables.brandInfo
                              }}>
                              {claim.user.firstname + " " + claim.user.lastname}
                            </Text>
                            <Text
                              note
                              style={{ color: variables.brandPrimary }}>
                              {I18n.t("global.taskStatus." + claim.status)}{" "}
                              {moment(claim.createdAt).format("L LT")}
                            </Text>
                          </Body>
                        </Left>
                      </CardItem>
                    </Card>
                  );
                default:
                  return (
                    <Card>
                      <CardItem>
                        <Left>
                          <Body>
                            <Text style={{ fontWeight: "200" }}>
                              {claim.user.firstname + " " + claim.user.lastname}
                            </Text>
                            <Text note>
                              {I18n.t("global.taskStatus." + claim.status)}{" "}
                              {moment(claim.createdAt).format("L LT")}
                            </Text>
                          </Body>
                        </Left>
                      </CardItem>
                    </Card>
                  );
              }
            })()}
          </TouchableOpacity>
        ));
      } else {
        return (
          <View style={{ margin: 15 }}>
            <Text style={{ fontSize: 14, fontWeight: "200" }}>
              {I18n.t("global.noItemFoundForList")}
            </Text>
          </View>
        );
      }
    };

    render() {
      const actionSheetLabels = this.state.values.reduce(
        (prev, next) => [...prev, next.name],
        []
      );

      return (
        <Content style={style.container}>
          {this.props.claims.length > 0 ? (
            <View container style={{ margin: 5 }}>
              <View style={{ padding: 10 }}>
                <Button
                  iconRight
                  block
                  transparent
                  onPress={() =>
                    ActionSheet.show(
                      {
                        options: actionSheetLabels,
                        title: I18n.t("global.selecetOne"),
                        tintColor: "#4b2f18"
                      },
                      buttonIndex => {
                        this.selectItem(actionSheetLabels[buttonIndex]);
                      }
                    )
                  }>
                  <Text uppercase={false}>
                    <Text uppercase={false}>
                      {I18n.t("taskDetails.volunteers.show")}:{" "}
                    </Text>
                    <Text uppercase={false} style={{ fontWeight: "bold" }}>
                      {this.state.selectedLabel}
                    </Text>
                  </Text>
                  <Icon name="ios-arrow-dropdown" />
                </Button>
              </View>

              {this.renderCards()}
            </View>
          ) : (
            <View style={{ margin: 15 }}>
              <Text style={{ fontSize: 14, fontWeight: "200" }}>
                {I18n.t("global.noItemFoundForList")}
              </Text>
            </View>
          )}
        </Content>
      );
    }
  }
);

const style = StyleSheet.create({
  container: {
    marginTop: 20
  },
  pickerStyle: {
    width: "100%",
    backgroundColor: "white",
    padding: 10
  },
  h3Text: {
    fontWeight: "bold"
  },
  statusText: {
    color: variables.brandInfo,
    marginLeft: 10
  },
  nameContainer: {
    width: "85%"
  },
  statusContainer: {
    width: "15%"
  }
});
