import types from "../actions/types";
import store from "../../../store";
import I18n from "../../../i18n";

const defaultState = {
  name: "",
  startdate: null,
  group: {
    name: I18n.t("global.selecetOne"),
    id: null
  },
  status: "open",
  volunteerneeded: null,
  description: "",
  owner: {
    name: "",
    phone: ""
  },
  contacts: [],
  categories: [],
  locationResults: [],
  groups: []
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.START_NEW_TASK:
      return {
        ...state,
        task: defaultState,
        mode: "add",
        contacts: [],
        locationResults: []
      };
    case types.SET_TASK:
      return {
        ...state,
        task: action.task
      };
    case types.EDIT_TASK:
      return {
        ...state,
        task: action.task,
        mode: "edit",
        locationResults: []
      };
    case types.GET_CONTACTS:
      return {
        ...state,
        contacts: action.contacts
      };
    case types.SET_TASK:
      return {
        ...state,
        task: action.task,
        locationResults: []
      };

    case types.UPDATE_CATEGORIES:
      return {
        ...state,
        task: {
          ...state.task,
          categories: action.categories
        }
      };
    case types.GET_LOCATION_RESULTS:
      return {
        ...state,
        locationResults: action.locationResults
      };
    case types.REMOVE_SUGGESTIONS:
      return {
        ...state,
        locationResults: []
      };
    case types.LOGOUT:
      return {
        ...defaultState
      };
    default:
      return state;
  }
};
