import { BackHandler, Platform, Linking, Alert } from "react-native";
import { NavigationActions, SwitchActions } from "react-navigation";
import PushNotification from "react-native-push-notification";
import { encode as btoa } from "base-64";
import {
  GET,
  POST,
  PUT,
  noRetryGET,
  noRetryPOST,
  noRetryPUT,
  setToken,
  getToken,
  getAxios
} from "../axios";
import { getGroups } from "../screens/groups/actions";
import { showToast } from "../utils";
import { isOffline } from "../offline";
import { sync } from "../sync";
import I18n, { getDeviceLang } from "../i18n";
import store from "../store";
import { FB_PAGE_ID } from "../const";
import types from "./types";
import { setLocale } from "../i18n";
import moment from "moment-timezone";

export async function logout(manual) {
  store.dispatch({
    type: types.CURRENT_USER,
    currentUser: null
  });
  if (!(await isOffline())) {
    await noRetryPOST("/logout");
  }
  store.dispatch({ type: types.LOGOUT });
  PushNotification.setApplicationIconBadgeNumber(0);
  setOpenTasksBadge(0);
  store.dispatch({ type: types.JWT_TOKEN, token: null });
  if (manual) {
    store.dispatch({
      type: types.REMEMBER_ME,
      rememberme: false,
      username: "",
      authorization: null
    });
  }
  setToken();

  const deviceLang = await getDeviceLang();
  setLocale(deviceLang[0]);

  const { rootNavigation } = store.getState().auth;
  if (rootNavigation) {
    rootNavigation.dispatch(SwitchActions.jumpTo({ routeName: "Welcome" }));
  }
}

async function basicLogin() {
  const login = await POST("/login");
  if (!login) return false;
  const { success, token } = login;
  if (success && (await getUser(`Bearer ${token}`))) {
    const { currentUser, notificationToken } = store.getState().auth;
    await PUT("/user", {
      id: currentUser.id,
      device: Platform.OS,
      token: notificationToken ? notificationToken : currentUser.token,
      timezone: generateTimeZone()
    });
  }
  return success;
}

export async function login(username, password, rememberme) {
  try {
    const basic = `Basic ${btoa(`${username}:${password}`)}`;
    setToken(basic);
    if (!rememberme) {
      store.dispatch({
        type: types.REMEMBER_ME,
        rememberme
      });
    }
    const success = await basicLogin();
    if (success) {
      if (rememberme) {
        store.dispatch({
          type: types.REMEMBER_ME,
          rememberme,
          username,
          authorization: basic
        });
      }
      await updateNotification();
    }
    return success;
  } catch (err) {
    return false;
  }
}

export async function refreshLogin() {
  const { currentUser, authorization } = store.getState().auth;
  if (authorization) {
    setToken(authorization);
    if (!(await basicLogin())) {
      const err = store.getState().error;
      if (err.response) {
        await logout();
        return false;
      }
      return true;
    }
    return true;
  } else if (currentUser) {
    await logout();
  }
  return false;
}

export async function getLanguages() {
  const result = await noRetryGET("/languages");
  store.dispatch({
    type: types.GET_LANGUAGES,
    languages: result || [
      { code: "en-US", label: "English" },
      { code: "es-ES", label: "Español" },
      { code: "pt-BR", label: "Português" },
      { code: "pl-PL", lable: "Polski" }
    ]
  });
}

export async function getUser(token) {
  setToken(token);
  store.dispatch({ type: types.JWT_TOKEN, token });
  const user = await noRetryGET("/user");
  if (user) {
    store.dispatch({
      type: types.CURRENT_USER,
      currentUser: user
    });

    setLocale(user.language);
    await Promise.all([getLanguages(), getGroups(), getOpenTasksCount()]);
  } else await refreshLogin();
}

export async function getOpenTasksCount() {
  try {
    store.dispatch({ type: types.PROGRESS_START });
    const res = await getAxios().head(`/tasks?type=Open`);
    if (
      res &&
      res.status == 200 &&
      res.headers &&
      res.headers["accept-ranges"]
    ) {
      const acceptRanges = res.headers["accept-ranges"];
      const badge = +acceptRanges.split("-")[1];
      PushNotification.setApplicationIconBadgeNumber(badge);
      setOpenTasksBadge(badge);
    }
    store.dispatch({ type: types.PROGRESS_END });
  } catch (err) {
    store.dispatch({ type: types.PROGRESS_END });
  }
}

export function setOpenTasksBadge(badge) {
  store.dispatch({ type: types.OPEN_TASKS_BADGE, badge });
}

function mapUser(currentUser) {
  // TODO: twilio lookup
  return {
    id: currentUser.id,
    username: currentUser.email,
    firstname: currentUser.firstname,
    lastname: currentUser.lastname,
    email: currentUser.email,
    phone: currentUser.phone,
    phoneformatted: currentUser.phoneformatted || currentUser.phone,
    phoneraw: currentUser.phoneraw || currentUser.phone,
    password: currentUser.id ? undefined : currentUser.password,
    sms: currentUser.sms,
    push: currentUser.push,
    messenger: currentUser.messenger,
    keeplogin: currentUser.keeplogin,
    device: Platform.OS,
    token: currentUser.token,
    language: currentUser.language ? currentUser.language : "en-US"
  };
}

export async function updateUser(currentUser) {
  const oldFB = store.getState().auth.currentUser.messenger;
  currentUser = mapUser(currentUser);
  if (await PUT("/user", currentUser)) {
    //setLocale(currentUser.language);
    if (!oldFB && currentUser.messenger) {
      const url = `https://m.me/${FB_PAGE_ID}?ref=${encodeURIComponent(
        JSON.stringify({
          token: getToken().replace("Bearer ", "")
        })
      )}`;
      Linking.openURL(url);
    }
    store.dispatch({ type: types.CURRENT_USER, currentUser });
    return true;
  }
}

export async function register(currentUser) {
  currentUser = mapUser(currentUser);
  if (await POST("/users", currentUser)) {
    if (
      await login(
        currentUser.email,
        currentUser.password,
        currentUser.keeplogin
      )
    ) {
      if (currentUser.messenger) {
        const url = `https://m.me/${FB_PAGE_ID}?ref=${encodeURIComponent(
          JSON.stringify({
            token: getToken().replace("Bearer ", "")
          })
        )}`;
        Linking.openURL(url);
      }
      return true;
    }
  }
}

export async function sendContactUs(form) {
  const { type, firstname, lastname, email, message } = form;
  return await POST("/contactus", {
    isFeedback: type == "feedback",
    fullname: `${firstname} ${lastname}`,
    email,
    message
  });
}

export async function updateNotification() {
  let { currentUser, notificationToken } = store.getState().auth;
  if (currentUser && currentUser.id && currentUser.token != notificationToken) {
    if (notificationToken) {
      currentUser = {
        ...currentUser,
        device: Platform.OS,
        token: notificationToken ? notificationToken : currentUser.token
      };

      const result = await PUT("/user", {
        id: currentUser.id,
        device: Platform.OS,
        token: notificationToken ? notificationToken : currentUser.token
      });

      if (result) {
        store.dispatch({ type: types.CURRENT_USER, currentUser });
      }
    }
  }
}

function generateTimeZone() {
  return {
    offset: moment.tz.zone(moment.tz.guess()).utcOffset(moment()),
    displayName: moment.tz.zone(moment.tz.guess()).abbr(moment()),
    standardDisplayName: moment.tz.zone(moment.tz.guess()).name
  };
}

export function setNotificationToken(token) {
  store.dispatch({ type: types.NOTIFICATION_TOKEN, token });
}

export function setSyncMode(mode) {
  store.dispatch({ type: types.SYNC_MODE, mode });
}

export async function forgotPassword(email) {
  await POST("/forgotpassword", { email });
  showToast(I18n.t("forgotPass.emailSucces"), "success");
}

export async function changePass(password) {
  await POST("/changepassword", {
    password
  });

  showToast(I18n.t("changePass.succesfullyChange"), "success");
}
