import types from "../actions/types";
import { isOffline } from "../../../connectiontype";
import { ifModifiedSince } from "../../../sync";

export default (state = {}, action) => {
  if (!isOffline()) {
    switch (action.type) {
      case types.UPDATE_GROUP:
      case types.SYNC_UPDATE_GROUP:
      case types.GROUP_DETAILS:
      case types.SYNC_GROUP_DETAILS:
        if (action.group && action.group.id) {
          return {
            ...state,
            [action.group.id]: {
              ...state[action.group.id],
              ...action.group,
              [ifModifiedSince]: new Date()
            }
          };
        }
        return state;
      case types.SYNC_GROUP_DELETE:
        if (action.group && action.group.id) {
          return {
            ...state,
            [action.group.id]: null
          };
        }
      case types.LOGOUT:
        return {};
      default:
        return state;
    }
  }
  return state;
};
