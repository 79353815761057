import types from "../../../actions/types";

export default Object.freeze({
  ...types,
  GET_GROUPS: "GET_GROUPS",
  CREATE_GROUP: "CREATE_GROUP",
  UPDATE_GROUP: "UPDATE_GROUP",
  SYNC_UPDATE_GROUP: "SYNC_UPDATE_GROUP",
  ADD_GROUP: "ADD_GROUP",
  DELETE_GROUP: "DELETE_GROUP",
  GROUP_DETAILS: "GROUP_DETAILS",
  SYNC_GROUP_DETAILS: "SYNC_GROUP_DETAILS",
  SYNC_GROUP_DELETE: "SYNC_GROUP_DELETE",
  CATEGORY_VOLUNTEERS: "CATEGORY_VOLUNTEERS",
  VOLUNTEER_DETAILS: "VOLUNTEER_DETAILS",
  LEAD_DETAILS: "LEAD_DETAILS",
  GLOBAL_CATEGORIES: "GLOBAL_CATEGORIES"
});
