import React, { Component } from "react";
import { Content, Text, Icon, Button, Card, CardItem, Left, Body } from "native-base";
import { View, StyleSheet } from "react-native";
import I18n from "../../i18n";
import styles from "../../styles";
import variables from "../../../native-base-theme/variables/platform";
import { text, email, phonecall } from "react-native-communications";
import { connect } from "react-redux";
import CommunicationActions from "../groups/components/CommunicationActions";

export default connect(state => ({
  task: state.taskDetails.task
}))(
  class Contacts extends Component {
    renderCards = () => {
      const { contacts } = this.props.task;

      return contacts.map((contact, key) => (
        <Card key={key}>
          <CardItem bordered>
            <Left>
              <Icon name="contact" style={{ color: variables.brandInfo }} />
              <Body>
                <Text style={{ fontWeight: "bold", color: variables.brandInfo }}>{contact.name}</Text>
              </Body>
            </Left>

          </CardItem>
          <CardItem>
            <Left>
              <Icon name="call" style={{ fontSize: 14 }} />
              <Body>
                <Text style={{ fontSize: 14 }}>
                  {contact.phoneformatted || contact.phoneraw || contact.phone}
                </Text>
              </Body>
            </Left>
          </CardItem>
          <CardItem>
            <Left>
              <Icon name="mail" style={{ fontSize: 14 }} />
              <Body>
                <Text style={{ fontSize: 14 }}>
                  {contact.email}
                </Text>
              </Body>
            </Left>
          </CardItem>

          <CardItem footer bordered>
            <CommunicationActions user={contact} i18n="groups.contacts" />
          </CardItem>
        </Card>
      ));
    };

    render() {
      return (
        <Content>
          <View style={style.container}>
            {this.props.task.contacts.length > 0 ? (
              <View >
                <Text style={{ fontSize: 14, fontWeight: "200", marginVertical: 15 }}> {I18n.t("taskDetails.contacts.ratesApply")}</Text>
                {this.renderCards()}
              </View>
            ) : (
                <View style={{ margin: 15 }}>
                  <Text style={{ fontSize: 14, fontWeight: "200" }}>{I18n.t("taskDetails.contacts.noContactsForTask")}</Text>
                </View>
              )}
          </View>
        </Content>
      );
    }
  }
);

const style = {
  container: {
    paddingHorizontal: 10,
    marginTop: 20
  },
  button: {
    marginLeft: 5
  }
};
