import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
export { default as Marker } from "./Marker";
export { default as Polyline } from "./Polyline";

class MapView extends Component {
  state = {
    center: null
  };

  handleMapMounted = map => {
    this.map = map;
    this.props.onMapReady && this.props.onMapReady();
  };

  animateToRegion(coordinate) {
    this.map.panTo({ lat: coordinate.latitude, lng: coordinate.longitude });
  }

  fitToCoordinates(coordinates, options = {}) {
    const bounds = new google.maps.LatLngBounds();

    coordinates.forEach(coord => {
      bounds.extend({ lat: coord.latitude, lng: coord.longitude });
    });

    (options.animated ? this.map.panToBounds : this.map.fitBounds).call(
      this.map,
      bounds,
      options.edgePadding
    );
  }

  fitToSuppliedMarkers(markers, options = {}) {
    const bounds = new google.maps.LatLngBounds();

    const coordinates = [];
    markers.forEach(id => {
      const marker = this.props.children.find(
        child => child.props && child.props.identifier === id
      );
      if (marker) {
        const coord = marker.props.coordinate;
        coordinates.push({ lat: coord.latitude, lng: coord.longitude });
      }
    });

    if (coordinates.length > 1) {
      coordinates.forEach(coord => bounds.extend(coord));
      (options.animated ? this.map.panToBounds : this.map.fitBounds).call(
        this.map,
        bounds,
        options.edgePadding
      );
    } else if (coordinates.length == 1) {
      this.map.panTo(coordinates[0]);
    }
  }

  onDragEnd = () => {
    const { onRegionChangeComplete } = this.props;
    if (this.map && onRegionChangeComplete) {
      const center = this.map.getCenter();
      onRegionChangeComplete({
        latitude: center.lat(),
        longitude: center.lng()
      });
    }
  };

  render() {
    const {
      region,
      initialRegion,
      onRegionChange,
      onPress,
      options
    } = this.props;
    const { center } = this.state;
    const style = this.props.style || styles.container;

    const centerProps = region
      ? {
          center: {
            lat: region.latitude,
            lng: region.longitude
          }
        }
      : center
      ? { center }
      : initialRegion
      ? {
          defaultCenter: {
            lat: initialRegion.latitude,
            lng: initialRegion.longitude
          }
        }
      : {
          defaultCenter: {
            lat: 42,
            lng: 27
          }
        };

    return (
      <View style={style}>
        <div style={{ width: "100%", height: "100%" }}>
          <LoadScript
            id="script-loader"
            googleMapsApiKey="AIzaSyCBLFz45TGB2A5U69gtsTzN3viq5O15k8M">
            <GoogleMap
              id="map"
              mapContainerStyle={{
                width: "100%",
                height: "100%"
              }}
              zoom={this.props.zoom || 15}
              {...centerProps}
              onLoad={map => this.handleMapMounted(map)}
              onClick={e =>
                typeof onPress === "function" &&
                onPress({
                  nativeEvent: {
                    coordinate: {
                      latitude: e.latLng.lat(),
                      longitude: e.latLng.lng()
                    }
                  }
                })
              }>
              {this.props.children}
            </GoogleMap>
          </LoadScript>
        </div>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    height: "100%"
  }
});

export default MapView;
