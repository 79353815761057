import types from "../actions/types";

const initalState = {
  alert: {
    loadMore: true,
    page: 0,
    list: null
  },
  "warning,info": {
    loadMore: true,
    page: 0,
    list: null
  }
};

export default (state = initalState, action) => {
  switch (action.type) {
    case types.ACTIVITY_GET_LIST:
      return {
        ...state,
        [action.list]: {
          list:
            action.page == 0
              ? [...action.result]
              : [...(state[action.list].list || []), ...action.result],
          page: action.page,
          loadMore: action.loadMore
        }
      };
    case types.ACTIVITY_RESET_LIST:
      return {
        ...state,
        [action.list]: {
          list: [],
          page: 0,
          loadMore: true
        }
      };
    case types.ACTIVITY_REMOVE:
      return {
        ...state,
        alert: {
          ...state.alert,
          list: state.alert.list.filter(it => it.id != action.item.id)
        },
        "warning,info": {
          ...state["warning,info"],
          list: state["warning,info"].list.filter(it => it.id != action.item.id)
        }
      };
    case types.LOGOUT:
      return {
        ...initalState
      };
    default:
      return state;
  }
};
