import types from "../actions/types";

export default (state = [], action) => {
  switch (action.type) {
    case types.GLOBAL_CATEGORIES:
      return action.categories || [];
    case types.LOGOUT:
      return [];
    default:
      return state;
  }
};
