import React, { Component } from "react";
import Geolocation from "react-native-geolocation-service";
import {
  View,
  StyleSheet,
  PermissionsAndroid,
  Platform,
  TouchableOpacity
} from "react-native";
import Modal from "react-native-modal";
import MapView, { Marker } from "react-native-maps";
import FusedLocation from "react-native-fused-location";
import { Button, Icon, Text, Grid, Row } from "native-base";
import axios from "axios";
import jsonp from "jsonp";
import { BING_LOCATION_ADDRESS, BING_KEY } from "../const";
import I18n from "../i18n";
import variables from "../../native-base-theme/variables/platform";

export default class MapPicker extends Component {
  // static propTypes = {
  //   submitRegionChange: PropTypes.func.isRequired,
  //   modalVisible: PropTypes.bool.isRequired,
  //   region: PropTypes.object,
  //   closeModal: PropTypes.func.isRequired
  // };

  state = {
    tempRegion: this.props.region,
    mapLoading: false,
    permission: false,
    coordinate: this.props.location
  };

  async getLocation(options) {
    return new Promise((resolve, reject) => {
      let highAccuracySuccess = false;
      let highAccuracyError = false;
      let highAccuracy =
        !options || options.highAccuracy === undefined
          ? true
          : options.highAccuracy;
      let timeout =
        !options || options.timeout === undefined ? 10000 : options.timeout;

      let getLowAccuracyPosition = () => {
        Geolocation.getCurrentPosition(
          position => {
            resolve(position.coords);
          },
          error => {
            // inform  the user
            reject(error);
          },
          {
            enableHighAccuracy: false,
            timeout: 10000,
            maxAge: 0
          }
        );
      };

      if (highAccuracy) {
        const watchId = Geolocation.watchPosition(
          position => {
            // location retrieved
            highAccuracySuccess = true;

            Geolocation.clearWatch(watchId);
            resolve(position.coords);
          },
          error => {
            highAccuracyError = true;
            Geolocation.clearWatch(watchId);
            getLowAccuracyPosition();
          },
          {
            enableHighAccuracy: true,
            timeout: 20000,
            maxAge: 0,
            distanceFilter: 1
          }
        );

        setTimeout(() => {
          if (!highAccuracySuccess && !highAccuracyError) {
            getLowAccuracyPosition();
          }
        }, timeout);
      } else getLowAccuracyPosition();
    });
  }

  // async componentDidMount() {
  //   try {
  //     const coords = await this.getLocation({ timeout: 3000 });

  //     const { width, height } = Dimensions.get("window");
  //     const ASPECT_RATIO = width / height;
  //     const LATITUDE_DELTA = 0.0922;
  //     const LONGITUDE_DELTA = LATITUDE_DELTA * ASPECT_RATIO;
  //     const SPACE = 0.01;

  //     this.setState({
  //       tempRegion: {
  //         latitude: coords.latitude,
  //         longitude: coords.longitude,
  //         latitudeDelta: 0.00922 * 1.5,
  //         longitudeDelta: 0.00421 * 1.5
  //       }
  //     });
  //     this.map.fitToCoordinates([coords], {
  //       edgePadding: { top: 500, right: 500, bottom: 500, left: 500 },
  //       animated: true
  //     });
  //   } catch (err) {
  //     this.setState({ tempRegion: this.props.region });
  //     if (
  //       this.state.tempRegion &&
  //       this.state.tempRegion.latitude &&
  //       this.state.tempRegion.longitude
  //     ) {
  //       this.map.animateToRegion(this.state.tempRegion);
  //     }
  //   }
  // }

  onRegionChange = region => {
    this.setState({ tempRegion: region });
  };

  mapLoadingFinished = async () => {
    this.setState({ mapLoading: false });
    if (Platform.OS == "ios" || Platform.OS == "web") {
      const location = this.state.myCoordinate || (await this.getLocation());
      const result = await this.getAddressByCoordinates(location);
      this.setState({
        myCoordinate: location,
        coordinate: this.state.coordinate, // || location,
        address: result && result.address
      });
      if (this.map) {
        this.animateToLocation(this.state.coordinate || location);
      }
    }
  };

  requestLocationPermission() {
    return new Promise(async (resolve, reject) => {
      const chckLocationPermission = PermissionsAndroid.check(
        PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION
      );
      if (chckLocationPermission === PermissionsAndroid.RESULTS.GRANTED) {
        this.setState({ permission: true });
        resolve(true);
      } else {
        try {
          const granted = await PermissionsAndroid.request(
            PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
            {
              title: I18n.t("location.title"),
              message: I18n.t("location.message")
            }
          );
          if (
            granted === PermissionsAndroid.RESULTS.GRANTED ||
            granted === true
          ) {
            this.setState({ permission: true });
            resolve(true);
          } else {
            this.props.closeModal();
            resolve(false);
          }
        } catch (err) {
          this.props.closeModal();
          reject(err);
        }
      }
    });
  }

  async getAddressByCoordinates(coordinate) {
    try {
      let result = null;
      if (Platform.OS == "web") {
        result = await new Promise((resolve, reject) => {
          jsonp(
            `${BING_LOCATION_ADDRESS}${coordinate.latitude},${
              coordinate.longitude
            }?&output=json&jsonp=BING_CALLBACK&key=${BING_KEY}`,
            {
              name: "BING_CALLBACK"
            },
            (err, data) => {
              if (err) return reject(err);
              resolve({ data });
            }
          );
        });
      } else {
        result = await axios.get(
          `${BING_LOCATION_ADDRESS}${coordinate.latitude},${
            coordinate.longitude
          }?&output=json&key=${BING_KEY}`
        );
      }

      return {
        ...coordinate,
        address:
          result.data.resourceSets[0].resources[0].address.formattedAddress
      };
    } catch (err) {
      return null;
    }
  }

  async componentDidMount() {
    if (Platform.OS === "android") {
      const granted = await this.requestLocationPermission();
      if (granted) {
        FusedLocation.setLocationPriority(
          FusedLocation.Constants.HIGH_ACCURACY
        );
        const location = await FusedLocation.getFusedLocation();

        if (!this.state.coordinate) {
          const result = await this.getAddressByCoordinates(location);
          this.setState({
            myCoordinate: location,
            coordinate: location,
            address: result && result.address
          });
          if (this.map && !this.isReady) {
            this.animateToLocation(location);
          }
          this.isReady = true;
        } else this.setState({ myCoordinate: location });

        FusedLocation.setLocationPriority(FusedLocation.Constants.BALANCED);
        FusedLocation.setLocationInterval(20000);
        FusedLocation.setFastestLocationInterval(15000);
        FusedLocation.setSmallestDisplacement(10);

        FusedLocation.startLocationUpdates();
        this.subscription = FusedLocation.on("fusedLocation", location => {
          /* location = {
            latitude: 14.2323,
            longitude: -2.2323,
            speed: 0,
            altitude: 0,
            provider: 'fused',
            accuracy: 30,
            bearing: 10,
            mocked: false,
            timestamp: '1513190221416'
          }
          */
          this.setState({ myCoordinate: location });
        });
      }
    }
  }

  animateToLocation(coordinate) {
    this.map.animateToRegion({
      latitude: coordinate.latitude,
      longitude: coordinate.longitude,
      latitudeDelta: 0.00922 * 1.5,
      longitudeDelta: 0.00421 * 1.5
    });
  }

  componentWillUnmount() {
    Geolocation.stopObserving();

    if (this.subscription) {
      FusedLocation.off(this.subscription);
      // FusedLocation.off(this.errSubscription);
      FusedLocation.stopLocationUpdates();
    }
  }

  selectMyLocation = async () => {
    const { myCoordinate: coordinate } = this.state;
    const result = await this.getAddressByCoordinates(coordinate);
    this.setState({
      coordinate,
      address: result && result.address
    });
  };

  onUserLocationChange = async ({ nativeEvent }) => {
    if (Platform.OS === "ios") {
      const { coordinate } = nativeEvent;
      if (coordinate && !this.isReady) {
        this.setState({
          coordinate: this.state.coordinate || coordinate,
          myCoordinate: coordinate,
          fromMaps: true
        });
        const result = await this.getAddressByCoordinates(coordinate);
        this.setState({
          address: result && result.address
        });
        this.map.animateToRegion({
          latitude: coordinate.latitude,
          longitude: coordinate.longitude,
          latitudeDelta: 0.00922 * 1.5,
          longitudeDelta: 0.00421 * 1.5
        });
        this.isReady = true;
      }
    }
  };

  render() {
    return (
      <Modal
        isVisible={
          this.props.modalVisible && Platform.OS === "android"
            ? this.state.permission
            : true
        }
        onBackdropPress={this.props.closeModal}
        onBackButtonPress={this.props.closeModal}>
        <View style={style.innerContainer}>
          <Grid>
            <Row size={-1}>
              <View style={style.address}>
                <View style={style.textBlock}>
                  {this.state.address ? (
                    <Text style={style.addressText}>{this.state.address}</Text>
                  ) : (
                    <Text style={style.addressText}>
                      {I18n.t("location.pin")}
                    </Text>
                  )}
                </View>
                <View>
                  <TouchableOpacity onPress={this.props.closeModal}>
                    <Icon style={style.addressText} name="close" />
                  </TouchableOpacity>
                </View>
              </View>
            </Row>
            <Row size={1}>
              <MapView
                style={style.map}
                loadingEnabled
                initialRegion={this.props.tempRegion}
                onPress={async ({ nativeEvent }) => {
                  const { coordinate } = nativeEvent;
                  const result = await this.getAddressByCoordinates(coordinate);
                  this.setState({
                    coordinate,
                    address: result && result.address
                  });
                }}
                onMapReady={this.mapLoadingFinished}
                zoomEnabled
                scrollEnabled
                pitchEnabled
                rotateEnabled
                showsUserLocation={true}
                showsMyLocationButton={false}
                onUserLocationChange={this.onUserLocationChange}
                ref={ref => {
                  this.map = ref;
                }}>
                {this.state.myCoordinate /*&& !this.state.coordinate*/ && (
                  <Marker
                    coordinate={this.state.myCoordinate}
                    title={"My Location"}
                    onPress={this.selectMyLocation}
                  />
                )}
                {this.state.coordinate &&
                  this.state.coordinate != this.state.myCoordinate && (
                    <Marker coordinate={this.state.coordinate} />
                  )}
              </MapView>
              {this.state.myCoordinate && (
                <Button
                  small
                  onPress={() => {
                    // this.selectMyLocation();
                    this.animateToLocation(this.state.myCoordinate);
                  }}
                  style={style.myLocation}>
                  <Icon
                    type={"MaterialIcons"}
                    name={"my-location"}
                    style={style.myLocationIcon}
                  />
                </Button>
              )}
            </Row>
            <Row size={-1}>
              <View style={{ flex: 1 }}>
                <Button
                  warning
                  block
                  disabled={!this.state.coordinate}
                  style={{
                    width: "100%",
                    borderRadius: 0,
                    maxWidth: Platform.OS === "web" ? "none" : null
                  }}
                  onPress={() => {
                    const { address, coordinate } = this.state;
                    const { latitude, longitude } = coordinate;
                    this.props.onSubmit({
                      address,
                      lat: latitude,
                      lng: longitude
                    });
                  }}>
                  <Text>{I18n.t("editTask.setLocaion")}</Text>
                </Button>
              </View>
            </Row>
          </Grid>
        </View>
      </Modal>
    );
  }
}

const style = StyleSheet.create({
  innerContainer: {
    width: "100%",
    height: "90%"
  },
  address: {
    flex: 1,
    backgroundColor: variables.brandInfo,
    paddingVertical: 5,
    paddingHorizontal: 10,
    flexDirection: "row",
    alignItems: "center"
  },
  addressText: {
    color: "#fff"
  },
  map: {
    width: "100%",
    height: "100%"
  },
  mapPointerStyle: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent"
  },
  textBlock: {
    flex: 1
  },
  myLocation:
    Platform.OS == "web"
      ? {
          backgroundColor: "#fff",
          border: 0,
          margin: 10,
          padding: 0,
          position: "absolute",
          // cursor: "pointer",
          // user-select: none;
          borderRadius: 2,
          height: 40,
          width: 40,
          shadowColor: "#000",
          shadowOpacity: 0.3,
          shadowRadius: 4,
          shadowOffset: { width: 0, height: 1 },
          // boxShadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
          // overflow: hidden;
          top: 50,
          right: 0,
          justifyContent: "center"
        }
      : {
          backgroundColor: "#fff",
          position: "absolute",
          top: 5,
          right: 5,
          padding: 0,
          margin: 0,
          opacity: 0.9
        },
  myLocationIcon: {
    color: "#666",
    fontSize: Platform.OS == "web" ? 24 : 16
  }
});
