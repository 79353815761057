import React, { Component } from "react";
import { Content, View, Card, CardItem, Button, H3, Text } from "native-base";
import { connect } from "react-redux";
import { markCompleteTask, markIncompleteTask, unclaimTask } from "./actions";
import VolunteerLeadDetails from "../../components/VolunteerLeadDetails";
import style from "../groups/details/style";
import styles from "../../styles";
import { Screen, showToast } from "../../utils";
import I18n from "../../i18n";

export default Screen(
  connect(state => ({
    currentUser: state.auth.currentUser,
    task: state.taskDetails.task,
    role: "volunteer",
    isAdmin: true
  }))(
    class extends Component {
      state = {
        user: this.props.navigation.state.params.volunteer
      };
      _working = false;

      markComplete = async () => {
        if (this._working) return;
        this._working = true;
        if (!(await markCompleteTask(this.props.task.id, this.state.user))) {
          this._working = false;
          return showToast(
            I18n.t("volunteer.errorMessages.markCompleteFailed"),
            "danger",
            3000
          );
        }
        this.props.navigation.goBack();
        this._working = false;
      };

      markIncomplete = async () => {
        if (this._working) return;
        this._working = true;
        if (!(await markIncompleteTask(this.props.task.id, this.state.user))) {
          this._working = false;
          return showToast(
            I18n.t("volunteer.errorMessages.markIncompleteFailed"),
            "danger",
            3000
          );
        }
        this.props.navigation.goBack();
        this._working = false;
      };

      unassignVolunteer = async () => {
        if (this._working) return;
        this._working = true;
        if (!(await unclaimTask(this.props.task.id, this.state.user))) {
          this._working = false;
          return showToast(
            I18n.t("volunteer.errorMessages.unassignVolunteerFailed"),
            "danger",
            3000
          );
        }
        this.props.navigation.goBack();
        this._working = false;
      };

      render() {
        return (
          <Content container>
            <View style={style.padding}>
              <VolunteerLeadDetails
                {...this.props}
                {...this.state}
                markComplete={this.markComplete}
                markIncomplete={this.markIncomplete}
                unassignVolunteer={this.unassignVolunteer}
              />
            </View>
          </Content>
        );
      }
    }
  ),
  _ => I18n.t("groups.volunteerdetails.title")
);
