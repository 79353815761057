import { combineReducers } from "redux";
import joined from "./joined";
import managed from "./managed";
import group from "./group";
import mode from "./mode";
import category from "./category";
import categoryvolunteers from "./categoryvolunteers";
import categories from "./categories";
import user from "./user";
import offline from "./offline";

export default combineReducers({
  joined,
  managed,
  group,
  mode,
  category,
  categoryvolunteers,
  categories,
  user,
  offline
});
