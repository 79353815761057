import React, { Component } from "react";
import { View, ScrollView, TouchableOpacity, StyleSheet } from "react-native";
import { Card, CardItem, Right, Icon, Text, Header } from "native-base";
import { connect } from "react-redux";
import I18n from "../../../i18n";

export default class AutoSuggest extends Component {
  render() {
    return (
      <View style={style.scrollCotnainer}>
        <Card>
          <ScrollView>
            {this.props.results.map((result, key) => (
              <TouchableOpacity
                key={key}
                onPress={() => this.props.handleResult(this.props.name, result)}
              >
                <View>
                  <CardItem>
                    <Text>{result.address}</Text>
                    <Right>
                      <Icon name="arrow-forward" />
                    </Right>
                  </CardItem>
                </View>
              </TouchableOpacity>
            ))}
          </ScrollView>
        </Card>
      </View>
    );
  }
}

const style = StyleSheet.create({
  scrollContainer: {
    width: 300
  }
});
