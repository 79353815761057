import React, { Component } from "react";
import { View, Image, StyleSheet, Alert } from "react-native";
import {
  Content,
  Item,
  Button,
  Text,
  Card,
  CardItem,
  CheckBox,
  Footer,
  ListItem,
  Body,
  H3,
  Icon
} from "native-base";
import { Screen, showToast } from "../../utils";
import styles from "../../styles";
import variable from "../../../native-base-theme/variables/platform";
import I18n from "../../i18n";
import MapView, { Marker } from "react-native-maps";
import Modal from "react-native-modal";
import moment from "moment";
import { connect } from "react-redux";
import { updateCategories } from "./actions";
import ConnectHeader from "../../components/ConnectHeader";
import category from "../groups/reducers/category";

const ConnectedHeader = connect(state => ({
  taskDetails: state.task.taskDetails.task,
  mode: state.task.taskDetails.mode
}))(props => {
  return <ConnectHeader title={props.taskDetails.name} {...props} />;
});

export default Screen(
  connect(state => ({
    categories: state.task.categories.categories,
    taskDetails: state.task.taskDetails.task,
    mode: state.task.taskDetails.mode
  }))(
    class Categories extends Component {
      generateCategories = (categories, taskCategories) => {
        if (this.props.mode === "edit") {
          return categories.map(cat => {
            if (taskCategories.some(cat2 => cat2.id === cat.id)) {
              return {
                ...cat,
                checked: true
              };
            } else {
              return {
                ...cat,
                checked: false
              };
            }
          });
        } else {
          return categories.reduce(
            (prev, next) => [
              ...prev,
              {
                id: next.id,
                name: next.name,
                checked: false
              }
            ],
            []
          );
        }
      };

      _working = false;
      state = {
        checkAll: false,
        categories: this.generateCategories(
          this.props.categories,
          this.props.taskDetails.categories
        ),
        mode: this.props.mode
      };

      handleChecking = key => {
        const newMockCategories = this.state.categories.map((item, index) => {
          if (index === key) {
            return {
              ...item,
              checked: !item.checked
            };
          } else {
            return item;
          }
        });

        const sameCheckboxes = newMockCategories.every(current => {
          return current.checked === true;
        });

        this.setState({
          categories: newMockCategories,
          checkAll: sameCheckboxes
        });
      };

      handleCheckAll = () => {
        this.setState({
          checkAll: !this.state.checkAll
        });

        const newMockCategories = this.state.categories.reduce(
          (prev, current) => {
            return [
              ...prev,
              {
                name: current.name,
                id: current.id,
                checked: !this.state.checkAll
              }
            ];
          },
          []
        );

        this.setState({
          categories: newMockCategories
        });
      };

      renderCategories = () => {
        return this.state.categories.map((category, key) => {
          return (
            <ListItem noBorder key={key}>
              <CheckBox
                checked={category.checked}
                onPress={() => this.handleChecking(key)}
              />
              <Body>
                <Text style={{ fontWeight: "bold" }}>{category.name}</Text>
              </Body>
            </ListItem>
          );
        });
      };

      handleSubmit = async () => {
        if (this._working) return;
        const sameCheckboxes = this.state.categories.every(current => {
          return !current.checked;
        });

        const filteredCategories = this.state.categories.filter(
          current => current.checked === true
        );

        const filtered = filteredCategories.reduce(
          (acc, next) => [
            ...acc,
            {
              name: next.name,
              id: next.id
            }
          ],
          []
        );

        if (sameCheckboxes) {
          showToast(I18n.t("global.noCategoriesSelected"), "danger", 3000);
        } else {
          this._working = true;
          await updateCategories(filtered);
          this.props.navigation.navigate("Review");
        }

        this._working = false;
      };

      render() {
        return (
          <Content container>
            <View style={style.containerStyle}>
              <View style={style.panelStyle}>
                <H3 style={{ color: variable.brandInfo }}>
                  {I18n.t("editTask.headerTitles.categories")}
                </H3>

                <ListItem noBorder>
                  <CheckBox
                    checked={this.state.checkAll}
                    onPress={this.handleCheckAll}
                  />
                  <Body>
                    <Text note>{I18n.t("editTask.checkAllFields")}</Text>
                  </Body>
                </ListItem>
                {this.renderCategories()}
              </View>

              <View style={{ padding: 15 }}>
                <Button iconRight block info onPress={this.handleSubmit}>
                  <Text uppercase={false}>
                    {I18n.t("editTask.continueToReview")}
                  </Text>
                  <Icon name="ios-arrow-forward" />
                </Button>
              </View>
            </View>
          </Content>
        );
      }
    }
  ),
  props => <ConnectedHeader {...props} />
);

const style = StyleSheet.create({
  containerStyle: {},
  panelStyle: {
    padding: 15,
    marginTop: 10,
    backgroundColor: "#FFFFFF"
  },
  panelTitle: {
    fontWeight: "bold"
  },
  panelTitleRequired: {
    color: variable.brandInfo
  }
});
