import React, { Component } from "react";
import { View } from "react-native";
import { Text, Icon } from "native-base";
import { connect } from "react-redux";
import styles from "../styles";
import I18n from "../i18n";
import variable from "../../native-base-theme/variables/platform";

export default connect(state => ({ offline: state.offline }))(
  class OfflineIndicator extends Component {
    render() {
      if (this.props.offline) {
        return (
          <View
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              alignItems: "flex-end"
            }}
          >
            <View
              style={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: 5
              }}
            >
              <Icon
                name="portable-wifi-off"
                type="MaterialIcons"
                style={{ color: "red", fontSize: 28 }}
              />
              <Text style={{ color: "red", fontSize: 14 }}>
                {I18n.t("offline.status")}
              </Text>
            </View>
          </View>
        );
      }

      return null;
    }
  }
);
