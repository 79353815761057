import { combineReducers } from "redux";
import task from "./task";
import direction from "./direction";
import offline from "./offline";

export default combineReducers({
  task,
  direction,
  offline
});
