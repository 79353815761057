import React, { Component } from "react";
import PropTypes from "prop-types";
import { View, Platform } from "react-native";
import { Item, Label, Text, Input } from "native-base";

Input.displayName = "Styled(Input)";

export default class ValidatedInput extends Component {
  static propTypes = {
    validInput: PropTypes.bool.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    errorMessage: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    showError: PropTypes.bool.isRequired,
    getRef: PropTypes.func,
    itemStyle: PropTypes.object,
    keyboardType: PropTypes.string,
    returnKeyType: PropTypes.string,
    onSubmitEditing: PropTypes.func,
    secureTextEntry: PropTypes.bool,
    multiLine: PropTypes.bool,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    noBorder: PropTypes.bool
  };

  render() {
    return (
      <View>
        <Item
          floatingLabel
          noBorder={this.props.noBorder}
          error={!this.props.validInput && this.props.showError}
          style={this.props.itemStyle ? this.props.itemStyle : {}}>
          <Label>{this.props.label}</Label>
          <Input
            style={this.props.style ? this.props.style : {}}
            getRef={this.props.getRef}
            name={this.props.name ? this.props.name : ""}
            keyboardType={
              this.props.keyboardType ? this.props.keyboardType : "default"
            }
            returnKeyType={
              this.props.returnKeyType ? this.props.returnKeyType : "done"
            }
            onSubmitEditing={() =>
              this.props.onSubmitEditing
                ? this.props.onSubmitEditing()
                : () => {}
            }
            onChange={evt => {
              if (Platform.OS === "web" && this.props.multiline) {
                const textarea = evt.target;
                textarea.style.height = "auto";
                textarea.style.height = textarea.scrollHeight + "px";
              }
              this.props.handleInputChange(evt, this.props.name);
            }}
            value={this.props.value ? this.props.value : ""}
            secureTextEntry={
              this.props.secureTextEntry ? this.props.secureTextEntry : false
            }
            autoCapitalize={
              this.props.autoCapitalize ||
              (this.props.keyboardType || this.props.secureTextEntry
                ? "none"
                : undefined)
            }
            autoCorrect={true}
            multiline={this.props.multiline || false}
            disabled={this.props.disabled}
          />
        </Item>
        {!this.props.validInput && this.props.showError && (
          <Text error>{this.props.errorMessage}</Text>
        )}
      </View>
    );
  }
}
