import PushNotificationIOS from "@react-native-community/push-notification-ios";
import PushNotification from "react-native-push-notification";
import { GCM_SENDER_ID } from "./const";
import { setNotificationToken, setOpenTasksBadge } from "./actions/auth";

export default () => {
  PushNotification.configure({
    // (optional) Called when Token is generated (iOS and Android)
    onRegister: function(token) {
      console.log("Push token:", token);
      setNotificationToken(token.token);
    },

    // (required) Called when a remote or local notification is opened or received
    onNotification: function(notification) {
      // process the notification
      if (notification.badge) {
        PushNotification.setApplicationIconBadgeNumber(+notification.badge);
        setOpenTasksBadge(+notification.badge);
      }

      // required on iOS only (see fetchCompletionHandler docs: https://facebook.github.io/react-native/docs/pushnotificationios.html)
      notification.finish(PushNotificationIOS.FetchResult.NoData);
    },

    // ANDROID ONLY: GCM Sender ID (optional - not required for local notifications, but is need to receive remote push notifications)
    senderID: GCM_SENDER_ID,

    // IOS ONLY (optional): default: all - Permissions to register.
    permissions: {
      alert: true,
      badge: true,
      sound: true
    },

    // Should the initial notification be popped automatically
    // default: true
    popInitialNotification: true,

    /**
     * (optional) default: true
     * - Specified if permissions (ios) and token (android and ios) will requested or not,
     * - if not, you must call PushNotificationsHandler.requestPermissions() later
     */
    requestPermissions: true
  });
};
