import { Alert } from "react-native";
import SplashScreen from "react-native-splash-screen";
import axios, { CancelToken } from "axios";
import { api } from "./const";
import store from "./store";
import { isOffline } from "./offline";
import { refreshLogin } from "./actions/auth";
import types from "./actions/types";
import I18n from "./i18n";
import { delay } from "./utils";

let axiosInstance = axios.create({
  baseURL: api,
  headers: {
    common: {
      "Content-Type": "application/json"
    }
  },
  responseType: "json"
});

export function getAxios() {
  return axiosInstance;
}

let cancelQueue = [];
let syncRequests = {};
export function cancelRequests() {
  while (cancelQueue.length > 0) {
    const c = cancelQueue.shift();
    if (typeof c == "function") {
      c();
    }
  }
}

let retryModal = null;
function axiosFactory(method, modal, sync, canRetry) {
  const axiosMethod = async function(...args) {
    if (retryModal) {
      await retryModal;
      await delay(100);
      return axiosMethod(...args);
    }
    store.dispatch({
      type: types.ERROR,
      err: null
    });
    try {
      const [url] = args;
      if (!sync) {
        store.dispatch({ type: types.PROGRESS_START, modal });
        cancelRequests();
        if (typeof syncRequests[url] == "function") {
          syncRequests[url]();
          delete syncRequests[url];
        }
      }

      const response = modal
        ? await axiosInstance[method](...args)
        : await axiosInstance[method](url, {
            cancelToken: new CancelToken(c => {
              if (sync) {
                syncRequests[url] = c;
              } else {
                cancelQueue.push(c);
              }
            })
          });
      if (!sync) store.dispatch({ type: types.PROGRESS_END });
      return response.data || (method == "get" ? null : {});
    } catch (err) {
      if (!sync) store.dispatch({ type: types.PROGRESS_END });
      if (axios.isCancel(err)) {
        return null;
      }
      if (!sync) {
        store.dispatch({
          type: types.ERROR,
          err
        });
        if (
          err &&
          err.response &&
          err.response.status == 401 &&
          (await refreshLogin())
        ) {
          return axiosMethod(...args);
        } else if (canRetry && err && !err.response) {
          console.log("retry request...", method, ...args);
          const retryRequest = () => {
            return new Promise(resolve => {
              try {
                SplashScreen.hide();
                Alert.alert(
                  I18n.t("retryLogin.title"),
                  I18n.t("retryLogin.content"),
                  [
                    {
                      text: I18n.t("retryLogin.cancel"),
                      onPress: () => resolve(false)
                    },
                    {
                      text: I18n.t("retryLogin.retry"),
                      onPress: () => resolve(true)
                    }
                  ],
                  {
                    cancelable: false
                  }
                );
              } catch (err) {
                resolve(false);
              }
            });
          };
          retryModal = retryRequest();
          const retry = await retryModal;
          retryModal = null;
          if (retry) {
            return axiosMethod(...args);
          }
        } else {
          console.log("request failed", method, ...args);
        }
      }
      return null;
    }
  };
  return axiosMethod;
}

export const noRetryGET = axiosFactory("get", true, false, false);
export const noRetryPOST = axiosFactory("post", true, false, false);
export const noRetryPUT = axiosFactory("put", true, false, false);
export const noRetryPATCH = axiosFactory("patch", true, false, false);
export const noRetryDELETE = axiosFactory("delete", true, false, false);

export const GET = axiosFactory("get", true, false, true);
export const POST = axiosFactory("post", true, false, true);
export const PUT = axiosFactory("put", true, false, true);
export const PATCH = axiosFactory("patch", true, false, true);
export const DELETE = axiosFactory("delete", true, false, true);

export const inlineGET = axiosFactory("get", false, false, true);
export const syncGET = axiosFactory("get", false, true, false);

export function setToken(token) {
  if (token) axiosInstance.defaults.headers.common["Authorization"] = token;
  else delete axiosInstance.defaults.headers.common["Authorization"];
}
export function getToken() {
  return axiosInstance.defaults.headers.common["Authorization"];
}
export function useApi(url) {
  axiosInstance.defaults.baseURL = url || axiosInstance.defaults.baseURL;
}
