// @flow

export default () => {
  const pickerTheme = {
    ".note": {
      color: "#8F8E95"
    },
    // width: 90,
    marginRight: -4,
    flexGrow: 1
  };

  return pickerTheme;
};
