import store from "../../../store";
import types from "./types";
import { delay, sortAlpha, showToast } from "../../../utils";
import { GET, POST, PUT } from "../../../axios";
import axios from "axios";
import jsonp from "jsonp";
import { Platform } from "react-native";
import { getGroups } from "../../groups/actions";
import { addNewTask, updateTaskList } from "../../dashboard/actions";
import { updateTaskDetails } from "../../taskDetails/actions";
import { BING_LOCATION_ADDRESS, BING_KEY } from "../../../const";
import I18n from "../../../i18n";

export async function getCategories(groupId) {
  const result = await GET(`/groups/${groupId}/categories`);
  if (result) {
    store.dispatch({
      type: types.GET_CATEGORIES,
      categories: sortAlpha(result, "name")
    });
  }
  return result;
}

export async function updateCategories(categories) {
  store.dispatch({ type: types.UPDATE_CATEGORIES, categories });
}

export async function startNewTask() {
  const { managed } = store.getState().groups;
  if (managed && managed.length > 0) {
    store.dispatch({ type: types.START_NEW_TASK });
    return true;
  }
}

export async function editTask(task) {
  store.dispatch({
    type: types.EDIT_TASK,
    task: task
  });
}

export function setTask(task) {
  store.dispatch({ type: types.SET_TASK, task });
}

export async function getContacts(groupId) {
  const result = await GET(`/groups/${groupId}/contacts`);
  store.dispatch({ type: types.GET_CONTACTS, contacts: result });
}

export async function createNewTask(task) {
  const result = await POST("/tasks", task);

  if (result) {
    const newTask = {
      ...task,
      canEdit: true,
      id: result.id
    };
    addNewTask(newTask);

    if (typeof result.sms == "number") {
      switch (result.sms) {
        case -3:
          showToast(
            I18n.t("editTask.errorMessages.smsFailedToSent"),
            "danger",
            3000
          );
          break;
        case -2:
          showToast(
            I18n.t("editTask.errorMessages.dailyLimit", {
              now: moment().format("L")
            }),
            "danger",
            3000
          );
          break;
        case -1:
          showToast(I18n.t("editTask.errorMessages.appLimit"), "danger", 3000);
          break;
        case 0:
          showToast(I18n.t("editTask.noSms"), null, 3000);
          break;
        case 1:
          showToast(
            I18n.t("editTask.smsPerson", {
              count: 1
            }),
            null,
            3000
          );
          break;
        default:
          showToast(
            I18n.t("editTask.sms", {
              count: result.sms
            }),
            null,
            3000
          );
          break;
      }
    }

    return true;
  } else return null;
}

export async function getSuggestionsByAddress(term) {
  try {
    let result = null;
    if (Platform.OS == "web") {
      result = await new Promise((resolve, reject) => {
        jsonp(
          `${BING_LOCATION_ADDRESS}${term}?&output=json&jsonp=BING_CALLBACK&key=${BING_KEY}`,
          {
            name: "BING_CALLBACK"
          },
          (err, data) => {
            if (err) return reject(err);
            resolve({ data });
          }
        );
      });
    } else {
      result = await axios.get(
        `${BING_LOCATION_ADDRESS}${term}?&output=json&key=${BING_KEY}`
      );
    }

    const filteredArray = result.data.resourceSets[0].resources
      .filter(function(it) {
        return it &&
          it.address &&
          it.address.countryRegion &&
          it.address.countryRegion != "United States"
          ? false
          : true;
      })
      .map(function(it) {
        return {
          address:
            it && it.address && it.address.formattedAddress
              ? it.address.formattedAddress
              : it.name,
          lat:
            it &&
            it.geocodePoints &&
            it.geocodePoints.length &&
            it.geocodePoints[0].coordinates
              ? it.geocodePoints[0].coordinates[0]
              : null,
          lng:
            it &&
            it.geocodePoints &&
            it.geocodePoints.length &&
            it.geocodePoints[0].coordinates
              ? it.geocodePoints[0].coordinates[1]
              : null
        };
      });

    store.dispatch({
      type: types.GET_LOCATION_RESULTS,
      locationResults: filteredArray
    });
  } catch (err) {}
}

export function removeAutoSuggests() {
  store.dispatch({ type: types.REMOVE_SUGGESTIONS });
}

export async function updateTask(id, task) {
  if (await PUT(`/tasks/${id}`, task)) {
    updateTaskDetails(task, id);
    updateTaskList(task);
    return true;
  } else return false;
}
