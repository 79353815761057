import types from "../actions/types";
import store from "../../../store";

export default (state = "volunteer", action) => {
  switch (action.type) {
    case types.GROUP_DETAILS:
      const { mode, group } = action;
      if (mode) return mode;
      if (group) {
        const { joined, managed } = store.getState().groups;
        if (joined.includes(action.group)) return "volunteer";
        if (managed.includes(action.group)) return "manage";
      }
      return state;
    case types.CREATE_GROUP:
      return "review";
    case types.LOGOUT:
      return "volunteer";
    default:
      return state;
  }
};
