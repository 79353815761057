import types from "../actions/types";

const initialState = {
  currentUser: null,
  email: "",
  password: "",
  syncMode: "always"
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CURRENT_USER:
      return { ...state, currentUser: action.currentUser };
    case types.JWT_TOKEN:
      return { ...state, token: action.token };
    case types.REMEMBER_ME:
      return {
        ...state,
        rememberme: action.rememberme,
        email: action.username || "",
        password: action.password || "",
        authorization: action.authorization
      };
    case types.ROOT_NAVIGATION:
      return {
        ...state,
        rootNavigation: action.rootNavigation
      };
    case types.NOTIFICATION_TOKEN:
      return {
        ...state,
        notificationToken: action.token
      };
    case types.OPEN_TASKS_BADGE:
      return {
        ...state,
        openTasks: action.badge || 0
      };
    case types.SYNC_MODE:
      return {
        ...state,
        syncMode: action.mode
      };
    default:
      return state;
  }
};
