import config from "react-native-config";
import DeviceInfo from "react-native-device-info";

export const api =
  config.REST_API || "https://4bells-api-dev.caravanstudiosapps.org";
export const discoversion =
  config.DISCOVERSION ||
  "https://discoversion.caravanstudiosapps.org/api/GetSettings";
export const namespace = "com.caravan.fourbells";
export const manifest = {
  version: config.VERSION_NAME || DeviceInfo.getVersion() || "2.0.2",
  buildDate: process.env.BUILD_DATE || new Date()
};
export const GCM_SENDER_ID = config.GCM_SENDER_ID || "812630236164";
export const BING_ADDRESS = "https://dev.virtualearth.net/REST/v1/Routes?";
export const BING_LOCATION_ADDRESS =
  "https://dev.virtualearth.net/REST/v1/Locations/";
export const BING_KEY =
  "AvUUudLxnDImpgNdr-IOPH-5oTZlVvA_LOH6lkwB8ZTyloCaaMmM1JYZqyLTOWAs";
export let FB_PAGE_ID = "190741648394318";
export function useFB_PAGE_ID(id) {
  FB_PAGE_ID = id;
}
