import types from "../actions/types";
import store from "../store";

const initialState = {
  active: false,
  modal: false
};

let counter = 0;
export default (state = initialState, action) => {
  if (action.type.indexOf("PROGRESS") >= 0) {
    let modal = state.modal;
    switch (action.type) {
      case types.PROGRESS_START:
        counter = Math.max(0, counter + 1);
        modal = modal || action.modal || false;
        break;
      case types.PROGRESS_END:
        counter = Math.max(0, counter - 1);
        if (counter == 0) {
          modal = false;
        }
        break;
      default:
        break;
    }
    return {
      active: counter > 0,
      modal
    };
  }
  return state;
};
