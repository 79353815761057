import React from "react";
import { SceneView } from "@react-navigation/core";
import DrawerSidebar from "../../node_modules/react-navigation-drawer/lib/module/views/DrawerSidebar";
import "./styles.css";

export default function DrawerView(props) {
  let { lazy, navigation } = props;
  let { routes } = navigation.state;

  return (
    <div className="drawer-view">
      <DrawerSidebar
        screenProps={props.screenProps}
        navigation={props.navigation}
        descriptors={props.descriptors}
        contentComponent={props.navigationConfig.contentComponent}
        contentOptions={props.navigationConfig.contentOptions}
        drawerPosition={props.navigationConfig.drawerPosition}
        style={{ maxWidth: 300, ...props.navigationConfig.style }}
        {...props.navigationConfig}
      />
      {routes.map((route, index) => {
        if (lazy && !loaded.includes(index)) {
          // Don't render a screen if we've never navigated to it
          return null;
        }

        let isFocused = navigation.state.index === index;
        let descriptor = props.descriptors[route.key];

        if (!isFocused) return null;
        return (
          <SceneView
            key={route.key}
            navigation={descriptor.navigation}
            screenProps={props.screenProps}
            component={descriptor.getComponent()}
          />
        );
      })}
    </div>
  );
}
