export default {
  global: {
    email: "Correo electrónico",
    password: "Contraseña",
    rememberMe: "Recuérdame",
    forgotPass: "¿Olvidó su contraseña?",
    login: "Iniciar sesión",
    loginTitle: "Iniciar sesión en 4Bells",
    loginFailedTitle: "Iniciar sesión",
    loginFailed: "Usuario o contraseña inválido/a",
    createAcc: "Crear cuenta",
    changePass: "Cambiar contraseña",
    and: "y",
    firstName: "Nombre",
    lastName: "Apellido",
    eula: "Acuerdo de licencia de usuario final",
    privacypolicy: "Política de privacidad",
    continue: "Continuar",
    save: "Guardar",
    saveChanges: "Guardar cambios",
    send: "Enviar",
    cancel: "Cancelar",
    callPhone: "Llamar al teléfono",
    noItemFoundForList: "Ningún elemento encontrado para esta lista",
    briefDesc: "Descripción breve",
    startDate: "Fecha de inicio",
    endDate: "Fecha de finalización",
    startLocation: "Ubicación inicial",
    endLocation: "Ubicación final",
    ownerName: "Nombre del titular",
    ownerPhone: "Teléfono del titular",
    numberVolunteers: "Número de voluntarios",
    categories: "Categorías",
    language: "Idioma",
    pleaseSelectLanguage: "Elegir idioma",
    isRequired: "se requiere",
    title: "Título",
    noCategoriesSelected: "Ninguna categoría escogida",
    confirm: "Por favor confirmar ",
    needToRestart: "Necesitamos reiniciar la app.",
    restart: "Reiniciar",
    ok: "OK",
    yes: "Sí",
    no: "No",
    close: "Cerrar",
    selecetOne: "Escoger uno",
    group: "Grupo",
    thankYou: "Gracias!",
    view: "Ver",
    taskStatus: {
      open: "Abierta",
      claimed: "Asignada",
      decline: "Rechazada",
      completed: "Completada",
      closed: "Cerrada",
      recalled: "Eliminada"
    },
    forminvalid: "El formulario es inválido"
  },
  location: {
    pin: "Clica en el mapa para seleccionar la ubicación",
    title: "4Bells está solicitando acceso a su ubicación",
    message:
      "Necesitamos tener acceso a la geolocalización para gestionar la ubicación de los dispositivos. Por favor, permítanos el acceso.",
    openInMapApp: "Abrir en la app de mapas"
  },
  drawer: {
    activities: "Resumen",
    tasks: "Tareas",
    groups: "Grupos",
    myAccount: "Mi Cuenta",
    sync: "Sincronizar",
    settings: "Configuración",
    contactUs: "Contáctenos",
    logout: "Cerrar sesión",
    faq: "Preguntas Frecuentes",
    terms: "Acuerdo de licencia de usuario final",
    privacy: "Política de privacidad",
    about: "Sobre"
  },
  sync: {
    complete: "Sincronización completada.",
    cancel: "Sincronización cancelada.",
    syncGroup: "Sincronizando grupo: {{name}}",
    groupFailed: "Grupo: {{name}} fallo de sincronización",
    tasks: "Sincronizando tareas {{list}}",
    open: "abierta",
    claimed: "asignada",
    syncTask: "Sincronizando tareas {{index}}/{{count}} {{list}}...",
    taskFailed: "Tarea: {{name}} fallo de sincronización",
    modes: {
      none: "Desactivar",
      always: "Siempre",
      wifi: "Solo con Wifi"
    }
  },
  errorMessages: {
    unexpectedError: "Ocurrió un error inesperado",
    firstNameRequired: "Se requiere nombre",
    lastNameRequired: "Se requiere apellido",
    emailRequired: "Se requiere correo electrónico",
    emailNotValid: "Correo electrónico inválido",
    phoneRequired: "Se require número de teléfono",
    phoneInvalid: "Número de teléfono inválido",
    passwordRequired: "Se requiere contraseña",
    passwordRule:
      "La contraseña debe tener al menos 6 caracteres, una combinación de letras mayúsculas y minúsculas y también números. No use caracteres especiales.",
    passwordNotMatch: "Las contraseñas tienen que coincidir",
    somethingWentWrong: "Algo salió mal",
    messageRequired: "Se requiere mensaje",
    endDateNeedToHigher:
      "¡La fecha de finalización debe ser posterior a la fecha de inicio!",
    groupRequired: "Se requiere grupo",
    titleRequired: "Se requiere título",
    briefDescRequired: "Se requiere descripción breve",
    startDateRequired: "Se requiere fecha de inicio",
    numberVolunteersRequired: "Se requiere número de voluntarios"
  },
  appError: {
    "Network Error": "Error de red",
    "Invalid username or password": "Usuario o contraseña inválido/a",
    "Registration warning": "Esta dirección de correo ya está en uso",
    "Request failed with status code 504": "El servidor no responde",
    "Request failed with status code 404": "No se encuentra el recurso",
    "Resource not found": "No se encuentra el recurso"
  },
  twilio: {
    lookup: "Verificando número de teléfono",
    invalid: "Número de teléfono inválido"
  },
  screenHeader: {
    activity: "Actividad",
    taskDetails: "Detalles tarea",
    editTask: "Editar tarea",
    addTask: "Añadir tarea",
    createAccount: "Crear cuenta",
    editAccount: "Editar mi cuenta",
    myAccount: "Mi cuenta",
    help: "Ayuda",
    settings: "Configuración"
  },
  welcome: {
    welcome: "Bienvenido a 4Bells",
    description:
      "Coordine voluntarios con una sola mano. Use la app 4Bells para enviar tareas, supervisar su progreso y completar trabajos urgentes.",
    or: "o",
    needHelp: "¿Necesita ayuda?",
    settings: "Configuración"
  },
  settings: {
    sync: {
      description: "Configuración de la Utilización de datos ",
      none: "Siempre usar menos datos",
      noneDescription:
        "Descargar la información del grupo y de las tareas solo cuando sea necesario.",
      always: "Automática (predeterminada)",
      alwaysDescription:
        "Cuando la aplicación esté en uso, descargar automáticamente toda la información nueva de los grupos y las tareas.",
      wifi: "Usar menos datos móviles",
      wifiDescription:
        "Cuando se usan los datos móviles, descargar la información de los grupos y de las tareas solo cuando sea necesario.",
      reducedOfflineData:
        "Aviso: al seleccionar menos datos, puede que se reduzca la información disponible cuando esté sin conexión.",
      update: "Actualizar todos los grupos y tareas"
    }
  },
  login: {
    rememberMe: "Recuérdame"
  },
  signup: {
    keepMe: "Permanecer conectado",
    fourbellsAlert: "Alertas de 4Bells",
    sendTextMessage: "Recibir mensajes de texto",
    ratesApply: "Se aplicarán tarifas ",
    sendNotification: "Recibir notificaciones",
    sendMessenger: "Recibir notificaciones por Facebook",
    termsAccept: "Al usar esta aplicación, usted acepta ",
    phone: "Número de teléfono",
    accountInfoSectionTitle: "Información de la cuenta",
    passwordSectionTitle: "Contraseña",
    languageSectionTitle: "Idioma"
  },
  newuser: {
    title: "Bienvenido/a",
    firststep: "Su primer paso",
    description:
      "Usted puede ser tanto voluntario, como coordinador de grupos. Escoja una de las opciones y empiece a crear grupos o únase a uno ya existente a través del panel de control.",
    volunteer: "Voluntario",
    creategroup: "Crear un grupo",
    viewPasswordRules: "Ver reglas contraseña"
  },
  help: {
    contactUs: "Contáctenos",
    aboutFourBells: "Sobre 4Bells",
    faq: "Preguntas Frecuentes",
    forgotPassLink: "¿Olvidó su contraseña?"
  },
  about: {
    title: "Sobre 4Bells",
    version: "Versión de la aplicación",
    releaseDate: "Fecha de lanzamiento",
    buildNumber: "Número de compilación",
    financialSupport: "Con el apoyo financiero de:",
    inspirationFeedback: "Recibimos inspiración y retroalimentación de:",
    beforeJayStackLink:
      "4Bells es un proyecto de Caravan Studios, una división de TechSoup. Colaboramos con",
    afterJayStackLink: "para construir y mantener 4Bells.",
    terms: "Acuerdo de licencia de usuario final",
    policy: "Política de privacidad"
  },
  offline: {
    status: "sin conexión",
    title: "No hay conexión de red",
    descriptionTitle: "Conexión de red no encontrada",
    description:
      "Lo sentimos, no encontramos ninguna conexión de red. Por favor, active el Wi-Fi o la conexión de red de su celular y reinicie 4Bells. ¡Gracias!"
  },
  forgotPass: {
    emailMe: "Recibir mi información por correo electrónico",
    emailSucces: "El correo electrónico se envió con éxito"
  },
  contactUs: {
    title: "Contáctenos",
    bellsfeedback: "Comentarios para 4Bells",
    bellsIssue: "Resolución de problemas con 4Bells",
    message: "Mensaje",
    sent: "Mensaje enviado",
    error: "Fallo al enviar el mensaje"
  },
  editAccount: {
    changePass: "Cambiar contraseña"
  },
  changePass: {
    succesfullyChange: "Su contraseña se cambió con éxito",
    passwordRule:
      "La contraseña debe tener al menos 6 caracteres, una combinación de letras mayúsculas y minúsculas y también números. No use caracteres especiales.",
    save: "Guardar"
  },
  activities: {
    title: "Resumen",
    tabHeaders: {
      alerts: "Por hacer",
      warnings: "Actividad"
    },
    emptyTodos: "No tiene nada pendiente",
    emptyActivities: "Usted no tiene ninguna actividad.",
    removed: "Actividad eliminada",
    actionNeeded: "Acción requerida",
    view: "Ver..."
  },
  dashboard: {
    spacesLeft: "puestos restantes",
    spaceLeft: "puesto restante",
    claimedBy: "Asignada a ",
    claimedMultiple: "Voluntarios múltiples",
    claimedUnknown: "desconocido",
    noOpenTask: "No hay tareas abiertas para solicitar",
    noClaimedTask: "No hay tareas asignadas",
    noClosedTask: "No ha completado ninguna tarea",
    view: "Ver...",
    manage: "Administrar...",
    tagManaging: "Administrando",
    tagOpen: "Abierta",
    tagClosed: "Cerrada",
    tagClaimed: "Asignada",
    claimedByYou: "Usted ha solicitado esta tarea",
    managedByYou: "Usted es coordinador de esta tarea",
    pleaseRespond: "Por favor, responda a esta tarea"
  },
  taskDetails: {
    tabHeaders: {
      details: "Detalles",
      volunteers: "Voluntarios",
      contacts: "Contactos",
      location: "Ubicación",
      history: "Historial",
      actions: "Acciones"
    },
    history: {
      claimed: "Asignada",
      declined: "Rechazada",
      completed: "Completada",
      closed: "Cerrada",
      modified: "Modificada",
      unclaimed: "Solicitud anulada",
      created: "Creada",
      incomplete: "Incompleta",
      admin: "(administrador)",
      decline: "Rechazar"
    },
    actions: {
      claim: "Solicitar",
      decline: "Rechazar",
      unclaim: "Cancelar solicitud",
      markComplete: "Marcar como completada",
      cancel: "Cancelar",
      taskRemoved: "¡La tarea fue eliminada!",
      noMoreSpacesLeft: "¡No quedan vacantes!",
      unclaimError: "¡Error al deshacer solicitud de tarea!",
      declineError: "¡Error al rechazar la tarea!",
      markCompleteError: "¡Error al marcar como completa!",
      markIncompleteError: "¡Error al marcar como incompleta!",
      missingTask: "Tareas pendientes",
      changeYourMind: "¿Cambió de parecer?",
      claimThisTask: "Solicitar esta tarea"
    },
    details: {
      editTask: "Editar esta tarea",
      briefDesc: "Descripción breve",
      categories: "Categorías ",
      status: "Estado",
      ownerName: "Nombre del Titular",
      ownerPhone: "Contacto del Titular",
      startDate: "Fecha de inicio",
      endDate: "Fecha de finalización",
      startLocation: "Ubicación inicial",
      endLocation: "Ubicación final",
      organization: "Organización ",
      volunteers: "Voluntarios",
      pleaseRespond: "Por favor, responda a esta tarea",
      isDeclined: "Usted rechazó esta tarea"
    },
    volunteers: {
      all: "Todas",
      claimed: "Solicitada",
      declined: "Rechazada",
      completed: "Completada",
      closed: "Cerrada",
      show: "Mostrar",
      status: "Estado:"
    },
    contacts: {
      ratesApply: "Se aplican cargos normales de mensajes de texto y llamadas",
      noContactsForTask: "No se añadieron contactos para esta tarea.",
      text: "Mensaje de texto",
      sendMail: "Enviar Mensaje"
    }
  },
  groups: {
    title: "Grupos",
    groupsJoined: "Mis Grupos Voluntarios",
    groupsManaged: "Grupos que Coordino",
    joinGroup: "Unirse a un grupo...",
    joinAnotherGroup: "Unirse a otro grupo...",
    createGroup: "Crear grupo...",
    profile: {
      title: "Crear grupo",
      edit: "Editar perfil grupo",
      groupName: "Nombre del grupo",
      address1: "Dirección 1",
      address2: "Dirección 2",
      city: "Ciudad",
      country: "País",
      postalCode: "Código Postal",
      state: "Estado, región o país",
      volunteerApproval: "Aprobación de voluntarios",
      enableApproval: "¿Activar la aprobación de voluntarios?",
      yes: "Sí",
      no: "No",
      continue: "Paso 2: Categorías",
      save: "Guardar",
      addressTitle: "Dirección",
      groupSettingsTitle: "Configuración del grupo",
      selectValuePlaceholder: "Seleccione una opción",
      selectStatePlaceholder: "Seleccione un estado",
      errorMessage: {
        groupName: "Se requiere Nombre de Grupo",
        volunteerApproval: "Se requiere aprobación de voluntarios"
      }
    },
    categories: {
      title: "Categorías",
      description:
        "Puede agrupar a los voluntarios según sus habilidades, tipos de tareas, o cualquier categoría relevante para cubrir sus necesidades.",
      examplesLabel: "Ejemplos",
      examples:
        "Coordinación de eventos, Llamadas telefónicas, Servir alimentos, Transporte",
      categoryLabel: "Seleccionar o agregar una categoría nueva",
      categoryButton: "Agregar",
      invalid: "Su grupo debe tener por lo menos una categoría.",
      continue: "Paso 3: Revisar",
      subscribe: "Suscribirse a categoría",
      unsubscribe: "Cancelar suscripción a categoría",
      next: "Siguiente",
      heading: "Categorías de voluntarios",
      prompt:
        "Escoja una de las categorías de ejemplo o añada sus propias categorías.",
      placeholder: "Agregar una categoría...",
      addButtonLabel: "Agregar",
      selectCategoriesPrompt: "Seleccione los tipos de tareas que le interesan"
    },
    contacts: {
      title: "Agregar Contactos",
      description:
        "Agregue personas de referencia que puedan ayudar o brindar información sobre las tareas de este grupo.",
      remove: "Eliminar",
      add: "Agregar Contacto...",
      save: "OK",
      form: {
        name: "Nombre",
        phone: "Teléfono",
        email: "Correo electrónico"
      },
      errorMessage: {
        name: "Se require nombre",
        phone: "Se require número de teléfono",
        email: "Se require correo electrónico",
        emailInvalid: "Correo electrónico inválido"
      },
      continue: "Paso 4: Revisar",
      rates: "Se aplican cargos normales de mensajes de texto y llamadas",
      callPhone: "Llamar ",
      sendText: "Mensaje de texto",
      sendMail: "Enviar correo electrónico"
    },
    review: {
      title: "Revisar",
      details: {
        groupName: "Nombre del grupo",
        connectionCode: "Código de acceso",
        address1: "Dirección 1",
        address2: "Dirección 2",
        country: "País",
        city: "Ciudad",
        state: "Estado",
        postalCode: "Código Postal",
        volunteerApproval: "Aprobación de voluntarios",
        yes: "Sí",
        no: "No"
      }
    },
    created: {
      title: "Grupo Creado",
      heading: "Se creó tu grupo",
      groupName: "Nombre del grupo",
      connectionCode: "Código de conexión ",
      description:
        "Recibirá un email con información adicional e instrucciones para conectarse con los voluntarios y crear tareas que puedan ser solicitadas por los mismos.",
      invite: "Invitar voluntarios"
    },
    volunteer: {
      title: "Grupo"
    },
    admin: {
      title: "Grupo",
      manageGroup: "Administrar este grupo"
    },
    manage: {
      title: "Administrar grupo",
      editGroup: "Editar grupo",
      deleteGroup: "Borrar grupo",
      addCategory: "Agregar una categoría",
      showPendingOnly: "Mostrar solo aprobaciones pendientes",
      categoryVolunteers: "{{count}} voluntarios",
      approveVolunteers:
        "{{approved}} aprobado(s) {{pending}} pendiente(s) {{rejected}} rechazado(s)",
      removeCategory: "Eliminar",
      viewVolunteers: "Ver",
      manageVolunteers: "Administrar",
      addContact: "Agregar un contacto",
      removeContact: "Eliminar",
      removeContactConfirm: "¿Está seguro de que quiere borrar el contacto?",
      inviteMore: "Invitar a más voluntarios",
      volunteers: "Voluntarios",
      invite: "Invitar a más voluntarios",
      categorySubtitle: "Categoría",
      manageCategoryDescription:
        "Administre las categorías de este grupo y vea qué voluntarios participan en cada categoría.",
      statusApproved: "Aprobado(s)",
      statusRejected: "Rechazado(s)",
      statusPending: "Pendiente(s)"
    },
    invitation: {
      subject: "Invitación para unirse a 4Bells"
    },
    actions: {
      completeSetup: "Guardar este grupo",
      createFailed: "Error al crear grupo",
      disconnect: "Salir del grupo",
      disconnectConfirm: "¿Está seguro que quiere salir del grupo?",
      deleteGroup: "Borrar grupo",
      deleteConfirm: "¿Está seguro de que quiere borrar el grupo?"
    },
    tabs: {
      details: "Detalles",
      categories: "Categorías",
      contacts: "Contactos",
      lead: "Coordinador",
      volunteers: "Voluntarios",
      interests: "Mis intereses"
    },
    categoryvolunteers: {
      title: "Lista de voluntarios",
      subtitle: "De categoría{{name}} ",
      status: "Estado",
      approved: "Aprobado",
      pending: "Pendiente",
      rejected: "Rechazado",
      actions: {
        approve: "Aprobar",
        reject: "Rechazar",
        disconnect: "Salir",
        remove: "Eliminar",
        changeToRejected: "Cambiar el estado a Rechazado",
        changeToApproved: "Cambiar el estado a Aprobado"
      }
    },
    volunteerdetails: {
      title: "Detalles del voluntario"
    },
    leaddetails: {
      title: "Detalles del coordinador"
    }
  },
  volunteer: {
    name: "Nombre",
    phone: "Teléfono",
    email: "Correo electrónico",
    status: "Estado",
    categories: "Categorías",
    promote: "Ascender a Coordinador",
    demote: "Convertir en Voluntario",
    disconnect: "Desconectar de este grupo",
    markComplete: "Marcar como completa",
    markIncomplete: "Marcar como incompleta",
    unassignVolunteer: "Retirar voluntario",
    groupMembership: "Afiliación al grupo",
    taskAssignment: "Asignación de tareas",
    errorMessages: {
      markCompleteFailed: "Fallo al marcar como completa",
      markIncompleteFailed: "Fallo al marcar como incompleta",
      unassignVolunteerFailed: "Fallo al retirar voluntario"
    }
  },
  connect: {
    title: "Conectar a un grupo",
    volunteerText:
      "Si usted es un voluntario, ingrese aquí el código de acceso proporcionado por el coordinador del grupo:",
    connectCodeLabel: "Código de acceso",
    connectButton: "Conectar a un grupo",
    invalidCode:
      "No es un código de acceso válido. Por favor, consulte al administrador del grupo para obtener el código correcto.",
    alreadyConnected:
      "Ya está conectado a este grupo, pero puede ser que su perfil aún esté pendiente de aprobación. Por favor, contacte al administrador del grupo para ser aprobado.",
    approve:
      "La solicitud para unirse al grupo fue enviada. El grupo aparecerá en su cuenta si la solicitud es aprobada.",
    thanksConnecting:
      "Gracias por unirse a {{groupName}}. Comenzará a recibir tareas cuando su solicitud sea aprobada.",
    groupName: "Nombre del grupo:",
    selectCategories: "Seleccione el tipo de tareas que le interesan",
    locating: "localizando grupo...",
    connecting: "conectando con el grupo..."
  },
  editTask: {
    headerTitles: {
      categories: "Seleccionar categorías",
      review: "Revisar"
    },
    chooseGroup: "Escoja un grupo",
    briefDescPlaceholder: "Breve descripción de la tarea",
    group: "Grupo",
    title: "Título",
    taskSummary: "Resumen de tareas",
    taskDetails: "Detalles Tarea",
    additionalInformation: "Información adicional (opcional)",
    datePanelTitle: "FECHA",
    locationPanelTitle: "UBICACIÓN",
    contactsPanelTitle: "CONTACTOS",
    selectDatePlaceholder: "Seleccionar fecha",
    selectContactsDescription:
      "Seleccione personas que pueden ofrecer ayuda o información sobre esta tarea.",
    selectContactsTip: "Consejo: Añada contactos de sus Grupos.",
    selectContacts: "Seleccionar contactos...",
    numberVolunteers: "Número de voluntarios",
    continueCategories: "Continuar a Categorías",
    setLocaion: "Definir ubicación",
    contacts: "Contactos",
    checkAllFields: "Marcar / Desmarcar todas las categorías",
    continueToReview: "Continuar para revisar",
    submit: "Enviar",
    didYouMean: "¿Quería decir ?",
    errorMessages: {
      addFailed: "Fallo al crear tarea",
      editFailed: "Fallo al actualizar tarea",
      smsFailedToSent: "Ocurrió un error y el SMS no se pudo enviar",
      appLimit:
        "Debe ser que hoy todo el mundo está usando 4Bells porque alcanzamos nuestro límite de mensajes de texto diarios. Esto no suele pasar a menudo: contáctenos si ya ha recibido este mensaje en otra ocasión, o si piensa que usará 4Bells en el futuro con esta misma demanda.",
      dailyLimit:
        "¡Debe estar muy ocupado! Hoy alcanzó su límite de 50 mensajes de texto diarios. Tendrá que esperar hasta {{now}} 11.59 PM (UTC) para enviar más ofertas. Contáctenos si piensa que en el futuro utilizará 4Bells con la misma demanda."
    },
    noSms: "El SMS no se envió a nadie",
    sms: "SMS enviado a {{count}} personas",
    smsPerson: "SMS enviado a {{count}} persona"
  },
  tasks: {
    title: "Tareas",
    tabHeaders: {
      openClaim: "Abiertas",
      currentClaimed: "Asignadas",
      closedTasks: "Cerradas"
    },
    addTask: "Agregar tarea...",
    addContacts: "Seleccionar contactos"
  },
  retryLogin: {
    title: "Error de red",
    content: "No se puede conectar con el servidor",
    cancel: "Cancelar",
    retry: "Rever"
  }
};
