import * as React from "react";
import { StyleSheet, View } from "react-native";
import TabBarItem from "./TabBarItem";
import TabBarIndicator from "./TabBarIndicator";

export default class TabBar extends React.Component {
  static defaultProps = {
    getLabelText: ({ route }) =>
      typeof route.title === "string" ? route.title.toUpperCase() : route.title,
    getAccessible: ({ route }) =>
      typeof route.accessible !== "undefined" ? route.accessible : true,
    getAccessibilityLabel: ({ route }) =>
      typeof route.accessibilityLabel === "string"
        ? route.accessibilityLabel
        : typeof route.title === "string"
        ? route.title
        : undefined,
    getTestID: ({ route }) => route.testID,
    renderIndicator: props => <TabBarIndicator {...props} />
  };

  render() {
    const {
      position,
      navigationState,
      scrollEnabled,
      // bounces,
      getAccessibilityLabel,
      getAccessible,
      getLabelText,
      getTestID,
      renderBadge,
      renderIcon,
      renderLabel,
      activeColor,
      inactiveColor,
      pressColor,
      pressOpacity,
      onTabPress,
      onTabLongPress,
      tabStyle,
      labelStyle,
      indicatorStyle,
      style
    } = this.props;
    const { routes } = navigationState;

    return (
      <View style={[styles.tabBar, style]}>
        <div className="swiper-container">
          {routes.map((route, tabIndex) => (
            <div key={route.key} className="swiper-tab-item">
              <TabBarItem
                tabIndex={tabIndex}
                position={position}
                route={route}
                navigationState={navigationState}
                getAccessibilityLabel={getAccessibilityLabel}
                getAccessible={getAccessible}
                getLabelText={getLabelText}
                getTestID={getTestID}
                renderBadge={renderBadge}
                renderIcon={renderIcon}
                renderLabel={renderLabel}
                activeColor={activeColor}
                inactiveColor={inactiveColor}
                pressColor={pressColor}
                pressOpacity={pressOpacity}
                onPress={() => {
                  onTabPress && onTabPress({ route });
                  this.props.jumpTo(route.key);
                }}
                onLongPress={() => onTabLongPress && onTabLongPress({ route })}
                labelStyle={labelStyle}
                style={tabStyle}
              />
            </div>
          ))}
          <TabBarIndicator
            style={indicatorStyle}
            count={navigationState.routes.length}
            index={navigationState.index}
          />
        </div>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  tabBar: {
    backgroundColor: "#2196f3",
    elevation: 4,
    shadowColor: "black",
    shadowOpacity: 0.1,
    shadowRadius: StyleSheet.hairlineWidth,
    shadowOffset: {
      height: StyleSheet.hairlineWidth,
      width: 0
    },
    zIndex: 1
  },
  tabContent: {
    flexDirection: "row",
    flexWrap: "nowrap"
  },
  indicatorContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
});
