import React, { Component } from "react";
import {
  Header,
  Tabs,
  Tab,
  Text,
  Content,
  Body,
  Left,
  Button,
  Icon,
  Right,
  Title,
  Subtitle
} from "native-base";
import { Alert, View, Platform } from "react-native";
import I18n from "../../i18n";
import { getTask } from "./actions";
import Location from "./location";
import Actions from "./actiontab";
import Details from "./details";
import Contacts from "./contacts";
import History from "./history";
import Volunteers from "./volunteers";
import VolunteerDetails from "./volunteerdetails";
import { Screen, HeaderWithBack, transitionConfig } from "../../utils";
import ScrollableTab from "../../components/ScrollableTab";
import DefaultTabBar from "../../components/DefaultTabBar";
import variable from "../../../native-base-theme/variables/platform";
import { createStackNavigator } from "react-navigation-stack";
import { connect } from "react-redux";
import ConnectHeader from "../../components/ConnectHeader";

const ConnectedHeader = connect(state => ({
  task: state.taskDetails.task
}))(props => {
  return <ConnectHeader title={props.task.name} {...props} />;
});

const TaskDetails = Screen(
  connect(state => ({
    task: state.taskDetails.task,
    offline: state.offline
  }))(
    class TaskDetailsTabs extends Component {
      async componentDidMount() {
        const idParam =
          +this.props.navigation.getParam("id") || this.props.task.id;
        if (idParam !== this.props.task.id) {
          await getTask(idParam);
        }
      }

      async componentDidUpdate(prevProps) {
        if (prevProps.offline != this.props.offline) {
          await getTask(this.props.task.id);
          this._tabs.goToPage(0);
        }
      }

      render() {
        const { canEdit, startlocation, endlocation } = this.props.task;
        const { offline } = this.props;

        return (
          <Tabs
            initialPage={0}
            ref={ref => (this._tabs = ref)}
            renderTabBar={
              canEdit
                ? () => (
                    <ScrollableTab
                      tabsContainerStyle={{
                        backgroundColor:
                          Platform.OS === "android"
                            ? variable.toolbarDefaultBg
                            : "white"
                      }}
                      style={{
                        borderBottomWidth: 0
                      }}
                    />
                  )
                : props => <DefaultTabBar {...props} />
            }>
            <Tab heading={I18n.t("taskDetails.tabHeaders.details")}>
              <Details {...this.props} />
            </Tab>
            {canEdit && (
              <Tab heading={I18n.t("taskDetails.tabHeaders.volunteers")}>
                <Volunteers {...this.props} />
              </Tab>
            )}
            <Tab heading={I18n.t("taskDetails.tabHeaders.contacts")}>
              <Contacts />
            </Tab>
            {!offline &&
              ((startlocation && startlocation.id) ||
                (endlocation && endlocation.id)) && (
                <Tab heading={I18n.t("taskDetails.tabHeaders.location")}>
                  <Location />
                </Tab>
              )}
            {canEdit && (
              <Tab
                heading={I18n.t("taskDetails.tabHeaders.history")}
                style={{ height: "100%" }}>
                <History />
              </Tab>
            )}
            {!offline && canEdit && (
              <Tab heading={I18n.t("taskDetails.tabHeaders.actions")}>
                <Actions {...this.props} />
              </Tab>
            )}
          </Tabs>
        );
      }
    }
  ),
  props => <ConnectedHeader {...props} />
);

export default createStackNavigator(
  {
    TaskDetails: {
      screen: TaskDetails
    },
    VolunteerDetails: {
      screen: VolunteerDetails
    }
  },
  {
    initialRouteName: "TaskDetails",
    headerMode: "none",
    navigationOptions: {
      header: null
    },
    transitionConfig
  }
);
