import React, { Component } from "react";
import {
  Text,
  Content,
  H3,
  Button,
  Header,
  Left,
  Body,
  Right,
  Title
} from "native-base";
import { View, Alert, BackHandler } from "react-native";
import { NavigationActions, StackActions } from "react-navigation";
import { createGroup, getGroups } from "./groups/actions";
import { Screen } from "../utils";
import I18n from "../i18n";

export default Screen(
  class NewUser extends Component {
    backHandler = () => {
      BackHandler.removeEventListener("hardwareBackPress", this.backHandler);
      this.props.navigation.dispatch(
        NavigationActions.navigate({
          routeName: "Main",
          action: StackActions.reset({
            index: 0,
            key: "Activities",
            actions: []
          })
        })
      );
      return true;
    };

    componentDidMount() {
      BackHandler.addEventListener("hardwareBackPress", this.backHandler);
    }

    groupNavigate = async to => {
      BackHandler.removeEventListener("hardwareBackPress", this.backHandler);
      // await getGroups();
      this.props.navigation.dispatch(
        NavigationActions.navigate({
          routeName: "Main",
          action: NavigationActions.navigate({
            routeName: "Groups",
            action: NavigationActions.navigate({
              routeName: to
            })
          })
        })
      );
    };

    createGroup = async () => {
      // await getGroups();
      await createGroup();
      this.groupNavigate("Profile");
    };

    render() {
      return (
        <Content>
          <View style={{ padding: 30 }}>
            <H3>{I18n.t("newuser.firststep")}</H3>
            <Text>{I18n.t("newuser.description")}</Text>
            <Button block primary onPress={() => this.groupNavigate("Connect")}>
              <Text uppercase={false}>{I18n.t("newuser.volunteer")}</Text>
            </Button>
            <Button block primary onPress={this.createGroup}>
              <Text uppercase={false}>{I18n.t("newuser.creategroup")}</Text>
            </Button>
          </View>
        </Content>
      );
    }
  },
  props => (
    <Header>
      <Left />
      <Body>
        <Title>{I18n.t("newuser.title")}</Title>
      </Body>
      <Right />
    </Header>
  )
);
