const Geolocation = {
  setRNConfiguration: function() {},
  requestAuthorization: function() {},
  getCurrentPosition: async function(success, error, options) {
    if (typeof success !== "function") {
      throw new Error("success callback must be a function");
    } else if (!navigator || !navigator.geolocation) {
      console.error("Navigator is undefined");
      return;
    }
    navigator.geolocation.getCurrentPosition(success, error, options);
  },
  watchPosition: function(success, error, options) {
    if (typeof success !== "function") {
      throw new Error("success callback must be a function");
    } else if (!navigator || !navigator.geolocation) {
      console.error("Navigator is undefined");
      return;
    }
    Geolocation.watchID = navigator.geolocation.watchPosition(
      success,
      error,
      options
    );
  },
  clearWatch: function(watchID) {
    if (!navigator || !navigator.geolocation) {
      console.error("Navigator is undefined");
      return;
    }
    navigator.geolocation.clearWatch(watchID);
  },
  stopObserving: function() {
    if (Geolocation.watchID) Geolocation.clearWatch(Geolocation.watchID);
  }
};
module.exports = Geolocation;
