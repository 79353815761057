import React, { Component } from "react";
import { Content, Text, Icon } from "native-base";
import { View, StyleSheet, Linking } from "react-native";
import moment from "moment";
import { Screen, HeaderWithBack, HTMLContent } from "../utils";
import styles from "../styles";
import I18n from "../i18n";
import { manifest } from "../const";

export default Screen(
  class About extends Component {
    render() {
      return (
        <Content container>
          <View style={styles.body}>
            <Text>
              {I18n.t("about.version")}: {manifest.version}
              {"\n"}
              {I18n.t("about.releaseDate")}:{" "}
              {moment(manifest.buildDate).format("L")}
              {"\n"}
              {I18n.t("about.buildNumber")}:{" "}
              {(
                "" +
                ((moment(manifest.buildDate)
                  .toDate()
                  .getTime() /
                  1000) |
                  0)
              ).slice(4)}
              {"\n"}
            </Text>
            <View>
              <Text style={style.heading}>
                {I18n.t("about.financialSupport")}
              </Text>
            </View>
            <Text
              note
              style={style.link}
              onPress={() =>
                Linking.openURL(
                  "https://microsoft.com/about/corporatecitizenship/en-us/"
                )
              }>
              Microsoft Corporate Citizenship
            </Text>
            <Text
              note
              style={style.link}
              onPress={() => Linking.openURL("https://www.twilio.org/impact/")}>
              Twilio.org
            </Text>
            <View>
              <Text style={style.heading}>
                {I18n.t("about.inspirationFeedback")}
              </Text>
            </View>
            <Text style={style.list}>
              {"\u2022"} Bay Area Food Recovery organizations{"\n"}
              {"\u2022"} Bay Area Animal Welfare organizations{"\n"}
              {"\u2022"} First Responders{"\n"}
              {"\u2022"} Emergency response volunteers{"\n"}
              {"\u2022"} Hotline operators{"\n"}
              {"\u2022"} Disaster planning and resilience experts{"\n"}
            </Text>
            <View>
              <Text style={style.heading}>
                {I18n.t("about.beforeJayStackLink")}{" "}
                <Text
                  note
                  style={style.link}
                  onPress={() => Linking.openURL("https://jaystack.com")}>
                  JayStack Technologies
                </Text>{" "}
                {I18n.t("about.afterJayStackLink")}
              </Text>
            </View>
            <View>
              <Text
                note
                style={style.link}
                onPress={() => Linking.openURL("https://www.4bells.org")}>
                4BellsApp.org
              </Text>
            </View>
            <View>
              <Text
                note
                style={style.link}
                onPress={() =>
                  Linking.openURL("https://www.4bells.org/4bells-terms")
                }>
                {I18n.t("about.terms")}
              </Text>
            </View>
            <View>
              <Text
                note
                style={style.link}
                onPress={() =>
                  Linking.openURL("https://www.4bells.org/4bells-privacy")
                }>
                {I18n.t("about.policy")}
              </Text>
            </View>
          </View>
        </Content>
      );
    }
  },
  _ => I18n.t("about.title")
);

const style = StyleSheet.create({
  heading: {
    marginVertical: 15,
    fontSize: 18
  },
  list: {
    marginHorizontal: 15,
    fontSize: 16
  },
  link: {
    marginHorizontal: 15,
    fontSize: 16,
    textDecorationLine: "underline"
  }
});
