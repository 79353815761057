import config from "react-native-config";
import axios from "axios";
import { encode as btoa } from "base-64";
import store from "./store";
import types from "./actions/types";

function createBasicAuth(sid, token) {
  return `Basic ${btoa(
    `${config.TWILIO_ACCOUNT_SID}:${config.TWILIO_AUTH_TOKEN}`
  )}`;
}

let basicAuth = createBasicAuth(
  config.TWILIO_ACCOUNT_SID,
  config.TWILIO_AUTH_TOKEN
);

export function useTwilio(sid, token) {
  basicAuth = createBasicAuth(sid, token);
}

export async function lookup(phone) {
  try {
    store.dispatch({ type: types.PROGRESS_START, modal: true });
    const { data } = await axios
      .create()
      .get(
        `https://lookups.twilio.com/v1/PhoneNumbers/${encodeURIComponent(
          phone
        )}?Type=carrier&Type=caller-name`,
        {
          headers: {
            Authorization: basicAuth
          }
        }
      );
    store.dispatch({ type: types.PROGRESS_END });
    return data;
  } catch (err) {
    store.dispatch({ type: types.PROGRESS_END });
    return null;
  }
}
