import * as React from "react";
import { StyleSheet, View } from "react-native";
import TabBar from "./TabBar";

class TabView extends React.Component {
  static defaultProps = {
    tabBarPosition: "top",
    renderTabBar: props => <TabBar {...props} />,
    renderLazyPlaceholder: () => null,
    keyboardDismissMode: "auto",
    swipeEnabled: true,
    lazy: false,
    lazyPreloadDistance: 0,
    removeClippedSubviews: false,
    springConfig: {},
    timingConfig: {},
    gestureHandlerProps: {}
  };

  _jumpToIndex = index => {
    if (index !== this.props.navigationState.index) {
      this.props.onIndexChange(index);
    }
  };
  _jumpToKey = key => {
    const { navigationState } = this.props;

    const index = navigationState.routes.findIndex(route => route.key === key);

    this._jumpToIndex(index);
  };

  render() {
    const {
      navigationState,
      tabBarPosition,
      renderTabBar,
      renderScene,
      style
    } = this.props;

    const tabBarProps = {
      jumpTo: this._jumpToKey,
      navigationState
    };
    return (
      <View style={[styles.pager, style]}>
        {tabBarPosition === "top" && renderTabBar(tabBarProps)}
        <div className="swiper-container">
          <div className="swiper-slide">
            {renderScene({
              jumpTo: this._jumpToKey,
              route: navigationState.routes[navigationState.index]
            })}
          </div>
        </div>
        {tabBarPosition === "bottom" && renderTabBar(tabBarProps)}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  pager: {
    flex: 1,
    overflow: "hidden"
  }
});

export default TabView;
