import { NavigationActions } from "react-navigation";
import qs from "qs";
import { removeActivity } from "./actions";
import { getTask } from "../taskDetails/actions";
import { groupDetails } from "../groups/actions";
import { showToast } from "../../utils";
import { isOffline, offlineGuard } from "../../offline";
import I18n from "../../i18n";

const routeMapping = {
  opportunitydetails: {
    navigation: data =>
      NavigationActions.navigate({
        routeName: "TaskDetails",
        params: data
      }),
    resolver: async data => {
      return await getTask(data.id);
    }
  },
  organizationdetails: {
    navigation: data =>
      NavigationActions.navigate({
        routeName: "Groups",
        action: NavigationActions.navigate({
          routeName: "Details",
          params: data
        })
      }),
    resolver: async data => {
      return await groupDetails("manage", { id: data.id, role: "admin" });
    }
  },
  tasks: {
    navigation: data =>
      NavigationActions.navigate({ routeName: "Tasks", params: data })
  },
  groups: {
    navigation: data => NavigationActions.navigate({ routeName: "Groups" })
  }
};

export default (item, props) => async () => {
  if (!isOffline && item.id && item.type.deactivate == "tap") {
    await removeActivity(item);
    showToast(I18n.t("activities.removed"));
  }
  if (item.target) {
    const [view, search] = item.target.replace(/^#\//, "").split("?");

    const data = qs.parse(search);
    const mapper = routeMapping[view];
    let resolved = true;
    if (mapper.resolver) resolved = await mapper.resolver(data);
    if (resolved) props.navigation.dispatch(mapper.navigation(data));
  }
};
