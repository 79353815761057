import { Alert } from "react-native";
import NetInfo from "@react-native-community/netinfo";
import { showToast, delay } from "./utils";
import store from "./store";
import { sync } from "./sync";
import I18n from "./i18n";
import discoversion from "./discoversion";
import * as connectionType from "./connectiontype";

const types = Object.freeze({
  OFFLINE: "OFFLINE"
});

export default (state = true, action) => {
  if (action.type == types.OFFLINE) {
    return !!action.offline;
  }
  return state;
};

export function isWifi() {
  return connectionType.isWifi();
}

export async function isOffline() {
  return await currentlyOffline();
}

async function currentlyOffline() {
  const net = await NetInfo.fetch();
  const offline = net.type == "none";
  store.dispatch({ type: types.OFFLINE, offline });

  return offline;
}

export async function initOffline() {
  if (!connectionType.get()) {
    NetInfo.addEventListener(async net => {
      const offline = net.type == "none";

      connectionType.set(net.type);
      await delay(500);
      store.dispatch({ type: types.OFFLINE, offline });

      if (!offline) {
        const { syncMode } = store.getState().auth;
        await discoversion(true);
        if (syncMode == "always" || (syncMode == "wifi" && isWifi())) {
          await sync();
        }
      }
    });
  }

  const net = await NetInfo.fetch();
  const offline = net.type == "none";
  connectionType.set(net.type);
  store.dispatch({ type: types.OFFLINE, offline });
}

export async function offlineGuard(message, fn) {
  if (!fn && message) {
    fn = message;
    message = null;
  }
  if (await isOffline()) {
    if (typeof message == "function") {
      message = await message();
    }
    if (typeof message == "string") {
      Alert.alert(I18n.t("offline.title"), message);
      return null;
    }
    if (!message) showToast(I18n.t("offline.title"), "danger");
    return message;
  } else return await fn();
}
