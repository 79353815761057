let connectionType = null;

export function isWifi() {
  return connectionType == "wifi";
}

export function isOffline() {
  return connectionType == "none";
}

export function set(type) {
  connectionType = type;
}

export function get() {
  return connectionType;
}
