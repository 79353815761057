import React, { Component, Fragment } from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Alert,
  RefreshControl,
  Platform
} from "react-native";
import PropTypes from "prop-types";
import {
  Content,
  Button,
  Text,
  Card,
  CardItem,
  H3,
  Icon,
  Left,
  Right,
  Body,
  ListItem
} from "native-base";
import moment from "moment";
import HTMLView from "react-native-htmlview";
import I18n from "../../i18n";
import { connect } from "react-redux";
import { getList, removeActivity } from "./actions";
import { isCloseToBottom, isCloseToTop } from "../../utils";
import styles from "../../styles";
import variables from "../../../native-base-theme/variables/platform";
import itemFactory from "./items";
import handler from "./handler";

export default itemFactory(
  "warning,info",
  props => (item, key) => {
    return (
      <TouchableWithoutFeedback key={key} onPress={handler(item, props)}>
        <Card
          style={
            item.type.type == "warning" ? style.warningCard : style.infoCard
          }>
          <CardItem bordered>
            {item.type.type == "warning" ? (
              <Left>
                <Icon
                  name="md-warning"
                  style={{ color: variables.brandSuccess, fontSize: 20 }}
                />
                <Body>
                  <Text style={{ color: variables.brandSuccess, fontSize: 12 }}>
                    {moment(item.createdAt).format("LLLL")}
                  </Text>
                </Body>
              </Left>
            ) : (
              <Left>
                <Icon
                  name="md-time"
                  style={{ color: variables.brandInfo, fontSize: 20 }}
                />
                <Body>
                  <Text style={{ color: variables.brandInfo, fontSize: 12 }}>
                    {moment(item.createdAt).format("LLLL")}
                  </Text>
                </Body>
              </Left>
            )}
          </CardItem>

          <CardItem>
            <Body>
              <HTMLView
                value={`<p>${item.html}</p>`}
                stylesheet={htmlStyle}
                TextComponent={Text}
                textComponentProps={{
                  style: { color: variables.defaultTextColor }
                }}
              />
            </Body>
          </CardItem>
        </Card>
      </TouchableWithoutFeedback>
    );
  },
  () => <Text>{I18n.t("activities.emptyActivities")}</Text>,
  () => I18n.t("activities.tabHeaders.warnings")
);

const htmlStyle = StyleSheet.create({
  span: {
    fontWeight: "bold"
  }
});

const style = {
  infoCard: {},
  warningCard: {
    borderColor: variables.brandSuccess
  }
};
