import React, { Component } from "react";
import { Image, StyleSheet } from "react-native";
import {
  Content,
  View,
  Button,
  Text,
  Input,
  Label,
  Item,
  CheckBox,
  Card,
  CardItem,
  Left,
  Right,
  Body,
  Form,
  H1,
  Header,
  Title,
  Container,
  Icon,
  ListItem
} from "native-base";
import PushNotification from "react-native-push-notification";
import { NavigationActions } from "react-navigation";
import { login } from "../actions/auth";
import { Screen, showToast } from "../utils";
import styles from "../styles";
import variables from "../../native-base-theme/variables/platform";
import I18n from "../i18n";
import store from "../store";
import { sync } from "../sync";
import { setOpenTasksBadge } from "../actions/auth";
import { emailPattern, passwordPattern } from "../variables";
import ValidatedInput from "../components/ValidatedInput";

const localStyles = StyleSheet.create({
  header: {
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: -50,
    position: "absolute"
  }
});

export default Screen(
  class Login extends Component {
    state = {
      email: "",
      password: "",
      checked: true,
      submitted: false,
      ...store.getState().auth
    };
    _working = false;

    componentDidMount() {
      PushNotification.setApplicationIconBadgeNumber(0);
      setOpenTasksBadge(0);
    }

    handleInputChange = (evt, name) => {
      this.setState({ [name]: evt.nativeEvent.text });
    };

    handleCheckboxChange = () => {
      this.setState({ checked: !this.state.checked });
    };

    attemptLogin = async () => {
      if (this._working) return;
      this._working = true;
      this.setState({ submitted: true });
      if (
        this.state.email &&
        this.state.password &&
        this.validEmail() &&
        this.validPassword()
      ) {
        if (
          await login(this.state.email, this.state.password, this.state.checked)
        ) {
          sync();
          this.props.navigation.navigate("Main");
        }
      }
      this._working = false;
    };

    validEmail = () => {
      if (this.state.email && this.state.email !== "") {
        return emailPattern.test(String(this.state.email).toLowerCase());
      } else {
        return false;
      }
    };

    validPassword = () => {
      if (this.state.password && this.state.password !== "") {
        return true;
      } else {
        return false;
      }
    };

    generateEmailError = () => {
      switch (true) {
        case !this.state.email ||
          (this.state.email && this.state.email.length === 0):
          return I18n.t("errorMessages.emailRequired");
          break;
        case !this.state.email ||
          (this.state.email &&
            !emailPattern.test(String(this.state.email).toLowerCase())):
          return I18n.t("errorMessages.emailNotValid");
          break;
        default:
          return "";
      }
    };

    generatePasswordError = () => {
      switch (true) {
        case !this.state.password ||
          (this.state.password && this.state.password.length === 0):
          return I18n.t("errorMessages.passwordRequired");
          break;
        default:
          return "";
      }
    };

    render() {
      return (
        <Content>
          <View style={localStyles.header}>
            {/* <Header noShadow>
            <Left> */}
            <Button
              primary
              transparent
              onPress={() =>
                this.props.navigation.dispatch(NavigationActions.back())
              }>
              <Icon name="arrow-back" />
              <Text>{I18n.t("global.cancel")}</Text>
            </Button>
            {/* </Left>
            <Body />
          </Header> */}
          </View>
          <View container style={{ paddingHorizontal: 20, marginTop: 40 }}>
            <View style={[styles.container, styles.center]}>
              <Image
                source={require("../images/logo_matted.png")}
                style={{ height: 110, resizeMode: "contain", margin: 15 }}
              />
            </View>

            <View style={{ padding: 20 }}>
              <H1>{I18n.t("global.loginTitle")}</H1>
              <View style={{ marginTop: 15 }}>
                <Form>
                  <ValidatedInput
                    validInput={this.validEmail()}
                    handleInputChange={this.handleInputChange}
                    name="email"
                    label={I18n.t("global.email")}
                    errorMessage={this.generateEmailError()}
                    value={this.state.email}
                    showError={this.state.submitted}
                    keyboardType="email-address"
                    returnKeyType="next"
                    onSubmitEditing={this._email}
                  />

                  <ValidatedInput
                    validInput={this.validPassword()}
                    handleInputChange={this.handleInputChange}
                    name="password"
                    label={I18n.t("global.password")}
                    errorMessage={this.generatePasswordError()}
                    value={this.state.password}
                    showError={this.state.submitted}
                    returnKeyType="done"
                    secureTextEntry={true}
                  />
                </Form>
              </View>

              <ListItem noBorder style={{ marginLeft: 0 }}>
                <CheckBox
                  checked={this.state.checked}
                  onPress={this.handleCheckboxChange}
                />
                <Body>
                  <Text>{I18n.t("login.rememberMe")}</Text>
                </Body>
              </ListItem>

              <Button block primary onPress={() => this.attemptLogin()}>
                <Text uppercase={false}>{I18n.t("global.login")}</Text>
              </Button>
              <View style={styles.right}>
                <Text
                  note
                  onPress={() => this.props.navigation.navigate("ForgotPass")}>
                  {I18n.t("global.forgotPass")}
                </Text>
              </View>
            </View>
          </View>
        </Content>
      );
    }
  }
);
