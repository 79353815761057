import React, { Component } from "react";
import { Marker } from "@react-google-maps/api";

class MapViewMarker extends Component {
  render() {
    const { title, coordinate, onPress } = this.props;
    return (
      <>
        <Marker
          icon={
            title == "My Location"
              ? new google.maps.MarkerImage(
                  require("./mylocation.png"),
                  new google.maps.Size(48, 48),
                  new google.maps.Point(0, 0),
                  new google.maps.Point(24, 24)
                )
              : null
          }
          title={title}
          position={{ lat: coordinate.latitude, lng: coordinate.longitude }}
          onClick={e =>
            typeof onPress === "function" && onPress({ nativeEvent: e })
          }
        />
      </>
    );
  }
}

export default MapViewMarker;
