import types from "../actions/types";
import { isOffline } from "../../../offline";
import { ifModifiedSince } from "../../../sync";

export default (state = {}, action) => {
  switch (action.type) {
    case types.SYNC_GET_TASK:
    case types.GET_TASK:
      if (action.task && action.task.id) {
        return {
          ...state,
          [action.task.id]: {
            ...state[action.task.id],
            ...action.task,
            [ifModifiedSince]: new Date()
          }
        };
      }
      return state;
    case types.SYNC_TASK_DELETE:
      if (action.task && action.task.id) {
        return {
          ...state,
          [action.task.id]: null
        };
      }
      return state;
    // case types.GET_DIRECTION:
    //   return {
    //     ...state,
    //     direction: action.direction
    //   };
    case types.LOGOUT:
      return {};
    default:
      return state;
  }
};
