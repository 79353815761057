import types from "../../../actions/types";

export default Object.freeze({
  ...types,
  GET_LIST: "GET_LIST",
  RESET_LIST: "RESET_LIST",
  DASHBOARD_FETCHING: "DASHBOARD_FETCHING",
  ADD_NEW_TASK: "ADD_NEW_TASK",
  UPDATE_TASK_IN_LIST: "UPDATE_TASK_IN_LIST",
  CLAIM_TASK: "CLAIM_TASK",
  DECLINE_TASK: "DECLINE_TASK",
  COMPLETE_TASK: "COMPLETE_TASK",
  UNCLAIM_TASK: "UNCLAIM_TASK",
  CANCEL_TASK: "CANCEL_TASK"
});
