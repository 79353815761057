import React, { Component } from "react";
import {
  Footer,
  FooterTab,
  Button,
  Icon,
  Text,
  Badge,
  View
} from "native-base";
import { Platform } from "react-native";
import { connect } from "react-redux";
import I18n from "../i18n";
import store from "../store";
import types from "../actions/types";

export default connect(state => ({
  openTasks: state.auth.openTasks
}))(
  class TabBar extends Component {
    isActive = tab => {
      return (
        (!this.props.currentScreen && tab == "Activities") ||
        this.props.currentScreen == tab
      );
    };

    navigate = (tab, resolver = () => true) => {
      return async () => {
        if (!this.isActive(tab)) {
          if (await resolver()) {
            this.props.navigation.navigate(tab);
          }
        }
      };
    };

    componentDidMount() {
      if (Platform.OS === "web") {
        store.dispatch({
          type: types.SET_CURRENT_SCREEN,
          currentScreen: this.props.currentScreen
        });
      }
    }

    render() {
      if (Platform.OS === "web") return null;
      return (
        <FooterTab style={{ width: "100%" }}>
          <Button
            vertical
            active={this.isActive("Activities")}
            onPress={this.navigate("Activities")}>
            <Icon name="notifications" />
            <Text uppercase={false} numberOfLines={1}>
              {I18n.t("drawer.activities")}
            </Text>
          </Button>
          <Button
            vertical
            badge={this.props.openTasks > 0}
            active={this.isActive("Tasks")}
            onPress={this.navigate("Tasks")}>
            {this.props.openTasks > 0 && (
              <Badge danger>
                <Text>{this.props.openTasks}</Text>
              </Badge>
            )}
            <Icon name="list-box" />
            <Text uppercase={false} numberOfLines={1}>
              {I18n.t("drawer.tasks")}
            </Text>
          </Button>
          <Button
            vertical
            active={this.isActive("Groups")}
            onPress={this.navigate("Groups")}>
            <Icon name="people" />
            <Text uppercase={false} numberOfLines={1}>
              {I18n.t("drawer.groups")}
            </Text>
          </Button>
        </FooterTab>
      );
    }
  }
);
