import React, { Component } from "react";
import { BackHandler, SafeAreaView, Image } from "react-native";
import { View, Text } from "native-base";
import {
  createAppContainer,
  createSwitchNavigator,
  StackActions,
  NavigationActions
} from "react-navigation";
import { withBrowserHistory } from "react-navigation-browser-history-helpers";
import { createStackNavigator } from "react-navigation-stack";
import { createDrawerNavigator } from "react-navigation-drawer";
import SplashScreen from "react-native-splash-screen";
import qs from "query-string";
import Welcome from "./screens/welcome";
import Login from "./screens/login";
import ForgotPass from "./screens/forgotpass";
import Help from "./screens/help";
import Settings from "./screens/settings";
import About from "./screens/about";
import Offline from "./screens/offline";
import ContactUs from "./screens/contactus";
import Activities from "./screens/activities";
import Tasks from "./screens/dashboard/dashboardTabs";
import Groups from "./screens/groups";
import MyAccount from "./screens/myaccount";
import NewUser from "./screens/newuser";
import ChangePassword from "./screens/changepass";
import TaskDetails from "./screens/taskDetails";
import SingleTask from "./screens/singleTask";
import DrawerItems from "./components/DrawerItems";
import { NavigationChange, transitionConfig } from "./utils";
import store from "./store";
import types from "./actions/types";
import { initOffline, isOffline } from "./offline";
import { sync } from "./sync";
import { getUser, updateNotification, getLanguages } from "./actions/auth";
import I18n, { setLocale } from "./i18n";
import styles from "./styles";
import { setToken } from "./axios";
import { groupDetails } from "./screens/groups/actions";
import { getTask } from "./screens/taskDetails/actions";

const Drawer = createDrawerNavigator(
  {
    Activities,
    Tasks,
    Groups
  },
  {
    contentComponent: DrawerItems
  }
);
Drawer.navigationOptions = {
  header: null
};

const OfflineStack = createStackNavigator(
  {
    Offline
  },
  {
    initialRouteName: "Offline",
    headerMode: "none",
    transitionConfig
  }
);

const WelcomeStack = createStackNavigator(
  {
    Welcome,
    Login,
    SignUp: MyAccount,
    NewUser,
    ForgotPass,
    Help,
    Settings,
    About,
    ContactUs
  },
  {
    initialRouteName: "Welcome",
    headerMode: "none",
    transitionConfig
  }
);

const AppStack = createStackNavigator(
  {
    Main: Drawer,
    MyAccount,
    ChangePassword,
    Help,
    Settings,
    About,
    ContactUs,
    TaskDetails,
    EditTask: SingleTask,
    AddTask: SingleTask
  },
  {
    initialRouteName: "Main",
    headerMode: "none",
    transitionConfig
  }
);

class AuthLoading extends Component {
  componentDidMount() {
    this._bootstrapAsync();

    store.dispatch({
      type: types.ROOT_NAVIGATION,
      rootNavigation: this.props.navigation
    });
  }

  _bootstrapAsync = async () => {
    const { auth, groups } = store.getState();

    await initOffline();
    const offline = await isOffline();

    if (auth.token) {
      if (auth.currentUser && auth.currentUser.language) {
        setLocale(auth.currentUser.language);
      }

      if (!offline) {
        await getUser(auth.token);
      } else {
        setToken(auth.token);
      }

      if (auth.currentUser) {
        if (!offline) {
          await updateNotification();
          sync();
        }
        if (typeof location !== "undefined" && location.pathname) {
          const path = location.pathname
            .split("/")
            .slice(1)
            .filter(Boolean);

          if (path.length > 0) {
            const params = qs.parse(location.search);
            let action;
            path
              .slice()
              .reverse()
              .forEach(routeName => {
                const prevAction = action;
                action = NavigationActions.navigate({
                  routeName,
                  params: prevAction ? {} : params,
                  action: prevAction
                });
              });
            this.props.navigation.dispatch(action);
          } else {
            this.props.navigation.navigate("App");
          }
        } else {
          this.props.navigation.navigate("App");
        }
      } else {
        await getLanguages();
        this.props.navigation.navigate("Welcome");
      }
    } else if (offline) {
      this.props.navigation.navigate("Offline");
    } else {
      await getLanguages();
      this.props.navigation.navigate("Welcome");
    }

    SplashScreen.hide();
  };

  render() {
    return (
      <SafeAreaView style={{ height: "100%" }}>
        <View style={{ height: "100%", alignItems: "center" }}>
          <Image
            source={require("./images/bg_horizontal.jpg")}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              alignSelf: "flex-start"
            }}
            resizeMode="cover"
          />
          <Image
            source={require("./images/logo_matted.png")}
            style={{
              width: "100%",
              height: 110,
              resizeMode: "contain",
              margin: 30,
              marginTop: 50
            }}
          />
        </View>
      </SafeAreaView>
    );
  }
}

export default withBrowserHistory(
  createAppContainer(
    createSwitchNavigator(
      {
        AuthLoading,
        App: AppStack,
        Welcome: WelcomeStack,
        Offline: OfflineStack
      },
      {
        initialRouteName: "AuthLoading"
      }
    )
  ),
  ({ path, params }) =>
    path.indexOf("Welcome") == 0 ||
    path.indexOf("Offline") == 0 ||
    (path.indexOf("App") == 0 && store.getState().auth.currentUser)
);
