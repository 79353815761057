import types from "../../../actions/types";

export default Object.freeze({
  ...types,
  EDIT_TASK: "EDIT_TASK",
  UPDATE_CATEGORIES: "UPDATE_CATEGORIES",
  GET_CATEGORIES: "GET_CATEGORIES",
  START_NEW_TASK: "START_NEW_TASK",
  SWITCH_MODE: "SWITCH_MODE",
  GET_CONTACTS: "GET_CONTACTS",
  SET_TASK: "SET_TASK",
  GET_LOCATION_RESULTS: "GET_LOCATION_RESULTS",
  REMOVE_SUGGESTIONS: "REMOVE_SUGGESTIONS"
});
