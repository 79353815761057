import React, { Component, Fragment } from "react";
import {
  Content,
  Card,
  CardItem,
  List,
  ListItem,
  Icon,
  Text,
  Button,
  Left,
  Body,
  Right,
  H2,
  H3
} from "native-base";
import { StyleSheet, View, Platform } from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import { connect } from "react-redux";
import { getGroups, createGroup, groupDetails } from "./actions";
import { Screen, HeaderWithDrawerMenu } from "../../utils";
import TabBar from "../../components/TabBar";
import I18n from "../../i18n";
import styles from "../../styles";
import variable from "../../../native-base-theme/variables/platform";

export default Screen(
  connect(state => ({
    joined: state.groups.joined,
    managed: state.groups.managed,
    offline: state.offline
  }))(
    class Groups extends Component {
      state = {
        isRefreshing: false
      };
      _working = false;

      async componentDidMount() {
        const { joined, managed } = this.props;
        if (joined && joined.length == 0 && managed && managed.length == 0) {
          this._working = true;
          await getGroups();
          this._working = false;
        }
      }

      refreshList = async () => {
        if (this._working) return;
        this._working = true;
        this.setState({ isRefreshing: !this.state.isRefreshing });
        await getGroups();
        this.setState({ isRefreshing: !this.state.isRefreshing });
        this._working = false;
      };

      goDetails = (mode, group) => async () => {
        if (this._working) return;
        this._working = true;
        if (await groupDetails(mode, group)) {
          this.props.navigation.navigate({
            routeName: "Details",
            params: { id: group.id }
          });
        }
        this._working = false;
      };

      createGroup = () => {
        if (!this._working) {
          createGroup();
          this.props.navigation.navigate("Profile");
        }
      };

      render() {
        const { joined, managed, offline } = this.props;
        return (
          <Content
            container
            // contentContainerStyle={{
            //   paddingBottom:
            //     variable.footerHeight - (variable.isIphoneX ? 34 : 14)
            // }}
            refreshControl={
              !offline ? (
                <RefreshControl
                  refreshing={this.state.isRefreshing}
                  onRefresh={this.refreshList}
                  enabled={!offline}
                />
              ) : null
            }>
            <View>
              {(joined && joined.length > 0) || !offline ? (
                <View
                  style={{
                    backgroundColor: "#FFFFFF",
                    paddingVertical: 15,
                    marginTop: 10
                  }}>
                  <ListItem itemHeader first icon>
                    <Left>
                      <Button
                        style={{ backgroundColor: variable.brandSuccess }}>
                        <Icon active name="star" />
                      </Button>
                    </Left>
                    <Body>
                      <H3
                        numberOfLines={1}
                        style={{ color: variable.brandInfo }}>
                        {I18n.t("groups.groupsJoined")}
                      </H3>
                    </Body>
                  </ListItem>

                  {joined && joined.length > 0 ? (
                    <Fragment>
                      {joined.map((group, index) => (
                        <ListItem
                          key={`${group.id}_${index}`}
                          onPress={this.goDetails("volunteer", group)}>
                          <Body>
                            <Text numberOfLines={1}>{group.name}</Text>
                          </Body>
                          <Right>
                            <Icon active name="arrow-forward" />
                          </Right>
                        </ListItem>
                      ))}
                    </Fragment>
                  ) : (
                    undefined
                  )}

                  {!offline ? (
                    <Button
                      transparent
                      info
                      onPress={() =>
                        !this._working &&
                        this.props.navigation.navigate("Connect")
                      }>
                      <Text
                        uppercase={false}
                        style={{
                          color: variable.brandInfo,
                          fontWeight: "bold",
                          fontSize: 14
                        }}>
                        {joined && joined.length > 0
                          ? I18n.t("groups.joinAnotherGroup")
                          : I18n.t("groups.joinGroup")}
                      </Text>
                    </Button>
                  ) : null}
                </View>
              ) : null}
              <View
                style={{
                  backgroundColor: "#FFFFFF",
                  paddingVertical: 15,
                  marginTop: 10
                }}>
                <ListItem itemHeader first icon>
                  <Left>
                    <Button style={{ backgroundColor: variable.brandSuccess }}>
                      <Icon active name="md-clipboard" />
                    </Button>
                  </Left>
                  <Body>
                    <H3 numberOfLines={1} style={{ color: variable.brandInfo }}>
                      {I18n.t("groups.groupsManaged")}
                    </H3>
                  </Body>
                </ListItem>

                {managed && managed.length > 0 ? (
                  <Fragment>
                    {managed.map((group, index) => (
                      <ListItem
                        key={`${group.id}_${index}`}
                        onPress={this.goDetails("manage", group)}>
                        <Body>
                          <Text numberOfLines={1}>{group.name}</Text>
                        </Body>
                        <Right>
                          <Icon active name="arrow-forward" />
                        </Right>
                      </ListItem>
                    ))}
                  </Fragment>
                ) : (
                  undefined
                )}

                {!offline ? (
                  <Button transparent info onPress={this.createGroup}>
                    <Text
                      uppercase={false}
                      style={{
                        color: variable.brandInfo,
                        fontWeight: "bold",
                        fontSize: 14
                      }}>
                      {I18n.t("groups.createGroup")}
                    </Text>
                  </Button>
                ) : null}
              </View>
            </View>
          </Content>
        );
      }
    }
  ),
  props => HeaderWithDrawerMenu(I18n.t("groups.title"), props),
  props => <TabBar {...props} currentScreen={"Groups"} />
);

const style = StyleSheet.create({
  padding: {
    padding: 15
  },
  icon: {
    fontSize: 16,
    marginHorizontal: 10
  }
});
