import { combineReducers } from "redux";
import auth from "./auth";
import error from "./error";
import progress from "./progress";
import offline from "../offline";
import groups from "../screens/groups/reducers";
import task from "../screens/singleTask/reducers";
import activities from "../screens/activities/reducers";
import dashboard from "../screens/dashboard/reducers";
import taskDetails from "../screens/taskDetails/reducers";
import misc from "./misc";

export default combineReducers({
  auth,
  error,
  progress,
  offline,
  groups,
  task,
  activities,
  dashboard,
  taskDetails,
  misc
});
