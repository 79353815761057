import React, { Component, PureComponent } from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Alert,
  RefreshControl,
  Platform,
  FlatList
} from "react-native";
import PropTypes from "prop-types";
import {
  Content,
  Button,
  Text,
  Card,
  CardItem,
  H3,
  Icon,
  Left,
  Right,
  Body,
  Badge
} from "native-base";
import Progress from "../../components/Progress";
import I18n from "../../i18n";
import styles from "../../styles";
import { isCloseToBottom, isCloseToTop } from "../../utils";
import { connect } from "react-redux";
import { getList } from "./actions";
import itemFactory from "./items";
import variables from "../../../native-base-theme/variables/platform";

export default itemFactory(
  "Claimed",
  class ClaimedTask extends PureComponent {
    render() {
      const { task, key, openTask, currentUser } = this.props;
      return (
        <TouchableOpacity onPress={() => openTask(task.id)} key={key}>
          <Card style={{ marginTop: 10 }}>
            <CardItem>
              <View>
                <View>
                  <View style={{ flexDirection: "row" }}>
                    <Badge info style={{ margin: 3 }}>
                      <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                        {I18n.t("dashboard.tagClaimed")}
                      </Text>
                    </Badge>
                    {task.canEdit ? (
                      <Badge
                        primary
                        style={{
                          margin: 3,
                          backgroundColor: "transparent",
                          borderColor: variables.brandPrimary,
                          borderWidth: 1
                        }}>
                        <Text
                          style={{
                            fontSize: 12,
                            fontWeight: "bold",
                            color: variables.brandPrimary
                          }}>
                          {I18n.t("dashboard.tagManaging")}
                        </Text>
                      </Badge>
                    ) : (
                      undefined
                    )}
                  </View>
                  <H3 style={{ fontWeight: "bold" }}>{task.name}</H3>
                  <View style={{ paddingVertical: 5 }}>
                    <Text style={{ fontSize: 14, fontWeight: "bold" }}>
                      <Icon name="people" style={{ fontSize: 14 }} />{" "}
                      {task.group.name}
                    </Text>
                  </View>
                </View>
              </View>
            </CardItem>

            <CardItem>
              <Body>
                {currentUser &&
                task.claimedUser &&
                task.claimedUser.id == currentUser.id ? (
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: variables.brandInfo
                    }}>
                    <Icon
                      name="ios-star"
                      style={{
                        fontSize: 14,
                        fontWeight: "100",
                        color: variables.brandInfo
                      }}
                    />{" "}
                    {I18n.t("dashboard.claimedByYou")}
                  </Text>
                ) : (
                  <Text style={{ fontSize: 14, fontWeight: "200" }}>
                    <Icon
                      name="ios-contact"
                      style={{ fontSize: 14, fontWeight: "100" }}
                    />{" "}
                    {I18n.t("dashboard.claimedBy")}{" "}
                    <Text style={{ fontSize: 14, fontWeight: "bold" }}>
                      {task.volunteerneeded > 1
                        ? I18n.t("dashboard.claimedMultiple")
                        : (
                            task.claimedUser || {
                              name: I18n.t("dashboard.claimedUnknown")
                            }
                          ).name}
                    </Text>
                  </Text>
                )}

                {task.canEdit ? (
                  <View style={{ paddingVertical: 5 }}>
                    <Text style={{ fontSize: 14, fontWeight: "200" }}>
                      <Icon
                        name="md-clipboard"
                        style={{ fontSize: 14, fontWeight: "100" }}
                      />{" "}
                      {I18n.t("dashboard.managedByYou")}
                    </Text>
                  </View>
                ) : (
                  undefined
                )}
              </Body>
            </CardItem>

            <View style={[styles.right, { padding: 10, paddingTop: 0 }]}>
              {task.canEdit ? (
                <Text note>{I18n.t("dashboard.manage")}</Text>
              ) : (
                <Text note>{I18n.t("dashboard.view")}</Text>
              )}
            </View>
          </Card>
        </TouchableOpacity>
      );
    }
  },
  () => (
    <View style={{ padding: 30 }}>
      <Text>{I18n.t("dashboard.noClaimedTask")}</Text>
    </View>
  ),
  null,
  () => I18n.t("tasks.tabHeaders.currentClaimed")
);
