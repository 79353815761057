import types from "../actions/types";
import store from "../../../store";

const initialState = {
  volunteers: 0,
  approved: 0,
  pending: 0,
  rejected: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CATEGORY_VOLUNTEERS:
      return { ...action.category };
    case types.LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};
