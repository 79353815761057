import React, { Component } from "react";
import { Text, Content, Form, Item, Label, Button } from "native-base";
import { View } from "react-native";
import { connect } from "react-redux";
import { Screen } from "../utils";
import I18n from "../i18n";
import { forgotPassword } from "../actions/auth";
import ValidatedInput from "../components/ValidatedInput";
import { emailPattern } from "../variables";

export default Screen(
  connect(state => ({
    offline: state.offline
  }))(
    class ForgotPass extends Component {
      state = {
        email: "",
        submitted: false
      };
      _working = false;

      handleSubmit = () => {
        this.setState(
          {
            submitted: true
          },
          async () => {
            if (this.validEmail()) {
              if (this._working) return;
              this._working = true;
              await forgotPassword(this.state.email);
              this.props.navigation.navigate("Welcome");
            }
            this._working = false;
          }
        );
      };

      handleInputChange = (evt, name) => {
        this.setState({ [name]: evt.nativeEvent.text });
      };

      validEmail = () => {
        if (this.state.email !== "") {
          return emailPattern.test(String(this.state.email).toLowerCase());
        } else {
          return false;
        }
      };

      generateEmailError = () => {
        switch (true) {
          case this.state.email.length === 0:
            return I18n.t("errorMessages.emailRequired");
          case !emailPattern.test(String(this.state.email).toLowerCase()):
            return I18n.t("errorMessages.emailNotValid");
          default:
            return "";
        }
      };

      render() {
        return (
          <Content container>
            <View style={{ padding: 30 }}>
              <Form>
                <ValidatedInput
                  validInput={this.validEmail()}
                  handleInputChange={this.handleInputChange}
                  label={I18n.t("global.email")}
                  errorMessage={this.generateEmailError()}
                  name="email"
                  keyboardType="email-address"
                  disabled={this.props.offline}
                  noBorder={this.props.offline}
                  value={this.state.email}
                  showError={this.state.submitted}
                  getRef={ref => (this._email = ref)}
                  returnKeyType="next"
                  autoCapitalize="none"
                  autoCorrect={true}
                  onSubmitEditing={() => this.handleSubmit()}
                />
              </Form>

              <Button
                block
                primary
                disabled={!this.validEmail()}
                onPress={() => this.handleSubmit()}>
                <Text uppercase={false}>{I18n.t("forgotPass.emailMe")}</Text>
              </Button>
            </View>
          </Content>
        );
      }
    }
  ),
  I18n.t("global.forgotPass")
);
