import React, { Component } from "react";
import {
  Content,
  Text,
  H3,
  Card,
  CardItem,
  Left,
  Right,
  Body,
  Icon,
  Button,
  View
} from "native-base";
import { Platform } from "react-native";
import { connect } from "react-redux";
import { leadDetails } from "../actions";
import { sortString } from "../../../utils";
import I18n from "../../../i18n";
import styles from "../../../styles";
import { isOffline } from "../../../offline";
import style from "./style";
import variables from "../../../../native-base-theme/variables/platform";

export default connect(state => ({
  leads: [...(state.groups.group.leads || [])]
}))(
  class Lead extends Component {
    _working = false;
    leadDetails = lead => async () => {
      if (this._working) return;
      this._working = true;
      const offline = await isOffline();

      if (!offline) {
        await leadDetails(lead);
        this.props.navigation.navigate("VolunteerDetails");
      }
      this._working = false;
    };

    render() {
      const { leads } = this.props;

      return (
        <Content>
          <View style={style.padding}>
            {leads && leads.length > 0 ? (
              sortString(leads, "name").map((lead, index) => (
                <Card key={`${lead.name}_${lead.phone}_${lead.email}_${index}`}>
                  <CardItem bordered>
                    <Left>
                      <Icon
                        name="md-clipboard"
                        style={{ color: variables.brandInfo }}
                      />
                      <Body>
                        <Text
                          style={{
                            fontWeight: "bold",
                            color: variables.brandInfo
                          }}>
                          {lead.name}
                        </Text>
                      </Body>
                    </Left>
                    <Right>
                      <Button
                        info
                        small
                        bordered
                        onPress={this.leadDetails(lead)}>
                        <Text uppercase={false}>{I18n.t("global.view")}</Text>
                      </Button>
                    </Right>
                  </CardItem>
                  <CardItem>
                    <Left>
                      <Icon name="call" style={{ fontSize: 14 }} />
                      <Body>
                        <Text style={{ fontSize: 14 }}>
                          {lead.phoneformatted || lead.phone}
                        </Text>
                      </Body>
                    </Left>
                  </CardItem>
                  <CardItem>
                    <Left>
                      <Icon name="mail" style={{ fontSize: 14 }} />
                      <Body>
                        <Text style={{ fontSize: 14 }}>{lead.email}</Text>
                      </Body>
                    </Left>
                  </CardItem>
                </Card>
              ))
            ) : (
              <View style={style.section}>
                <Text>{I18n.t("global.noItemFoundForList")}</Text>
              </View>
            )}
          </View>
        </Content>
      );
    }
  }
);
