import React, { Component } from "react";
import {
  Text,
  Content,
  Left,
  Radio,
  List,
  Card,
  CardItem,
  Body,
  Item,
  CheckBox,
  Form,
  Label,
  Input,
  Right,
  Button
} from "native-base";
import { View, TouchableOpacity, Alert, Platform } from "react-native";
import { connect } from "react-redux";
import ValidatedInput from "../components/ValidatedInput";
import { emailPattern } from "../variables";
import { sendContactUs } from "../actions/auth";
import { Screen, HeaderWithBack, showToast } from "../utils";
import I18n from "../i18n";
import styles from "../styles";
import store from "../store";

export default Screen(
  connect(state => ({
    currentUser: state.auth.currentUser
  }))(
    class extends Component {
      state = {
        type: "feedback",
        ...(this.props.currentUser
          ? this.props.currentUser
          : {
              email: "",
              firstname: "",
              lastname: ""
            }),
        message: "",
        submitted: false
      };
      _working = false;

      validEmail = () => {
        if (this.state.email !== "") {
          return emailPattern.test(String(this.state.email).toLowerCase());
        } else {
          return false;
        }
      };

      generateEmailError = () => {
        switch (true) {
          case !this.state.email:
            return I18n.t("errorMessages.emailRequired");
            break;
          case !emailPattern.test(String(this.state.email).toLowerCase()):
            return I18n.t("errorMessages.emailNotValid");
            break;
          default:
            return "";
        }
      };

      handleCheckboxChange(value) {
        this.setState({ type: value });
      }

      handleInputChange = (evt, name) => {
        this.setState({ [name]: evt.nativeEvent.text });
      };

      handleSubmit = async () => {
        if (this._working) return;
        this._working = true;
        const { firstname, lastname, message, email } = this.state;
        this.setState({ submitted: true });
        if (
          this.validEmail() &&
          firstname.length > 0 &&
          lastname.length > 0 &&
          message.length > 0
        ) {
          await sendContactUs(this.state);
          if (store.getState().error) {
            showToast(I18n.t("contactUs.error"), "danger");
          } else {
            showToast(I18n.t("contactUs.sent"));
            this.props.navigation.goBack();
          }
        }
        this._working = false;
      };

      render() {
        return (
          <Content container>
            <View style={{ padding: 30 }}>
              <Card>
                <CardItem>
                  <Radio
                    onPress={() => this.handleCheckboxChange("feedback")}
                    selected={this.state.type === "feedback" ? true : false}
                    rounded
                  />
                  <Left style={{ flex: 1 }}>
                    <Text>{I18n.t("contactUs.bellsfeedback")}</Text>
                  </Left>
                </CardItem>
                <CardItem>
                  <Radio
                    onPress={() => this.handleCheckboxChange("issue")}
                    selected={this.state.type === "issue" ? true : false}
                    rounded
                  />
                  <Left style={{ flex: 1 }}>
                    <Text>{I18n.t("contactUs.bellsIssue")}</Text>
                  </Left>
                </CardItem>
              </Card>

              <Form>
                <ValidatedInput
                  validInput={
                    !!this.state.firstname && this.state.firstname.length > 0
                  }
                  handleInputChange={this.handleInputChange}
                  label={I18n.t("global.firstName")}
                  errorMessage={I18n.t("errorMessages.firstNameRequired")}
                  name="firstname"
                  value={this.state.firstname}
                  showError={this.state.submitted}
                  getRef={ref => (this._firstName = ref)}
                  returnKeyType="next"
                  onSubmitEditing={() => this._lastName._root.focus()}
                />
                <ValidatedInput
                  validInput={
                    !!this.state.lastname && this.state.lastname.length > 0
                  }
                  handleInputChange={this.handleInputChange}
                  label={I18n.t("global.lastName")}
                  errorMessage={I18n.t("errorMessages.lastNameRequired")}
                  name="lastname"
                  value={this.state.lastname}
                  showError={this.state.submitted}
                  getRef={ref => (this._lastName = ref)}
                  returnKeyType="next"
                  onSubmitEditing={() => this._email._root.focus()}
                />
                <ValidatedInput
                  validInput={this.validEmail()}
                  handleInputChange={this.handleInputChange}
                  label={I18n.t("global.email")}
                  errorMessage={this.generateEmailError()}
                  name="email"
                  keyboardType="email-address"
                  value={this.state.email}
                  showError={this.state.submitted}
                  getRef={ref => (this._email = ref)}
                  returnKeyType="next"
                  onSubmitEditing={() => this._message._root.focus()}
                />
                <ValidatedInput
                  itemStyle={{
                    height: Platform.OS === "ios" ? 140 : undefined
                  }}
                  style={{ height: Platform.OS === "ios" ? 100 : undefined }}
                  multiline={true}
                  numberOfLines={3}
                  validInput={
                    !!this.state.message && this.state.message.length > 0
                  }
                  handleInputChange={this.handleInputChange}
                  label={I18n.t("contactUs.message")}
                  errorMessage={I18n.t("errorMessages.messageRequired")}
                  name="message"
                  value={this.state.message}
                  showError={this.state.submitted}
                  getRef={ref => (this._message = ref)}
                  returnKeyType="done"
                />
              </Form>

              <View style={styles.right}>
                <Button
                  small
                  primary
                  onPress={() => this.handleSubmit()}
                  style={{ marginHorizontal: 5 }}>
                  <Text uppercase={false}>{I18n.t("global.send")}</Text>
                </Button>
                <Button
                  small
                  primary
                  onPress={() => this.props.navigation.goBack()}>
                  <Text uppercase={false}>{I18n.t("global.cancel")}</Text>
                </Button>
              </View>
            </View>
          </Content>
        );
      }
    }
  ),
  _ => I18n.t("contactUs.title")
);
