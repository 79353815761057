import React, { Component } from "react";
import {
  Header,
  Left,
  Button,
  Icon,
  Body,
  Title,
  Subtitle,
  Right
} from "native-base";
import { NavigationActions } from "react-navigation";
import store from "../store";
import I18n from "../i18n";

class ConnectHeader extends Component {
  render() {
    const { mode, group, hasTabs, navigation, title, subtitle } = this.props;

    return (
      <Header hasTabs={typeof hasTabs == "undefined" ? true : hasTabs}>
        <Left>
          <Button
            transparent
            onPress={() => navigation.dispatch(NavigationActions.back())}
          >
            <Icon name="arrow-back" style={{ color: "white" }} />
          </Button>
        </Left>
        <Body>
          <Title>{title}</Title>
          {!!subtitle && (
            <Subtitle numberOfLines={1}>{subtitle || group.name}</Subtitle>
          )}
        </Body>
        <Right />
      </Header>
    );
  }
}

export default props => <ConnectHeader {...props} />;
