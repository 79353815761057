import * as React from "react";
import { StyleSheet, View, Animated, Easing } from "react-native";

export default class TabBarIndicator extends React.Component {
  state = {
    _containerWidth: 0,
    width: 0,
    left: new Animated.Value(0)
  };

  onContainerLayout = e => {
    this.setState(
      {
        _containerWidth: e.nativeEvent.layout.width,
        width: e.nativeEvent.layout.width / this.props.count
      },
      () => {
        Animated.timing(this.state.left, {
          toValue: this.props.index * this.state.width,
          easing: Easing.ease,
          duration: 200
        }).start();
      }
    );
  };

  componentDidUpdate() {
    if (this.state.width) {
      Animated.timing(this.state.left, {
        toValue: this.props.index * this.state.width,
        easing: Easing.ease,
        duration: 200
      }).start();
    }
  }

  render() {
    const { style } = this.props;
    const { left, width } = this.state;

    return (
      <View style={styles.indicatorContainer} onLayout={this.onContainerLayout}>
        <Animated.View
          style={[
            styles.indicator,
            {
              left,
              width
            },
            style
          ]}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  indicatorContainer: {
    position: "absolute",
    width: "100%",
    left: 0,
    bottom: 0,
    right: 0
  },
  indicator: {
    backgroundColor: "#ffeb3b",
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
    height: 2
  }
});
