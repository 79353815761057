import types from "../actions/types";
import store from "../../../store";

const initialState = {
  profile: {
    name: "",
    address1: "",
    address2: "",
    city: "",
    country: "United States",
    postalcode: "",
    state: "",
    approvevolunteer: null
  },
  categories: [],
  contacts: [],
  volunteers: [],
  leads: [],
  name: "",
  role: "admin"
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_GROUP:
      return {
        ...initialState,
        ...action.group
      };
    case types.SYNC_UPDATE_GROUP:
      if (action.group.id != state.id) {
        return state;
      }
    case types.UPDATE_GROUP:
      return {
        ...initialState,
        ...state,
        ...action.group
      };
    case types.SYNC_GROUP_DETAILS:
      if (action.group.id != state.id) {
        return state;
      }
    case types.GROUP_DETAILS:
      if (action.group) {
        return {
          ...initialState,
          ...state,
          ...action.group
        };
      }
      return state;
    case types.LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};
