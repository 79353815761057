import types from "../actions/types";

const initialState = {
  languages: [],
  currentScreen: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LANGUAGES:
      return { ...state, languages: action.languages || [] };
    case types.SET_CURRENT_SCREEN:
      return { ...state, currentScreen: action.currentScreen };
    default:
      return state;
  }
};
