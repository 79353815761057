import React, { Component } from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Alert,
  RefreshControl,
  Platform,
  FlatList,
  Text as RNText
} from "react-native";
import { SafeAreaView } from "react-navigation";
import PropTypes from "prop-types";
import {
  Content,
  Button,
  Text,
  Card,
  CardItem,
  H3,
  Icon,
  Left,
  Right
} from "native-base";
import moment from "moment";
import Progress from "../../components/Progress";
import { lazy } from "../../utils";
import I18n from "../../i18n";
import styles from "../../styles";
import variables from "../../../native-base-theme/variables/platform";
import { connect } from "react-redux";
import { getList, resetList, loadMore } from "./actions";
import { getTask } from "../taskDetails/actions";
import { startNewTask } from "../singleTask/actions";

export default function Items(
  type,
  ItemComponent,
  EmptyComponent,
  renderHeader,
  label
) {
  return lazy(
    connect(state => ({
      items: state.dashboard[type].list,
      canLoadMore: state.dashboard[type].loadMore,
      managed: state.groups.managed,
      currentUser: state.auth.currentUser,
      offline: state.offline,
      progress: state.progress.active
    }))(
      class extends Component {
        static title = label;

        state = {
          offline: this.props.offline,
          isRefreshing: false
        };

        async componentDidMount() {
          this._mounted = true;
          await this.refreshList(true);
        }

        componentWillUnmount() {
          this._mounted = false;
        }

        refreshList = async force => {
          if (!this.props.items || force) {
            await getList(0, type);
          }
        };

        loadMore = async () => {
          await loadMore(type);
        };

        refreshActiveTab = async () => {
          if (this._mounted) {
            this.setState({
              isRefreshing: true
            });
          }
          await this.refreshList(true);
          if (this._mounted) {
            this.setState({
              isRefreshing: false
            });
          }
        };

        static getDerivedStateFromProps(props, state) {
          if (!props.offline && state.offline != props.offline) {
            return {
              offline: props.offline
            };
          }
          return null;
        }

        async componentDidUpdate(prevProps) {
          if (!this.props.offline && prevProps.offline != this.props.offline) {
            await this.refreshList(true);
          }
        }

        openTask = async id => {
          if (await getTask(id)) {
            this.props.navigation.navigate("TaskDetails", { params: { id } });
          }
        };

        addTask = async () => {
          if (await startNewTask()) {
            this.props.navigation.navigate("AddTask");
          }
        };

        render() {
          return (
            <SafeAreaView
              style={{ height: "100%" }}
              // forceInset={{ horizontal: "always", top: "always" }}
            >
              <FlatList
                // removeClippedSubviews
                disableVirtualization
                data={this.props.items}
                keyExtractor={(item, key) => `${item.id}_${key}`}
                renderItem={({ item, key }) => (
                  <ItemComponent
                    task={item}
                    key={key}
                    openTask={this.openTask}
                    addTask={this.addTask}
                    currentUser={this.props.currentUser}
                  />
                )}
                ListHeaderComponent={
                  renderHeader &&
                  renderHeader({ ...this.props, addTask: this.addTask })
                }
                ListEmptyComponent={
                  !this.props.progress &&
                  this.props.items &&
                  this.props.items.length == 0 &&
                  EmptyComponent(this.props)
                }
                ListFooterComponent={
                  (this.props.canLoadMore ||
                    !this.props.items ||
                    (this.props.items && this.props.items.length == 0)) &&
                  !this.state.isRefreshing && <Progress />
                }
                onRefresh={this.refreshActiveTab}
                refreshing={this.state.isRefreshing}
                onEndReachedThreshold={0.5}
                onEndReached={() => {
                  if (
                    this.props.items &&
                    this.props.items.length > 0 &&
                    !this.props.progress &&
                    this.props.canLoadMore
                  ) {
                    this.loadMore();
                  }
                }}
              />
            </SafeAreaView>
          );
        }
      }
    )
  );
}

const style = StyleSheet.create({
  containerStyle: {
    paddingHorizontal: 10
  }
});
