import React, { Component, Fragment } from "react";
import {
  Text,
  Content,
  Form,
  Label,
  Input,
  Item,
  Card,
  CardItem,
  Body,
  Left,
  Right,
  CheckBox,
  H3,
  Button,
  Picker,
  Icon,
  List,
  ListItem
} from "native-base";
import { View, Linking, Platform } from "react-native";
import { connect } from "react-redux";
import ConnectHeader from "../components/ConnectHeader";
import ValidatedInput from "../components/ValidatedInput";
import { register, updateUser, getLanguages } from "../actions/auth";
import { Screen, HeaderWithBack, showToast } from "../utils";
import styles from "../styles";
import I18n from "../i18n";
import { lookup } from "../twilio";
import { passwordPattern, emailPattern } from "../variables";
import CustomPickerHeader from "../components/CustomPickerHeader";
import { setLocale } from "../i18n";
import { resetList, getList } from "./activities/actions";
import { NavigationActions, StackActions } from "react-navigation";
import variable from "../../native-base-theme/variables/platform";

const Header = connect(state => ({
  currentUser: state.auth.currentUser,
  offline: state.offline
}))(props => (
  <ConnectHeader
    {...props}
    title={I18n.t(
      props.currentUser && props.currentUser.id
        ? props.offline
          ? "screenHeader.myAccount"
          : "screenHeader.editAccount"
        : "screenHeader.createAccount"
    )}
  />
));

function getLocale() {
  let locale = I18n.locale;
  if (locale.startsWith("es-")) {
    return "es-ES";
  } else if (locale.startsWith("pt-")) {
    return "pt-BR";
  } else if (locale.startsWith("pl-")) {
    return "pl-PL";
  } else if (!locale.startsWith("en-")) {
    return "en-US";
  } else return locale;
}

export default Screen(
  connect(state => ({
    languages: state.misc.languages,
    currentUser: state.auth.currentUser,
    offline: state.offline
  }))(
    class MyAccount extends Component {
      state = {
        username: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        phoneformatted: "",
        phoneraw: "",
        password: "",
        passwordConf: "",
        sms: true,
        push: Platform.OS != "web",
        keeplogin: true,
        ...this.props.currentUser,
        language:
          (this.props.currentUser && this.props.currentUser.language) ||
          getLocale(),
        submitted: false,
        twilio: false,
        dirty: false
      };
      _working = false;

      async componentDidMount() {
        const { languages } = this.props;
        if (!languages || (languages && languages.length == 0)) {
          await getLanguages();
        }
      }

      handleInputChange = (evt, name) => {
        let newState = { [name]: evt.nativeEvent.text, dirty: true };
        if (name == "phone") {
          newState = {
            ...newState,
            phoneformatted: "",
            phoneraw: "",
            twilio: false
          };
        }
        this.setState(newState);
      };

      handleCheckboxChange = field => {
        this.setState({ [field]: !this.state[field], dirty: true });
      };

      generateEmailError = () => {
        switch (true) {
          case this.state.email.length === 0:
            return I18n.t("errorMessages.emailRequired");
          case !emailPattern.test(String(this.state.email).toLowerCase()):
            return I18n.t("errorMessages.emailNotValid");
          default:
            return "";
        }
      };

      generatePasswordError = () => {
        switch (true) {
          case this.state.password.length === 0:
            return I18n.t("errorMessages.passwordRequired");
          case !passwordPattern.test(String(this.state.password)):
            return I18n.t("errorMessages.passwordRule");
          default:
            return "";
        }
      };

      generatePhoneError = () => {
        switch (true) {
          case this.state.phone.length == 0:
            return I18n.t("errorMessages.phoneRequired");
          case this.state.twilio && this.state.phoneformatted == 0:
            return I18n.t("errorMessages.phoneInvalid");
          default:
            return "";
        }
      };

      validEmail = () => {
        if (this.state.email !== "") {
          return emailPattern.test(String(this.state.email).toLowerCase());
        } else {
          return false;
        }
      };

      validPassword = () => {
        if (this.state.password !== "") {
          return passwordPattern.test(String(this.state.password));
        } else {
          return false;
        }
      };

      validateAllInputs = () => {
        return {
          firstName: this.state.firstname.length > 0,
          lastName: this.state.lastname.length > 0,
          phoneNumber: this.state.phone.length > 0,
          twilio:
            this.state.phoneformatted.length > 0 &&
            this.state.phoneraw.length > 0,
          email: this.state.id || this.validEmail(),
          password: this.state.id || this.validPassword(),
          passwordConf:
            this.state.id || this.state.password === this.state.passwordConf,
          language: this.state.language.length > 0
        };
      };

      validateForm = async () => {
        this.setState({
          submitted: true
        });

        if (
          this.state.phone &&
          this.state.phone.length > 0 &&
          (!this.state.id ||
            this.props.currentUser.phone != this.state.phone ||
            this.state.dirty)
        ) {
          showToast(I18n.t("twilio.lookup"));
          let data = await lookup(this.state.phone);
          if (!data) {
            data = { phone_number: this.state.phone, national_format: "" };
            showToast(I18n.t("twilio.invalid"), "danger");
          }

          this.setState({
            phone: data.phone_number,
            phoneraw: this.state.phone,
            phoneformatted: data.national_format,
            twilio: true
          });
        }
        const validatedObject = this.validateAllInputs();
        const formValid = Object.keys(validatedObject).every(
          element => validatedObject[element]
        );
        return formValid;
      };

      handleLanguageChange = value => {
        this.setState({ language: value, dirty: true });
      };

      attemptRegister = async () => {
        if (this._working) return;
        this._working = true;
        if ((await this.validateForm()) && (await register(this.state))) {
          setLocale(this.state.language);
          this.props.navigation.navigate("NewUser");
        } else {
          this._content._root.scrollToPosition(0, 0);
        }
        this._working = false;
      };

      saveUserProfile = async () => {
        if (this._working) return;
        this._working = true;
        if (await this.validateForm()) {
          if (this.state.dirty && (await updateUser(this.state))) {
            setLocale(this.state.language);
            resetList("alert");
            resetList("warning,info");
            getList(0, "alert");

            const resetAction = StackActions.reset({
              index: 0,
              actions: [NavigationActions.navigate({ routeName: "Main" })]
            });
            this.props.navigation.dispatch(resetAction);
          }
        } else {
          showToast(I18n.t("global.forminvalid"), "danger", 3000);
        }
        this._working = false;
      };

      render() {
        const { offline } = this.props;
        return (
          <Content container ref={ref => (this._content = ref)}>
            <View>
              {/* Top level view container */}
              <View>
                {/* Online view */}
                <Form>
                  {this.state.id ? (
                    <Fragment>
                      <View style={style.topPanel}>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "center"
                          }}>
                          <H3>
                            {this.state.firstname} {this.state.lastname}
                          </H3>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "center"
                          }}>
                          <Text>{this.state.email}</Text>
                        </View>

                        {!offline ? (
                          <Fragment>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "center"
                              }}>
                              <Button
                                transparent
                                info
                                small
                                onPress={() =>
                                  this.props.navigation.navigate(
                                    "ChangePassword"
                                  )
                                }>
                                <Text uppercase={false}>
                                  {I18n.t("editAccount.changePass")}
                                </Text>
                              </Button>
                            </View>
                          </Fragment>
                        ) : (
                          undefined
                        )}
                      </View>
                    </Fragment>
                  ) : (
                    undefined
                  )}
                  <View style={style.sectionPanel}>
                    {/* Account Info section */}
                    <H3 style={style.sectionPanelTitle}>
                      {I18n.t("signup.accountInfoSectionTitle")}
                    </H3>
                    <ValidatedInput
                      validInput={this.state.firstname.length > 0}
                      handleInputChange={this.handleInputChange}
                      label={I18n.t("global.firstName")}
                      errorMessage={I18n.t("errorMessages.firstNameRequired")}
                      name="firstname"
                      disabled={offline}
                      noBorder={offline}
                      value={this.state.firstname}
                      showError={this.state.submitted}
                      getRef={ref => (this._firstName = ref)}
                      returnKeyType="next"
                      onSubmitEditing={() => this._lastName._root.focus()}
                    />
                    <ValidatedInput
                      validInput={this.state.lastname.length > 0}
                      handleInputChange={this.handleInputChange}
                      label={I18n.t("global.lastName")}
                      errorMessage={I18n.t("errorMessages.lastNameRequired")}
                      name="lastname"
                      disabled={offline}
                      noBorder={offline}
                      value={this.state.lastname}
                      showError={this.state.submitted}
                      getRef={ref => (this._lastName = ref)}
                      returnKeyType="next"
                      onSubmitEditing={() =>
                        (this.state.id
                          ? this._phoneNumber
                          : this._email
                        )._root.focus()
                      }
                    />
                    {!this.state.id ? (
                      <Fragment>
                        <ValidatedInput
                          validInput={this.validEmail()}
                          handleInputChange={this.handleInputChange}
                          label={I18n.t("global.email")}
                          errorMessage={this.generateEmailError()}
                          name="email"
                          keyboardType="email-address"
                          disabled={offline || !!this.state.id}
                          noBorder={offline || !!this.state.id}
                          value={this.state.email}
                          showError={this.state.submitted}
                          getRef={ref => (this._email = ref)}
                          returnKeyType="next"
                          onSubmitEditing={() =>
                            this._phoneNumber._root.focus()
                          }
                        />
                      </Fragment>
                    ) : (
                      undefined
                    )}
                    <ValidatedInput
                      validInput={
                        this.state.phone.length > 0 &&
                        (!this.state.twilio ||
                          (this.state.twilio &&
                            this.state.phoneformatted.length > 0))
                      }
                      handleInputChange={this.handleInputChange}
                      label={I18n.t("signup.phone")}
                      errorMessage={this.generatePhoneError()}
                      name="phone"
                      keyboardType="phone-pad"
                      disabled={offline}
                      noBorder={offline}
                      value={this.state.phone}
                      showError={this.state.submitted}
                      getRef={ref => (this._phoneNumber = ref)}
                      returnKeyType="next"
                      onSubmitEditing={() =>
                        (this.state.id
                          ? this._firstName
                          : this._password
                        )._root.focus()
                      }
                    />
                  </View>
                  {/* End account info section */}
                  {!offline ? (
                    <Fragment>
                      {!this.state.id ? (
                        <Fragment>
                          <View style={style.sectionPanel}>
                            {/* Password section */}
                            <H3 style={style.sectionPanelTitle}>
                              {I18n.t("signup.passwordSectionTitle")}
                            </H3>
                            <Text style={style.sectionPanelDescription}>
                              {I18n.t("changePass.passwordRule")}
                            </Text>
                            <ValidatedInput
                              validInput={this.validPassword()}
                              handleInputChange={this.handleInputChange}
                              label={I18n.t("global.password")}
                              errorMessage={this.generatePasswordError()}
                              name="password"
                              secureTextEntry={true}
                              value={this.state.password}
                              showError={this.state.submitted}
                              getRef={ref => (this._password = ref)}
                              returnKeyType="next"
                              onSubmitEditing={() =>
                                this._passwordConf._root.focus()
                              }
                            />
                            <ValidatedInput
                              validInput={
                                this.state.password === this.state.passwordConf
                              }
                              handleInputChange={this.handleInputChange}
                              label={
                                I18n.t("global.confirm") +
                                " " +
                                I18n.t("global.password")
                              }
                              errorMessage={I18n.t(
                                "errorMessages.passwordNotMatch"
                              )}
                              name="passwordConf"
                              secureTextEntry={true}
                              value={this.state.passwordConf}
                              showError={this.state.submitted}
                              getRef={ref => (this._passwordConf = ref)}
                              returnKeyType="done"
                            />
                            <View style={style.paddingTop}>
                              <ListItem noBorder style={style.checkboxListItem}>
                                <CheckBox
                                  checked={this.state.keeplogin}
                                  onPress={() =>
                                    this.handleCheckboxChange("keeplogin")
                                  }
                                />
                                <Body>
                                  <Text style={style.greyText}>
                                    {I18n.t("signup.keepMe")}
                                  </Text>
                                </Body>
                              </ListItem>
                            </View>
                          </View>
                          {/* End password section */}
                        </Fragment>
                      ) : (
                        undefined
                      )}
                      <View style={style.sectionPanel}>
                        {/* Language picker section */}
                        <H3 style={style.sectionPanelTitle}>
                          {I18n.t("signup.languageSectionTitle")}
                        </H3>
                        {Platform.OS === "ios" ? (
                          <Picker
                            style={style.picker}
                            iosIcon={<Icon name="ios-arrow-down" />}
                            selectedValue={this.state.language}
                            placeholder={I18n.t("global.pleaseSelectLanguage")}
                            placeholderStyle={{
                              color: variable.inputColorPlaceholder
                            }}
                            textStyle={{ color: variable.inputColor }}
                            onValueChange={value =>
                              this.handleLanguageChange(value)
                            }
                            renderHeader={backAction => (
                              <CustomPickerHeader backAction={backAction} />
                            )}>
                            {this.props.languages.map((group, key) => (
                              <Picker.Item
                                label={group.label}
                                value={group.code}
                                key={key}
                              />
                            ))}
                          </Picker>
                        ) : (
                          <Picker
                            style={style.picker}
                            selectedValue={this.state.language}
                            placeholder={I18n.t("global.pleaseSelectLanguage")}
                            onValueChange={value =>
                              this.handleLanguageChange(value)
                            }>
                            {this.props.languages.map((group, key) => (
                              <Picker.Item
                                label={group.label}
                                value={group.code}
                                key={key}
                              />
                            ))}
                          </Picker>
                        )}
                      </View>
                      {/* End language picker section */}
                      <View style={style.sectionPanel}>
                        {/* Notification Preferences section */}
                        <H3 style={style.sectionPanelTitle}>
                          {I18n.t("signup.fourbellsAlert")}
                        </H3>
                        <ListItem style={style.checkboxListItem}>
                          <CheckBox
                            checked={this.state.sms}
                            onPress={() => this.handleCheckboxChange("sms")}
                          />
                          <Body>
                            <Text>{I18n.t("signup.sendTextMessage")}</Text>
                          </Body>
                        </ListItem>
                        {Platform.OS != "web" && (
                          <ListItem style={style.checkboxListItem}>
                            <CheckBox
                              checked={this.state.push}
                              onPress={() => this.handleCheckboxChange("push")}
                            />
                            <Body>
                              <Text>{I18n.t("signup.sendNotification")}</Text>
                            </Body>
                          </ListItem>
                        )}
                        <ListItem style={style.checkboxListItem}>
                          <CheckBox
                            checked={this.state.messenger}
                            onPress={() =>
                              this.handleCheckboxChange("messenger")
                            }
                          />
                          <Body>
                            <Text>{I18n.t("signup.sendMessenger")}</Text>
                          </Body>
                        </ListItem>
                      </View>
                      {/* End notification preferences section */}
                      {!this.state.id ? (
                        <Fragment>
                          <View style={style.padding}>
                            <Text>
                              {I18n.t("signup.termsAccept")}{" "}
                              <Text
                                link
                                onPress={() =>
                                  Linking.openURL(
                                    "https://www.4bells.org/4bells-terms"
                                  )
                                }>
                                {I18n.t("global.eula")}
                              </Text>{" "}
                              {I18n.t("global.and")}{" "}
                              <Text
                                link
                                onPress={() =>
                                  Linking.openURL(
                                    "https://www.4bells.org/4bells-privacy"
                                  )
                                }>
                                {I18n.t("global.privacypolicy")}
                              </Text>
                              .
                            </Text>
                          </View>
                        </Fragment>
                      ) : (
                        undefined
                      )}
                      <View style={style.padding}>
                        {this.state.id ? (
                          <Button
                            block
                            primary
                            disabled={!this.state.dirty}
                            onPress={this.saveUserProfile}>
                            <Text uppercase={false}>
                              {I18n.t("global.saveChanges")}
                            </Text>
                          </Button>
                        ) : (
                          <Button block primary onPress={this.attemptRegister}>
                            <Text uppercase={false}>
                              {I18n.t("global.continue")}
                            </Text>
                          </Button>
                        )}
                      </View>
                    </Fragment>
                  ) : (
                    undefined
                  )}
                </Form>
              </View>
              {/* End online view container */}
            </View>
            {/* End top level view container */}
          </Content>
        );
      }
    }
  ),
  props => <Header {...props} />
);

const style = {
  padding: {
    padding: 20
  },
  section: {
    marginBottom: 10
  },
  picker: {
    justifyContent: "flex-start",
    width: "100%",
    height: 50
  },
  topPanel: {
    backgroundColor: "rgb(255,255,255)",
    padding: 10,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 15,
    marginBottom: 10
  },
  sectionPanel: {
    backgroundColor: "rgb(255,255,255)",
    padding: 20,
    borderRadius: 15,
    margin: 10
  },
  sectionPanelTitle: {
    color: variable.brandInfo
  },
  sectionPanelDescription: {
    fontWeight: "bold",
    fontSize: 12
  },
  paddingTop: {
    paddingTop: 20
  },
  greyText: {
    fontWeight: "bold"
  },
  checkboxListItem: {
    marginLeft: 0
  }
};
