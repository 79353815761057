import React, { Component } from "react";
import {
  Content,
  Text,
  Form,
  Item,
  Label,
  Input,
  Button,
  H1,
  H2,
  H3,
  Card,
  CardItem,
  CheckBox,
  Left,
  Right,
  Body,
  ListItem
} from "native-base";
import { StyleSheet, View, Keyboard } from "react-native";
import { joinRequest, connectGroup } from "./actions";
import { Screen, HeaderWithBack, showToast, sortString } from "../../utils";
import I18n from "../../i18n";
import variables from "../../../native-base-theme/variables/platform";

export default Screen(
  class Connect extends Component {
    state = {
      connectionCode: null,
      connectDisabled: true,
      connected: false,
      error: null,
      lookup: null,
      group: null,
      categories: []
    };
    _working = false;

    connect = async () => {
      if (this._working) return;
      this._working = true;
      Keyboard.dismiss();
      if (!this.state.lookup) {
        showToast(I18n.t("connect.locating"));
        const group = await joinRequest(this.state.connectionCode);
        if (group) {
          const { error, categories } = group;
          if (error) {
            this.setState({
              lookup: true,
              error
            });
          } else {
            this.setState({
              ...this.state,
              lookup: true,
              group,
              categories,
              connectDisabled: true
            });
          }
        } else {
          this.setState({
            lookup: true,
            error: "InvalidCode"
          });
        }
      } else {
        showToast(I18n.t("connect.connecting"));
        if (
          await connectGroup(
            this.state.group,
            this.state.categories.filter(it => it.selected).map(it => it.id)
          )
        ) {
          this.setState({
            ...this.state,
            approveInfo: true,
            connectDisabled: true
          });

          if (!this.state.group.approvevolunteer) {
            this.props.navigation.goBack();
          }
        }
      }
      this._working = false;
    };

    codeChanged = evt => {
      this.setState({
        ...this.state,
        connectionCode:
          evt.nativeEvent.text.length == 0 ||
          Number.isNaN(+evt.nativeEvent.text)
            ? ""
            : Math.floor(+evt.nativeEvent.text).toString(),
        connectDisabled: evt.nativeEvent.text.length != 6,
        lookup: null,
        error: null,
        group: null,
        categories: []
      });
    };

    selectCategoryChanged = category => {
      return () => {
        if (!this.state.approveInfo) {
          category.selected = !category.selected;
          this.setState({
            ...this.state,
            connectDisabled:
              this.state.categories.filter(it => it.selected).length == 0
          });
        }
      };
    };

    render() {
      return (
        <Content container>
          <View style={style.container}>
            {this.state.approveInfo ? (
              undefined
            ) : (
              <View style={style.margin}>
                <Text>{I18n.t("connect.volunteerText")}</Text>
                <Form>
                  <Item floatingLabel>
                    <Label>{I18n.t("connect.connectCodeLabel")}</Label>
                    <Input
                      name="connectioncode"
                      keyboardType="numeric"
                      returnKeyType="done"
                      autoCorrect={true}
                      maxLength={6}
                      onSubmitEditing={this.connect}
                      onChange={this.codeChanged}
                      value={this.state.connectionCode}
                    />
                  </Item>
                </Form>
                {(() => {
                  if (this.state.lookup && this.state.group) {
                    return (
                      <View style={{ marginVertical: 15 }}>
                        <H3
                          style={{
                            color: variables.brandInfo,
                            fontWeight: "bold"
                          }}>
                          {this.state.group.name}
                        </H3>
                        <Text style={{ fontWeight: "200" }}>
                          {I18n.t("connect.selectCategories")}
                        </Text>
                        <View style={{ marginVertical: 15 }}>
                          {sortString(this.state.categories, "name").map(
                            (category, index) => (
                              <ListItem
                                style={{ marginLeft: 0 }}
                                key={`${category.name}_${index}`}>
                                <CheckBox
                                  checked={category.selected}
                                  onPress={this.selectCategoryChanged(category)}
                                />
                                <Body>
                                  <Text>{category.name}</Text>
                                </Body>
                              </ListItem>
                            )
                          )}
                        </View>
                      </View>
                    );
                  }
                })()}
                <Button
                  block
                  primary={this.state.group ? false : true}
                  info={this.state.group ? true : false}
                  disabled={this.state.connectDisabled}
                  onPress={this.connect}>
                  <Text uppercase={false}>
                    {this.state.group
                      ? I18n.t("connect.connectButton")
                      : I18n.t("global.ok")}
                  </Text>
                </Button>
              </View>
            )}
            {(() => {
              if (
                this.state.lookup &&
                !this.state.group &&
                this.state.error &&
                this.state.error != "AlreadyConnect"
              ) {
                return <Text>{I18n.t("connect.invalidCode")}</Text>;
              } else if (
                this.state.lookup &&
                !this.state.group &&
                this.state.error == "AlreadyConnect"
              ) {
                return <Text>{I18n.t("connect.alreadyConnected")}</Text>;
              } else if (
                this.state.group &&
                this.state.approveInfo &&
                !this.state.group.approvevolunteer
              ) {
                return <Text>{I18n.t("connect.approve")}</Text>;
              } else if (
                this.state.group &&
                this.state.group.approvevolunteer
              ) {
                return (
                  <Text>
                    {I18n.t("connect.thanksConnecting", {
                      groupName: this.state.group.name
                    })}
                  </Text>
                );
              }
            })()}
          </View>
        </Content>
      );
    }
  },
  _ => I18n.t("connect.title")
);

const style = StyleSheet.create({
  container: {
    padding: 15
  },
  margin: {
    marginBottom: 15
  }
});
