import variable from "./../variables/platform";

export default (variables = variable) => {
  const platform = variables.platform;
  const cardTheme = {
    ".transparent": {
      shadowColor: null,
      shadowOffset: null,
      shadowOpacity: null,
      shadowRadius: null,
      elevation: null
    },
    ".brown": {
      backgroundColor: "#4b331e"
    },
    marginVertical: 5,
    marginHorizontal: 2,
    flex: 1,
    borderWidth: platform === "web" ? 1 : variables.borderWidth,
    borderRadius: 5,
    borderColor: variables.cardBorderColor,
    flexWrap: "nowrap",
    backgroundColor: variables.cardDefaultBg,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 1.5,
    elevation: 3,
    minHeight: "auto"
  };

  return cardTheme;
};
