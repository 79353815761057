import types from "../actions/types";
import store from "../../../store";

export default (state = [], action) => {
  switch (action.type) {
    case types.CATEGORY_VOLUNTEERS:
      return action.volunteers || state;
    case types.LOGOUT:
      return [];
    default:
      return state;
  }
};
