import variable from "./../variables/platform";

export default (variables = variable) => {
  const platformStyle = variables.platformStyle;
  const platform = variables.platform;

  const footerTheme = {
    "NativeBase.Left": {
      "NativeBase.Button": {
        ".transparent": {
          backgroundColor: "transparent",
          borderColor: null,
          elevation: 0,
          shadowColor: null,
          shadowOffset: null,
          shadowRadius: null,
          shadowOpacity: null
        },
        "NativeBase.Icon": {
          color: variables.topTabBarActiveTextColor
        },
        "NativeBase.IconNB": {
          color: variables.topTabBarActiveTextColor
        },
        alignSelf: null
      },
      flex: 1,
      alignSelf: "center",
      alignItems: "flex-start"
    },
    "NativeBase.Body": {
      flex: 1,
      alignItems: "center",
      alignSelf: "center",
      flexDirection: "row",
      "NativeBase.Button": {
        alignSelf: "center",
        ".transparent": {
          backgroundColor: "transparent",
          borderColor: null,
          elevation: 0,
          shadowColor: null,
          shadowOffset: null,
          shadowRadius: null,
          shadowOpacity: null
        },
        ".full": {
          height: variables.footerHeight,
          paddingBottom: variables.footerPaddingBottom,
          flex: 1
        },
        "NativeBase.Icon": {
          color: variables.topTabBarActiveTextColor
        },
        "NativeBase.IconNB": {
          color: variables.topTabBarActiveTextColor
        }
      }
    },
    "NativeBase.Right": {
      "NativeBase.Button": {
        ".transparent": {
          backgroundColor: "transparent",
          borderColor: null,
          elevation: 0,
          shadowColor: null,
          shadowOffset: null,
          shadowRadius: null,
          shadowOpacity: null
        },
        "NativeBase.Icon": {
          color: variables.topTabBarActiveTextColor
        },
        "NativeBase.IconNB": {
          color: variables.topTabBarActiveTextColor
        },
        alignSelf: null
      },
      flex: 1,
      alignSelf: "center",
      alignItems: "flex-end"
    },

    ".globalFooter": {
      backgroundColor: variables.footerDefaultBg,
      borderTopWidth:
        platform === "ios" && platformStyle !== "material"
          ? variables.borderWidth
          : undefined,
      borderColor:
        platform === "ios" && platformStyle !== "material"
          ? "#cbcbcb"
          : undefined,
      height: variables.footerHeight
    },

    //
    flexDirection: "row",
    justifyContent: "center",
    //paddingBottom: platform === "ios" ? 20 : variables.footerPaddingBottom,

    paddingBottom: variables.footerPaddingBottom,
    elevation: 3,
    left: 0,
    right: 0
  };
  return footerTheme;
};
