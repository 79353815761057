import { createStackNavigator } from "react-navigation-stack";
import TaskDetails from "./taskDetails";
import Categories from "./categories";
import Review from "./review";

export default createStackNavigator(
  {
    AddTask: {
      screen: TaskDetails
    },
    Categories: {
      screen: Categories
    },
    Review: {
      screen: Review
    }
  },
  {
    headerMode: "none",
    navigationOptions: {
      header: null
    }
  }
);
