import React, { Component, Fragment } from "react";
import { View } from "react-native";
import {
  Content,
  Card,
  CardItem,
  Button,
  H3,
  Text,
  Left,
  Body,
  Icon,
  Right
} from "native-base";
import { connect } from "react-redux";
import {
  volunteerApprove,
  volunteerReject,
  volunteerDisconnect,
  volunteerRemove
} from "./actions";
import CommunicationActions from "./components/CommunicationActions";
import Header from "./details/header";
import style from "./details/style";
import styles from "../../styles";
import { Screen, sortString, sortCondition } from "../../utils";
import I18n from "../../i18n";
import variables from "../../../native-base-theme/variables/platform";

const ConnectHeader = connect(state => ({
  category: state.groups.category
}))(props => (
  <Header
    title={I18n.t("groups.categoryvolunteers.title")}
    subtitle={I18n.t("groups.categoryvolunteers.subtitle", props.category)}
    {...props}
  />
));

export default Screen(
  connect(state => ({
    group: state.groups.group,
    category: state.groups.category,
    categoryvolunteers: state.groups.categoryvolunteers
  }))(
    class extends Component {
      renderActions(volunteer) {
        const { group, navigation } = this.props;

        return (
          <Fragment>
            {group.profile.approvevolunteer ? (
              <Fragment>
                {volunteer.status == "pending" ? (
                  <Fragment>
                    <Button
                      full
                      primary
                      small
                      style={{ backgroundColor: "green" }}
                      onPress={() => volunteerApprove(volunteer)}>
                      <Text uppercase={false}>
                        {I18n.t("groups.categoryvolunteers.actions.approve")}
                      </Text>
                    </Button>

                    <Button
                      full
                      primary
                      small
                      style={{ backgroundColor: variables.brandDanger }}
                      onPress={() => volunteerReject(volunteer)}>
                      <Text uppercase={false}>
                        {I18n.t("groups.categoryvolunteers.actions.reject")}
                      </Text>
                    </Button>
                  </Fragment>
                ) : (
                  undefined
                )}

                {volunteer.status == "rejected" ? (
                  <Button
                    full
                    transparent
                    info
                    small
                    onPress={() => volunteerApprove(volunteer)}>
                    <Text uppercase={false} style={{ fontWeight: "bold" }}>
                      {I18n.t(
                        "groups.categoryvolunteers.actions.changeToApproved"
                      )}
                    </Text>
                  </Button>
                ) : (
                  undefined
                )}

                {volunteer.status == "approved" ? (
                  <Button
                    full
                    transparent
                    info
                    small
                    onPress={() => volunteerReject(volunteer)}>
                    <Text uppercase={false} style={{ fontWeight: "bold" }}>
                      {I18n.t(
                        "groups.categoryvolunteers.actions.changeToRejected"
                      )}
                    </Text>
                  </Button>
                ) : (
                  undefined
                )}
              </Fragment>
            ) : (
              undefined
            )}
            <Fragment></Fragment>
          </Fragment>
        );
      }

      render() {
        const { group, categoryvolunteers } = this.props;
        return (
          <Content container>
            <View style={style.padding}>
              {sortCondition(categoryvolunteers, "pending").map(
                (volunteer, index) => (
                  <Card
                    key={`${volunteer.name}_${volunteer.phone}_${volunteer.email}_${index}`}>
                    <CardItem bordered>
                      {group.profile.approvevolunteer ? (
                        <Left>
                          {volunteer.status == "approved" && (
                            <Icon
                              name="md-checkmark-circle"
                              style={{ color: "green" }}
                            />
                          )}
                          {volunteer.status == "rejected" && (
                            <Icon
                              name="md-close-circle"
                              style={{ color: variables.brandDanger }}
                            />
                          )}
                          {volunteer.status == "pending" && (
                            <Icon
                              name="md-help-circle"
                              style={{ color: variables.brandWarning }}
                            />
                          )}
                          <Body>
                            <Text
                              style={{
                                fontWeight: "bold",
                                color: variables.brandInfo
                              }}>
                              {volunteer.name}
                            </Text>
                            <Text note>
                              {I18n.t(
                                `groups.categoryvolunteers.${volunteer.status}`
                              )}
                            </Text>
                          </Body>
                        </Left>
                      ) : (
                        <Left>
                          <Icon
                            name="contact"
                            style={{ color: variables.brandInfo }}
                          />
                          <Body>
                            <Text
                              style={{
                                fontWeight: "bold",
                                color: variables.brandInfo
                              }}>
                              {volunteer.name}
                            </Text>
                          </Body>
                        </Left>
                      )}
                      <Right>
                        <Button
                          info
                          small
                          transparent
                          onPress={async () => {
                            await volunteerRemove(volunteer);
                            if (this.props.category.volunteers == 0) {
                              navigation.goBack();
                            }
                          }}>
                          <Text uppercase={false}>
                            {I18n.t("groups.categoryvolunteers.actions.remove")}
                          </Text>
                        </Button>
                      </Right>
                    </CardItem>

                    <CardItem>
                      <Left>
                        <Icon name="call" style={{ fontSize: 14 }} />
                        <Body>
                          <Text style={{ fontSize: 14 }}>
                            {volunteer.phone}
                          </Text>
                        </Body>
                      </Left>
                    </CardItem>

                    <CardItem>
                      <Left>
                        <Icon name="mail" style={{ fontSize: 14 }} />
                        <Body>
                          <Text style={{ fontSize: 14 }}>
                            {volunteer.email}
                          </Text>
                        </Body>
                      </Left>
                    </CardItem>
                    <CardItem>
                      <CommunicationActions
                        user={volunteer}
                        i18n="groups.contacts"
                      />
                    </CardItem>
                    <CardItem footer bordered>
                      <Body>{this.renderActions(volunteer)}</Body>
                    </CardItem>
                  </Card>
                )
              )}
            </View>
          </Content>
        );
      }
    }
  ),
  props => <ConnectHeader {...props} />
);
