import React, { Component } from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Alert,
  RefreshControl,
  Platform
} from "react-native";
import PropTypes from "prop-types";
import {
  Content,
  Button,
  Text,
  Card,
  CardItem,
  H3,
  Icon,
  Left,
  Right,
  Body,
  ListItem
} from "native-base";
import HTMLView from "react-native-htmlview";
import I18n from "../../i18n";
import { connect } from "react-redux";
import { getList } from "./actions";
import { isCloseToBottom, isCloseToTop, showToast } from "../../utils";
import styles from "../../styles";
import variables from "../../../native-base-theme/variables/platform";
import itemFactory from "./items";
import handler from "./handler";

export default itemFactory(
  "alert",
  props => (item, key) => {
    return (
      <TouchableWithoutFeedback key={key} onPress={handler(item, props)}>
        <Card>
          <CardItem bordered>
            <Left>
              <Icon
                name="ios-notifications-outline"
                style={{ color: variables.brandInfo, fontSize: 20 }}
              />
              <Body>
                <Text style={{ color: variables.brandInfo, fontSize: 14 }}>
                  {I18n.t("activities.actionNeeded")}
                </Text>
              </Body>
            </Left>
          </CardItem>

          <CardItem>
            <Body>
              <HTMLView
                value={`<p>${item.html}</p>`}
                stylesheet={htmlStyle}
                TextComponent={Text}
                textComponentProps={{
                  style: { color: variables.defaultTextColor }
                }}
              />
            </Body>
          </CardItem>
          <View style={[styles.right, { padding: 10, paddingTop: 0 }]}>
            <Text note>{I18n.t("activities.view")}</Text>
          </View>
        </Card>
      </TouchableWithoutFeedback>
    );
  },
  () => <Text>{I18n.t("activities.emptyTodos")}</Text>,
  () => I18n.t("activities.tabHeaders.alerts")
);

const htmlStyle = StyleSheet.create({
  span: {
    fontWeight: "bold"
  }
});
