export default {
  global: {
    email: "Adres email",
    password: "Hasło ",
    rememberMe: "Zapamiętaj mnie ",
    forgotPass: "Zapomniałeś hasła?",
    login: "Zaloguj ",
    loginTitle: "Zaloguj do 4Bells",
    loginFailedTitle: "",
    loginFailed: "Niepoprawny login lub hasło",
    createAcc: "Załóż konto",
    changePass: "Zmiana hasła",
    and: "i",
    firstName: "Imię",
    lastName: "Nazwisko",
    eula: "Umowa licencyjna użytkownika końcowego",
    privacypolicy: "Polityka prywatności ",
    continue: "Dalej",
    save: "Zapisz",
    saveChanges: "Zapisz zmiany ",
    send: "Wyślij",
    cancel: "Anuluj",
    callPhone: "Zadzwoń na telefon",
    noItemFoundForList: "Nie znaleźiono elementów ",
    briefDesc: "Krótki opis",
    endDate: "Data końca",
    startLocation: "Miejsce początku",
    endLocation: "Miejsce końca",
    ownerName: "Imię właściciela ",
    ownerPhone: "Telefon do właściciela ",
    numberVolunteers: "Liczba wolontariuszy",
    startDate: "Data początku ",
    categories: "Kategorie ",
    language: "Język",
    pleaseSelectLanguage: "Wybierz język",
    isRequired: "wymagane",
    title: "Tytuł",
    noCategoriesSelected: "Nie wybrano kategorii",
    confirm: "Potwierdź proszę",
    needToRestart: "Będziemy musieli uruchomić aplikację ponownie",
    restart: "Uruchom ponownie",
    ok: "OK",
    yes: "Tak",
    no: "Nie",
    close: "Zamknij",
    selecetOne: "Wybierz jedno",
    group: "Grupa",
    thankYou: "Dziękuję!",
    view: "Widok",
    taskStatus: {
      open: "Utwórz",
      claimed: "Przypisane",
      decline: "Odmowa",
      completed: "Zakończone",
      closed: "Zamknięte",
      recalled: "Usunięte"
    },
    forminvalid: "Niepoprawny formularz"
  },
  location: {
    pin: "Wybierz lokalizacje na mapie",
    title: "Zaakceptuj użycie lokalizacji aby używać 4Bells",
    message:
      "Potrzebujemy zgody na lokalizację urządzenia. Prosimy o jej udzielenie.",
    openInMapApp: "Otwórz w aplikacji mapy "
  },
  drawer: {
    activities: "Podsumowanie",
    tasks: "Zadania",
    groups: "Grupy",
    myAccount: "Moje konto",
    sync: "Synchronizuj",
    settings: "Ustawienia",
    contactUs: "Skontaktuj się z nami",
    logout: "Wyloguj",
    faq: "Najczęściej zadawane pytania",
    terms: "Umowa licencyjna użytkownika końcowego",
    privacy: "Polityka prywatności",
    about: "O nas"
  },
  sync: {
    complete: "Zsynchronizowano ",
    cancel: "Synchronizacja wstrzymana",
    syncGroup: "Synchronizacja grupy: {{name}}",
    groupFailed: "Grupa: {{name}} synchronizacja nieudana",
    tasks: "Synchronizacja {{list}} zadań",
    open: "Otwórz",
    claimed: "przypisane ",
    syncTask: "Synchronizacja  {{index}}/{{count}} {{list}} zadań.",
    taskFailed: "Zadanie: {{name}} synchronizacja nieudana",
    modes: {
      none: "Wyłącz",
      always: "Zawsze",
      wifi: "Tylko WI-Fi"
    }
  },
  errorMessages: {
    unexpectedError: "Niespodziewany błąd",
    firstNameRequired: "Wymagane imię",
    lastNameRequired: "Wymagane nazwisko",
    emailRequired: "Wymagany adres email",
    emailNotValid: "Wpisany adres email jest niepoprawny",
    phoneRequired: "Wymagany numer telefonu",
    phoneInvalid: "Niepoprawny numer telefonu",
    passwordRequired: "Konieczne hasło",
    passwordRule:
      "Hasło musi mieć co najmniej 6 znaków. Powinno zawierać duże i małe litery oraz cyfry. Prosimy nie używać znaków specjalnych. ",
    passwordNotMatch: "Podane hasła różnią się",
    somethingWentWrong: "Ups..coś poszło nie tak",
    messageRequired: "Wymagana wiadomość",
    endDateNeedToHigher:
      "Data zakończenie musi być późniejsza niż data rozpoczęcia!",
    groupRequired: "Wymagana grupa",
    titleRequired: "Wymagany tytuł",
    briefDescRequired: "Wymagany krótki opis",
    startDateRequired: "Wymagana data początku",
    numberVolunteersRequired: "Wymagane jest wskazanie liczby wolontariuszy"
  },
  appError: {
    "Network Error": "Brak sieci",
    "Invalid username or password": "Niepoprawna nazwa użytkownika lub hasło",
    "Registration warning": "Ten email już istnieje ",
    "Request failed with status code 504": "Serwer nie odpowiada",
    "Request failed with status code 404": "Nie znaleziono źródła",
    "Resource not found": "Nie znaleziono źródła"
  },
  twilio: {
    lookup: "Sprawdzanie numeru telefonu",
    invalid: "Błędny numer telefonu (w Polsce rozpocznij od +48)"
  },
  screenHeader: {
    activity: "Aktywność",
    taskDetails: "Szczegóły zadania",
    editTask: "Edytuj zadanie",
    addTask: "Stwórz zadanie",
    createAccount: "Stwórz konto",
    editAccount: "Edytuj konto",
    myAccount: "Moje konto",
    help: "Pomoc",
    settings: "Ustawienia"
  },
  welcome: {
    welcome: "Witamy w 4Bells",
    description:
      "Zorganizuj prace wolontariuszy. Użyj aplikacji 4Bells żeby przydzielać zadania i monitorować ich wykonanie.",
    or: "albo",
    needHelp: "Potrzebujesz pomocy?",
    settings: "Ustawienia"
  },
  settings: {
    sync: {
      description: "Ustawienia zużycia danych",
      none: "Zawsze minimalizuj użyte dane",
      noneDescription: "Pobierz niezbędzne infomracje o grupach i zadaniach.",
      always: "Automatyczne (domyślne)",
      alwaysDescription:
        "Automatycznie pobierz wszystkie bieżące informacje dotyczące grupy oraz zadań za każdym razem gdy jesteś on-line.",
      wifi: "Ogranicz zużycie danych mobilnych",
      wifiDescription:
        "Pobierz niezbędne informacje o grupach i zadaniach  przy użyciu danych komórkowych.",
      reducedOfflineData:
        "Uwaga: Wybranie mniejszego zakresu danych może ograniczyć zakres informacji dostępnych off-line.",
      update: "Aktualizuj grupy i zadania "
    }
  },
  login: {
    rememberMe: "Zapamiętaj mnie"
  },
  signup: {
    keepMe: "Nie wylogowywuj mnie",
    fourbellsAlert: "Powiadomienia 4Bells",
    sendTextMessage: "Wysyłaj powiadomienia wiadomością SMS",
    ratesApply: "Stawki będą obowiązywały",
    sendNotification: "Wysyłaj powiadomienia",
    sendMessenger: "Wysyłaj powiadomienia na Facebook",
    termsAccept: "Używając aplikacji zgadzasz się na",
    phone: "Numer telefonu",
    accountInfoSectionTitle: "Informacje o koncie",
    passwordSectionTitle: "Hasło",
    languageSectionTitle: "Język"
  },
  newuser: {
    title: "Witamy",
    firststep: "Pierwsze kroki",
    description:
      "Możesz być zarówno wolontariuszem jak i osobą organizującą ich pracę (koordynatorem). Wybierz teraz jedną z tych ról co pozwoli Ci albo stworzyć grupę wolontariuszy albo przyłączyć się do już istniejącej. ",
    volunteer: "Zostań wolontariuszem",
    creategroup: "Stwórz grupę",
    viewPasswordRules: "Pokaż zasady tworzenia hasła"
  },
  help: {
    contactUs: "Skontaktuj się z nami",
    aboutFourBells: "O 4Bells",
    faq: "Najczęściej zadawane pytania",
    forgotPassLink: "Zapomniałeś hasła?"
  },
  about: {
    title: "O 4Bells",
    version: "Wersja aplikacji",
    releaseDate: "Data publikacji aplikacji",
    buildNumber: "Numer kompilacji",
    financialSupport: "Wsparcie finansowe pochodzi od:",
    inspirationFeedback: "Źródłem naszych inspiracji i sugestii są: ",
    beforeJayStackLink:
      "4Bells to aplikacja stworzona przez Caravan Studios będący częścią  organizacji TechSoup. Wersja Polska Fundacja Stocznia. Współpracujemy z:",
    afterJayStackLink: "aby stworzyć i utrzymać aplikację 4Bells.",
    terms: "Umowa licencyjna użytkownika końcowego",
    policy: "Polityka Prywatności "
  },
  offline: {
    status: "Tryb Offline",
    title: "Brak połączenia z Internetem ",
    descriptionTitle: "Nie znaleziono połączenia ",
    description:
      "Przykro nam brak dostępu do sieci. Proszę uruchom dostęp do sieci Wi-Fi albo sieci komórkowej na urządzeniu i uruchom ponownie aplikacje 4Bells. Dziękujemy!"
  },
  forgotPass: {
    emailMe: "Wysyłaj informacje na adres email",
    emailSucces: "Pomyślnie wysłano email"
  },
  contactUs: {
    title: "Skontaktuj się z nami",
    bellsfeedback: "Opinie nie temat aplikacji 4Bells",
    bellsIssue: "Konieczne rozstrzygnięcie problemu 4Bells",
    message: "Wiadomość",
    sent: "Wiadomość wysłana",
    error: "Nie udało się wysłać wiadomości"
  },
  editAccount: {
    changePass: "Zmień hasło"
  },
  changePass: {
    succesfullyChange: "Zmiana hasła przebiegła pomyślnie",
    passwordRule:
      "Hasło musi mieć co najmniej 6 znaków. Powinno zawierać duże i małe litery oraz cyfry. Prosimy nie używać znaków specjalnych. ",
    save: "Zapisz"
  },
  activities: {
    title: "Podsumowanie",
    tabHeaders: {
      alerts: "Do zrobienia",
      warnings: "Dziennik aktywności"
    },
    emptyTodos: "Nie masz żadnych zadań do wykonania",
    emptyActivities: "Nie masz żadnych zadań",
    removed: "Aktywność usunięta",
    actionNeeded: "Wymagane działanie",
    view: "Pokaż..."
  },
  dashboard: {
    spacesLeft: "vacaty",
    spaceLeft: "vacat",
    claimedBy: "Prośba wysłana przez",
    claimedMultiple: "Grupa wolontariuszy",
    claimedUnknown: "nieznany",
    noOpenTask: "Nie ma zadań, o które może się ubiegać ",
    noClaimedTask: "Nie ubiegasz się obecnie o żadne zadanie",
    noClosedTask: "Nie wykonałeś żadnych zadań",
    view: "Pokaż...",
    manage: "Zarządzaj...",
    tagManaging: "Zarządzanie",
    tagOpen: "Otwórz",
    tagClosed: "Zamknięte",
    tagClaimed: "Wysłano prośbę",
    claimedByYou: "Ubiegasz się o to zadanie",
    managedByYou: "Zarządzasz tym zadaniem",
    pleaseRespond: "Proszę odpowiedz na to zadanie"
  },
  taskDetails: {
    tabHeaders: {
      details: "Szczegóły",
      volunteers: "Wolontariusze",
      contacts: "Kontakty",
      location: "Miejsce",
      history: "Historia",
      actions: "Działania"
    },
    history: {
      claimed: "Wysłano prośbę",
      declined: "Odrzucono prośbę",
      completed: "Zrealizowane",
      closed: "Zamknięte",
      modified: "Zmodyfikowane",
      unclaimed: "Cofnięto prośbę",
      created: "Utworzono",
      incomplete: "Niekompletne",
      admin: "(administrator)",
      decline: ""
    },
    actions: {
      claim: "Wyślij prośbę",
      decline: "Odrzuć prośbę",
      unclaim: "Cofnij prośbę",
      markComplete: "Zadanie wykonane",
      cancel: "Anuluj",
      taskRemoved: "Zadanie zostało usunięte!",
      noMoreSpacesLeft: "Nie ma już miejsc ",
      unclaimError: "Nie udało się cofnąć prośby o przydzielenie zadania",
      declineError: "Błędne odrzucenie prośby o przydzielenie zadania!",
      markCompleteError: "Błędne oznaczenie zadania jako ukończonego!",
      markIncompleteError: "Błędne oznaczenie zadania jako niedokończonego!",
      missingTask: "Brakujące zadanie",
      changeYourMind: "Zmieniłeś zdanie?",
      claimThisTask: "Wyślij prośbę o przydzielenie tego zadania"
    },
    details: {
      editTask: "Edytuj zadanie",
      briefDesc: "Krótki opis",
      categories: "Kategorie",
      status: "Status",
      ownerName: "Imię właściciela ",
      ownerPhone: "Telefon właściciela",
      startDate: "Data początku",
      endDate: "Data końca",
      startLocation: "Miejsce początku",
      endLocation: "Miejsce końca",
      organization: "Organizacja",
      volunteers: "Wolontariusze",
      pleaseRespond: "Proszę, odpowiedz na to zadanie",
      isDeclined: "Odrzuć zadanie"
    },
    volunteers: {
      all: "Wszystkie",
      claimed: "Wysłano prośbę",
      declined: "Wykonane ",
      completed: "Wykonane ",
      closed: "Zamknięte",
      show: "Pokaż",
      status: "Status:"
    },
    contacts: {
      ratesApply: "Ceny sms i połączeń zgodne ze stawkami operatora.",
      noContactsForTask:
        "Żadne kontakty nie zostały przypisane do tego zadania",
      text: "Wyślij wiadomość SMS",
      sendMail: "Wyślij wiadomość email"
    }
  },
  groups: {
    title: "Grupy",
    groupsJoined: "Grupy w których jestem",
    groupsManaged: "Grupy którymi zarządzam",
    joinGroup: "Dołącz do grupy...",
    joinAnotherGroup: "Dołącz do kolejnej grupy...",
    createGroup: "Utwórz grupę...",
    profile: {
      title: "Utwórz grupę",
      edit: "Edytuj profil grupy",
      groupName: "Nazwa grupy",
      address1: "Adres 1 ",
      address2: "Adres 2",
      city: "Miasto",
      country: "Kraj",
      postalCode: "Kod pocztowy",
      state: "Województwo",
      volunteerApproval: "Zatwierdzanie wolontariuszy",
      enableApproval: "Czy zamierzasz zatwierdzać kandydatury wolontariuszy?",
      yes: "Tak",
      no: "Nie",
      continue: "Krok 2: Kategorie",
      save: "Zapisz",
      addressTitle: "Adres",
      groupSettingsTitle: "Ustawienia grupy",
      selectValuePlaceholder: "Wybierz opcje ",
      selectStatePlaceholder: "Wybierz województwo",
      errorMessage: {
        groupName: "Wymagana nazwa grupy",
        volunteerApproval: "Wymagane zatwierdzenie wolontariuszy"
      }
    },
    created: {
      title: "Utworzono grupę",
      heading: "Twoja grupa została utworzona",
      groupName: "Nazwa grupy",
      connectionCode: "Kod dostępu ",
      description:
        "Otrzymasz email z  potwierdzeniem, informacjami i wskazówkami dotyczącymi kontaktu z wolontariuszami i tworzeniem dla nich zadań. ",
      invite: "Zaproś wolontariuszy"
    },
    categories: {
      title: "Kategorie",
      description:
        "Możesz przydzielać pracę wolontariuszom ze względu na ich umiejętności, typy zadań lub inne kategorie, które odpowiadają potrzebom.",
      examplesLabel: "Przykłady",
      examples:
        "Pomoc w organizowani wydarzeń, wykonywania telefonów, dostarzczanie żywności, transport",
      categoryLabel: "Wybierz lub dodaj nowa kategorię",
      categoryButton: "Dodaj",
      invalid: "Twoja grupa musi zawierać co najmniej jedną kategorię",
      continue: "Krok 3: Kontakty",
      subscribe: "zasubskrybuj kategorię...",
      unsubscribe: "odsuskrybuj kategorię...",
      next: "Następny",
      heading: "Kategorie wolontariuszy",
      prompt: "wybierz z przykładowych kategorii poniżej lub dodaj własne.",
      placeholder: "Dodaj kategorię",
      addButtonLabel: "Dodaj",
      selectCategoriesPrompt:
        "Wybierz typy zadań, którymi jesteś zainteresowany."
    },
    contacts: {
      title: "Dodaj kontakty",
      description:
        "Uwzględnij osoby które mogą ułatwić zdobywanie informacji na temat zadań w tej grupie.",
      remove: "Usuń",
      add: "Dodaj kontakt...",
      save: "OK",
      form: {
        name: "Imię",
        phone: "Numer telefonu",
        email: "adres email"
      },
      errorMessage: {
        name: "Imię jest wymagane",
        phone: "Wymagany numer telefonu",
        email: "Wymagany  adres email",
        emailInvalid: "Email jest niepoprawny"
      },
      continue: "Krok 4: Przegląd",
      rates: "Cena za SMSy i połączenia zgodna ze stawkami operatora.",
      callPhone: "Zadzwoń",
      sendText: "Wyślij wiadomość SMS",
      sendMail: "Wyślij wiadomość email"
    },
    review: {
      title: "Przegląd",
      details: {
        groupName: "Nazwa grupy",
        connectionCode: "Kod dostępu",
        address1: "Adres 1 ",
        address2: "Adres 2",
        country: "Kraj",
        city: "Miasto",
        state: "Województwo",
        postalCode: "Kod pocztowy",
        volunteerApproval: "Potwierdzanie wolontariuszy",
        yes: "tak",
        no: "nie"
      }
    },
    volunteer: {
      title: "Grupa"
    },
    admin: {
      title: "Grupa",
      manageGroup: "Zarządzaj grupą"
    },
    manage: {
      title: "Zarządzaj grupą",
      editGroup: "Edytuj grupę",
      deleteGroup: "Usuń grupę",
      addCategory: "Dodaj kategorię",
      showPendingOnly: "Pokaż tylko prośby oczekujące na zatwierdzenie",
      categoryVolunteers: "{{count}} wolontariuszy",
      approveVolunteers:
        "{{approved}} zatwierdzone {{pending}} oczekujące {{rejected}} odrzuceni wolontariusze",
      removeCategory: "Usuń",
      viewVolunteers: "Wyświetl",
      manageVolunteers: "Zarządzaj ",
      addContact: "Dodaj do kontaktów",
      removeContact: "Usuń",
      removeContactConfirm: "Na pewno chcesz usunąć kontakt {{name}}?",
      inviteMore: "Zaproś więcej wolontariuszy",
      volunteers: "Wolontariusze",
      invite: "Zaproś więcej wolontariuszy",
      categorySubtitle: "Kategoria",
      manageCategoryDescription:
        "Zarządzaj kategoriami dla tej grupy i przeglądaj wolontariuszy, którzy są powiązani z każdą z kategorii.",
      statusApproved: "Zaakceptowano",
      statusRejected: "Odrzucone",
      statusPending: "Oczekujące"
    },
    invitation: {
      subject: "Zaproś do 4Bells"
    },
    actions: {
      completeSetup: "Zapisz tę grupę",
      createFailed: "Nie udało się utorzyć grupy",
      disconnect: "Odłącz się od tej grupy ",
      disconnectConfirm: "Czy na pewno chcesz odłączyć się od tej grupy?",
      deleteGroup: "Usuń tę grupę",
      deleteConfirm: "Czy na pewno chcesz usunąć tę grupę?"
    },
    tabs: {
      details: "Szczegóły",
      categories: "Kategorie",
      contacts: "Kontakty",
      lead: "Koordynator",
      volunteers: "Wolontariusze ",
      interests: "Moje zainteresowania"
    },
    categoryvolunteers: {
      title: "Lista wolontariuszy",
      subtitle: "Z {{name}} kategorii",
      status: "status",
      approved: "Zaakceptowano",
      pending: "oczekujące",
      rejected: "Odmowa",
      actions: {
        approve: "Zaakceptuj",
        reject: "Odmów",
        disconnect: "Rozłącz",
        remove: "Usuń",
        changeToRejected: "Zmień status na odrzucono",
        changeToApproved: "Zmień status na potwierdzono"
      }
    },
    volunteerdetails: {
      title: "Szczegóły"
    },
    leaddetails: {
      title: "Najważniejsze szczegóły "
    }
  },
  volunteer: {
    name: "Imię",
    phone: "Numer telefonu",
    email: "Adres email",
    status: "Status",
    categories: "Kategorie",
    promote: "Awansuj na leadera",
    demote: "Zmień na wolontariusza",
    disconnect: "Odłącz się od tej grupy",
    markComplete: "Zadanie wykonane",
    markIncomplete: "Zadanie niewykonane",
    unassignVolunteer: "Spraw, że wolontariusz nie jest przypisany",
    groupMembership: "Członkostwo w grupie",
    taskAssignment: "Przydział zadań",
    errorMessages: {
      markCompleteFailed: "Nie udało się zaznaczyć jako wykonane",
      markIncompleteFailed: "Nie udało się zaznaczyć jako niewykonane",
      unassignVolunteerFailed: "Nie udało się "
    }
  },
  connect: {
    title: "Połącz z grupą",
    volunteerText:
      "Jeśli jesteś wolontariuszem proszę wpisz kod upoważniający do przyłączenia się do grupy. Powinien być Ci przekazany przez koordynatora (znajduje się m.in w mailu zapraszającym). ",
    connectCodeLabel: "Kod dostępu",
    connectButton: "Połącz",
    invalidCode:
      "To nie jest poprawny kod przyłączenia do grupy wolontariuszy. Proszę skontaktuj się z koordynatorem wolontariuszy w celu uzyskania poprawnego kodu.",
    alreadyConnected:
      "Wysłałeś już zgłoszenie do grupy. Być może Twoje zgłoszenie czeka na zatwierdzenie ze strony koordynatora. Jeśli to konieczne i możliwe skontaktuj się z nim.",
    approve:
      "Prośba o dołączenie do grupy wolontariuszy została przesłana do koordynatora. Grupa wyświetli się jako dostępna dopiero po tym jak zostaniesz do niej przyjęty przez koordynatora.",
    thanksConnecting:
      "Dziękujemy za deklaracje dołączenie do grup  {{groupName}}. Po tym jak zostaniesz zaakceptowany przez koordynatora będziesz otrzymywał zadania, do których można się przypisać. ",
    groupName: "Nazwa grupy:",
    selectCategories: "Wybierz typy zadań, które Cię interesują.",
    locating: "Lokalizowanie grupy...",
    connecting: "Łączenie z grupą..."
  },
  editTask: {
    headerTitles: {
      categories: "Wybierz kategorie",
      review: "Przegląd"
    },
    chooseGroup: "Wybierz grupę",
    briefDescPlaceholder: "Krótki opis zadania",
    group: "Grupa",
    title: "Tytuł",
    taskSummary: "Podsumowanie zadania",
    taskDetails: "Szczegółu zadania",
    additionalInformation: "Dodatkowe informacje (Optional)",
    datePanelTitle: "DATA",
    locationPanelTitle: "MIEJSCE",
    contactsPanelTitle: "KONTAKTY",
    selectDatePlaceholder: "Wybierz datę",
    selectContactsDescription:
      "Wybierz osoby, które mogą udzielać informacji na temat tego zadania.",
    selectContactsTip: "Wskazówka: Dodaj kontakty ze swoich grup",
    selectContacts: "Wybierz kontakty...",
    numberVolunteers: "Liczba wolontariuszy",
    continueCategories: "Kontynuuj do kategorii",
    setLocaion: "Wybierz miejsce",
    contacts: "Kontakty",
    checkAllFields: "Zaznacza/odznacz wszystkie kategorie",
    continueToReview: "Kontynuuj przeglądanie",
    submit: "Zatwierdź",
    didYouMean: "Co masz na myśli?",
    errorMessages: {
      addFailed: "Nie udało się utworzyć zadania",
      editFailed: "Nie udało się zaktualizować zadania",
      smsFailedToSent: "Błąd, nie udało się wysłać wiadomości SMS",
      appLimit:
        "prawdopodobnie wszyscy używają dzisiaj 4Bells bo limit smsów został wyczerpany.  Zwykle to się nie zdarza, skontaktuj się z nami jeżeli ta sytuacja się powtarza lub jeżeli zamierzasz w przyszłości używać 4Bells w takiej ilości.",
      dailyLimit:
        "Musisz być bardzo zapracowany! Wyczerpałeś dzienny limit 50 wiadomości. Musisz poczekać do {{now}} 11:59 żeby wysłać więcej zadań. Skontaktuj się z nami jeżeli będziesz w przyszłości używał 4Bells w takiej ilości."
    },
    noSms: "Nie wysłano wiadomości SMS",
    sms: "SMS wysłany do {{count}} osób",
    smsPerson: "SMS wysłany do {{count}} osoby"
  },
  tasks: {
    title: "Zadania",
    tabHeaders: {
      openClaim: "Utwórz",
      currentClaimed: "Wysłano prośbę",
      closedTasks: "Zamknięte"
    },
    addTask: "Dodaj zadanie...",
    addContacts: "Wybierz kontakty"
  },
  retryLogin: {
    title: "Błąd sieci",
    content: "Brak połączenia z serwerem",
    cancel: "Anuluj",
    retry: "Spróbuj ponownie"
  }
};
