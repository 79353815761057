import variable from "./../variables/platform";

export default (variables = variable) => {
  const leftTheme = {
    flex: 1,
    flexBasis: "auto",
    alignSelf: "center",
    alignItems: "flex-start"
  };

  return leftTheme;
};
