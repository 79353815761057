export default Object.freeze({
  PROGRESS_START: "PROGRESS_START",
  PROGRESS_END: "PROGRESS_END",
  PROGRESS_MODAL: "PROGRESS_MODAL",
  ERROR: "ERROR",
  CURRENT_USER: "CURRENT_USER",
  JWT_TOKEN: "JWT_TOKEN",
  REMEMBER_ME: "REMEMBER_ME",
  ROOT_NAVIGATION: "ROOT_NAVIGATION",
  NOTIFICATION_TOKEN: "NOTIFICATION_TOKEN",
  LOGOUT: "LOGOUT",
  GET_LANGUAGES: "GET_LANGUAGES",
  SET_CURRENT_SCREEN: "SET_CURRENT_SCREEN",
  OPEN_TASKS_BADGE: "OPEN_TASKS_BADGE",
  SYNC_MODE: "SYNC_MODE"
});
