import React, { Component } from "react";
import { Content, Button, Text, H1, View } from "native-base";
import { Image } from "react-native";
import PushNotification from "react-native-push-notification";
import { Screen } from "../utils";
import styles from "../styles";
import I18n from "../i18n";
import { setOpenTasksBadge } from "../actions/auth";
import variable from "../../native-base-theme/variables/platform";

export default Screen(
  class Login extends Component {
    componentDidMount() {
      PushNotification.setApplicationIconBadgeNumber(0);
      setOpenTasksBadge(0);
    }

    render() {
      return (
        <Content container>
          <View style={{ padding: 20 }}>
            <View style={[styles.container, styles.center]}>
              <Image
                source={require("../images/logo_matted.png")}
                style={{
                  width: "100%",
                  height: 110,
                  resizeMode: "contain",
                  margin: 30
                }}
              />
            </View>
            <View>
              <H1 style={style.welcomeTitle}>{I18n.t("welcome.welcome")}</H1>
              <Text style={style.welcomeDescription}>
                {I18n.t("welcome.description")}
              </Text>
            </View>
            <View>
              <Button
                block
                primary
                onPress={() => this.props.navigation.navigate("Login")}>
                <Text uppercase={false}>{I18n.t("global.login")}</Text>
              </Button>
            </View>
            <View>
              <Text style={style.or}>{I18n.t("welcome.or")}</Text>
            </View>
            <View>
              <Button
                bordered
                block
                primary
                onPress={() => this.props.navigation.navigate("SignUp")}>
                <Text uppercase={false}>{I18n.t("global.createAcc")}</Text>
              </Button>
            </View>

            <View style={styles.right}>
              <Button
                info
                small
                transparent
                onPress={() => this.props.navigation.navigate("Help")}>
                <Text uppercase={false}>{I18n.t("welcome.needHelp")}</Text>
              </Button>
            </View>

            <View style={styles.right}>
              <Button
                info
                small
                transparent
                onPress={() => this.props.navigation.navigate("Settings")}>
                <Text uppercase={false}>{I18n.t("welcome.settings")}</Text>
              </Button>
            </View>
          </View>
        </Content>
      );
    }
  }
);

const style = {
  welcomeTitle: {
    textAlign: "center",
    color: variable.brandInfo
  },
  welcomeDescription: {
    textAlign: "center",
    marginTop: 30,
    marginBottom: 15,
    fontWeight: "100"
  },
  or: {
    textAlign: "center"
  }
};
