import axios from "axios";
import PushNotification from "react-native-push-notification";
import { inlineGET as GET, syncGET } from "../../../axios";
import store from "../../../store";
import { offlineGuard } from "../../../offline";
import { showToast } from "../../../utils";
import I18n from "../../../i18n";
import types from "../actions/types";
import { setOpenTasksBadge, getOpenTasksCount } from "../../../actions/auth";

const paginationSize = 10;

const fetching = {
  Open: false,
  Claimed: false,
  Closed: false
};
export async function getList(page = 0, list, sync) {
  if (fetching[list]) {
    return;
  }
  fetching[list] = true;

  const result = await offlineGuard(async () => {
    if (list == "Open" && page == 0) {
      await getOpenTasksCount();
    }
    const result = await (sync ? syncGET : GET)(
      `/tasks?page=${page}&size=${paginationSize}&type=${list}`
    );

    if (result) {
      const loadMore = result.length == paginationSize;

      store.dispatch({
        type: types.GET_LIST,
        page,
        list,
        result,
        loadMore
      });

      return result;
    }
  });

  fetching[list] = false;
  return result;
}

export async function loadMore(list, sync) {
  const { page, loadMore } = store.getState().dashboard[list];
  if (loadMore) {
    return await getList(page + 1, list, sync);
  }
}

export function resetList(list) {
  fetching[list] = false;
  store.dispatch({ type: types.RESET_LIST, list });
}

export function addNewTask(task) {
  store.dispatch({ type: types.ADD_NEW_TASK, task });
  setOpenTasksBadge(store.getState().auth.openTasks + 1);
}

export function updateTaskList(task) {
  store.dispatch({
    type: types.UPDATE_TASK_IN_LIST,
    task
  });
}

export function claimTaskList(task) {
  store.dispatch({
    type: types.CLAIM_TASK,
    task
  });
  setOpenTasksBadge(store.getState().auth.openTasks - 1);
}

export function declineTaskList(task) {
  store.dispatch({
    type: types.DECLINE_TASK,
    task
  });
  setOpenTasksBadge(store.getState().auth.openTasks - 1);
}

export function unclaimTaskList(task) {
  store.dispatch({
    type: types.UNCLAIM_TASK,
    task
  });
  setOpenTasksBadge(store.getState().auth.openTasks + 1);
}

export function completeTaskList(task) {
  store.dispatch({
    type: types.COMPLETE_TASK,
    task
  });
}

export function cancelTask(task) {
  store.dispatch({
    type: types.CANCEL_TASK,
    task
  });
}
