import React, { Component } from "react";
import {
  Header,
  Text,
  Content,
  Body,
  Left,
  Button,
  Icon,
  Right,
  Title,
  Subtitle
} from "native-base";
import { Dimensions, Text as RNText, View, Platform } from "react-native";
import { TabView, TabBar } from "react-native-tab-view";
import { cancelRequests } from "../../axios";
import { Screen } from "../../utils";
import I18n from "../../i18n";
import BottomTabBar from "../../components/TabBar";
import Todos from "./todos";
import Activities from "./activities";
import variables from "../../../native-base-theme/variables/platform";

const initialLayout = {
  height: 0,
  width: Dimensions.get("window").width
};

let timer = null;

export default Screen(
  class ActivitiesTabs extends Component {
    state = {
      index: 0,
      routes: [
        { key: "Todos", component: Todos },
        { key: "Activities", component: Activities }
      ]
    };

    componentDidMount() {
      timer = setTimeout(() => (timer = null), 1000);
    }

    componentWillUnmount() {
      if (!timer) cancelRequests();
    }

    _handleIndexChange = index => this.setState({ index });

    _renderHeader = props => (
      <TabBar
        {...props}
        indicatorStyle={{
          borderBottomColor: variables.topTabBarActiveBorderColor,
          borderBottomWidth: 4
        }}
        tabStyle={{
          backgroundColor: "transparent",
          opacity: 1
        }}
        style={{
          backgroundColor: "#fff",
          height: 45
        }}
        renderLabel={scene => {
          return (
            <RNText
              numberOfLines={1}
              style={{
                fontSize: 16,
                marginTop: 3,
                paddingHorizontal: 10,
                color: "#4b2f18",
                fontWeight: scene.focused ? "600" : "400",
                overflow: "visible"
              }}>
              {scene.route.component.title()}
            </RNText>
          );
        }}
      />
    );

    _renderScene = ({ route }) => {
      const Scene = route.component;
      const index = this.state.routes.indexOf(route);

      return (
        <View
          style={{
            width: "100%",
            height: "100%"
            // paddingBottom:
            //   variables.footerHeight - (variables.isIphoneX ? 34 : 14)
          }}>
          <Scene active={index == this.state.index} {...this.props} />
        </View>
      );
    };

    render() {
      return (
        <TabView
          navigationState={{ ...this.state }}
          renderScene={this._renderScene}
          renderTabBar={this._renderHeader}
          onIndexChange={this._handleIndexChange}
          initialLayout={initialLayout}
        />
      );
    }
  },
  props => (
    <Header hasTabs>
      <Left>
        {Platform.OS != "web" && (
          <Button transparent onPress={() => props.navigation.openDrawer()}>
            <Icon name="menu" style={{ color: "white" }} />
          </Button>
        )}
      </Left>
      <Body>
        <Title>{I18n.t("activities.title")}</Title>
      </Body>
      <Right />
    </Header>
  ),
  props => <BottomTabBar {...props} currentScreen={"Activities"} />
);
