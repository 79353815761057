import types from "../actions/types";
import store from "../../../store";

const initialState = {
  categories: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CATEGORIES:
      return {
        ...state,
        categories: action.categories
      };
    case types.LOGOUT:
      return {
        categories: []
      };
    default:
      return state;
  }
};
