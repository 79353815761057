import React, { Component } from "react";
import { Content, Text, Icon, Button, Right } from "native-base";
import { View, StyleSheet, Alert } from "react-native";
import { Screen } from "../../utils";
//import styles from '../../styles';
import MapView from "react-native-maps";
import openMap from "react-native-open-maps";
import I18n from "../../i18n";
import { connect } from "react-redux";
import {
  claimTask,
  unclaimTask,
  declineTask,
  markCompleteTask,
  removeTask
} from "./actions/index";
import { NavigationActions } from "react-navigation";
import variables from "../../../native-base-theme/variables/platform";

export default connect(state => ({
  task: state.taskDetails.task,
  currentUser: state.auth.currentUser
}))(
  class Actions extends Component {
    removeTask = async () => {
      await removeTask(this.props.task.id);
      this.props.navigation.dispatch(NavigationActions.back());
    };

    isClaimEnabled = () => {
      return (
        this.props.task.status == "open" &&
        (this.props.task.claims.length === 0 ||
          (this.props.task.claims.length > 0 &&
            !this.props.task.claims.some(c => {
              return (
                this.props.currentUser &&
                c.user.id == this.props.currentUser.id &&
                c.status != "decline"
              );
            })))
      );
    };

    isDeclineEnabled = () => {
      return (
        !this.props.task.canEdit &&
        this.props.task.status == "open" &&
        (!this.props.task.claims ||
          (this.props.task.claims &&
            !this.props.task.claims.some(c => {
              return (
                this.props.currentUser &&
                c.user.id == this.props.currentUser.id &&
                (c.status == "claimed" ||
                  c.status == "decline" ||
                  c.status == "closed")
              );
            })))
      );
    };

    isUnClaimEnabled = () => {
      return (
        (this.props.task.claims &&
          this.props.task.claims.some(c => {
            return (
              this.props.currentUser &&
              c.user.id == this.props.currentUser.id &&
              c.status == "claimed"
            );
          })) ||
        (this.props.task.status == "claimed" && this.props.task.canEdit)
      );
    };

    isCompleteEnabled = () => {
      return (
        (this.props.task.claims &&
          this.props.task.claims.some(c => {
            return (
              this.props.currentUser &&
              c.user.id == this.props.currentUser.id &&
              c.status == "claimed"
            );
          })) ||
        (this.props.task.status != "closed" && this.props.task.canEdit)
      );
    };

    render() {
      return (
        <Content container>
          <View style={[styles.container]}>
            {this.isClaimEnabled() && (
              <Button
                iconLeft
                block
                info
                bordered
                onPress={() => claimTask(this.props.task.id)}
                style={styles.buttonStyle}>
                <Icon
                  name="md-star-outline"
                  style={{ color: variables.brandInfo }}
                />
                <Text uppercase={false}>
                  {I18n.t("taskDetails.actions.claim")}
                </Text>
              </Button>
            )}

            {this.isDeclineEnabled() && (
              <Button
                iconLeft
                block
                primary
                bordered
                onPress={() => declineTask(this.props.task.id)}
                style={styles.buttonStyle}>
                <Icon
                  name="md-close"
                  style={{ color: variables.brandDanger }}
                />
                <Text uppercase={false}>
                  {I18n.t("taskDetails.actions.decline")}
                </Text>
              </Button>
            )}

            {this.isCompleteEnabled() && (
              <Button
                iconLeft
                block
                info
                bordered
                onPress={() => markCompleteTask(this.props.task.id)}
                style={styles.buttonStyle}>
                <Icon
                  name="md-checkbox-outline"
                  style={{ color: variables.brandInfo }}
                />
                <Text uppercase={false}>
                  {I18n.t("taskDetails.actions.markComplete")}
                </Text>
              </Button>
            )}

            {this.isUnClaimEnabled() && (
              <Button
                iconLeft
                block
                primary
                bordered
                onPress={() => unclaimTask(this.props.task.id)}
                style={styles.buttonStyle}>
                <Icon name="md-undo" />
                <Text uppercase={false}>
                  {I18n.t("taskDetails.actions.unclaim")}
                </Text>
              </Button>
            )}

            {this.props.task.status != "recalled" && (
              <Button
                iconLeft
                block
                primary
                bordered
                onPress={this.removeTask}
                style={styles.buttonStyle}>
                <Icon name="ios-trash" />
                <Text uppercase={false}>
                  {I18n.t("taskDetails.actions.cancel")}
                </Text>
              </Button>
            )}
          </View>
        </Content>
      );
    }
  }
);

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 5,
    height: "100%",
    paddingVertical: 20,
    paddingTop: 20
  },
  buttonStyle: {
    marginVertical: 5
  }
});
